import React, { FC } from 'react';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { TickIcon, CrossIcon } from 'app/icons';

const EditNameFormSchema = Yup.object().shape({
  name: Yup.string().trim().required('This field is required'),
});

interface EditNameFormProps {
  initialValues: any;
  onSubmit: (values: any) => void;
  formClass?: string;
  onClose: () => void;
}

const EditNameForm: FC<EditNameFormProps> = (props) => {
  return (
    <>
      <Formik
        initialValues={props.initialValues}
        enableReinitialize
        validationSchema={EditNameFormSchema}
        onSubmit={props.onSubmit}
      >
        <Form>
          <div>
            <div className='flex items-center gap-3'>
              <Field
                name='name'
                type='text'
                id='name'
                className='form-control md:w-full'
              />

              <button
                className='inline-block ml-2 h-4 w-4 p-0 border-none cursor-pointer text-success '
                type='submit'
              >
                <TickIcon width={24} height={24} />
              </button>

              <button
                className='inline-block ml-2 h-4 w-4 p-0 border-none cursor-pointer text-danger '
                type='button'
                onClick={props.onClose}
              >
                <CrossIcon width={24} height={24} />
              </button>
            </div>
            <div className='form-error'>
              <ErrorMessage name='name' />
            </div>
          </div>
        </Form>
      </Formik>
    </>
  );
};

export default EditNameForm;
