import { useQuery } from '@tanstack/react-query';
import Spinner from 'app/components/Spinner/Spinner';
import StatusBadge from 'app/components/StatusBadge/StatusBadge';
import http from 'app/config/http';
import useDialog from 'app/hooks/use-dialog';
import { TRootState } from 'app/store';
import { ISubAgencyUser } from 'app/types';
import { formatCurrency } from 'app/utils/currency-utils';
import moment from 'moment';
import { FC } from 'react';
import { useSelector } from 'react-redux';
import RemarkListModal from '../RemarkListModal/RemarkListModal';

const getStatusClass = (status: string) => {
  switch (status) {
    case 'PENDING':
      return 'yellow';
    case 'APPROVED':
      return 'green';
    case 'REJECTED':
      return 'red';
  }
};

const getStatusText = (status: string) => {
  switch (status) {
    case 'PENDING':
      return 'Pending';
    case 'APPROVED':
      return 'Approved';
    case 'REJECTED':
      return 'Rejected';
  }
};

const getMethodText = (method: string) => {
  switch (method) {
    case 'BANK_TRANSFER':
      return 'Bank Transfer';
    case 'CHEQUE_OR_CASH':
      return 'Cheque / Cash';
    case 'RAZORPAY_GATEWAY':
      return 'Online';
  }
};

interface IAgentRemark {
  comment: string;
  createdAt: string;
  by: ISubAgencyUser;
}

interface PaymentsProps {}

const Payments: FC<PaymentsProps> = (props) => {
  const remarkListDialog = useDialog<IAgentRemark[]>([]);
  const paymentQuery = useQuery(['payments'], async () => {
    const { data } = await http.get(
      `${process.env.REACT_APP_API_URL}/api/v1/sub-agent/payments`,
    );
    return data;
  });

  const payments = paymentQuery.data;

  const { user } = useSelector((state: TRootState) => state.auth);

  return (
    <div className='mt-8'>
      <table className='w-full min-w-[800px]'>
        <thead>
          <tr>
            <td className='bg-[#F9FAFB] text-sm font-semibold py-5 pl-10'>
              Request ID
            </td>
            <td className='bg-[#F9FAFB] text-sm font-semibold py-5'>
              Requested By
            </td>
            <td className='bg-[#F9FAFB] text-sm font-semibold py-5'>
              Payment Method
            </td>
            <td className='bg-[#F9FAFB] text-sm font-semibold py-5'>Amount</td>
            <td className='bg-[#F9FAFB] text-sm font-semibold py-5'>
              Transaction Date
            </td>
            <td className='bg-[#F9FAFB] text-sm font-semibold py-5'>
              Agent Remark
            </td>
            <td className='bg-[#F9FAFB] text-sm font-semibold py-5'>{`${user.agency.name} Remarks`}</td>
            <td className='bg-[#F9FAFB] text-sm font-semibold py-5 pr-10'>
              Created At
            </td>
          </tr>
        </thead>
        <tbody>
          {!paymentQuery.isLoading && payments.length === 0 && (
            <tr>
              <td
                align='center'
                colSpan={10}
                className='py-12 font-medium text-base text-[#98A2B3]'
              >
                - No data available -
              </td>
            </tr>
          )}
          {!paymentQuery.isLoading &&
            payments.map((row: any, i: number) => (
              <tr
                key={row._id}
                className='border-b border-gray-200 last:border-none'
              >
                <td className='text-sm font-normal py-4 pl-10'>
                  <div className='font-semibold'>{row._id}</div>
                  <StatusBadge
                    className='mt-1'
                    color={getStatusClass(row.status)}
                  >
                    {getStatusText(row.status)}
                  </StatusBadge>
                  {/* <Tooltip
                                        content={
                                            row.status !== 'PENDING' ? (
                                                <div className="px-4 py-3">
                                                    <p className="text-xs">{getStatusText(row.status)} By</p>
                                                    <div className="text-sm font-semibold mt-1">
                                                        {row.method === 'RAZORPAY_GATEWAY'
                                                            ? 'Farepod Pay'
                                                            : row.actionBy?.name}
                                                    </div>
                                                </div>
                                            ) : null
                                        }
                                        placement="bottom-start"
                                    >
                                        <StatusBadge className="mt-1" color={getStatusClass(row.status)}>
                                            {getStatusText(row.status)}
                                        </StatusBadge>
                                    </Tooltip> */}
                </td>
                <td className='text-sm font-normal py-4'>
                  <div className='font-semibold'>{row.requestedBy.name}</div>
                </td>
                <td className='text-sm font-normal py-4'>
                  <div className='flex flex-col items-center'>
                    <div className='font-semibold'>
                      {getMethodText(row.method)}
                    </div>
                    {row.method === 'BANK_TRANSFER' && (
                      <div className='flex flex-col items-center text-center text-slate-500 text-[.7rem]'>
                        <div className=''>{row.details?.bank?.name}</div>
                        <div>{row.details?.bank?.account?.number}</div>
                      </div>
                    )}
                  </div>
                </td>
                <td className='text-sm font-normal py-4'>
                  <div className='font-semibold whitespace-nowrap'>
                    {formatCurrency(row.amount)}
                  </div>
                </td>
                <td className='text-sm font-normal py-4'>
                  <div className='font-semibold'>
                    {moment(row.details.transactionDate, 'YYYYMMDD').format(
                      'DD MMM, YYYY',
                    )}
                  </div>
                </td>
                <td className='text-sm font-normal py-4'>
                  <div className='font-semibold'>
                    {row.subAgentRemark || '-'}
                  </div>
                </td>
                <td className='text-sm font-normal py-4'>
                  <div
                    className='font-semibold text-blue-500 cursor-pointer'
                    onClick={() => remarkListDialog.show(row.agentRemarks)}
                  >
                    View
                  </div>
                </td>
                <td className='text-sm font-normal py-4 pr-10'>
                  <div className='min-w-max'>
                    <p>
                      {moment(row.createdAt, 'YYYYMMDDHHmmss').format(
                        'DD MMM, YYYY',
                      )}
                    </p>
                    <p>
                      {moment(row.createdAt, 'YYYYMMDDHHmmss').format(
                        'hh:mm A',
                      )}
                    </p>
                  </div>
                </td>
              </tr>
            ))}
        </tbody>
      </table>
      {paymentQuery.isLoading && (
        <div className='flex justify-center items-center min-h-[300px] w-full'>
          <Spinner />
        </div>
      )}

      <RemarkListModal
        show={remarkListDialog.isOpen}
        onClose={remarkListDialog.hide}
        remarks={remarkListDialog.data}
      />
    </div>
  );
};

export default Payments;
