import { Dispatch, FC, SetStateAction } from 'react';
import { useSelector } from 'react-redux';
import { TRootState } from 'app/store';

interface AccountingNavProps {
  activeTab: string;
  setActiveTab: Dispatch<SetStateAction<string>>;
}

const AccountingNav: FC<AccountingNavProps> = (props) => {
  const { activeTab, setActiveTab } = props;
  const { user } = useSelector((state: TRootState) => state.auth);
  return (
    <ul className='flex text-lg font-normal px-9 '>
      <li className='tab-item'>
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <a
          className={`nav-tab  ${
            activeTab === '1'
              ? 'font-bold cursor-default text-primary underline underline-offset-4'
              : ''
          }`}
          onClick={() => setActiveTab('1')}
        >
          Agency Balance
        </a>
      </li>

      <li className='tab-item'>
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <a
          className={`nav-tab   ${
            activeTab === '2'
              ? 'font-bold cursor-default text-primary underline underline-offset-4'
              : ''
          }`}
          onClick={() => setActiveTab('2')}
        >
          {/* {`View ${user.agency.name} bank account details`} */}
          {`${user.agency.name} Bank Details`}
        </a>
      </li>
    </ul>
  );
};

export default AccountingNav;
