import { FC, ReactNode } from 'react';
import { createPortal } from 'react-dom';
import { AnimatePresence, motion } from 'framer-motion';
import { CrossIcon } from 'app/icons';

const modalAnimation = {
  variants: {
    hidden: {
      opacity: 0,
      y: '-100vh',
    },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.1,
        type: 'spring',
        damping: 25,
        stiffness: 250,
      },
    },
    exit: {
      y: '-100vh',
      opacity: 0,
    },
  },
  initial: 'hidden',
  animate: 'visible',
  exit: 'exit',
};

interface ModalProps {
  show: boolean;
  children: ReactNode;
  onClose: () => void;
  onBackdropClick?: () => void;
  className?: string;
  showCloseIcon?: boolean;
}

const Modal: FC<ModalProps> = (props) => {
  const { show, onBackdropClick, onClose, className, children, showCloseIcon } =
    props;

  return createPortal(
    <AnimatePresence initial={false} mode='wait'>
      {show && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className={
            'fixed top-0 left-0 w-full h-full flex justify-center items-center z-[997] bg-black/40'
          }
          onClick={onBackdropClick || onClose}
        >
          <div
            className={
              'fixed left-0 top-0 w-full h-full overflow-x-hidden overflow-y-auto z-[998]'
            }
          >
            <div
              className={
                'flex items-center justify-center w-auto m-4 min-h-[calc(100%-2rem)]'
              }
            >
              <motion.div
                {...modalAnimation}
                className={`relative card px-10 py-8 z-[9999] max-w-full overflow-hidden w-[960px] rounded-xl ${className}`}
                onClick={(e) => e.stopPropagation()}
              >
                {/* <button
                                    type="button"
                                    className="absolute top-4 right-3 w-7 h-7 flex justify-center items-center text-gray-400 border-none cursor-pointer z-[999]"
                                    onClick={onClose}
                                >
                                    <CrossIcon width={20} height={20} />
                                </button> */}
                {showCloseIcon && (
                  <button
                    type='button'
                    className='bg-white rounded-full  absolute top-4 right-3 w-6 h-6 flex justify-center items-center text-gray-400 border-none cursor-pointer z-[999]'
                    onClick={onClose}
                  >
                    <CrossIcon width={16} height={16} />
                  </button>
                )}

                <div>{children}</div>
              </motion.div>
            </div>
          </div>
        </motion.div>
      )}
    </AnimatePresence>,
    document.getElementById('modal-root'),
  );
};

Modal.defaultProps = {
  showCloseIcon: true,
};

export default Modal;
