import { formatCurrency } from 'app/utils/currency-utils';
import React, { FC } from 'react';

interface ISubAgencyBalanceAndPay {
  availableBalance: number;
  handlePayment: () => void;
}

const SubAgencyBalanceAndPay: FC<ISubAgencyBalanceAndPay> = (props) => {
  const { availableBalance, handlePayment } = props;
  return (
    <div className='card px-8 py-7 mb-4'>
      <div className='text-xl font-bold'>Agency Balance</div>
      <div className='mt-8 text-sm'>Available Balance</div>

      <div className='mt-0 flex items-center'>
        <div className='text-lg font-semibold whitespace-nowrap '>
          {formatCurrency(availableBalance)}
        </div>
        <button
          className='btn btn-primary ml-9'
          //onClick={() => reviewDialog.show(PaymentTypeEnum.CREDIT)}
          onClick={handlePayment}
        >
          Review and Pay
        </button>
      </div>
    </div>
  );
};

export default SubAgencyBalanceAndPay;
