import { useMutation, useQuery } from '@tanstack/react-query';
import LoaderButton from 'app/components/LoaderButton/LoaderButton';
import PageLoader from 'app/components/PageLoader/PageLoader';
import { TRootState } from 'app/store';
import { authActions } from 'app/store/auth';
import axios from 'axios';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createSearchParams, Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { ILoginForm } from './types';
import loginImage from 'assets/images/login-1.jpeg';
import styles from './Login.module.scss';
import { hexToRgb } from 'app/utils/color-utils';
import Color from 'color';
import Tooltip from 'app/components/Tooltip/Tooltip';
import * as Yup from 'yup';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Navigation, Pagination } from 'swiper';
import OtpField from 'react-otp-field';
import { BackIcon } from 'app/icons';
import StaticNav from 'app/components/StaticNav/StaticNav';
import { IStaticPage } from 'app/types';
import http from 'app/config/http';
import Cookies from 'js-cookie';
import { IMetaTag } from 'app/types/meta-tag';
import { Helmet } from 'react-helmet';

const initialValues: ILoginForm = {
  username: '',
  password: '',
  rememberMe: true,
};

const loginSchema = Yup.object({
  username: Yup.string()
    // .trim()
    .required('This field is required')
    .test('no-space-in-username', 'Spaces are not allowed.', (value) => {
      let result = true;
      for (let i = 0; i < (value ? value.length : 0); i++) {
        if (value[i] === ' ') {
          result = false;
          break;
        }
      }

      return result;
    }),
  password: Yup.string()
    // .trim()
    .required('This field is required')
    .test('no-space-in-password', 'Spaces are not allowed.', (value) => {
      let result = true;
      for (let i = 0; i < (value ? value.length : 0); i++) {
        if (value[i] === ' ') {
          result = false;
          break;
        }
      }

      return result;
    }),
  rememberMe: Yup.mixed().required('This field is required'),
});

interface LoginProps {}

type PageRenderType =
  | 'CREDENTIAL_PAGE'
  | 'OTP_VERIFY_PAGE'
  | 'TRUST_DEVICE_PAGE';

const Login: FC<LoginProps> = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loginResponse, setLoginResponse] = useState({
    email: '',
    _id: '',
  });

  const [renderPage, setRenderPage] =
    useState<PageRenderType>('CREDENTIAL_PAGE');

  const [data, setData] = useState({
    name: '',
    email: '',
    phoneNumber: '',
    logo: '',
    tagline: '',
    loginImages: [loginImage],
    staticPages: [],
    metas: [],
  });

  const loginQuery = useQuery(
    ['loginDetails'],
    async () => {
      const { data } = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/v1/sub-agent/auth/details`,
        {
          domain: window.location.hostname,
          // domain: 'sub-agent.farepod.co',  // example is here
        },
      );

      return data;
    },
    {
      retry: 0,
    },
  );

  const { token } = useSelector((state: TRootState) => state.auth);

  const loginMutation = useMutation(async (values: any) => {
    const myHeaders: any = {};

    let almond = Cookies.get('almond');
    if (almond) {
      myHeaders.almond = almond;
    }

    const { data } = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/v1/sub-agent/auth/authenticate`,
      values,
      {
        headers: {
          ...myHeaders,
        },
      },
    );

    return data;
  });

  const trustedDeviceMutation = useMutation(async () => {
    const res = await http.post(
      `${process.env.REACT_APP_API_URL}/api/v1/sub-agent/auth/trusted-device`,
    );

    const almond = res.headers['almond'];
    return { almond };
  });

  useEffect(() => {
    if (token) {
      navigate('/', { replace: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Set Data
  useEffect(() => {
    if (loginQuery.data) {
      const {
        name,
        email,
        phoneNumber,
        logoUrl,
        iconUrl,
        tagline,
        primaryColor,
        secondaryColor,
        loginImages,
        staticPages,
        metas,
      } = loginQuery.data;

      const loginImageUrls = loginImages.map((l: any) => l.url);

      setData({
        name: name,
        logo: logoUrl,
        tagline: tagline,
        loginImages:
          loginImageUrls.length > 0 ? loginImageUrls : data.loginImages,
        email: email,
        phoneNumber: phoneNumber,
        staticPages: staticPages,
        metas: metas,
      });

      // set css color variables
      document.documentElement.style.setProperty(
        '--primary',
        hexToRgb(primaryColor),
      );
      document.documentElement.style.setProperty(
        '--secondary',
        hexToRgb(secondaryColor),
      );

      const secondaryColorObj = Color(secondaryColor);
      if (secondaryColorObj.isLight()) {
        document.documentElement.style.setProperty('--secondary-fg', '0 0 0');
      }

      // set title
      document.title = `${name} ${tagline.trim().length > 0 ? '-' : ''}  ${tagline}`;

      // set favicon
      const link = document.querySelector("link[rel~='icon']");
      link.setAttribute('href', iconUrl);
    }
    if (loginQuery.isError) {
      setData({
        name: 'Farepod',
        email: '',
        phoneNumber: '',
        logo: '/icons/logo.svg',
        tagline: 'Best fares. Always.',
        loginImages: [loginImage],
        staticPages: [],
        metas: [],
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loginQuery.data, dispatch, loginQuery.isError]);

  // trustedDeviceMutation;
  const handleTrustDevice = async () => {
    try {
      const { almond } = await trustedDeviceMutation.mutateAsync();
      Cookies.set('almond', almond, { expires: 500000 });
    } catch (err: any) {
      toast.error(err.response.data.message);
    } finally {
      navigate(
        {
          pathname: '/',
          search: createSearchParams({
            showAnnoucement: 'true',
          }).toString(),
        },
        {
          replace: true,
        },
      );
    }
  };

  const handleSubmit = async (values: ILoginForm) => {
    if (!values.username || !values.password) {
      toast.error('Invalid Credentials.');
      return;
    }

    try {
      const data = await loginMutation.mutateAsync({
        username: values.username,
        password: values.password,
        domain: window.location.hostname,
      });

      //we need to validate this browser
      if (data?.isVerified === false) {
        const { _id, email } = data;
        setLoginResponse({
          email,
          _id,
        });
        setRenderPage('OTP_VERIFY_PAGE');
        return;
      }

      // the device is trusted and we got the tokens seth auth and goto homepage

      dispatch(
        authActions.login({ token: data.token, rememberMe: values.rememberMe }),
      );
      navigate(
        {
          pathname: '/',
          search: createSearchParams({
            showAnnoucement: 'true',
          }).toString(),
        },
        {
          replace: true,
        },
      );
    } catch (err: any) {
      toast.error(err.response.data.message);
    }
  };

  const otpVerifyMutation = useMutation(async (values: any) => {
    const { data } = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/v1/sub-agent/auth/two-factor-validatation/${loginResponse._id}`,
      values,
    );

    return data;
  });

  const handleOtpVerification = async (otp: string) => {
    try {
      const { token } = await otpVerifyMutation.mutateAsync({
        otp,
      });

      dispatch(authActions.login({ token: token, rememberMe: true }));
      setRenderPage('TRUST_DEVICE_PAGE');
    } catch (err: any) {
      toast.error(err.response.data.message);
    }
  };

  if (loginQuery.isLoading) return <PageLoader color='#000000' />;

  const selectComponent = (selectedPage: PageRenderType) => {
    let selctedComponent = (
      <CredentialPage
        data={data}
        handleSubmit={handleSubmit}
        isLoading={loginMutation.isLoading}
      />
    );

    switch (selectedPage) {
      case 'CREDENTIAL_PAGE':
        selctedComponent = (
          <CredentialPage
            data={data}
            handleSubmit={handleSubmit}
            isLoading={loginMutation.isLoading}
          />
        );
        break;
      case 'OTP_VERIFY_PAGE':
        selctedComponent = (
          <OtpVerify
            data={data}
            isLoading={otpVerifyMutation.isLoading}
            handleSubmit={handleOtpVerification}
            crypticeEmail={loginResponse.email}
          />
        );
        break;
      case 'TRUST_DEVICE_PAGE':
        selctedComponent = (
          <TrustDevice
            data={data}
            handleTrustDevice={handleTrustDevice}
            isLoading={trustedDeviceMutation.isLoading}
          />
        );
        break;
      default:
        selctedComponent = (
          <CredentialPage
            data={data}
            handleSubmit={handleSubmit}
            isLoading={loginMutation.isLoading}
          />
        );
        break;
    }
    return selctedComponent;
  };

  return <>{selectComponent(renderPage)}</>;
};

interface CredentialPageProps {
  data: {
    name: string;
    email: string;
    phoneNumber: string;
    logo: string;
    tagline: string;
    loginImages: string[];
    staticPages: IStaticPage[];
    metas: IMetaTag[];
  };
  handleSubmit: (values: ILoginForm) => Promise<void>;
  isLoading: boolean;
}

const CredentialPage: FC<CredentialPageProps> = (props) => {
  const { data, handleSubmit, isLoading } = props;

  return (
    <div className='min-h-screen bg-secondary relative'>
      <Helmet>
        {data.metas.map((meta) => (
          <meta name={meta.name} content={meta.content} />
        ))}
      </Helmet>

      <div className='absolute top-0 left-0 h-2/6 bg-[#F1F0F0] w-screen'></div>
      <div className='px-8 py-4 mx-auto max-w-[1200px] w-full relative'>
        <div className='flex justify-center pt-8'>
          <img src={data.logo} className='h-12' alt='' />
        </div>

        <div className='flex items-end  gap-0 md:gap-6 sm:-mr-10  '>
          <div
            className={`${styles['card-shadow']} card rounded-lg grid grid-cols-2 gap-3 overflow-hidden mt-10 w-full`}
          >
            <div className=' col-span-2 px-8 py-10   md:col-span-1 md:px-12 md:py-10'>
              {/* Form Heading */}
              <h3 className='font-bold'>Sign in</h3>
              <div className='text-base text-gray-800 font-light mt-3'>
                Please enter the following details to sign in
              </div>
              {/* Form */}
              <div className='mt-8'>
                <Formik
                  initialValues={initialValues}
                  validationSchema={loginSchema}
                  enableReinitialize
                  onSubmit={handleSubmit}
                >
                  <Form>
                    <div className='relative'>
                      <label
                        htmlFor='user'
                        className='absolute top-2 block px-4 text-sm text-gray-500'
                      >
                        Email or Username
                      </label>
                      <Field
                        id='user'
                        name='username'
                        className='border-2 border-[#ECE9E9] w-full pb-2 px-4 pt-7 rounded-md'
                        type='text'
                      />
                      <div className='form-error'>
                        <ErrorMessage name='username' />
                      </div>
                    </div>

                    <div className='relative mt-6'>
                      <label
                        htmlFor='password'
                        className='absolute top-2 block px-4 text-sm text-gray-500'
                      >
                        Password
                      </label>
                      <Field
                        id='password'
                        name='password'
                        className='border-2 border-[#ECE9E9] w-full pb-2 px-4 pt-7 rounded-md'
                        type='password'
                      />
                      <div className='form-error'>
                        <ErrorMessage name='password' />
                      </div>
                    </div>

                    <div className='flex flex-col gap-4  sm:flex-row sm:justify-between text-sm font-light mt-8'>
                      <label htmlFor='rememberMe' className='hidden'>
                        <Field
                          id='rememberMe'
                          name='rememberMe'
                          type='checkbox'
                          className='form-checkbox mr-2'
                        />{' '}
                        Remember me
                      </label>
                      <div>
                        <Link to={'/password/forget'}>Forgot password?</Link>
                      </div>
                    </div>

                    <LoaderButton
                      type='submit'
                      className='w-full py-4 mt-8 bg-primary rounded-lg text-white'
                      isLoading={isLoading}
                    >
                      Login
                    </LoaderButton>
                  </Form>
                </Formik>
              </div>

              <div className='flex justify-center flex-wrap text-base font-medium mt-10'>
                {data.email !== '' && (
                  <>
                    <span>Not an agent?&nbsp;</span>
                    <span className='text-primary underline'>
                      <Link to={'/register'}>Register</Link>
                    </span>
                    &nbsp; or &nbsp;
                    <Tooltip
                      content={
                        <div className='px-6 py-1'>
                          <ul className='divide-y divide-gray-200'>
                            <li className='py-3'>
                              <div className='text-[0.7rem] font-light text-right'>
                                {data.email}
                              </div>
                            </li>
                            <li className='py-3'>
                              <div className='text-[0.7rem] font-light text-right'>
                                {data.phoneNumber}
                              </div>
                            </li>
                          </ul>
                        </div>
                      }
                      placement='bottom-end'
                    >
                      <span className='text-primary underline '>
                        {' '}
                        Contact Us{' '}
                      </span>
                    </Tooltip>
                  </>
                )}
              </div>
            </div>
            <div className='col-span-1  hidden sm:block '>
              <Swiper
                spaceBetween={30}
                centeredSlides={true}
                autoplay={{
                  delay: 2500,
                  disableOnInteraction: false,
                }}
                pagination={{
                  clickable: false,
                }}
                // navigation={false}
                modules={[Autoplay, Pagination, Navigation]}
                className='w-full h-full'
              >
                {data.loginImages.map((img: string) => (
                  <SwiperSlide key={img}>
                    <div
                      className='w-full h-full bg-cover bg-right-top bg-no-repeat'
                      style={{ backgroundImage: `url(${img})` }}
                    />
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          </div>

          <div
            className={`${styles['vertical-text']} text-3xl font-bold text-secondary-fg  hidden sm:block `}
          >
            {data.tagline}
          </div>
        </div>
      </div>

      <StaticNav staticPages={data.staticPages} name={data.name} />
    </div>
  );
};
interface OtpVerifyProps {
  data: {
    name: string;
    email: string;
    phoneNumber: string;
    logo: string;
    tagline: string;
    loginImages: string[];
    staticPages: IStaticPage[];
  };
  handleSubmit: (otp: string) => void;
  isLoading: boolean;
  crypticeEmail: string;
}

const OtpVerify: FC<OtpVerifyProps> = (props) => {
  const { data, handleSubmit, isLoading, crypticeEmail } = props;
  const [otp, setOtp] = useState('');
  const navigate = useNavigate();

  return (
    <div className='min-h-screen bg-secondary relative'>
      <div className='absolute top-0 left-0 h-2/6 bg-[#F1F0F0] w-screen'></div>
      <div className='px-8 py-4 mx-auto max-w-[1200px] w-full relative'>
        <div className='flex justify-center pt-8'>
          <img
            src={data.logo}
            className='h-12'
            alt=''
            onClick={() => {
              navigate('/', { replace: true });
            }}
          />
        </div>

        <div className='flex justify-center   gap-0 md:gap-6   '>
          <div
            className={`${styles['card-shadow']} card rounded-lg  gap-3 overflow-hidden mt-10 w-[600px]`}
          >
            <div className=' px-10 py-10   '>
              {/* Form Heading */}
              <div className='mb-5'>
                <Link
                  className='inline-flex items-center font-semibold gap-2 text-lg cursor-pointer text-gray-900'
                  to='/'
                >
                  <BackIcon height={12} />
                  <span className='ms-2'>Back</span>
                </Link>
              </div>

              <div>
                <h3 className='font-bold'>Verify your sign in</h3>
                <div className='text-base text-gray-800 font-light mt-3'>
                  {/* Please enter the following details to reset your password */}
                  {`We’ve sent you a confirmation code on ${crypticeEmail}`}
                </div>
                {/* <Otp /> */}
                <div className='mt-8 flex justify-center'>
                  <OtpField
                    classNames='flex items-center gap-2 sm:gap-5'
                    value={otp}
                    numInputs={6}
                    onChange={(val: any) => setOtp(val)}
                    autoFocus={true}
                    inputProps={{
                      className:
                        'border border-gray-200 focus:border-primary rounded-lg h-8 w-8  sm:w-12 sm:h-12 text-center font-semibold text-lg text-gray-900 outline-none',
                      disabled: false,
                    }}
                    isTypeNumber={true}
                  />
                </div>

                <div className='mt-20'>
                  <LoaderButton
                    disabled={otp.length !== 6}
                    className='bg-primary text-white text-sm font-semibold rounded-lg w-full '
                    onClick={() => handleSubmit(otp)}
                    isLoading={isLoading}
                  >
                    Verify sign in
                  </LoaderButton>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <StaticNav staticPages={data.staticPages} name={data.name} />
    </div>
  );
};

interface TrustDeviceProps {
  data: {
    name: string;
    email: string;
    phoneNumber: string;
    logo: string;
    tagline: string;
    loginImages: string[];
    staticPages: IStaticPage[];
  };
  handleTrustDevice: () => void;
  isLoading: boolean;
}

const TrustDevice: FC<TrustDeviceProps> = (props) => {
  const { data, handleTrustDevice, isLoading } = props;
  const navigate = useNavigate();

  const handleNotSecure = () => {
    navigate(
      {
        pathname: '/',
        search: createSearchParams({
          showAnnoucement: 'true',
        }).toString(),
      },
      {
        replace: true,
      },
    );
  };

  return (
    <div className='min-h-screen bg-secondary relative'>
      <div className='absolute top-0 left-0 h-2/6 bg-[#F1F0F0] w-screen'></div>
      <div className='px-8 py-4 mx-auto max-w-[1200px] w-full relative'>
        <div className='flex justify-center pt-8'>
          <img
            src={data.logo}
            className='h-12'
            alt=''
            onClick={() => {
              navigate('/', { replace: true });
            }}
          />
        </div>

        <div className='flex justify-center   gap-0 md:gap-6   '>
          <div
            className={`${styles['card-shadow']} card rounded-lg  gap-3 overflow-hidden mt-10 w-[600px]`}
          >
            <div className=' px-10 py-10   '>
              {/* Form Heading */}
              <div className='mb-5'>
                {/* <Link
                                    className="inline-flex items-center font-semibold gap-2 text-lg cursor-pointer text-gray-900"
                                    to="/"
                                >
                                    <BackIcon height={12} />
                                    <span className="ms-2">Back</span>
                                </Link> */}
              </div>

              <div>
                <h3 className='font-bold text-center'>Trust this browser?</h3>

                <div className='mt-6 flex justify-center'>
                  <div>
                    Should you choose to trust this browser, you will not be
                    asked for a verification code the next time you sign in
                  </div>
                </div>

                {/* <div className="mt-20">
                                    <LoaderButton
                                        disabled={otp.length !== 6}
                                        className="bg-primary text-white text-sm font-semibold rounded-lg w-full "
                                        onClick={() => handleSubmit(otp)}
                                        isLoading={isLoading}
                                    >
                                        Verify sign in
                                    </LoaderButton>
                                </div> */}

                <div className='mt-20'>
                  <div className='flex justify-between items-center'>
                    <button onClick={handleNotSecure}>Not now</button>

                    <div className='flex gap-4'>
                      <LoaderButton
                        isLoading={isLoading}
                        onClick={handleTrustDevice}
                        className='bg-primary text-white text-sm font-semibold rounded-lg px-4 py-2 '
                      >
                        Trust
                      </LoaderButton>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <StaticNav staticPages={data.staticPages} name={data.name} />
    </div>
  );
};

export default Login;
