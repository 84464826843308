import { FC, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { TRootState } from 'app/store';
import { useSelector } from 'react-redux';
import { formatCurrency } from 'app/utils/currency-utils';
import { SwapIcon } from 'app/icons';
import { IFlightSearchForm } from 'app/types';
import * as Yup from 'yup';
import { ErrorMessage, Field, Form, Formik, FormikProps } from 'formik';
import AirportSearchFieldMobile from 'app/components/AirportSearchField/AirportSearchFieldMobile';
import { DatePicker } from 'app/library/datepicker';
import { useQuery } from '@tanstack/react-query';
import http from 'app/config/http';
import PageLoader from 'app/components/PageLoader/PageLoader';
import { generateNumberArray } from 'app/utils/list-utils';
import { createSearchParams, useNavigate } from 'react-router-dom';
import moment from 'moment';
import { toast } from 'react-toastify';
import { useMediaQuery } from 'react-responsive';
import useDialog from 'app/hooks/use-dialog';
import AnnouncementModal from '../Home/components/AnnouncementModal/AnnouncementModal';
import MobileTopBar from 'app/components/MobileTopBar/MobileTopBar';

const cabinClass = [
  {
    key: 'Economy',
    value: 'ECONOMY',
  },
  {
    key: 'Premium Economy',
    value: 'PREMIUM_ECONOMY',
  },
  {
    key: 'Business',
    value: 'BUSINESS',
  },
  {
    key: 'First',
    value: 'FIRST',
  },
];

const SearchFormSchema = Yup.object({
  passengers: Yup.object({
    adult: Yup.number().min(1).max(9),
    child: Yup.number().min(0).max(8),
    infant: Yup.number().min(0).max(8),
  }),
  from: Yup.object().nullable().required('This field is required'),
  to: Yup.object()
    .nullable()
    .required('This field is required')
    .test('not same', 'Please select different to and from', function (to) {
      const { from } = this.parent;

      if (to === null || from === null) {
        return false;
      }

      return from.code !== to.code;
    }),
  travelDate: Yup.date().required('This field is required'),
  isDirectFlight: Yup.boolean().required('This field is required'),
  cabinClass: Yup.string().required('This field is required'),
});

const initialValues: IFlightSearchForm = {
  passengers: {
    adult: 1,
    child: 0,
    infant: 0,
  },
  from: null,
  to: null,
  travelDate: new Date(),
  isDirectFlight: false,
  cabinClass: cabinClass[0].value,
};

interface MobileSearchProps {}

const MobileSearch: FC<MobileSearchProps> = (props) => {
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });
  const navigate = useNavigate();
  const { user } = useSelector((state: TRootState) => state.auth);
  const annoucementDialog = useDialog();
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (!isMobile) {
      navigate('/');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMobile]);

  const { creditLimit, availableCashBalance, availableCredit } = useSelector(
    (state: TRootState) => state.account,
  );

  let availableBalance = availableCredit + availableCashBalance;
  let amountDue = creditLimit - availableCredit - availableCashBalance;

  if (availableBalance < 0) {
    availableBalance = 0;
  }

  if (amountDue < 0) {
    amountDue = 0;
  }

  const handleSwapAirport = (
    values: IFlightSearchForm,
    setFieldValue: (
      field: string,
      value: any,
      shouldValidate?: boolean,
    ) => void,
  ) => {
    const fromAirport = values.from;
    const toAirport = values.to;

    setFieldValue('from', toAirport, false);
    setFieldValue('to', fromAirport, false);
  };

  const handleFlightSubmit = (values: IFlightSearchForm) => {
    const totalPassenger = +values.passengers.adult + +values.passengers.child;

    if (+values.passengers.infant > +values.passengers.adult) {
      toast.error('Number of Infants cannot be greater than the Adults');
      return;
    }
    if (totalPassenger > 9) {
      toast.error('Max passengers limit is 9 (Adult + Child)');
      return;
    }

    // const cabinClass = 'ECONOMY';

    const { adult, child, infant } = values.passengers;
    const { from, to, travelDate, isDirectFlight, cabinClass } = values;

    const departureDate = moment(travelDate).format('YYYYMMDD');

    navigate({
      pathname: '/flights',
      search: createSearchParams({
        cabinClass: cabinClass,
        fromCode: from.code,
        fromName: from.name,
        fromCityCode: from.cityCode,
        fromCity: from.city,
        fromCountry: from.country,
        fromCountryCode: from.countryCode,
        toCode: to.code,
        toName: to.name,
        toCityCode: to.cityCode,
        toCity: to.city,
        toCountry: to.country,
        toCountryCode: to.countryCode,
        adult: adult.toString(),
        child: child.toString(),
        infant: infant.toString(),
        departureDate: departureDate,
        pft: 'REGULAR',
        isDirectFlight: isDirectFlight.toString(),
        // isConnectingFlight: 'false',
      }).toString(),
    });
  };

  const statsQuery = useQuery(['stats'], async () => {
    const { data } = await http.get(
      `${process.env.REACT_APP_API_URL}/api/v1/sub-agent/sub-agency/stats`,
    );

    return data;
  });

  useEffect(() => {
    if (Boolean(searchParams.get('showAnnoucement')) === true) {
      annoucementDialog.show();
      searchParams.delete('showAnnoucement');
      setSearchParams(searchParams);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  if (statsQuery.isLoading) return <PageLoader />;

  const statsData = statsQuery.data;

  return (
    <>
      <MobileTopBar />

      <div className='bg-secondary text-center py-2 text-base font-semibold '>
        {' '}
        {user.subAgency.name}{' '}
      </div>

      <div className='px-6 py-8 bg-white w-screen'>
        {/* icon and wallet balance */}

        {/* trip type one way and rounded */}
        {/* <div className="mt-6 rounded-l-xl rounded-r-xl border border-[#F9DC02] overflow-hidden flex  text-base leading-5 font-semibold">
                <button className="flex-1 py-3 bg-[#F9DC02] ">One Way</button>
                <button className="flex-1 py-3">Round Trip</button>
            </div> */}

        {/* search form */}
        <div className='mt-6'>
          <Formik
            initialValues={initialValues}
            enableReinitialize={false}
            validationSchema={SearchFormSchema}
            onSubmit={handleFlightSubmit}
          >
            {({ values, setFieldValue }: FormikProps<IFlightSearchForm>) => (
              <Form>
                <div className='flex gap-8  border-b relative '>
                  <div className='flex-1'>
                    <label
                      className='text-xs font-normal text-[#52525B] mb-5'
                      htmlFor='from'
                    >
                      From {values.from && ` - ${values.from.cityCode}`}
                    </label>
                    <AirportSearchFieldMobile
                      // className="form-field"
                      className='outline-none'
                      placeholder='Select From Airport'
                      selectedAirport={values.from}
                      onSelectedAirportChange={(airport: any) =>
                        setFieldValue('from', airport)
                      }
                    />
                    <div className='form-error text-secondary-fg/60'>
                      <ErrorMessage name='from' />
                    </div>
                  </div>
                  <div className='absolute  left-[50%] -translate-x-[50%] z-[9]'>
                    <div
                      className=' text-blue-500 w-10 h-10 rounded-full flex justify-center items-center mx-auto cursor-pointer border-2 border-blue-500  '
                      onClick={() => handleSwapAirport(values, setFieldValue)}
                    >
                      <SwapIcon height={16} />
                    </div>
                  </div>
                  <div className='flex-1 text-right'>
                    <label
                      className='text-xs font-normal text-[#52525B] mb-5'
                      htmlFor='from'
                    >
                      To {values.to && ` - ${values.to.cityCode}`}
                    </label>
                    <AirportSearchFieldMobile
                      // className=" text-right  "
                      className='outline-none text-right w-full '
                      placeholder='Select to Airport'
                      selectedAirport={values.to}
                      onSelectedAirportChange={(airport: any) =>
                        setFieldValue('to', airport)
                      }
                      rightIndent
                    />
                    <div className='form-error text-secondary-fg/60 text-right'>
                      <ErrorMessage name='to' />
                    </div>
                  </div>
                </div>
                <div className='flex  border-b mt-5'>
                  <div className='flex-1'>
                    <label
                      className='text-xs font-normal text-[#52525B] mb-5'
                      htmlFor='from'
                    >
                      Depart on
                    </label>
                    <DatePicker
                      className='bg-white cursor-pointer text-xl leading-10 font-semibold'
                      onChange={(val) => setFieldValue('travelDate', val)}
                      value={values.travelDate}
                      minDate={new Date()}
                      format='DD MM YYYY'
                    />
                  </div>
                  {/* <div className="flex-1 text-right">
                                    <label className="text-xs font-normal text-[#52525B] mb-5" htmlFor="from">
                                        Return
                                    </label>
                                    <DatePicker
                                        className="bg-white cursor-pointer text-xl leading-10 font-semibold"
                                        onChange={(val) => setFieldValue('travelDate', val)}
                                        value={values.travelDate}
                                        minDate={new Date()}
                                    />
                                </div> */}
                </div>
                <div className='border-b mt-5 w-full'>
                  {/* <div>
                                    <label className="text-xs font-normal text-[#52525B] " htmlFor="from">
                                        Travellers
                                    </label>
                                </div> */}

                  {/* className="form-label text-secondary-fg" */}
                  <div className='grid grid-cols-3 gap-2'>
                    <div className='col-span-1'>
                      <label
                        className='text-xs font-normal text-[#52525B] bg '
                        htmlFor='adults'
                      >
                        Adult
                      </label>
                      <div className='w-full'>
                        <Field
                          as='select'
                          name='passengers.adult'
                          className='form-field bg-white'
                        >
                          {generateNumberArray(1, 9).map((val) => {
                            return (
                              <option key={val} value={val}>
                                {val}
                              </option>
                            );
                          })}
                        </Field>
                        <div className='form-error text-secondary-fg/60'>
                          <ErrorMessage name='passengers.adult' />
                        </div>
                      </div>
                    </div>
                    <div className='col-span-1'>
                      <label
                        className='text-xs font-normal text-[#52525B] '
                        htmlFor='children'
                      >
                        Children
                      </label>
                      <Field
                        as='select'
                        name='passengers.child'
                        className='form-field bg-white'
                      >
                        {generateNumberArray(0, 8).map((val) => {
                          return (
                            <option key={val} value={val}>
                              {val}
                            </option>
                          );
                        })}
                      </Field>
                      <div className='form-error text-secondary-fg/60'>
                        <ErrorMessage name='passengers.child' />
                      </div>

                      <div className='text-right text-xs font-normal text-secondary-fg opacity-50 top-[7em] left-[1em]'>
                        2 - 11 Years
                      </div>
                    </div>
                    <div className='col-span-1'>
                      <label
                        className='text-xs font-normal text-[#52525B]'
                        htmlFor='infant'
                      >
                        Infants
                      </label>

                      <Field
                        as='select'
                        name='passengers.infant'
                        className='form-field bg-white'
                      >
                        {generateNumberArray(0, 8).map((val) => {
                          return (
                            <option key={val} value={val}>
                              {val}
                            </option>
                          );
                        })}
                      </Field>

                      <div className='form-error text-secondary-fg/60'>
                        <ErrorMessage name='passengers.infant' />
                      </div>

                      <div className='text-right text-xs font-normal mt-1 text-secondary-fg opacity-50 top-[7em]'>
                        Below 2 Years
                      </div>
                    </div>
                  </div>
                </div>

                <div className='border-b mt-5 w-full'>
                  <div className='flex gap-4'>
                    <div className='flex-1'>
                      <label
                        className='text-xs font-normal text-[#52525B] '
                        htmlFor='from'
                      >
                        Cabin Class
                      </label>
                      <Field
                        as='select'
                        name='cabinClass'
                        type='text'
                        id='cabin-class'
                        className='form-field bg-white'
                      >
                        <option value='' disabled>
                          Select Cabin
                        </option>
                        {cabinClass.map((row: any) => (
                          <option key={row.value} value={row.value}>
                            {`${row.key}`}
                          </option>
                        ))}
                      </Field>
                      <div className='form-error'>
                        <ErrorMessage name='cabinClass' />
                      </div>
                    </div>
                    <div className='flex-1 flex flex-col gap-4'>
                      <label
                        className='text-xs font-normal text-[#52525B] '
                        htmlFor='from'
                      >
                        Direct Flights only
                      </label>
                      <div className='form-switch success'>
                        <input
                          id='isDirectFlight'
                          type='checkbox'
                          role='switch'
                          checked={values.isDirectFlight}
                          onClick={() =>
                            setFieldValue(
                              'isDirectFlight',
                              !values.isDirectFlight,
                            )
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className='mt-6'>
                  <button className='w-full text-white bg-black rounded-lg py-3'>
                    Search Flight
                  </button>
                </div>
              </Form>
            )}
          </Formik>

          <div className='mt-9 hidden'>
            <div className=' text-base leading-5 font-semibold'>
              Agency Stats
            </div>
            <div className='mt-6 flex flex-col gap-3'>
              {/* Monthly sales */}
              <div className='border-2 border-[#ECE9E9] bg-[#F4F4F4] rounded-lg p-5'>
                <div className='text-base leading-5 font-semibold text-black/50'>
                  Months Sales
                </div>
                <div className='flex justify-between items-center'>
                  <div className='text-3xl leading-10 font-semibold'>
                    {formatCurrency(statsData.sale.currentMonth)}
                  </div>
                  {/* <div className="text-[#077C0B] text-base leading-5 font-medium">
                                    {formatCurrency(12000)}
                                </div> */}
                </div>
              </div>
              {/* today's sale */}
              <div className='border-2 border-[#ECE9E9] bg-[#F4F4F4] rounded-lg p-5'>
                <div className='text-base leading-5 font-semibold text-black/50'>
                  Total sales
                </div>
                <div className='flex justify-between items-center'>
                  <div className='text-3xl leading-10 font-semibold'>
                    {formatCurrency(statsData.sale.total)}
                  </div>
                </div>
              </div>

              {/* totat users */}
              <div className='border-2 border-[#ECE9E9] bg-[#F4F4F4] rounded-lg p-5'>
                <div className='text-base leading-5 font-semibold text-black/50'>
                  Users
                </div>
                <div className='flex justify-between items-center'>
                  <div className='text-3xl leading-10 font-semibold'>
                    {statsData.subAgencyUserCount}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {annoucementDialog.isOpen && (
          <AnnouncementModal onClose={annoucementDialog.hide} />
        )}
        {/* {true && <AnnouncementModal onClose={annoucementDialog.hide} />} */}
      </div>
    </>
  );
};

export default MobileSearch;
