const Icon = (props: any) => (
  <svg
    width={24}
    height={24}
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M17.5 19.005V4.995a.8.8 0 00-1.244-.666l-1.27.847a.8.8 0 01-.943-.041L12.5 3.9a.8.8 0 00-1 0L9.957 5.135a.8.8 0 01-.944.04l-1.27-.846a.8.8 0 00-1.243.666v14.01a.8.8 0 001.244.666l1.27-.846a.8.8 0 01.943.04L11.5 20.1a.8.8 0 001 0l1.543-1.235a.8.8 0 01.944-.04l1.27.846a.8.8 0 001.243-.666z'
      stroke='currentColor'
    />
    <path
      d='M9.5 9.5h5M9.5 12h5M9.5 14.5h5'
      stroke='currentColor'
      strokeLinecap='round'
    />
  </svg>
);

export default Icon;
