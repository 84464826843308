export const rgbToHex = (rgb: string) => {
  const [r, g, b] = rgb.split(' ');

  return (
    '#' +
    [r, g, b]
      .map((val) => {
        const x = +val;
        const hex = x.toString(16);
        return hex.length === 1 ? '0' + hex : hex;
      })
      .join('')
  );
};

export const hexToRgb = (hex: string) => {
  const hexArr = hex
    .replace(
      /^#?([a-f\d])([a-f\d])([a-f\d])$/i,
      (m, r, g, b) => '#' + r + r + g + g + b + b,
    )
    .substring(1)
    .match(/.{2}/g);
  const rgbArr = hexArr.map((x) => parseInt(x, 16));

  return rgbArr.join(' ');
};
