import { useMutation, useQueryClient } from '@tanstack/react-query';
import DeleteModal from 'app/components/DeleteModal/DeleteModal';
import {
  Dropdown,
  DropdownItem,
  DropdownList,
} from 'app/components/Dropdown/Dropdown';
import http from 'app/config/http';
import useDialog from 'app/hooks/use-dialog';
import { MenuDotIcon } from 'app/icons';
import React, { FC } from 'react';
import { toast } from 'react-toastify';
import BankAccountAddModal from '../BankAccountAddModal/BankAccountAddModal';
import update from 'immutability-helper';
import BankAccountDetailModal from '../BankAccountDetailModal/BankAccountDetailModal';
import { IBank } from 'app/types';

interface BankAccountListProps {
  accounts: IBank[];
}

const BankAccountList: FC<BankAccountListProps> = (props) => {
  const { accounts } = props;
  const BankAddDialog = useDialog('');
  const deleteDialog = useDialog<string>();
  const accountDetailsDialog = useDialog<IBank>({
    _id: '',
    name: '',
    ifsc: '',
    account: {
      type: '',
      number: '',
      holderName: '',
    },
  });

  const queryClient = useQueryClient();

  const mutation = useMutation(async (payload: any) => {
    const { data } = await http.delete(
      `${process.env.REACT_APP_API_URL}/api/v1/sub-agent/sub-agency/banks/${payload.id}`,
      payload,
    );

    return data;
  });

  const handleDeleteBank = async () => {
    const _id = deleteDialog.data;
    try {
      await mutation.mutateAsync({
        id: _id,
      });

      toast.success('Bank Accound Deleted successfully');

      const index = accounts.findIndex((r) => r._id === _id);

      queryClient.setQueryData(['subAgency', 'bankList'], (prev: any) => {
        return update(prev, {
          $splice: [[index, 1]],
        });
      });
    } catch (ex: any) {
      toast.error(ex?.data?.message || 'Some error occured, please try again.');
    } finally {
      deleteDialog.hide();
    }
  };

  return (
    <div className='mt-4 '>
      <div className='flex justify-end  mx-6'>
        <button
          onClick={() => BankAddDialog.show()}
          className='text-sm font-semibold text-blue-500'
        >
          Add Bank
        </button>
      </div>
      <div className='overflow-auto'>
        <table className='mt-4'>
          <thead>
            <tr>
              <td className='bg-[#F9FAFB] text-sm font-semibold py-4 pl-4 pr-2'>
                Account Holder
              </td>
              <td className='bg-[#F9FAFB] text-sm font-semibold py-4 px-2'>
                Account Number
              </td>
              <td className='bg-[#F9FAFB] text-sm font-semibold py-4 px-2'>
                Type
              </td>
              <td className='bg-[#F9FAFB] text-sm font-semibold py-4 px-2'>
                IFSC
              </td>
              <td className='bg-[#F9FAFB] text-sm font-semibold py-4 px-2'>
                Bank
              </td>

              <td className='bg-[#F9FAFB] text-sm font-semibold py-4 px-2 pl-2 pr-4'></td>
            </tr>
          </thead>
          <tbody>
            {accounts.map((row: any, i: number) => {
              return (
                <tr key={i}>
                  <td className='text-sm font-normal py-4 pl-4 pr-2'>
                    {row.account.holderName}
                  </td>
                  <td className='text-sm font-normal py-4 px-2 max-w-[140px] truncate'>
                    {row.account.number}
                  </td>
                  <td className='text-sm font-normal py-4 px-2 '>
                    {row.account.type}
                  </td>
                  <td className='text-sm font-normal py-4 px-2 max-w-[140px] truncate'>
                    {row.ifsc}
                  </td>

                  <td className='text-sm font-normal py-4 px-2 pl-2 pr-4'>
                    {row.name}
                  </td>
                  <td className='td tb'>
                    <Dropdown
                      target={
                        <div className='dropdown-action'>
                          <MenuDotIcon />{' '}
                        </div>
                      }
                      menu={
                        <DropdownList>
                          <DropdownItem
                            onClick={() => accountDetailsDialog.show(row)}
                          >
                            View
                          </DropdownItem>
                          <DropdownItem
                            onClick={() => deleteDialog.show(row._id)}
                          >
                            <div className='text-red-500'>Delete</div>
                          </DropdownItem>
                        </DropdownList>
                      }
                    />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>

      <BankAccountAddModal
        onClose={BankAddDialog.hide}
        show={BankAddDialog.isOpen}
      />

      <BankAccountDetailModal
        onClose={accountDetailsDialog.hide}
        show={accountDetailsDialog.isOpen}
        bankAccount={accountDetailsDialog.data}
      />

      <DeleteModal
        show={deleteDialog.isOpen}
        message={'Are you sure you want to delete this Bank Account?'}
        onClose={deleteDialog.hide}
        onConfirm={handleDeleteBank}
        isLoading={mutation.isLoading}
      />
    </div>
  );
};

export default BankAccountList;
