import { FC } from 'react';
import http from 'app/config/http';
import { IFlightPriceDetail } from 'app/types';
import { useQuery } from '@tanstack/react-query';
import Loader from 'app/components/Loader/Loader';

import {
  getFormattedFareRuleKey,
  getFormattedFareTypeKey,
} from 'app/utils/flight-utils';

interface IFareRules {
  selectedPriceItem: IFlightPriceDetail;
}

const FareRules: FC<IFareRules> = (props) => {
  const { selectedPriceItem } = props;
  const fareRulesQuery = useQuery(
    ['fareRulesById', selectedPriceItem],
    async () => {
      const { data } = await http.post(
        `${process.env.REACT_APP_API_URL}/api/v1/sub-agent/flights/${selectedPriceItem.id}/fare-rules`,
        {
          fareSupplier: selectedPriceItem.fareSupplier,
        },
      );

      return data;
    },
  );

  if (fareRulesQuery.isLoading)
    return (
      <div className='text-center'>
        <Loader />
      </div>
    );

  if (fareRulesQuery.isError)
    return <p>Please contact support for fare rules.</p>;

  // for series fare : fare rule will be a string
  if (selectedPriceItem.fareSupplier === '0')
    return <p className='whitespace-pre-wrap'>{fareRulesQuery.data}</p>;
  if (selectedPriceItem.fareSupplier === '2')
    return <p className='whitespace-pre-wrap'>{fareRulesQuery.data}</p>;
  if (selectedPriceItem.fareSupplier === '3') {
    if (fareRulesQuery.data)
      return <p className='whitespace-pre-wrap'>{fareRulesQuery.data}</p>;
    else return <p>Please contact support for fare rules.</p>;
  }

  // for normal fare : fare rule will be an array of objects
  if (fareRulesQuery.data.length === 0)
    return <p>Please contact support for fare rules.</p>;

  return fareRulesQuery.data.map((fight: any, fightIndex: number) => (
    <div key={fightIndex}>
      <h6 className='font-bold mb-4'>{fight.flightName}</h6>
      <div className='grid grid-cols-4 gap-4'>
        {fight.rules.map((rule: any, ruleIndex: number) => (
          <div key={fightIndex + '' + ruleIndex} className='col-span-4'>
            <div className='font-medium mb-2'>
              {getFormattedFareRuleKey(rule.key)}
            </div>

            <div>
              {rule.rows.map((row: any, rowIndex: number) => (
                <div
                  key={fightIndex + '' + ruleIndex + '' + rowIndex}
                  className='flex gap-8 text-gray-600 text-sm '
                >
                  <div className='w-1/6'>
                    {getFormattedFareTypeKey(row.type)}
                  </div>
                  <div className='flex-1 leading-6'>{row.value}</div>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  ));
};

export default FareRules;
