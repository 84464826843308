import { TRootState } from 'app/store';
import { authActions } from 'app/store/auth';
import jwtDecode from 'jwt-decode';
import moment from 'moment';
import { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, useLocation } from 'react-router-dom';

const checkValidToken = (token: string): boolean => {
  try {
    const payload: any = jwtDecode(token);

    const isExpired = moment().isAfter(moment.unix(payload.exp));

    return !isExpired;
  } catch (e: any) {
    return false;
  }
};

interface AuthGuardProps {
  page: any;
}

const AuthGuard: FC<AuthGuardProps> = (props) => {
  const location = useLocation();
  const dispatch = useDispatch();

  const { token } = useSelector((state: TRootState) => state.auth);

  if (!token) {
    return <Navigate to='/login' state={{ from: location }} replace />;
  }

  const isValid = checkValidToken(token);

  if (!isValid) {
    dispatch(authActions.logout());

    return <Navigate to='/login' state={{ from: location }} replace />;
  }

  return props.page;
};

export default AuthGuard;
