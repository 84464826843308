import debounce from 'lodash.debounce';
import { FC, useCallback } from 'react';

interface TableSearchProps {
  placeholder: string;
  onSearch?: (q: string) => void;
  onSearchReset?: () => void;
}

const TableSearch: FC<TableSearchProps> = (props) => {
  const { placeholder, onSearch, onSearchReset } = props;

  const changeHandler = async (e: any) => {
    const val = e.target.value.trim();

    if (val === '') {
      onSearchReset?.();
    }

    onSearch?.(val);
  };

  const handleKeyUp = useCallback(debounce(changeHandler, 10), []);

  return (
    <input
      type='text'
      className='border border-gray-300 rounded-md text-sm px-4 py-2 outline-none w-96 max-w-full'
      placeholder={placeholder}
      onKeyUp={handleKeyUp}
    />
  );
};

export default TableSearch;
