import { SubAgencyUserStatusEnum } from 'app/enums/sub-agency-user-status';
import moment from 'moment';

export const getGenderFromTitle = (title: string) => {
  if (['Ms', 'Mrs'].includes(title)) return 'Female';
  else return 'Male';
};

export const getInitials = (name: string) => {
  const arr = name.split(' ');

  let initials = arr[0].substring(0, 1);

  if (arr.length > 1) {
    initials += arr[arr.length - 1].substring(0, 1);
  }

  return initials.toUpperCase();
};

export const formatDOB = (dob: string, format: string) => {
  return moment(dob, format).format('DD MMM YYYY');
};

export const getAmendmentStatusColor = (status: string) => {
  switch (status) {
    case 'PENDING':
      return 'yellow';
    case 'APPROVED':
      return 'green';
    case 'REJECTED':
      return 'red';
    case 'COMPLETED':
      return 'green';
    case 'AWAITING_SUB_AGENCY_APPROVAL':
      return 'yellow';
    case 'APPROVED_BY_SUB_AGENCY':
      return 'yellow';
    case 'AWAITING_AGENCY_APPROVAL':
      return 'yellow';
  }
};

export const getAmendmentStatusText = (status: string) => {
  switch (status) {
    case 'PENDING':
      return 'Pending';
    case 'APPROVED':
      return 'Approved';
    case 'REJECTED':
      return 'Rejected';
    case 'COMPLETED':
      return 'Completed';
    case 'AWAITING_SUB_AGENCY_APPROVAL':
      return 'Awaitng sub agency approval';
    case 'APPROVED_BY_SUB_AGENCY':
      return 'Approved by sub agency';
    case 'AWAITING_AGENCY_APPROVAL':
      return 'Awaiting agency approval';
  }
};

export const getRefundTypeBackgroundColor = (status: string) => {
  switch (status) {
    case 'REFUNDABLE':
      return 'bg-success';
    case 'NON_REFUNDABLE':
      return 'bg-danger';
    case 'PARTIAL_REFUNDABLE':
      return 'bg-yellow-400';
    default:
      return 'bg-slate-500';
  }
};

export const mapBooleanToSubAgencyUserStatus = (
  statusInBoolean: boolean,
): SubAgencyUserStatusEnum => {
  return statusInBoolean
    ? SubAgencyUserStatusEnum.ENABLED
    : SubAgencyUserStatusEnum.DISABLED;
};

export const mapSubAgencyUserStatusToBoolean = (
  status: SubAgencyUserStatusEnum,
): boolean => {
  return status === SubAgencyUserStatusEnum.ENABLED ? true : false;
};
