import { useQuery } from '@tanstack/react-query';
import PageLoader from 'app/components/PageLoader/PageLoader';
import http from 'app/config/http';
import { TRootState } from 'app/store';
import { accountActions } from 'app/store/account';
import { authActions } from 'app/store/auth';
import { hexToRgb } from 'app/utils/color-utils';
import Color from 'color';
import { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet } from 'react-router-dom';

interface AuthLayoutProps {}

const AuthLayout: FC<AuthLayoutProps> = () => {
  const dispatch = useDispatch();
  const { user } = useSelector((state: TRootState) => state.auth);
  const account = useSelector((state: TRootState) => state.account);

  if (window.self !== window.top) {
    console.log('condition met reloading...');
    window.top.location.reload();
  }

  const detailsQuery = useQuery(['profileData'], async () => {
    const { data } = await http.get(
      `${process.env.REACT_APP_API_URL}/api/v1/sub-agent/sub-agency/details`,
    );

    return data;
  });

  const accountQuery = useQuery(['accountData'], async () => {
    const { data } = await http.get(
      `${process.env.REACT_APP_API_URL}/api/v1/sub-agent/sub-agency/account`,
    );

    return data;
  });

  // Set Account data
  useEffect(() => {
    if (accountQuery.data) {
      dispatch(accountActions.setAccountData(accountQuery.data));
    }
  }, [accountQuery.data, dispatch]);

  // Set profile
  useEffect(() => {
    if (detailsQuery.data) {
      dispatch(authActions.setUser(detailsQuery.data));

      const {
        agency: { name, iconUrl, tagline, primaryColor, secondaryColor },
      } = detailsQuery.data;

      // set css color variables
      document.documentElement.style.setProperty(
        '--primary',
        hexToRgb(primaryColor),
      );
      document.documentElement.style.setProperty(
        '--secondary',
        hexToRgb(secondaryColor),
      );

      const secondaryColorObj = Color(secondaryColor);
      if (secondaryColorObj.isLight()) {
        document.documentElement.style.setProperty('--secondary-fg', '0 0 0');
      }
      // set title
      document.title = name + ' - ' + tagline;

      // set favicon
      const link = document.querySelector("link[rel~='icon']");
      link.setAttribute('href', iconUrl);
    }
  }, [detailsQuery.data, dispatch]);

  if (accountQuery.isLoading || detailsQuery.isLoading || !user || !account)
    return <PageLoader color='#000000' />;

  return <Outlet />;
};

export default AuthLayout;
