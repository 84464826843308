import { useQuery } from '@tanstack/react-query';
import BgOverlay from 'app/components/BgOverlay/BgOverlay';
import PageLoader from 'app/components/PageLoader/PageLoader';
import TableDateFilter from 'app/components/TableDateFilter/TableDateFilter';
import TableSearch from 'app/components/TableSearch/TableSearch';
import http from 'app/config/http';
import React, { FC, useEffect, useState } from 'react';
import BookingTable from './components/BookingTable/BookingTable';

interface BookingListProps {}

const BookingList: FC<BookingListProps> = (props) => {
  // return (
  //     <div className="flex justify-center items-center h-screen w-screen">
  //         <div className="text-2xl"> Under Construction </div>
  //     </div>
  // );

  // const bookingQuery = useQuery(['bookingList'], async () => {
  //     const { data } = await http.get(`${process.env.REACT_APP_API_URL}/api/v1/sub-agent/series-tickets-bookings`);
  //     return data;
  // });

  const [filteredBookingList, setFilteredBookingList] = useState([]);
  const [filter, setFilter] = useState({
    search: '',
    date1: '',
    date2: '',
  });

  const bookingQuery = useQuery(['bookingList'], async () => {
    const { data } = await http.get(
      `${process.env.REACT_APP_API_URL}/api/v1/sub-agent/series-ticket-bookings`,
    );
    return data;
  });

  useEffect(() => {
    if (bookingQuery.data) {
      setFilteredBookingList(bookingQuery.data);
    }
  }, [bookingQuery.data]);

  // Filter Hook
  useEffect(() => {
    if (!bookingQuery.data) return;

    let filteredArr = bookingQuery.data;

    if (filter.search !== '') {
      const val = filter.search.toLowerCase();

      filteredArr = filteredArr.filter((row: any) => {
        const flightValues = Object.values(row.flight).join(',').toLowerCase();
        const arrivalAirportValues = Object.values(row.trip.arrival.airport)
          .join(',')
          .toLowerCase();
        const departureAirportValues = Object.values(row.trip.departure.airport)
          .join(',')
          .toLowerCase();

        let flag = flightValues.includes(val);
        flag = flag || row._id.toLowerCase().includes(val);
        flag = flag || arrivalAirportValues.includes(val);
        flag = flag || departureAirportValues.includes(val);
        flag = flag || departureAirportValues.includes(val);

        // flag = flag || row.bookedBy.name.toLowerCase().includes(val);

        return flag;
      });
    }

    if (filter.date1) {
      filteredArr = filteredArr.filter((row: any) => {
        return +row.trip.departure.date >= +filter.date1;
      });
    }

    if (filter.date2) {
      filteredArr = filteredArr.filter((row: any) => {
        return +row.trip.departure.date <= +filter.date2;
      });
    }

    setFilteredBookingList(filteredArr);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter]);

  const handleSearch = (q: string) => {
    setFilter((prev) => ({ ...prev, search: q }));
  };

  const handleDateFilter = (date1: string, date2: string) => {
    setFilter((prev) => ({ ...prev, date1, date2 }));
  };

  if (bookingQuery.isLoading) return <PageLoader />;

  return (
    <div>
      <BgOverlay />

      <div className='container min-h-full'>
        <div className='flex py-8'>
          <div className={`title`}>Bookings</div>
        </div>
        <div className='card '>
          <div className='flex flex-col sm:flex-row justify-between items-center px-4 sm:px-6 pt-5 gap-6 '>
            <TableSearch
              placeholder='Search for a booking by flight, airport, reference'
              onSearch={handleSearch}
            />

            <TableDateFilter onChange={handleDateFilter} />
          </div>

          <div className='mt-6 overflow-auto '>
            <BookingTable bookings={filteredBookingList} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default BookingList;
