import BgOverlay from 'app/components/BgOverlay/BgOverlay';
import { FC } from 'react';
import Transactions from './components/Transactions/Transactions';
import http from 'app/config/http';
import { useQuery } from '@tanstack/react-query';
import PageLoader from 'app/components/PageLoader/PageLoader';

interface AccountingProps {}

const Accounting: FC<AccountingProps> = (props) => {
  const transactionQuery = useQuery(['seriesTransactions'], async () => {
    const { data } = await http.get(
      `${process.env.REACT_APP_API_URL}/api/v1/sub-agent/series-tickets/ledger`,
    );

    return data;
  });

  if (transactionQuery.isLoading) return <PageLoader />;

  return (
    <div>
      <BgOverlay />

      <div className='container min-h-full'>
        <div className='flex py-8'>
          <div className='title text-secondary-fg'>Accounts</div>
        </div>

        {/* // transaction table */}
        <div className='card py-9'>
          <Transactions transactions={transactionQuery.data} />
        </div>
      </div>
    </div>
  );
};

export default Accounting;
