import React, { FC } from 'react';
import { INavLink } from '../../NavBarTwo';
import { NavLink } from 'react-router-dom';
import {
  Dropdown,
  DropdownItem,
  DropdownList,
} from 'app/components/Dropdown/Dropdown';
import { ChevronIcon } from 'app/icons';

interface INavLinkItem {
  navItem: INavLink;
}

const NavLinkItem: FC<INavLinkItem> = (props) => {
  const { navItem } = props;

  if (!navItem.children || navItem.children.length === 0) {
    return (
      <li>
        <NavLink
          to={navItem.to}
          className={({ isActive }) =>
            `text-sm   ${isActive ? 'font-semibold' : 'font-normal'} `
          }
        >
          {navItem.label}
        </NavLink>
      </li>
    );
  }

  return (
    <Dropdown
      placement={'bottom-start'}
      target={
        <NavLink
          onClick={(e) => e.preventDefault()}
          to={navItem.to}
          className={({ isActive }) =>
            `text-sm   ${isActive ? 'font-semibold' : 'font-normal'} `
          }
        >
          <div className='flex gap-2 items-center px-4 shadow-black '>
            {navItem.label}
            <ChevronIcon height={5} />
          </div>
        </NavLink>
      }
      menu={
        <DropdownList>
          {navItem.children.map((navItem) => (
            <DropdownItem to={navItem.to}>
              <NavLinkItem navItem={navItem} key={navItem.label} />
            </DropdownItem>
          ))}
        </DropdownList>
      }
    />
  );
};

export default NavLinkItem;
