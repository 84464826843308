import React, { FC } from 'react';
import { barCodeInputStringGenerator } from 'app/utils/ticket-utils';
import BarCodeGenerator from 'app/components/BarCodeGenerator/BarCodeGenerator';

interface ITicketBarCode {
  airlineCode: string;
  DepartureDate: string;
  flightNum: string;
  fromAirport: string;
  pnr: string | null;
  toAirport: string;
  firstName: string;
  lastName: string;
}

const TicketBarCode: FC<ITicketBarCode> = (props) => {
  const {
    airlineCode,
    DepartureDate,
    flightNum,
    fromAirport,
    pnr,
    toAirport,
    firstName,
    lastName,
  } = props;

  if (pnr === null) return <div></div>;

  const combinedDetailString = barCodeInputStringGenerator({
    airlineCode,
    DepartureDate,
    flightNum,
    fromAirport,
    pnr,
    toAirport,
    firstName,
    lastName,
  });

  return <BarCodeGenerator input={combinedDetailString} />;
};

export default TicketBarCode;
