import { createSlice } from '@reduxjs/toolkit';

type IAccountData = {
  creditLimit: number;
  availableCredit: number;
  availableCashBalance: number;
};

const initialState: IAccountData = {
  creditLimit: 0,
  availableCredit: 0,
  availableCashBalance: 0,
};

const accountSlice = createSlice({
  name: 'account',
  initialState: initialState,
  reducers: {
    setAccountData(state, action) {
      state.creditLimit = action.payload.creditLimit;
      state.availableCredit = action.payload.availableCredit;
      state.availableCashBalance = action.payload.availableCashBalance;
    },
  },
});

export const accountActions = accountSlice.actions;

export default accountSlice;
