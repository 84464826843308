import { useQuery } from '@tanstack/react-query';
import { FC, useEffect, useState } from 'react';
import styles from './Login.module.scss';
import { hexToRgb } from 'app/utils/color-utils';
import Color from 'color';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import Spinner from 'app/components/Spinner/Spinner';
import DOMPurify from 'dompurify';

import StaticNav from 'app/components/StaticNav/StaticNav';
import BackLink from 'app/components/BackLink/BackLink';
import PageNotFound from '../PageNotFound/PageNotFound';

interface StaticPageProps {}

const StaticPage: FC<StaticPageProps> = (props) => {
  const { route } = useParams();

  const [sanitizedData, setSanitizedData] = useState('');
  const [data, setData] = useState({
    name: '',
    email: '',
    phoneNumber: '',
    logo: '',
    tagline: '',
    staticPages: [],
  });

  const loginQuery = useQuery(
    ['loginDetails'],
    async () => {
      const { data } = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/v1/sub-agent/auth/details`,
        {
          domain: window.location.hostname,
          // domain: 'sub-agent.farepod.co',  // example is here
        },
      );

      return data;
    },
    {
      retry: 0,
    },
  );

  const usePageContentQuery = useQuery(
    [route],
    async () => {
      const { data } = await axios.post(
        // `${process.env.REACT_APP_API_URL}/api/v1/sub-agent/static-page/route/${encodeURIComponent(
        //     `/static/${route}`
        // )}`,

        `${process.env.REACT_APP_API_URL}/api/v1/sub-agent/static-pages/${route}`,
        {
          domain: window.location.hostname,
        },
      );
      return data;
    },
    {
      retry: 0,
    },
  );

  // Set Data
  useEffect(() => {
    if (loginQuery.data) {
      const {
        name,
        email,
        phoneNumber,
        logoUrl,
        iconUrl,
        tagline,
        primaryColor,
        secondaryColor,
        staticPages,
      } = loginQuery.data;

      setData({
        name: name,
        logo: logoUrl,
        tagline: tagline,
        email: email,
        phoneNumber: phoneNumber,
        staticPages: staticPages,
      });

      // set css color variables
      document.documentElement.style.setProperty(
        '--primary',
        hexToRgb(primaryColor),
      );
      document.documentElement.style.setProperty(
        '--secondary',
        hexToRgb(secondaryColor),
      );

      const secondaryColorObj = Color(secondaryColor);
      if (secondaryColorObj.isLight()) {
        document.documentElement.style.setProperty('--secondary-fg', '0 0 0');
      }

      // set title
      document.title = name + ' - ' + tagline;

      // set favicon
      const link = document.querySelector("link[rel~='icon']");
      link.setAttribute('href', iconUrl);
    }
    if (loginQuery.isError) {
      setData({
        name: 'Farepod',
        email: '',
        phoneNumber: '',
        logo: '/icons/logo.svg',
        tagline: 'Best fares. Always.',
        staticPages: [],
      });
    }
  }, [loginQuery.data, loginQuery.isError]);

  // const sanitizedData = () => ({
  //     __html: DOMPurify.sanitize(data),
  // });

  useEffect(() => {
    if (usePageContentQuery?.data?.content) {
      setSanitizedData(() => {
        return DOMPurify.sanitize(usePageContentQuery.data.content);
      });
    }
  }, [usePageContentQuery.data]);

  // useEffect(() => {
  //     if (usePageContentQuery.isError) {

  //     }
  // }, [usePageContentQuery.isError]);

  if (usePageContentQuery.isError) return <PageNotFound />;

  return (
    <div className='min-h-screen bg-secondary relative'>
      <div className='absolute top-0 left-0 h-2/6 bg-[#F1F0F0] w-screen'></div>
      <div className='px-8 py-4 mx-auto max-w-[1200px] w-full relative'>
        <div className='flex justify-center pt-8'>
          <img src={data.logo} className='h-12' alt='' />
        </div>

        <div className='flex items-end  gap-0 md:gap-6 sm:-mr-10   '>
          <div
            className={`${styles['card-shadow']} card rounded-lg  overflow-hidden mt-10 w-full`}
          >
            <div className=' col-span-2 px-8 py-10   md:px-12 md:py-10 min-h-[536px] '>
              <div>
                <div className='mb-5'>
                  <BackLink />
                </div>
              </div>
              {usePageContentQuery.isLoading ? (
                <div className='flex justify-center items-center py-8'>
                  <Spinner />
                </div>
              ) : (
                <div
                  className='static-page'
                  dangerouslySetInnerHTML={{
                    __html: sanitizedData,
                  }}
                ></div>
              )}
            </div>
          </div>

          {/* <div
                        className={`${styles['vertical-text']} text-3xl font-bold text-secondary-fg  hidden sm:block `}
                    >
                        {data.tagline}
                    </div> */}
        </div>
      </div>

      <StaticNav staticPages={data.staticPages} name={data.name} />
    </div>
  );
};

export default StaticPage;
