import { FC, ReactNode, useRef, useState } from 'react';
import styles from './Tooltip.module.scss';

/* eslint-disable jsx-a11y/anchor-is-valid */
import {
  arrow,
  autoUpdate,
  flip,
  FloatingPortal,
  offset,
  Placement,
  safePolygon,
  shift,
  useDismiss,
  useFloating,
  useHover,
  useInteractions,
} from '@floating-ui/react';

interface TooltipProps {
  children: ReactNode;
  content: ReactNode;
  placement?: Placement;
  backgroundColor?: string;
  triggers?: string[];
}

const Tooltip: FC<TooltipProps> = (props) => {
  const {
    children,
    content,
    placement: inputPlacement,
    backgroundColor,
  } = props;

  const [open, setOpen] = useState<boolean>(false);

  const arrowRef = useRef(null);
  const {
    context,
    x,
    y,
    refs,
    strategy,
    placement,
    middlewareData: { arrow: { x: arrowX, y: arrowY } = {} },
  } = useFloating({
    whileElementsMounted: autoUpdate,
    placement: inputPlacement,
    middleware: [flip(), shift(), offset(10), arrow({ element: arrowRef })],
    open: open,
    onOpenChange: setOpen,
  });

  const staticSide = {
    top: 'bottom',
    right: 'left',
    bottom: 'top',
    left: 'right',
  }[placement.split('-')[0]];

  const { getReferenceProps, getFloatingProps } = useInteractions([
    useHover(context, {
      handleClose: safePolygon({
        // blockPointerEvents: false,
      }),
    }),
    useDismiss(context),
  ]);

  return (
    <>
      <div
        ref={refs.setReference}
        className='cursor-pointer relative'
        {...getReferenceProps()}
      >
        {children}
      </div>

      <FloatingPortal>
        {open && (
          <div
            ref={refs.setFloating}
            className={`rounded-lg ${styles['shadow']}`}
            style={{
              position: strategy,
              top: y ?? 0,
              left: x ?? 0,
              width: 'max-content',
              backgroundColor: backgroundColor,
            }}
            {...getFloatingProps()}
          >
            {content}
            <div
              ref={arrowRef}
              className='absolute w-4 h-4 rotate-45'
              style={{
                left: arrowX != null ? arrowX : '',
                top: arrowY != null ? arrowY : '',
                right: '',
                bottom: '',
                [staticSide]: '-4px',
                backgroundColor: backgroundColor,
              }}
            ></div>
          </div>
        )}
      </FloatingPortal>
    </>
  );
};

Tooltip.defaultProps = {
  placement: 'top',
  backgroundColor: '#fff',
};

export default Tooltip;
