import { useQuery } from '@tanstack/react-query';
import BgOverlay from 'app/components/BgOverlay/BgOverlay';
import PageLoader from 'app/components/PageLoader/PageLoader';
import StatsCard from 'app/components/StatsCard/StatsCard';
import TableDateFilter from 'app/components/TableDateFilter/TableDateFilter';
import TableSearch from 'app/components/TableSearch/TableSearch';
import http from 'app/config/http';
import { ISeriesTicket } from 'app/types/series-ticket';
import React, { FC, useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import SeriesInventoryTable from './components/SeriesInventoryTable/SeriesInventoryTable';

// const statsQuery = {
//     data: {
//         totalSeats: 81,
//         totalSoldSeats: 17,
//         pendingTickets: 5,
//         submittedTickets: 0,
//         totalTickets: 9,
//     },
//     isLoading: false,
// };

interface SeriesInventoryListProps {}

const SeriesInventoryList: FC<SeriesInventoryListProps> = (props) => {
  const [filteredSeriesTicketList, setFilteredSeriesTicketList] = useState<
    ISeriesTicket[]
  >([]);
  const [filter, setFilter] = useState({
    search: '',
    date1: '',
    date2: '',
    sector: '',
  });

  const statsQuery = useQuery(['seriesTicketStats'], async () => {
    const { data } = await http.get(
      `${process.env.REACT_APP_API_URL}/api/v1/sub-agent/series-tickets/stats`,
    );

    return data;
  });

  const seriesTicketListQuery = useQuery<ISeriesTicket[]>(
    ['seriesTicketList'],
    async () => {
      const { data } = await http.get(
        `${process.env.REACT_APP_API_URL}/api/v1/sub-agent/series-tickets`,
      );

      return data;
    },
  );
  const sectors = useMemo(() => {
    const tickets = seriesTicketListQuery.data;
    if (!tickets) return [];

    const _sectorsSet = new Set<string>();

    tickets.forEach((row) => {
      _sectorsSet.add(
        row.trip.departure.airport.cityCode +
          '-' +
          row.trip.arrival.airport.cityCode,
      );
    });

    return Array.from(_sectorsSet);
  }, [seriesTicketListQuery.data]);

  useEffect(() => {
    if (seriesTicketListQuery.data) {
      setFilteredSeriesTicketList(seriesTicketListQuery.data);
    }
  }, [seriesTicketListQuery.data]);

  // Filter Hook
  useEffect(() => {
    if (!seriesTicketListQuery.data) return;

    let filteredArr = seriesTicketListQuery.data;

    if (filter.search !== '') {
      const val = filter.search.toLowerCase();

      filteredArr = filteredArr.filter((row) => {
        const flightValues = Object.values(row.flight).join(',').toLowerCase();
        const arrivalAirportValues = Object.values(row.trip.arrival.airport)
          .join(',')
          .toLowerCase();
        const departureAirportValues = Object.values(row.trip.departure.airport)
          .join(',')
          .toLowerCase();

        let flag = flightValues.includes(val);

        flag = flag || arrivalAirportValues.includes(val);
        flag = flag || departureAirportValues.includes(val);
        flag = flag || row._id.toLowerCase().includes(val);
        flag = flag || row.pnr.toLowerCase().includes(val);

        return flag;
      });
    }

    if (filter.date1) {
      filteredArr = filteredArr.filter((row) => {
        return +row.trip.departure.date >= +filter.date1;
      });
    }

    if (filter.date2) {
      filteredArr = filteredArr.filter((row) => {
        return +row.trip.departure.date <= +filter.date2;
      });
    }

    if (filter.sector !== '') {
      const [departureCityCode, arrivalCityCode] = filter.sector.split('-');

      filteredArr = filteredArr.filter((row) => {
        return (
          row.trip.departure.airport.cityCode === departureCityCode &&
          row.trip.arrival.airport.cityCode === arrivalCityCode
        );
      });
    }

    setFilteredSeriesTicketList(filteredArr);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter]);

  const handleSearch = (q: string) => {
    setFilter((prev) => ({ ...prev, search: q }));
  };

  const handleDateFilter = (date1: string, date2: string) => {
    setFilter((prev) => ({ ...prev, date1, date2 }));
  };

  const handleSectorChangeFilter = (sector: string) => {
    setFilter((prev) => ({ ...prev, sector }));
  };

  if (seriesTicketListQuery.isLoading || statsQuery.isLoading)
    return <PageLoader />;

  const stats = statsQuery.data;

  return (
    <div>
      <BgOverlay />

      <div className='container min-h-full'>
        <div className=' grid grid-cols-5 sm:grid-cols-6 xl:grid-cols-5 gap-6 py-8'>
          <div className='col-span-5 sm:col-span-2 xl:col-span-1'>
            <StatsCard title='TOTAL SEATS LISTED' content={stats.totalSeats} />
          </div>
          <div className='col-span-5 sm:col-span-2 xl:col-span-1'>
            <StatsCard
              title='TOTAL SEATS SOLD'
              content={stats.totalSoldSeats}
            />
          </div>
          <div className='col-span-5 sm:col-span-2 xl:col-span-1'>
            <StatsCard
              title='PENDING SUBMISSION'
              content={stats.pendingTickets}
            />
          </div>
          <div className='col-span-5 sm:col-span-2 xl:col-span-1'>
            <StatsCard
              title='SUBMITTED TICKETS'
              content={stats.submittedTickets}
            />
          </div>
          <div className='col-span-5 sm:col-span-2 xl:col-span-1'>
            <StatsCard title='TOTAL TICKETS' content={stats.totalTickets} />
          </div>
        </div>
        <div className='card mb-4'>
          {/* card heading  */}
          <div className='mx-9 pt-9 flex justify-between items-end'>
            <div className='text-xl font-bold'>Your Inventory</div>
            <Link
              to={'/my-series/series-inventory/add'}
              className='text-sm font-semibold text-blue-500'
            >
              Add Inventory
            </Link>
          </div>

          <div className='overflow-auto'>
            <div className='flex justify-between gap-4 items-center px-6 pt-5'>
              <TableSearch
                placeholder='Search for a inventory by name, PNR, reference'
                onSearch={handleSearch}
                onSearchReset={() =>
                  setFilteredSeriesTicketList(seriesTicketListQuery.data)
                }
              />

              <div className='flex items-center gap-8'>
                <select
                  className=' form-field border border-gray-300 rounded-md !text-sm !pl-4 !pr-8 !py-2 !outline-none font-medium min-w-[200px]  '
                  onChange={(e) => handleSectorChangeFilter(e.target.value)}
                >
                  <option value=''>Filter by Sector</option>
                  {sectors.map((s) => (
                    <option key={s} value={s}>
                      {s}
                    </option>
                  ))}
                </select>

                <TableDateFilter onChange={handleDateFilter} />
              </div>
            </div>
            <div className='mt-9 pb-10'>
              <SeriesInventoryTable seriesTickets={filteredSeriesTicketList} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SeriesInventoryList;
