export const getFormattedStops = (stops: number) => {
  if (stops <= 0) return 'Non Stop';
  else if (stops === 1) return '1 Stop';
  else return stops + ' Stops';
};

export const calculateStops = (segments: any[]) => {
  let stops = segments.length - 1;

  segments.forEach((s) => {
    stops += s.stops;
  });

  return stops;
};

export const getFormattedFareRuleKey = (key: string) => {
  const FareRuleKey: any = {
    NO_SHOW: 'No Show',
    DATECHANGE: 'Date Change Fee',
    CANCELLATION: 'Cancellation Fee',
    SEAT_CHARGEABLE: 'Seat Chargeable',
  };

  return FareRuleKey[key];
};

export const getFormattedFareTypeKey = (key: string) => {
  const FareTypeKey: any = {
    DEFAULT: 'All',
    BEFORE_DEPARTURE: 'Before Departure',
    AFTER_DEPARTURE: 'After Departure',
  };

  return FareTypeKey[key];
};

export const getFormattedRefundableType = (key: string) => {
  switch (key) {
    case 'REFUNDABLE':
      return 'Refundable';
    case 'NON_REFUNDABLE':
      return 'Non-refundable';
    case 'PARTIAL_REFUNDABLE':
      return 'Partial Refundable';
    default:
      return '-';
  }
};
