import { FC, useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { hexToRgb } from 'app/utils/color-utils';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import { BackIcon } from 'app/icons';
import styles from './ResetPassword.module.scss';
import Color from 'color';
import { ErrorMessage, Field, Formik, Form } from 'formik';
import LoaderButton from 'app/components/LoaderButton/LoaderButton';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import OtpField from 'react-otp-field';
import PageLoader from 'app/components/PageLoader/PageLoader';
import StaticNav from 'app/components/StaticNav/StaticNav';

const PasswordFormSchema = Yup.object().shape({
  password: Yup.string()
    .trim()
    .required('This field is required')
    .min(5, 'The password should be atleast 5 characters long'),
  confirmPassword: Yup.string()
    .required('This field is required')
    .when('password', {
      is: (val: any) => (val && val.length > 0 ? true : false),
      then: Yup.string()
        .trim()
        .oneOf([Yup.ref('password')], 'Passwords do not match'),
    }),
});

const initialValues: any = {
  password: '',
  confirmPassword: '',
};

interface ResetPasswordProps {}

const ResetPassword: FC<ResetPasswordProps> = (props) => {
  const { token } = useParams();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [otp, setOtp] = useState('');

  const [data, setData] = useState({
    name: '',
    email: '',
    phoneNumber: '',
    logo: '',
    tagline: '',
    staticPages: [],
  });

  const loginQuery = useQuery(
    ['loginDetails'],
    async () => {
      const { data } = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/v1/sub-agent/auth/details`,
        {
          domain: window.location.hostname,
          // domain: 'sub-agent.farepod.co',  // example is here
        },
      );
      return data;
    },
    {
      retry: 0,
    },
  );

  const emailQuery = useQuery(
    ['emailVerifyQuery'],
    async () => {
      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/v1/sub-agent/auth/forgot-password/${token}`,
      );
      return data;
    },
    {
      retry: 0,
    },
  );

  // Set Data
  useEffect(() => {
    if (loginQuery.data) {
      const {
        name,
        email,
        phoneNumber,
        logoUrl,
        iconUrl,
        tagline,
        primaryColor,
        secondaryColor,
        staticPages,
      } = loginQuery.data;

      setData({
        name: name,
        logo: logoUrl,
        tagline: tagline,
        email: email,
        phoneNumber: phoneNumber,
        staticPages: staticPages,
      });

      // set css color variables
      document.documentElement.style.setProperty(
        '--primary',
        hexToRgb(primaryColor),
      );
      document.documentElement.style.setProperty(
        '--secondary',
        hexToRgb(secondaryColor),
      );

      const secondaryColorObj = Color(secondaryColor);
      if (secondaryColorObj.isLight()) {
        document.documentElement.style.setProperty('--secondary-fg', '0 0 0');
      }

      // set title
      document.title = name + ' - ' + tagline;

      // set favicon
      const link = document.querySelector("link[rel~='icon']");
      link.setAttribute('href', iconUrl);
    }
  }, [loginQuery.data]);

  const otpVerifyMutation = useMutation(async (payload: any) => {
    const { data } = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/v1/sub-agent/auth/validate-otp/${token}`,
      payload,
    );

    return data;
  });

  const handleOtpVerify = async () => {
    try {
      const response = await otpVerifyMutation.mutateAsync({ otp });
      queryClient.setQueryData(['emailVerifyQuery'], (prev: any) => {
        return { ...response };
      });
    } catch (e) {
      toast.error((e as any).response.data.message);
    }
  };

  const setPasswordMutation = useMutation(async (payload: any) => {
    const { data } = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/v1/sub-agent/auth/reset-password/${emailQuery.data._id}`,
      payload,
    );

    return data;
  });

  const handleSubmit = async (values: any) => {
    try {
      await setPasswordMutation.mutateAsync({ password: values.password });
      toast.success('Password updated successfully');
      navigate('/');
    } catch (e) {
      let errMsg = (e as any).data?.message || 'Something went wrong';
      toast.error(errMsg);
    }
  };

  if (emailQuery.isError) navigate('/login');

  if (emailQuery.isLoading) return <PageLoader />;

  const { email, isVerified } = emailQuery.data;

  return (
    <div className='min-h-screen bg-secondary relative'>
      <div className='absolute top-0 left-0 h-2/6 bg-[#F1F0F0] w-screen'></div>
      <div className='px-8 py-4 mx-auto max-w-[1200px] w-full relative'>
        <div className='flex justify-center pt-8'>
          <img
            src={data.logo}
            className='h-12'
            alt=''
            onClick={() => {
              navigate('/', { replace: true });
            }}
          />
        </div>

        <div className='flex justify-center   gap-0 md:gap-6   '>
          <div
            className={`${styles['card-shadow']} card rounded-lg  gap-3 overflow-hidden mt-10 w-[600px]`}
          >
            <div className=' px-10 py-10   '>
              {/* Form Heading */}
              <div className='mb-5'>
                <Link
                  className='inline-flex items-center font-semibold gap-2 text-lg cursor-pointer text-gray-900'
                  to='/'
                >
                  <BackIcon height={12} />
                  <span className='ms-2'>Back</span>
                </Link>
              </div>

              {!isVerified && (
                <div>
                  <h3 className='font-bold'>Verify your email</h3>
                  <div className='text-base text-gray-800 font-light mt-3'>
                    {/* Please enter the following details to reset your password */}
                    {`We’ve sent you a confirmation code on ${email}`}
                  </div>
                  {/* <Otp /> */}
                  <div className='mt-8 flex justify-center'>
                    <OtpField
                      classNames='flex items-center gap-2 sm:gap-5'
                      value={otp}
                      numInputs={6}
                      onChange={(val: any) => setOtp(val)}
                      autoFocus={true}
                      inputProps={{
                        className:
                          'border border-gray-200 focus:border-primary rounded-lg h-8 w-8  sm:w-12 sm:h-12 text-center font-semibold text-lg text-gray-900 outline-none',
                        disabled: false,
                      }}
                    />
                  </div>

                  <div className='mt-20'>
                    <LoaderButton
                      disabled={otp.length !== 6}
                      className='bg-primary text-white text-sm font-semibold rounded-lg w-full '
                      onClick={handleOtpVerify}
                      isLoading={otpVerifyMutation.isLoading}
                    >
                      Verify account
                    </LoaderButton>
                  </div>
                </div>
              )}

              {isVerified && (
                <div>
                  <h3 className='font-bold'>Reset password</h3>
                  <div className='text-base text-gray-800 font-light mt-3'>
                    Enter and confirm your new password
                  </div>
                  <Formik
                    initialValues={initialValues}
                    validationSchema={PasswordFormSchema}
                    enableReinitialize
                    onSubmit={handleSubmit}
                  >
                    {({ values, setFieldValue }) => (
                      <Form>
                        <div>
                          {/* Agency Details */}
                          <div className='mt-8'>
                            <div>
                              <div className='relative mt-4'>
                                <label
                                  htmlFor='password'
                                  className='absolute top-2 block px-4 text-sm text-gray-500'
                                >
                                  New Password
                                </label>
                                <Field
                                  id='password'
                                  name='password'
                                  className='border-2 border-[#ECE9E9] w-full pb-2 px-4 pt-7 rounded-md'
                                  type='password'
                                />
                                <div className='form-error'>
                                  <ErrorMessage name='password' />
                                </div>
                              </div>
                              <div className='relative mt-4'>
                                <label
                                  htmlFor='confirmPassword'
                                  className='absolute top-2 block px-4 text-sm text-gray-500'
                                >
                                  Confirm Password
                                </label>
                                <Field
                                  id='confirmPassword'
                                  name='confirmPassword'
                                  className='border-2 border-[#ECE9E9] w-full pb-2 px-4 pt-7 rounded-md'
                                  type='password'
                                />
                                <div className='form-error'>
                                  <ErrorMessage name='confirmPassword' />
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className='mt-20'>
                            <LoaderButton
                              disabled={false}
                              type='submit'
                              className='bg-primary text-white text-sm font-semibold rounded-lg w-full '
                              isLoading={setPasswordMutation.isLoading}
                            >
                              Confirm
                            </LoaderButton>
                          </div>
                        </div>
                      </Form>
                    )}
                  </Formik>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <StaticNav staticPages={data.staticPages} name={data.name} />
    </div>
  );
};

export default ResetPassword;
