import React, { FC } from 'react';
import { IBooking, IFlightSingle } from 'app/types';
import useDialog from 'app/hooks/use-dialog';
import { formatCurrency } from 'app/utils/currency-utils';
import FareSummarySideDrawer from './components/FareSummarySideDrawer/FareSummarySideDrawer';

interface IBookedPartialTicketPriceDetailsProps {
  flightDetails: IFlightSingle | IBooking;
}

const BookedPartialTicketPriceDetails: FC<
  IBookedPartialTicketPriceDetailsProps
> = (props) => {
  const { flightDetails } = props;
  const chargesDialog = useDialog();
  return (
    <>
      {flightDetails.isTaxed && (
        <div className='sticky top-[calc(var(--navbar-height)+16px)]'>
          <div className='card px-5 py-4 mb-4  max-h-[calc(100vh-var(--navbar-height)-32px)] overflow-auto no-scrollbar'>
            <div className='flex  items-center justify-between'>
              <div className='font-semibold'>Fare Summary </div>
              <div
                onClick={chargesDialog.show}
                className='text-blue-500 text-sm font-bold cursor-pointer'
              >
                View Fare Details
              </div>
            </div>

            <div className='mt-8'>
              <div className='flex justify-between items-center text-sm'>
                <div>Base Fare</div>
                <div>
                  {formatCurrency(flightDetails.taxDetails.totalBaseFare)}
                </div>
              </div>
              <div className='flex justify-between items-center text-sm mt-4'>
                <div>Fee & Surcharges</div>
                <div>
                  {formatCurrency(flightDetails.taxDetails.totalTaxesAndFees)}
                </div>
              </div>

              <div className='flex justify-between items-center text-sm mt-4 font-semibold'>
                <div>Original Fare</div>
                <div>
                  {formatCurrency(flightDetails.taxDetails.originalFare)}
                </div>
              </div>

              <div className='flex justify-between items-center text-sm mt-4'>
                <div>Mgt. Fee</div>
                <div>
                  {formatCurrency(flightDetails.taxDetails.managementFees)}
                </div>
              </div>
              <div className='flex justify-between items-center text-sm mt-4 '>
                <div>Mgt. Fee GST</div>
                <div>
                  {formatCurrency(flightDetails.taxDetails.managementFeesGST)}
                </div>
              </div>
              <div className='flex justify-between items-center text-sm mt-4 font-semibold'>
                <div>Mgt. Fee Total</div>
                <div>
                  {formatCurrency(flightDetails.taxDetails.managementFeesTotal)}
                </div>
              </div>
            </div>

            <div className='font-semibold flex justify-between items-center mt-8'>
              <div>Total Amount</div>
              <div>{formatCurrency(flightDetails.taxDetails.totalFare)}</div>
            </div>
          </div>
        </div>
      )}
      {!flightDetails.isTaxed && (
        <div className='card px-5 py-4 mb-4 sticky top-[calc(var(--navbar-height)+16px)] max-h-[calc(100vh-var(--navbar-height)-32px)] overflow-auto no-scrollbar'>
          <div className='font-semibold'>Fare Summary </div>

          <div className='mt-8'>
            <div className='flex justify-between items-center text-sm'>
              <div>Base Fare</div>
              <div>
                {formatCurrency(flightDetails.priceDetail.totalBaseFare)}
              </div>
            </div>
            <div className='flex justify-between items-center text-sm mt-4'>
              <div>Fees & Surcharges</div>
              <div>
                {formatCurrency(flightDetails.priceDetail.totalTaxesAndFees)}
              </div>
            </div>
          </div>
          <div className='font-semibold flex justify-between items-center mt-8'>
            <div>Total Amount</div>
            <div>{formatCurrency(flightDetails.priceDetail.totalFare)}</div>
          </div>
        </div>
      )}
      <FareSummarySideDrawer
        onClose={chargesDialog.hide}
        show={chargesDialog.isOpen}
        booking={flightDetails}
      />
    </>
  );
};

export default BookedPartialTicketPriceDetails;
