const Icon = (props: any) => (
  <svg
    width={24}
    height={24}
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <g opacity={0.5}>
      <circle
        cx={13}
        cy={8.66669}
        r={2.75}
        stroke='currentColor'
        strokeLinecap='round'
      />
      <path
        d='M14.88 7.02a2.209 2.209 0 11.807 3.018M11.12 7.02a2.209 2.209 0 10-.807 3.018'
        stroke='currentColor'
      />
      <path
        d='M13 13.542c4.486 0 5.595 3.684 5.868 5.505.083.546-.357.995-.91.995H8.042c-.553 0-.993-.449-.91-.995.273-1.82 1.382-5.505 5.868-5.505z'
        stroke='currentColor'
        strokeLinecap='round'
      />
      <path
        d='M20.927 16.79l-.487.117.486-.117zm-6.75-3.155l-.368-.337-.62.68.907.15.082-.493zm4.436 4.24l-.48.139.104.361h.376v-.5zm-1.821-4.917c1.15 0 1.952.575 2.534 1.369.592.808.93 1.817 1.114 2.58l.973-.234c-.198-.818-.576-1.975-1.28-2.937-.715-.977-1.79-1.778-3.341-1.778v1zm-2.245 1.014c.548-.6 1.268-1.014 2.245-1.014v-1c-1.305 0-2.28.569-2.984 1.34l.739.674zm-.451.156c2.515.418 3.578 2.299 4.037 3.886l.96-.278c-.508-1.757-1.769-4.085-4.833-4.595l-.164.987zm5.924 3.247h-1.407v1h1.407v-1zm.42-.468c.054.22-.11.468-.42.468v1c.873 0 1.617-.773 1.393-1.702l-.973.234zM11.822 13.635l.082.493.908-.15-.62-.68-.37.337zM5.074 16.79l-.486-.117.486.117zm2.313 1.085v.5h.376l.104-.361-.48-.139zm1.821-4.917c.977 0 1.697.413 2.245 1.014l.739-.674c-.704-.771-1.679-1.34-2.984-1.34v1zM5.56 16.907c.184-.763.522-1.772 1.114-2.58.582-.794 1.384-1.369 2.534-1.369v-1c-1.551 0-2.626.801-3.34 1.778-.705.962-1.083 2.119-1.28 2.937l.972.234zm.42.468c-.31 0-.474-.247-.42-.468l-.972-.234c-.225.93.52 1.702 1.392 1.702v-1zm1.407 0H5.98v1h1.407v-1zm.48.639c.46-1.587 1.522-3.468 4.037-3.886l-.164-.986c-3.064.509-4.325 2.837-4.833 4.594l.96.278z'
        fill='currentColor'
      />
    </g>
  </svg>
);

export default Icon;
