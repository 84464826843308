import { createListenerMiddleware, createSlice } from '@reduxjs/toolkit';

type IAuthData = {
  token: string;
  user: null | {
    name: string;
    phoneNumber: string;
    email: string;
    agency: {
      name: string;
      email: string;
      phoneNumber: string;
      logoUrl: string;
      primaryColor: string;
      secondaryColor: string;
      iconUrl: string;
      tagline: string;
      fareDisplayCount: number;
    };
    subAgency: {
      _id: string;
      name: string;
      phoneNumber: string;
      email: string;
    };
  };
};

const initialState: IAuthData = {
  token: null,
  user: null,
};

const TOKEN_KEY = 'fsa_token';

const getStorage = () => {
  if (localStorage.getItem(TOKEN_KEY) != null) {
    return localStorage;
  }

  if (sessionStorage.getItem(TOKEN_KEY) != null) {
    return sessionStorage;
  }

  return null;
};

const getInitialState = (): IAuthData => {
  const storage = getStorage();

  if (!storage) return initialState;

  return {
    token: storage.getItem(TOKEN_KEY),
    user: null,
  };
};

const authSlice = createSlice({
  name: 'auth',
  initialState: getInitialState(),
  reducers: {
    login(state, action) {
      const { token } = action.payload;

      state.token = token;
    },
    setToken(state, action) {
      state.token = action.payload;
    },
    setUser(state, action) {
      state.user = action.payload;
    },
    removeToken(state, action) {
      state.token = null;
    },
    removeUser(state, action) {
      state.user = null;
    },
    logout() {
      return initialState;
    },
  },
});

export const authActions = authSlice.actions;

export const authListenerMiddleware = createListenerMiddleware();
// Listen for login
authListenerMiddleware.startListening({
  actionCreator: authActions.login,
  effect: (action, listenerApi) => {
    const payload = action.payload;

    if (payload.rememberMe) {
      localStorage.setItem(TOKEN_KEY, payload.token);
    } else {
      sessionStorage.setItem(TOKEN_KEY, payload.token);
    }
  },
});
// Listen for logout
authListenerMiddleware.startListening({
  actionCreator: authActions.logout,
  effect: (action, listenerApi) => {
    const storage = getStorage();
    storage.removeItem(TOKEN_KEY);
  },
});

export default authSlice;
