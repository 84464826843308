import moment from 'moment';
import http from 'app/config/http';
import { FC } from 'react';
import contryList from 'country-list';
import { BackIcon, SeperatorDot } from 'app/icons';
import capitalize from 'lodash.capitalize';
import { useNavigate, useParams } from 'react-router-dom';
import { IBookingPassenger } from 'app/types';
import { useQuery } from '@tanstack/react-query';
import { formatCurrency } from 'app/utils/currency-utils';
import BgOverlay from 'app/components/BgOverlay/BgOverlay';
import PageLoader from 'app/components/PageLoader/PageLoader';
import {
  formatDOB,
  getGenderFromTitle,
  getRefundTypeBackgroundColor,
} from 'app/utils/common-utils';
import {
  formatDate,
  formatMinutesToTime,
  formatTime,
} from 'app/utils/date-utils';
import {
  calculateStops,
  getFormattedRefundableType,
  getFormattedStops,
} from 'app/utils/flight-utils';
import { DOB_MANDATORY } from 'app/config';

interface BookingDetailProps {}

const BookingDetail: FC<BookingDetailProps> = (props) => {
  const { bookingId } = useParams();
  const navigate = useNavigate();

  const query = useQuery(['bookingDetail'], async () => {
    const { data } = await http.get(
      `${process.env.REACT_APP_API_URL}/api/v1/sub-agent/series-ticket-bookings/${bookingId}`,
    );

    return data;
  });

  // const downloadMutation = useMutation(async () => {
  //     const { data } = await http.get(
  //         `${process.env.REACT_APP_API_URL}/api/v1/agent/bookings/${bookingId}/print-ticket`,
  //         {
  //             responseType: 'blob',
  //         }
  //     );

  //     return data;
  // });

  if (query.isLoading) return <PageLoader />;

  const booking = query.data;

  const getRefundableType = () =>
    booking.priceDetail.fareDescription.adult.refundableType;
  const getCabinClass = () =>
    booking.priceDetail.fareDescription.adult.cabinClass;
  //const getFareType = () => (booking.fareSupplier === '0' ? 'Series' : 'Normal');
  const isInternational = () =>
    booking.departure.airport.countryCode !==
    booking.arrival.airport.countryCode;

  return (
    <div>
      <BgOverlay />
      <div className='container min-h-full'>
        <div className='flex flex-col gap-4 sm:flex-row  sm:justify-between sm:items-center py-8'>
          <div className={`title flex items-center gap-2`}>
            <span className='md:hidden' onClick={() => navigate(-1)}>
              <BackIcon />
            </span>
            Booking details
          </div>

          <div className={`text-sm `}>
            Booking ID :{' '}
            <span className='font-semibold mx-1'>{booking._id}</span> | Booked
            On :{' '}
            <span className='font-semibold mx-1'>
              {moment(booking.updatedAt).format('DD MMM, YYYY')}
            </span>{' '}
            at{' '}
            <span className='font-semibold mx-1'>
              {moment(booking.updatedAt).format('h:mm:ss a')}
            </span>
          </div>
        </div>

        <div className='grid grid-cols-3 gap-9'>
          <div className='col-span-3 lg:col-span-2'>
            <div className='card px-8 py-7 mb-4'>
              <div className='text-xl font-bold'>Flight Details</div>

              <div className='mt-8'>
                <div className='flex justify-between gap-2'>
                  <div>
                    <div className='font-semibold'>
                      {booking.departure.airport.city}{' '}
                      <span className='font-light'> to </span>{' '}
                      {booking.arrival.airport.city}
                    </div>

                    <div className='flex items-center gap-2 text-sm mt-2'>
                      <div>
                        {formatDate(booking.departure.date, 'dddd, MMM D')}
                      </div>
                      <SeperatorDot />
                      <div>
                        <span className='mr-2'>
                          {getFormattedStops(calculateStops(booking.segments))}
                        </span>
                        <span>{formatMinutesToTime(booking.duration)}</span>
                      </div>
                    </div>
                  </div>

                  <div className='flex flex-col items-end'>
                    {getRefundableType() ? (
                      <div
                        className={`rounded-full py-[0.4rem] px-3 leading-none text-white text-xs font-medium ${getRefundTypeBackgroundColor(
                          getRefundableType(),
                        )}`}
                      >
                        {getFormattedRefundableType(getRefundableType())}
                      </div>
                    ) : (
                      <div className='py-[0.7rem]'></div>
                    )}
                    <div className='flex items-center gap-2 text-sm mt-2'>
                      <div>{capitalize(getCabinClass().toLowerCase())}</div>
                      {/* <SeperatorDot />
                                            <div>{getFareType()} Fare</div> */}
                    </div>
                  </div>
                </div>

                <div className='mt-7 space-y-6 overflow-auto'>
                  {booking.segments.map((segment: any, i: number) => (
                    <div key={i}>
                      <div className='flex justify-between items-center bg-white rounded-md gap-6'>
                        <div className='flex items-center gap-2 min-w-max'>
                          <img
                            crossOrigin='anonymous'
                            className='w-9 h-9'
                            src={segment.flight.logo}
                            alt=''
                          />
                          <div>
                            <div className='text-base font-semibold'>
                              {segment.flight.name}
                            </div>
                            <div className='text-xs text-black/70 mt-[2px]'>{`${segment.flight.code} ${segment.flight.number}`}</div>
                          </div>
                        </div>
                        <div className='flex justify-center gap-6 min-w-max'>
                          <div>
                            <div className='flex gap-2'>
                              <div className='font-medium'>
                                {formatDate(segment.departure.date)}
                              </div>
                              <div className='font-semibold'>
                                {formatTime(segment.departure.time)}
                              </div>
                            </div>
                            <div className='text-xs font-normal text-black/70 mt-[2px] w-28'>
                              {segment.departure.airport.name}
                              {segment.departure.airport.terminal &&
                                ', ' + segment.departure.airport.terminal}
                            </div>
                          </div>
                          <div className='flex flex-col items-center mt-1'>
                            <div className='text-xs font-normal text-black/70'>
                              {formatMinutesToTime(segment.duration)}
                            </div>
                            {/* Line */}
                            <div className='bg-[#ECAE0E] h-1 w-24 rounded-lg mt-1' />
                            {/*  */}
                          </div>
                          <div>
                            <div className='flex gap-2'>
                              <div className='font-medium'>
                                {formatDate(segment.arrival.date)}
                              </div>
                              <div className='font-semibold'>
                                {formatTime(segment.arrival.time)}
                              </div>
                            </div>
                            <div className='text-xs font-normal text-black/70 mt-[2px] w-28'>
                              {segment.arrival.airport.name}{' '}
                              {segment.arrival.airport.terminal &&
                                ', ' + segment.arrival.airport.terminal}
                            </div>
                          </div>
                        </div>
                        <div className='min-w-max'>
                          <div className='font-semibold'>
                            {
                              booking.priceDetail.fareDescription.adult.baggage
                                .checkIn
                            }
                          </div>
                          <div className='text-xs font-normal text-black/70 mt-[2px]'>
                            Check-in
                          </div>
                        </div>
                        <div className='min-w-max'>
                          <div className='font-semibold'>
                            {
                              booking.priceDetail.fareDescription.adult.baggage
                                .cabin
                            }
                          </div>
                          <div className='text-xs font-normal text-black/70 mt-[2px]'>
                            Cabin
                          </div>
                        </div>
                      </div>
                      {segment.layoverDuration !== 0 && (
                        <div className='mx-auto w-60 mt-6 bg-gray-100 rounded-full px-8 py-2 text-sm text-center'>
                          Layover Time -{' '}
                          <span className='font-semibold'>
                            {formatMinutesToTime(segment.layoverDuration)}
                          </span>
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              </div>
            </div>

            <div className='card px-8 py-7'>
              <div className='text-2xl font-bold mb-8'>Traveller Details</div>

              <div className='overflow-auto no-scrollbar'>
                <div className='grid grid-cols-5 gap-8 mb-4 min-w-[650px] '>
                  <div className='col-start-2 col-span-1'>
                    <div className='text-xs font-normal text-gray-500'>
                      Name
                    </div>
                  </div>
                  <div className='col-span-1'>
                    <div className='text-xs font-normal text-gray-500'>
                      Airline PNR
                    </div>
                  </div>
                  <div className='col-span-1'>
                    <div className='text-xs font-normal text-gray-500'>
                      eTicket Number
                    </div>
                  </div>
                  <div className='col-span-1'>
                    <div className='text-xs font-normal text-gray-500'>
                      Seat Number
                    </div>
                  </div>
                </div>
                {booking.passengers.adults.map((row: any, i: number) => (
                  <PassengerDetail
                    index={i}
                    row={row}
                    type='Adult'
                    key={i}
                    isInternational={isInternational()}
                  />
                ))}

                {booking.passengers.children.map((row: any, i: number) => (
                  <>
                    <PassengerDetail
                      index={i}
                      row={row}
                      type='Child'
                      key={i}
                      isInternational={isInternational()}
                    />
                  </>
                ))}

                {booking.passengers.infants.map((row: any, i: number) => (
                  <>
                    <PassengerDetail
                      index={i}
                      row={row}
                      type='Infant'
                      key={i}
                      isInternational={isInternational()}
                    />
                  </>
                ))}
              </div>
            </div>
          </div>
          {/* side cards */}
          <div className=' col-span-3 lg:col-span-1'>
            <div className='card p-4 mb-4'>
              <div className='text-sm font-semibold'>Fare Summary</div>
              <div className='mt-8'>
                <div className='flex justify-between'>
                  <div className='text-sm  font-normal'>Base Fare</div>
                  <div className='text-sm font-normal'>
                    {formatCurrency(booking.priceDetail.totalBaseFare)}
                  </div>
                </div>
                <div className='mt-4 flex justify-between'>
                  <div className='text-sm  font-normal'>Fees & Surcharges</div>
                  <div className='text-sm  font-normal'>
                    {formatCurrency(booking.priceDetail.totalTaxesAndFees)}
                  </div>
                </div>
                {/* <div className="mt-4 flex justify-between">
                                    <div className="text-sm  font-normal">Discounts</div>
                                    <div className="text-sm  font-normal text-primary">₹ 123</div>
                                </div> */}
              </div>
              <div className='mt-8 text-base  font-semibold flex justify-between'>
                <div>Total Amount </div>
                <div>{formatCurrency(booking.priceDetail.totalFare)}</div>
              </div>
              <div className='mt-3 text-base  font-semibold flex justify-between'>
                <div>Payment (AGY BAL)</div>
                <div className='text-success'>
                  -{formatCurrency(booking.priceDetail.totalFare)}
                </div>
              </div>
              <div className='mt-8 text-base  font-semibold flex justify-between'>
                <div>Balance</div>
                <div className='text-success'>₹ 0</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <PrintOptionModa
            show={printDialog.isOpen}
            onClose={printDialog.hide}
            onSubmit={(value: any) => handlePrintTicket(value)}
        /> */}
    </div>
  );
};

interface PassengerDetailProps {
  index: number;
  type: string;
  row: IBookingPassenger;
  isInternational: boolean;
}

const PassengerDetail: FC<PassengerDetailProps> = (props) => {
  const { index, row, type, isInternational } = props;
  return (
    <>
      <div key={index} className='grid grid-cols-5 gap-8  min-w-[650px]'>
        <div className='col-span-1'>
          <div className='text-lg font-semibold'>{`${type} ${index + 1}`}</div>
        </div>
        <div className='col-span-1'>
          <div className='text-lg font-semibold'>
            {row.title} {row.firstName} {row.lastName}
            <div className='text-xs font-normal'>
              {getGenderFromTitle(row.title)}, {type}
            </div>
            {(type === 'Infant' || isInternational || DOB_MANDATORY) &&
              row.dob && (
                <div className='text-xs font-normal'>
                  (DOB - {formatDOB(row.dob, 'YYYY-MM-DD')})
                </div>
              )}
          </div>
        </div>
        <div className='col-span-1'>
          <div className='text-lg font-semibold'>{row.pnrs[0]?.number}</div>
        </div>
        <div className='col-span-1'>
          <div className='text-lg font-semibold'>
            {row.ticketNumbers[0]?.number || ''}
          </div>
        </div>
        <div className='col-span-1'>
          <div className='text-lg font-semibold'>-</div>
        </div>
      </div>
      {isInternational && (
        <div
          key={index}
          className='grid grid-cols-5 gap-8 mb-2 min-w-[650px] text-xs mt-1'
        >
          <div className='col-span-1'></div>
          <div className='col-span-1 flex flex-col justify-between'>
            <div className='text-xs font-normal text-gray-500'>
              Nationality :
            </div>
            <div className='text-sm font-normal'>
              {' '}
              {contryList.getName(row.passport.nationality)}{' '}
            </div>
          </div>
          <div className='col-span-1 flex flex-col justify-between '>
            <div className='text-xs font-normal text-gray-500'>Passport :</div>
            <div className='text-sm font-normal'> {row.passport.number} </div>
          </div>
          <div className='col-span-1 flex flex-col justify-between '>
            <div className='text-xs font-normal text-gray-500'>Issue :</div>
            <div className='text-sm font-normal'>
              {moment(row.passport.issueDate, 'YYYYMMDD').format('DD MMM YYYY')}
            </div>
          </div>
          <div className='col-span-1 flex flex-col justify-between '>
            <div className='text-xs font-normal text-gray-500'>Expiry :</div>
            {moment(row.passport.expiry, 'YYYYMMDD').format('DD MMM YYYY')}
          </div>
        </div>
      )}
    </>
  );
};

export default BookingDetail;
