import React, { FC, useState } from 'react';
import { EditPencilIcon } from 'app/icons';
import ProfileForm from '../ProfileForm/ProfileForm';
import PasswordForm from '../PasswordForm/PasswordForm';
import EditNameForm from '../EditNameForm/EditNameForm';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import http from 'app/config/http';
import { getInitials } from 'app/utils/common-utils';
import { useDispatch, useSelector } from 'react-redux';
import { TRootState } from 'app/store';
import { authActions } from 'app/store/auth';

interface EditDetailsProps {
  profile: any;
}

const EditDetails: FC<EditDetailsProps> = (props) => {
  const queryClient = useQueryClient();
  const { profile } = props;
  const dispatch = useDispatch();
  const auth = useSelector((state: TRootState) => state.auth);

  const [nameEditForm, setNameEditForm] = useState<boolean>(false);

  const profileUpdateMutation = useMutation(async (payload: any) => {
    const { data } = await http.put(
      `${process.env.REACT_APP_API_URL}/api/v1/sub-agent/profile`,
      payload,
    );

    return data;
  });

  const onSubmit = async (values: any) => {
    try {
      const res = await profileUpdateMutation.mutateAsync({
        name: values.name,
        // email: profile.email,
        phoneNumber: profile.phoneNumber,
      });

      setNameEditForm(false);

      queryClient.setQueryData(['profile'], () => {
        return {
          ...res,
        };
      });

      dispatch(
        authActions.setUser({
          ...auth.user,
          name: values.name,
        }),
      );

      toast.success('Name updated successfully');
    } catch (ex: any) {
      toast.error(ex?.data?.message || 'Some error occured, please try again.');
      console.log(ex);
    }
  };

  return (
    <>
      <div className='flex items-center gap-6'>
        {/* <img src={dp} alt="user dp" className="rounded-full h-24 w-24 object-cover" /> */}
        <div className='w-12 h-12 bg-primary/10 rounded-full flex items-center justify-center'>
          <div className='text-primary text-lg font-semibold'>
            {getInitials(profile.username)}
          </div>
        </div>

        {nameEditForm ? (
          <EditNameForm
            initialValues={{
              name: profile.name,
            }}
            onClose={() => setNameEditForm(false)}
            onSubmit={onSubmit}
          />
        ) : (
          <>
            <div>{profile.name}</div>
            <div onClick={() => setNameEditForm(true)}>
              <EditPencilIcon />
            </div>
          </>
        )}
      </div>

      <ProfileForm profile={profile} />
      <PasswordForm />
    </>
  );
};

export default EditDetails;
