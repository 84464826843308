import { ChevronRightIcon, SeperatorDot } from 'app/icons';
import React, { FC } from 'react';
import ReactPaginate from 'react-paginate';

interface PaginationRangeProps {
  handlePageChange: (value: any) => void;
  pageCount: number;
  PageNumber: number;
}

const PaginationRange: FC<PaginationRangeProps> = (props) => {
  const { handlePageChange, pageCount } = props;
  return (
    <ReactPaginate
      breakLabel={
        <>
          <SeperatorDot />
          <SeperatorDot />
          <SeperatorDot />
        </>
      }
      previousLabel={<ChevronRightIcon className='rotate-180' />}
      nextLabel={<ChevronRightIcon />}
      onPageChange={handlePageChange}
      pageRangeDisplayed={3}
      pageCount={pageCount}
      renderOnZeroPageCount={null}
      marginPagesDisplayed={1}
      containerClassName='pagination'
      pageClassName='page-item'
      previousClassName='page-item'
      nextClassName='page-item'
      pageLinkClassName='page-link'
      activeClassName='active'
      previousLinkClassName='page-link page-link-end'
      nextLinkClassName='page-link page-link-end'
      breakClassName='page-item'
      breakLinkClassName='page-link'
      forcePage={props.PageNumber}
    />
  );
};

export default PaginationRange;
