const Icon = (props: any) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={16}
    height={16}
    fill='currentColor'
    className='bi bi-funnel-fill'
    viewBox='0 0 16 16'
    {...props}
  >
    <path d='M1.5 1.5A.5.5 0 012 1h12a.5.5 0 01.5.5v2a.5.5 0 01-.128.334L10 8.692V13.5a.5.5 0 01-.342.474l-3 1A.5.5 0 016 14.5V8.692L1.628 3.834A.5.5 0 011.5 3.5v-2z' />
  </svg>
);

export default Icon;
