import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import dayjs from 'dayjs';
import minMax from 'dayjs/plugin/minMax';
import { Provider } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import SwiperCore, { Navigation } from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
import store from './app/store';
import AppRouter from './AppRouter';
dayjs.extend(minMax);

// Create a client
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      cacheTime: 0,
    },
  },
});

function App() {
  SwiperCore.use([Navigation]);

  return (
    <>
      <Provider store={store}>
        <QueryClientProvider client={queryClient}>
          <AppRouter />
        </QueryClientProvider>
      </Provider>
      <ToastContainer autoClose={3000} hideProgressBar={true} />
    </>
  );
}

export default App;
