import { FC, ReactNode } from 'react';
import styles from './PrintLayoutWrapper.module.scss';
import BackLink from 'app/components/BackLink/BackLink';

interface IPrintLayoutWrapper {
  children: ReactNode;
}

const PrintLayoutWrapper: FC<IPrintLayoutWrapper> = (props) => {
  const { children } = props;

  return (
    <>
      <div
        className={`${styles['print-btn-wrapper']} w-[643px] mx-auto mt-6 px-4`}
      >
        <div className='mb-5'>
          <BackLink />
        </div>
        <button
          className='btn-primary px-8 py-2 rounded-lg text-sm font-medium w-36 max-w-full'
          onClick={window.print}
        >
          Print
        </button>
      </div>

      <div
        className={`${styles['page-wrapper']} flex min-h-[calc(100vh)] h-full`}
      >
        {children}
      </div>
    </>
  );
};

export default PrintLayoutWrapper;
