import debounce from 'lodash.debounce';
import { FC, useState, useEffect, useCallback } from 'react';
import http from 'app/config/http';
import { IAirline } from 'app/types';

interface AirlineSearchFieldProps {
  selectedAirline: IAirline;
  onSelectedAirlineChange: (airline: IAirline) => void;
  placeholder: string;
  id?: string;
  className?: string;
}

const AirlineSearchField: FC<AirlineSearchFieldProps> = (props) => {
  const {
    selectedAirline,
    onSelectedAirlineChange,
    placeholder,
    id,
    className,
  } = props;

  const [value, setValue] = useState<string>('');
  const [suggestionList, setSuggestionList] = useState<IAirline[]>([]);
  const [showSuggestionList, setShowSuggestionList] = useState<boolean>(false);

  useEffect(() => {
    document.body.addEventListener('click', closeSuggestionList);

    return () => {
      document.body.removeEventListener('click', closeSuggestionList);
    };
  });

  const closeSuggestionList = () => {
    setShowSuggestionList(false);
  };

  const changeHandler = async (e: any) => {
    const _value = e.target.value.trim();
    setValue(_value);

    if (_value.length < 2) {
      closeSuggestionList();
      setSuggestionList([]);

      return;
    }

    const { data } = await http.get(
      `${process.env.REACT_APP_API_URL}/api/v1/sub-agent/airlines`,
      {
        params: {
          q: _value,
        },
      },
    );

    setSuggestionList(data);

    setShowSuggestionList(true);
  };

  const handleKeyUp = useCallback(debounce(changeHandler, 200), []);

  const handleFocus = () => {
    setValue('');
  };

  const handleBlur = () => {
    setValue('');
  };

  const handleAirlineSelect = (airline: any) => {
    setValue('');
    onSelectedAirlineChange(airline);
    closeSuggestionList();
  };

  return (
    <div className='w-full' onClick={(e) => e.stopPropagation()}>
      <div className='relative'>
        <input
          id={id}
          type='text'
          className={className}
          autoComplete='off'
          onFocus={handleFocus}
          onBlur={handleBlur}
          value={value}
          onKeyUp={handleKeyUp}
          onChange={(e) => setValue(e.target.value)}
        />

        {!value && (
          <div className='absolute top-0 left-0 w-full h-full flex items-center px-4 pointer-events-none '>
            <div className='w-full whitespace-nowrap overflow-hidden text-ellipsis'>
              {selectedAirline ? (
                <div className='flex justify-between items-center'>
                  <span className='font-medium text-lg'>
                    {' '}
                    {selectedAirline.name}
                  </span>
                  <span className='text-gray-500'>{selectedAirline.code}</span>
                </div>
              ) : (
                <span className='text-gray-400'>{placeholder}</span>
              )}
            </div>
          </div>
        )}

        {showSuggestionList && (
          <div className='absolute w-full max-h-60 overflow-y-auto z-40 bg-white rounded-md border border-gray-200 drop-shadow'>
            {suggestionList.length > 0 && (
              <ul>
                {suggestionList.map((airline, index) => (
                  <li
                    key={index}
                    className='py-3 px-4 cursor-pointer'
                    onClick={() => handleAirlineSelect(airline)}
                  >
                    {`${airline.name} (${airline.code})`}
                  </li>
                ))}
              </ul>
            )}

            {suggestionList.length === 0 && (
              <div
                className='py-3 px-4 cursor-pointer text-gray-500'
                onClick={closeSuggestionList}
              >
                No flights found
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default AirlineSearchField;
