import { FC } from 'react';
import { ErrorMessage, Field, Form, Formik, FormikHelpers } from 'formik';
import http from 'app/config/http';
import { useMutation } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

const PasswordFormSchema = Yup.object().shape({
  current_password: Yup.string().trim().required('This field is required'),
  new_password: Yup.string().trim().required('This field is required'),
  confirm_password: Yup.string()
    .trim()
    .required('This field is required')
    .when('new_password', {
      is: (val: any) => (val && val.length > 0 ? true : false),
      then: Yup.string()
        .trim()
        .oneOf([Yup.ref('new_password')], 'Passwords do not match'),
    }),
});

interface PasswordFormProps {}

const PasswordForm: FC<PasswordFormProps> = (props) => {
  const passwordUpdateMutation = useMutation(async (payload: any) => {
    const { data } = await http.put(
      `${process.env.REACT_APP_API_URL}/api/v1/sub-agent/profile/password`,
      payload,
    );

    return data;
  });

  const passwordFormInitialValues = {
    current_password: '',
    new_password: '',
    confirm_password: '',
  };

  const handlePasswordChange = async (
    values: any,
    formikHelpers: FormikHelpers<any>,
  ) => {
    try {
      await passwordUpdateMutation.mutateAsync({
        password: values.current_password,
        newPassword: values.new_password,
      });

      formikHelpers.resetForm();

      toast.success('Password changed successfully');
    } catch (ex: any) {
      const errorMsg = ex.data?.message
        ? ex.data?.message
        : 'Something went wrong';
      toast.error(errorMsg);
      console.log(ex);
    }
  };

  return (
    <div className='mt-16'>
      <div className='text-base font-semibold'>Change Password</div>
      <div className='mt-4'>
        <Formik
          initialValues={passwordFormInitialValues}
          validationSchema={PasswordFormSchema}
          onSubmit={handlePasswordChange}
        >
          <Form action=''>
            <Field
              name='current_password'
              type='password'
              placeholder='Enter old password'
              className='form-control md:w-3/4'
            />
            <div className='form-error'>
              <ErrorMessage name='current_password' />
            </div>

            <Field
              name='new_password'
              type='password'
              id='newPass'
              placeholder='Enter new password'
              className='form-control md:w-3/4 mt-4'
            />
            <div className='form-error'>
              <ErrorMessage name='new_password' />
            </div>

            <Field
              name='confirm_password'
              type='password'
              id='confirmPass'
              placeholder='Confirm new password'
              className='form-control md:w-3/4 mt-4'
            />
            <div className='form-error'>
              <ErrorMessage name='confirm_password' />
            </div>
            <button type='submit' className='btn btn-primary mt-8 block'>
              Change Password
            </button>
          </Form>
        </Formik>
      </div>
    </div>
  );
};

export default PasswordForm;
