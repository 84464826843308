import { FC } from 'react';
import BgOverlay from 'app/components/BgOverlay/BgOverlay';
import SubAgencyUserAddForm from './components/SubAgencyUserAddForm/SubAgencyUserAddForm';
import { useNavigate } from 'react-router-dom';
import { BackIcon } from 'app/icons';

interface SubAgencyUserAddProps {}

const SubAgencyUserAdd: FC<SubAgencyUserAddProps> = (props) => {
  const navigate = useNavigate();

  return (
    <>
      {' '}
      <BgOverlay />
      <div className='container min-h-full'>
        <div className='flex py-8'>
          <div className='title text-secondary-fg flex items-center gap-3'>
            <span className='md:hidden' onClick={() => navigate(-1)}>
              <BackIcon />
            </span>{' '}
            Add User
          </div>
        </div>
        <div className='card p-9 mb-4'>
          <SubAgencyUserAddForm />
        </div>
      </div>
    </>
  );
};

export default SubAgencyUserAdd;
