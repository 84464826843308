import LoaderButton from 'app/components/LoaderButton/LoaderButton';
import Modal from 'app/components/Modal/Modal';
import React, { FC, ReactNode } from 'react';

interface StatusUpdateModalProps {
  show: boolean;
  message: ReactNode;
  onClose: () => void;
  onConfirm: () => void;
  isLoading?: boolean;
}

const StatusUpdateModal: FC<StatusUpdateModalProps> = (props) => {
  return (
    <Modal
      className='px-10 py-10 w-[520px]'
      show={props.show}
      onClose={props.onClose}
    >
      <div className='text-gray-900 mt-4 text-center'>{props.message}</div>
      <div className='flex justify-center gap-4 mt-6'>
        <LoaderButton
          className='bg-primary text-white px-6 text-sm rounded-lg'
          onClick={props.onConfirm}
          isLoading={props.isLoading}
        >
          Update
        </LoaderButton>
        <button
          className='bg-gray-200 text-gray-600 px-6 text-sm rounded-lg'
          onClick={props.onClose}
        >
          Cancel
        </button>
      </div>
    </Modal>
  );
};

StatusUpdateModal.defaultProps = {
  isLoading: false,
};

export default StatusUpdateModal;
