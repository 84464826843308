export const DOB_MANDATORY = true;
export const TRIPJACK_ERROR_INDICATOR = '#?#?';

export const EXEMPT_PNR = 'OFFER_FARE_WITHOUT_PNR';

export const SHOW_NON_INFANT_DOB = false;

// weather to show dob of adult and child on print ticket
// dob of infant will always be shown
export const SHOW_DOB_ON_PRINT_TICKET = false;
