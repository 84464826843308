const Icon = (props: any) => (
  <svg
    width='20px'
    height='20px'
    viewBox='0 0 20 20'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <g
      transform='translate(-134 -708) translate(134 708) translate(1.667 .833)'
      stroke='currentColor'
      strokeWidth={1.5}
      fill='none'
      fillRule='evenodd'
      strokeLinecap='round'
    >
      <rect x={0} y={1.83333333} width={16.5} height={16.5} rx={2} />
      <path d='M11.9166667 0L11.9166667 3.66666667' />
      <path d='M4.58333333 0L4.58333333 3.66666667' />
      <path d='M0 7.33333333L16.5 7.33333333' />
    </g>
  </svg>
);

export default Icon;
