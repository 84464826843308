import { CrossIcon } from 'app/icons';
import { AnimatePresence, motion } from 'framer-motion';
import { FC, ReactNode } from 'react';
import { createPortal } from 'react-dom';

const backdropAnimation = {
  variants: {
    hidden: {
      opacity: 0,
    },
    visible: {
      opacity: 1,
    },
  },
  initial: 'hidden',
  animate: 'visible',
  exit: 'hidden',
};

const drawerAnimation = {
  variants: {
    hidden: {
      x: '100vh',
    },
    visible: {
      x: 0,
    },
  },
  transition: { duration: 0.35, ease: 'linear' },
  initial: 'hidden',
  animate: 'visible',
  exit: 'hidden',
};
/**
 * SideDrawer
 */
interface SideDrawerProps {
  show: boolean;
  children: ReactNode;
  onClose: () => void;
  onBackdropClick?: () => void;
  className?: string;
}
const SideDrawer: FC<SideDrawerProps> = (props) => {
  const { show, onClose, children, onBackdropClick, className } = props;

  return createPortal(
    <AnimatePresence initial={false} mode='wait'>
      {show && (
        <>
          {/* Backdrop */}
          <motion.div
            {...backdropAnimation}
            className='fixed top-0 left-0 w-full h-full flex justify-center items-center z-[90] bg-black/30'
            onClick={onBackdropClick || onClose}
          >
            {/* Drawer */}
            <div className='fixed left-0 top-0 w-full h-full overflow-x-hidden overflow-y-auto z-[91]'>
              <motion.aside
                {...drawerAnimation}
                className={[
                  'relative bg-white z-[92] max-w-full min-h-full ml-auto p-6 ',
                  className,
                ].join(' ')}
                onClick={(e) => e.stopPropagation()}
              >
                <div>
                  {/* Cross Button */}
                  <button
                    type='button'
                    className='absolute top-8 right-6 cursor-pointer z-[92]'
                    onClick={onClose}
                  >
                    <CrossIcon className='w-5 h-5 text-secondary-500' />
                  </button>
                </div>

                {/*Children */}
                <div className='mt-4'>{children}</div>
              </motion.aside>
            </div>
          </motion.div>
        </>
      )}
    </AnimatePresence>,
    document.getElementById('drawer-root'),
  );
};

export default SideDrawer;
