/* eslint-disable jsx-a11y/anchor-is-valid */
import { Dispatch, FC, SetStateAction } from 'react';

interface ProfileNavProps {
  activeTab: string;
  setActiveTab: Dispatch<SetStateAction<string>>;
}

const ProfileNav: FC<ProfileNavProps> = (props) => {
  const { activeTab, setActiveTab } = props;

  return (
    <ul className='flex text-base justify-between sm:justify-start font-normal gap-4 '>
      <li className='tab-item'>
        <a
          className={`nav-tab ${
            activeTab === '1'
              ? 'font-bold cursor-default text-primary underline underline-offset-4'
              : ''
          }`}
          onClick={() => setActiveTab('1')}
        >
          Personal
        </a>
      </li>

      <li className='tab-item'>
        <a
          className={`nav-tab ${
            activeTab === '2'
              ? 'font-bold cursor-default text-primary underline underline-offset-4'
              : ''
          }`}
          onClick={() => setActiveTab('2')}
        >
          Agency
        </a>
      </li>
      <li className='tab-item'>
        <a
          className={`nav-tab ${
            activeTab === '3'
              ? 'font-bold cursor-default text-primary underline underline-offset-4'
              : ''
          }`}
          onClick={() => setActiveTab('3')}
        >
          Bank <span className='hidden md:inline'>Account</span>
          <span className='md:hidden'>A/C</span>
        </a>
      </li>
    </ul>
  );
};

export default ProfileNav;
