import BgOverlay from 'app/components/BgOverlay/BgOverlay';
import React, { FC } from 'react';
import SeriesInventoryAddForm from './components/SeriesInventoryAddForm/SeriesInventoryAddForm';

interface SeriesInventoryAddProps {}

const SeriesInventoryAdd: FC<SeriesInventoryAddProps> = (props) => {
  return (
    <>
      {' '}
      <BgOverlay />
      <div className='container min-h-full'>
        <div className='flex py-8'>
          <div className='title text-secondary-fg'>Add Series Inventory</div>
        </div>
        {/* <div className="card p-9 mb-4"> */}

        <SeriesInventoryAddForm />
        {/* </div> */}
      </div>
    </>
  );
};

export default SeriesInventoryAdd;
