import moment from 'moment';
import http from 'app/config/http';
import { IBooking } from 'app/types';
import useDialog from 'app/hooks/use-dialog';
import Modal from 'app/components/Modal/Modal';
import { FC, useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import Spinner from 'app/components/Spinner/Spinner';
import { formatCurrency } from 'app/utils/currency-utils';
import BgOverlay from 'app/components/BgOverlay/BgOverlay';
import MarkupModal from './components/MarkupModal/MarkupModal';
import { createSearchParams, useNavigate } from 'react-router-dom';
import PrintOptionModal from './components/PrintOptionModal/PrintOptionModal';
import FlightDetails from 'app/modules/booking/components/FlightDetails/FlightDetails';
import PassengerDetails from 'app/modules/booking/components/PassengerDetails/PassengerDetails';
import BookedTicketPriceDetails from 'app/components/BookedTicketPriceDetails/BookedTicketPriceDetails';

interface BookingDetailDialogProps {
  show: boolean;
  onClose: () => void;
  bookingId: string;
}

const BookingDetailDialog: FC<BookingDetailDialogProps> = (props) => {
  const { show, onClose, bookingId } = props;
  const navigate = useNavigate();

  const printDialog = useDialog();
  const markupDialog = useDialog();

  const [bgColor, setBgColor] = useState<string>('bg-secondary');
  const [txtColor, setTxtColor] = useState<string>('text-secondary-fg');

  const bookingQuery = useQuery<IBooking>(['booking', bookingId], async () => {
    const { data } = await http.get(
      `${process.env.REACT_APP_API_URL}/api/v1/sub-agent/bookings/${bookingId}`,
    );

    return data;
  });

  const booking = bookingQuery.data;

  useEffect(() => {
    if (!bookingQuery.data) return;

    const currentTime = moment().format('YYYYMMDDhhmmss');

    let bgColor = '';
    let txtColor = '';

    if (booking.status === 'CANCELLED') {
      bgColor = 'bg-danger';
      txtColor = 'text-white';
    } else if (
      `${booking.departure.date}${booking.departure.time}` >= currentTime &&
      booking.status === 'COMPLETED'
    ) {
      bgColor = 'bg-secondary';
      txtColor = 'text-secondary-fg';
    } else if ('COMPLETED') {
      bgColor = 'bg-success';
      txtColor = 'text-white';
    }

    setBgColor(bgColor);
    setTxtColor(txtColor);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bookingQuery.data]);

  const handlePrintTicket = (values: any) => {
    printDialog.hide();

    navigate({
      pathname: `/bookings/${booking._id}/ticket`,
      search: createSearchParams({
        ...values,
      }).toString(),
    });
  };

  if (bookingQuery.isLoading)
    return (
      <Modal show={show} onClose={onClose} className=' bg-[#EEEEEE]'>
        <div className='flex justify-center items-center'>
          <Spinner />
        </div>
      </Modal>
    );

  const isCancelled = booking.status === 'CANCELLED';

  return (
    <Modal show={show} onClose={onClose} className='px-0 pt-0 bg-[#EEEEEE]'>
      <BgOverlay bgClass={bgColor} />

      <div className='min-h-full container'>
        <div className='flex flex-col gap-4 sm:flex-row  sm:justify-between sm:items-center py-8'>
          <div className={`title ${txtColor}`}>Booking details</div>

          <div className={`text-sm ${txtColor}`}>
            Booking ID :{' '}
            <span className='font-semibold mx-1'>{booking._id}</span> | Booked
            On :{' '}
            <span className='font-semibold mx-1'>
              {moment(booking.updatedAt).format('DD MMM, YYYY')}
            </span>{' '}
            at{' '}
            <span className='font-semibold mx-1'>
              {moment(booking.updatedAt).format('h:mm:ss a')}
            </span>
          </div>
        </div>

        <div className='grid grid-cols-3 gap-9'>
          <div className='col-span-3 lg:col-span-2'>
            <FlightDetails booking={booking} />
            <PassengerDetails booking={booking} />
          </div>
          {/* side cards */}
          <div className=' col-span-3 lg:col-span-1'>
            {/* ticked Share , print , donwload */}
            <div className='card p-4 mb-4 '>
              <div className='text-sm font-semibold'>Ticket(s)</div>
              {/* <a className={`block text-sm font-normal text-blue-500 mt-8`}>Share Ticket</a> 

                            <a className={`block text-sm font-normal text-blue-500 mt-4`}>Download Ticket</a> */}

              {/* <Link
                                to={`/bookings/${booking._id}/ticket`}
                                // target="_blank"
                                className="block text-sm text-primary cursor-pointer mt-4"
                            >
                                Print Ticket
                            </Link> */}

              <button
                onClick={printDialog.show}
                className={`mt-4 block text-sm text-blue-500   ${isCancelled ? '' : 'cursor-pointer'} `}
                disabled={isCancelled}
              >
                Print Ticket
              </button>
            </div>

            {/* ticked change / cancel */}
            {/* <div className="card p-4 mb-4">
                            <div className="text-sm font-semibold">Change Ticket</div>
                            <a className={`block text-sm font-normal text-blue-500 mt-8`}>Request Change</a>
                            <a className={`block text-sm font-normal text-danger mt-4`}>Cancel Ticket</a>
                        </div> */}

            {/* <div className='card p-4 mb-4'>
              <div className='text-sm font-semibold'>Fare Summary</div>
              <div className='mt-8'>
                <div className='flex justify-between'>
                  <div className='text-sm  font-normal'>Base Fare</div>
                  <div className='text-sm font-normal'>
                    {formatCurrency(booking.priceDetail.totalBaseFare)}
                  </div>
                </div>
                <div className='mt-4 flex justify-between'>
                  <div className='text-sm  font-normal'>Fees & Surcharges</div>
                  <div className='text-sm  font-normal'>
                    {formatCurrency(
                      booking.priceDetail.totalTaxesAndFees +
                        booking.priceDetail.additionalMarkup,
                    )}
                  </div>
                </div>
                                // <div className="mt-4 flex justify-between">
                                //     <div className="text-sm  font-normal">Discounts</div>
                                //     <div className="text-sm  font-normal text-primary">₹ 123</div>
                                // </div> 
              </div>
              <div className='mt-8 text-base  font-semibold flex justify-between'>
                <div>
                  Total Amount
                  {!isCancelled && (
                    <span
                      className={`ml-2 text-xs font-light text-blue-500 ${
                        isCancelled ? '' : 'cursor-pointer'
                      } `}
                      onClick={markupDialog.show}
                    >
                      Markup
                    </span>
                  )}
                </div>
                <div>
                  {formatCurrency(
                    booking.priceDetail.totalFare +
                      booking.priceDetail.additionalMarkup,
                  )}
                </div>
              </div>
              <div className='mt-3 text-base  font-semibold flex justify-between'>
                <div>Payment (AGY BAL)</div>
                <div className='text-success'>
                  -
                  {formatCurrency(
                    booking.priceDetail.totalFare +
                      booking.priceDetail.additionalMarkup,
                  )}
                </div>
              </div>
              <div className='mt-8 text-base  font-semibold flex justify-between'>
                <div>Balance</div>
                <div className='text-success'>₹ 0</div>
              </div>
            </div> */}

            <BookedTicketPriceDetails
              booking={booking}
              isCancelledOrPending={false}
            />
          </div>
        </div>
      </div>
      <PrintOptionModal
        show={!isCancelled && printDialog.isOpen}
        onClose={printDialog.hide}
        onSubmit={(value: any) => handlePrintTicket(value)}
      />
      <MarkupModal
        show={!isCancelled && markupDialog.isOpen}
        onClose={markupDialog.hide}
        bookingId={bookingId}
        currentMarkup={booking.priceDetail.additionalMarkup}
        // onSubmit={(value: any) => handlePrintTicket(value)}
      />
    </Modal>
  );
};

export default BookingDetailDialog;
