import { useMutation, useQueryClient } from '@tanstack/react-query';
import LoaderButton from 'app/components/LoaderButton/LoaderButton';
import Modal from 'app/components/Modal/Modal';
import http from 'app/config/http';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import React, { FC } from 'react';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

interface MarkupModalProps {
  show: boolean;
  onClose: () => void;
  bookingId: string;
  currentMarkup: number;
  // onSubmit: (val: any) => void;
}

const MarkupModal: FC<MarkupModalProps> = (props) => {
  const { bookingId, onClose, show, currentMarkup } = props;
  const queryClient = useQueryClient();

  const markupMutation = useMutation(async (values: any) => {
    const { data } = await http.put(
      `${process.env.REACT_APP_API_URL}/api/v1/sub-agent/bookings/${bookingId}/additional-markup`,
      values,
    );

    return data;
  });

  const handleSubmit = async (values: any) => {
    // make backend request here

    try {
      await markupMutation.mutateAsync({
        additionalMarkup: values.markup,
      });
      queryClient.fetchQuery(['booking', bookingId]);
      toast.success('Markup updated successfully');
    } catch (ex: any) {
      toast.error(ex?.data?.message || 'Some error occured, please try again.');
    } finally {
      onClose();
    }
  };

  return (
    <Modal show={show} onClose={onClose} className='w-[520px]'>
      <div className='text-2xl font-bold mb-8'>Add Markup </div>
      <Formik
        initialValues={{ markup: currentMarkup }}
        enableReinitialize
        validationSchema={Yup.object({
          markup: Yup.number()
            .typeError('You must specify a number')
            .required('This field is required')
            .min(0, 'The amount must be greater than Zero'),
        })}
        onSubmit={handleSubmit}
      >
        <Form>
          <Field
            type='text'
            name='markup'
            placeholder='Add Markup'
            className='form-control'
          />
          <div className='form-error'>
            <ErrorMessage name={'markup'} />
          </div>
          <LoaderButton className='bg-primary text-white text-sm font-semibold px-8 mt-8 rounded-lg'>
            Update
          </LoaderButton>
        </Form>
      </Formik>
    </Modal>
  );
};

export default MarkupModal;
