const Icon = (props: any) => {
  return (
    <svg
      width={18}
      height={22}
      viewBox='0 0 18 22'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <ellipse
        cx={9.00112}
        cy={5.44388}
        rx={4.57143}
        ry={4.44388}
        fill='currentColor'
        stroke='currentColor'
        strokeWidth={1.8}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M12.556 14.334H5.444c-.412 0-.618 0-.792.015a4 4 0 00-3.637 3.637C1 18.16 1 18.366 1 18.778c0 .206 0 .309.008.396a2 2 0 001.818 1.818c.087.008.19.008.396.008h11.556c.206 0 .31 0 .396-.008a2 2 0 001.818-1.818c.008-.087.008-.19.008-.396 0-.412 0-.618-.015-.792a4 4 0 00-3.637-3.637c-.174-.015-.38-.015-.792-.015z'
        fill='currentColor'
        stroke='currentColor'
        strokeWidth={1.8}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default Icon;
