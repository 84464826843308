import { IAgency, ISubAgency } from 'app/types';
import React, { FC } from 'react';

interface ITicketHeader {
  subAgency: ISubAgency;
  agency: IAgency;
}
const TickerHeader: FC<ITicketHeader> = (props) => {
  const { agency, subAgency } = props;

  const getFormattedAddress = (address: any) => {
    return `${address.line}, ${address.city}, ${address.state}`;
  };

  const getFormattedContactDetails = (email: string, number: string = '') => {
    return `${email} ${number}`;
  };

  return (
    <div className='flex justify-between pt-8 px-10'>
      <img className='h-9' src={subAgency.logoUrl} alt='' />
      <div className='flex flex-col text-right'>
        <div className='font-semibold text-primary'>
          {subAgency ? subAgency.name : agency.name}
        </div>
        <div className='text-xs font-medium mt-1'>
          {subAgency
            ? getFormattedAddress(subAgency.address)
            : getFormattedAddress(agency.address)}
        </div>
        <div className='text-xs font-medium mt-1'>
          {subAgency
            ? getFormattedContactDetails(subAgency.email, subAgency.phone)
            : getFormattedContactDetails(agency.email, subAgency.phone)}
        </div>
      </div>
    </div>
  );
};

export default TickerHeader;
