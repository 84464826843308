const Icon = (props: any) => (
  <svg
    width={24}
    height={24}
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M20.71 5.69c-.424.076-1.003.268-1.847.55L11.087 8.83c-1.219.407-2.105.703-2.737.966-.656.274-.92.469-1.019.614a1.5 1.5 0 000 1.678c.098.145.363.34 1.019.614.632.263 1.518.56 2.737.966l.057.018c.431.144.73.244.989.408a2.5 2.5 0 01.772.773c.165.258.264.557.408.988l.018.057c.407 1.219.703 2.105.966 2.737.274.656.469.92.614 1.019a1.5 1.5 0 001.678 0c.145-.098.34-.363.614-1.019.264-.632.56-1.518.966-2.737l2.592-7.776c.281-.844.473-1.424.548-1.847.076-.428.001-.525-.037-.562-.037-.038-.134-.113-.562-.037zm-.175-.984c.51-.09 1.045-.085 1.445.314.399.4.404.935.314 1.445-.09.505-.306 1.154-.57 1.947l-.015.04-2.592 7.778-.007.021c-.397 1.192-.705 2.114-.984 2.784-.27.65-.559 1.18-.977 1.463a2.5 2.5 0 01-2.798 0c-.418-.283-.706-.814-.977-1.463-.28-.67-.587-1.592-.984-2.784l-.007-.021c-.17-.51-.232-.684-.322-.827a1.5 1.5 0 00-.464-.464c-.143-.09-.316-.152-.826-.322l-.023-.007c-1.191-.397-2.113-.705-2.783-.984-.65-.27-1.18-.559-1.463-.977a2.5 2.5 0 010-2.798c.283-.418.814-.706 1.463-.977.67-.28 1.592-.587 2.784-.984l.021-.007 7.777-2.592.041-.014c.793-.264 1.442-.48 1.947-.57z'
      fill='currentColor'
    />
  </svg>
);

export default Icon;
