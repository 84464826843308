import * as Yup from 'yup';
import { FC } from 'react';
import moment from 'moment';
import { toast } from 'react-toastify';
import { IFlightSearchForm } from 'app/types';
import { CalendarIcon, SwapIcon } from 'app/icons';
import { DatePicker } from 'app/library/datepicker';
import { generateNumberArray } from 'app/utils/list-utils';
import { createSearchParams, useNavigate } from 'react-router-dom';
import AirportSearch from './components/AirportSearch/AirportSearch';
import toAirportSuggestions from '../constants/toAirportSuggestions';
import { ErrorMessage, Field, Form, Formik, FormikProps } from 'formik';
import fromAirportSuggestions from '../constants/fromAirportSuggestions';
// import DatePicker from 'react-datepicker';
// import AirportSearchField from 'app/components/AirportSearchField/AirportSearchField';

const cabinClass = [
  {
    key: 'Economy',
    value: 'ECONOMY',
  },
  {
    key: 'Premium Economy',
    value: 'PREMIUM_ECONOMY',
  },
  {
    key: 'Business',
    value: 'BUSINESS',
  },
  {
    key: 'First',
    value: 'FIRST',
  },
];

const SearchFormSchema = Yup.object({
  passengers: Yup.object({
    adult: Yup.number().min(1).max(9),
    child: Yup.number().min(0).max(8),
    infant: Yup.number().min(0).max(8),
  }),
  from: Yup.object().nullable().required('This field is required'),
  to: Yup.object()
    .nullable()
    .required('This field is required')
    .test('not same', 'Please select different to and from', function (to) {
      const { from } = this.parent;

      if (to === null || from === null) {
        return false;
      }

      return from.code !== to.code;
    }),
  travelDate: Yup.date().required('This field is required'),
  isDirectFlight: Yup.boolean().required('This field is required'),
  cabinClass: Yup.string().required('This field is required'),
});

const initialValues: IFlightSearchForm = {
  passengers: {
    adult: 1,
    child: 0,
    infant: 0,
  },
  from: null,
  to: null,
  travelDate: new Date(),
  isDirectFlight: false,
  cabinClass: cabinClass[0].value,
};

interface FlightSearchFormProps {}

const FlightSearchForm: FC<FlightSearchFormProps> = (_props) => {
  const navigate = useNavigate();

  const handleSwapAirport = (
    values: IFlightSearchForm,
    setFieldValue: (
      field: string,
      value: any,
      shouldValidate?: boolean,
    ) => void,
  ) => {
    const fromAirport = values.from;
    const toAirport = values.to;

    setFieldValue('from', toAirport, false);
    setFieldValue('to', fromAirport, false);
  };

  const handleFlightSubmit = (values: IFlightSearchForm) => {
    const totalPassenger = +values.passengers.adult + +values.passengers.child;

    if (+values.passengers.infant > +values.passengers.adult) {
      toast.error('Number of Infants cannot be greater than the Adults');
      return;
    }
    if (totalPassenger > 9) {
      toast.error('Max passengers limit is 9 (Adult + Child)');
      return;
    }

    // const cabinClass = 'ECONOMY';

    const { adult, child, infant } = values.passengers;
    const { from, to, travelDate, isDirectFlight, cabinClass } = values;

    const departureDate = moment(travelDate).format('YYYYMMDD');

    navigate({
      pathname: 'flights',
      search: createSearchParams({
        cabinClass: cabinClass,
        fromCode: from.code,
        fromName: from.name,
        fromCityCode: from.cityCode,
        fromCity: from.city,
        fromCountry: from.country,
        fromCountryCode: from.countryCode,
        toCode: to.code,
        toName: to.name,
        toCityCode: to.cityCode,
        toCity: to.city,
        toCountry: to.country,
        toCountryCode: to.countryCode,
        adult: adult.toString(),
        child: child.toString(),
        infant: infant.toString(),
        departureDate: departureDate,
        pft: 'REGULAR',
        isDirectFlight: isDirectFlight.toString(),
        // isConnectingFlight: 'false',
      }).toString(),
    });
  };

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize={false}
      validationSchema={SearchFormSchema}
      onSubmit={handleFlightSubmit}
    >
      {({ values, setFieldValue }: FormikProps<IFlightSearchForm>) => (
        <Form>
          <>
            <div className='relative'>
              <div className='grid grid-cols-12 gap-x-5 gap-y-8 mt-6'>
                <div className='col-span-12 md:col-span-6 '>
                  <label
                    className='form-label text-secondary-fg'
                    htmlFor='from'
                  >
                    From
                  </label>
                  {/* <AirportSearchField
                    className='form-field'
                    placeholder='Select From Airport'
                    selectedAirport={values.from}
                    onSelectedAirportChange={(airport: any) =>
                      setFieldValue('from', airport)
                    }
                  /> */}
                  <AirportSearch
                    selectedAirport={values.from}
                    setField={(airport) => setFieldValue('from', airport)}
                    placeholder='Select From Airport'
                    initialAirportSuggestion={fromAirportSuggestions}
                  />
                  <div className='form-error text-secondary-fg/60'>
                    <ErrorMessage name='from' />
                  </div>
                </div>

                <div className='absolute top-[100px] md:top-[38px] left-[50%] -translate-x-[50%] z-[9]'>
                  <div
                    className='bg-white text-primary w-10 h-10 rounded-full flex justify-center items-center mx-auto cursor-pointer border-2 border-gray-200'
                    onClick={() => handleSwapAirport(values, setFieldValue)}
                  >
                    <SwapIcon height={16} />
                  </div>
                </div>
                <div className='col-span-12 md:col-span-6'>
                  <label className='form-label text-secondary-fg' htmlFor='to'>
                    To
                  </label>
                  {/* <AirportSearchField
                    className='form-field'
                    placeholder='Select to Airport'
                    selectedAirport={values.to}
                    onSelectedAirportChange={(airport: any) =>
                      setFieldValue('to', airport)
                    }
                  /> */}

                  <AirportSearch
                    selectedAirport={values.to}
                    setField={(airport) => setFieldValue('to', airport)}
                    placeholder='Select to Airport'
                    initialAirportSuggestion={toAirportSuggestions}
                  />

                  <div className='form-error text-secondary-fg/60'>
                    <ErrorMessage name='to' />
                  </div>
                </div>
              </div>
            </div>
            <div className='grid grid-cols-12 gap-x-5 gap-y-8 mt-8'>
              <div className='col-span-12 md:col-span-6'>
                <label
                  htmlFor='travelDate'
                  className='form-label text-secondary-fg'
                >
                  Depart on
                </label>

                <div className='relative flex items-center'>
                  <div className='absolute left-4 z-10'>
                    <CalendarIcon />
                  </div>

                  {/* <DatePicker
                                        id="travelDate"
                                        className="form-field pl-12 cursor-pointer caret-transparent"
                                        selected={values.travelDate}
                                        onChange={(val) => setFieldValue('travelDate', val)}
                                        minDate={new Date()}
                                        dateFormat="dd MMMM, yyyy"
                                        closeOnScroll
                                        showMonthDropdown
                                        showYearDropdown
                                        dropdownMode="select"
                                        onChangeRaw={(e: any) => e.preventDefault()}
                                    /> */}
                  <DatePicker
                    className='form-field bg-white pl-12 cursor-pointer'
                    onChange={(val) => setFieldValue('travelDate', val)}
                    value={values.travelDate}
                    minDate={new Date()}
                    format='DD MM YYYY'
                  />
                </div>

                <div className='form-error text-secondary-fg/60'>
                  <ErrorMessage name='travelDate' />
                </div>
              </div>
              {/* <div className="col-span-2 md:col-span-1">
                            <label htmlFor="return" className="form-label text-secondary-fg">
                                Return on
                            </label>
                            <br />

                            <DatePicker
                                className="form-field"
                                selected={values.routeInfo[0].travelDate}
                                onChange={(e) => {
                                    setFieldValue('routeInfo[0].travelDate', e);
                                }}
                                minDate={departDate}
                                dateFormat="dd MMMM, yyyy"
                                closeOnScroll
                            />
                        </div> */}
            </div>

            <div className='grid grid-cols-10 gap-x-5 gap-y-8 mt-8'>
              <div className='col-span-10 sm:col-span-4'>
                <div className='flex gap-x-5'>
                  <div className='w-full'>
                    <label
                      htmlFor='adult'
                      className='form-label text-secondary-fg'
                    >
                      Adult
                    </label>
                    <div className='w-full'>
                      <Field
                        as='select'
                        name='passengers.adult'
                        className='form-field'
                      >
                        {generateNumberArray(1, 9).map((val) => {
                          return (
                            <option key={val} value={val}>
                              {val}
                            </option>
                          );
                        })}
                      </Field>
                      <div className='form-error text-secondary-fg/60'>
                        <ErrorMessage name='passengers.adult' />
                      </div>
                    </div>
                  </div>
                  <div className='w-full'>
                    <div className='relative'>
                      <label
                        htmlFor='children'
                        className='form-label text-secondary-fg'
                      >
                        Children
                      </label>
                      <Field
                        as='select'
                        name='passengers.child'
                        className='form-field'
                      >
                        {generateNumberArray(0, 8).map((val) => {
                          return (
                            <option key={val} value={val}>
                              {val}
                            </option>
                          );
                        })}
                      </Field>
                      <div className='form-error text-secondary-fg/60'>
                        <ErrorMessage name='passengers.child' />
                      </div>

                      <div className='text-sm font-normal mt-1 text-secondary-fg opacity-50 top-[7em] left-[1em]'>
                        2 - 11 Years
                      </div>
                    </div>
                  </div>
                  <div className='w-full'>
                    <div className='relative'>
                      <label
                        htmlFor='infants'
                        className='form-label text-secondary-fg'
                      >
                        Infants
                      </label>

                      <Field
                        as='select'
                        name='passengers.infant'
                        className='form-field'
                      >
                        {generateNumberArray(0, 9).map((val) => {
                          return (
                            <option key={val} value={val}>
                              {val}
                            </option>
                          );
                        })}
                      </Field>

                      <div className='form-error text-secondary-fg/60'>
                        <ErrorMessage name='passengers.infant' />
                      </div>

                      <div className='text-sm font-normal mt-1 text-secondary-fg opacity-50 top-[7em]'>
                        Below 2 Years
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className='col-span-10 sm:col-span-3 '>
                <div className='w-full'>
                  <label
                    className='form-label text-secondary-fg'
                    htmlFor='cabin-class'
                  >
                    Cabin Class
                  </label>
                  <Field
                    as='select'
                    name='cabinClass'
                    type='text'
                    id='cabin-class'
                    className='form-field'
                  >
                    <option value='' disabled>
                      Select Cabin
                    </option>
                    {cabinClass.map((row: any) => (
                      <option key={row.value} value={row.value}>
                        {`${row.key}`}
                      </option>
                    ))}
                  </Field>
                  <div className='form-error'>
                    <ErrorMessage name='cabinClass' />
                  </div>
                </div>
              </div>

              <div className='col-span-10 sm:col-span-3 sm:mt-12'>
                <div className='flex sm:justify-center items-center'>
                  <div className='form-switch success'>
                    <input
                      id='isDirectFlight'
                      type='checkbox'
                      role='switch'
                      onChange={() => {}} // for error fix
                      checked={values.isDirectFlight}
                      onClick={() =>
                        setFieldValue('isDirectFlight', !values.isDirectFlight)
                      }
                    />
                  </div>
                  <label
                    htmlFor='isDirectFlight'
                    className='form-label text-secondary-fg mb-0 ml-3'
                  >
                    Direct Flights only
                  </label>
                </div>
              </div>

              <div className='col-span-10 sm:col-span-3 '>
                <button
                  type='submit'
                  className='form-btn text-xl btn-primary px-4 py-4 themeBtn '
                >
                  Search flights
                </button>
              </div>
            </div>
          </>
        </Form>
      )}
    </Formik>
  );
};

export default FlightSearchForm;
