const Icon = (props: any) => (
  <svg
    width={16}
    height={16}
    viewBox='0 0 16 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M9.5 2.667h-3c-2.121 0-3.182 0-3.841.67C2 4.005 2 5.082 2 7.237v1.524c0 2.155 0 3.232.659 3.902.659.67 1.72.67 3.841.67h3c2.121 0 3.182 0 3.841-.67.659-.67.659-1.747.659-3.902V7.238c0-2.155 0-3.232-.659-3.902-.659-.67-1.72-.67-3.841-.67z'
      stroke='#222'
    />
    <path d='M4.667 5.333h2' stroke='#222' strokeLinecap='round' />
    <path
      d='M12.667 10.667h-1.334c-.31 0-.465 0-.592-.034a1 1 0 01-.707-.708C10 9.798 10 9.643 10 9.333s0-.465.034-.592a1 1 0 01.707-.707C10.868 8 11.023 8 11.333 8h1.334c.31 0 .465 0 .592.034a1 1 0 01.707.707c.034.127.034.282.034.592s0 .465-.034.592a1 1 0 01-.707.708c-.127.034-.282.034-.592.034z'
      stroke='#222'
    />
  </svg>
);

export default Icon;
