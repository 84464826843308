import { CrossIcon } from 'app/icons';
import { IFlightPriceDetail, IFlightSegment } from 'app/types';
import { formatCurrency } from 'app/utils/currency-utils';
import {
  formatDate,
  formatMinutesToTime,
  formatTime,
} from 'app/utils/date-utils';
import {
  getFormattedFareRuleKey,
  getFormattedFareTypeKey,
  getFormattedStops,
} from 'app/utils/flight-utils';
import { FC, useContext, useState } from 'react';
import capitalize from 'lodash.capitalize';
import FlightsContext from 'app/pages/Flights/context/flights-context';
import http from 'app/config/http';
import { useQuery } from '@tanstack/react-query';
import Loader from 'app/components/Loader/Loader';
import { AnimatePresence, motion } from 'framer-motion';
import useBackButtonWithModal from 'app/hooks/use-back-button-with-modal';

const drawerAnimation = {
  variants: {
    hidden: {
      x: '100vh',
    },
    visible: {
      x: 0,
      transition: { duration: 0.3 },
    },
  },
  initial: 'hidden',
  animate: 'visible',
  exit: 'hidden',
};

interface FlightDetailModalProps {
  onClose: () => void;
  // onSubmit: () => void;
  segments: IFlightSegment[];
  selectedPriceItem: IFlightPriceDetail;
  show: boolean;
}

const FlightDetailModal: FC<FlightDetailModalProps> = (props) => {
  const { onClose, segments, selectedPriceItem, show } = props;
  const { passengers } = useContext(FlightsContext);
  const [showRules, setShowRules] = useState<boolean>(false);
  const closeModal = useBackButtonWithModal(show, onClose);

  // useEffect(() => {
  //     if (show) {
  //         window.history.pushState('backPressed', null, null);
  //         window.history.pushState('dummy', null, null);
  //         window.addEventListener('popstate', hideModal, { once: true });
  //     }

  //     return () => {
  //         window.removeEventListener('popstate', hideModal);
  //     };
  // }, [show]);

  // const hideModal = (event: PopStateEvent) => {
  //     if (event.state === 'backPressed') {
  //         window.history.back();
  //         onClose();
  //     }
  // };

  const fareRulesQuery = useQuery(
    ['fareRulesById', selectedPriceItem],
    async () => {
      const { data } = await http.post(
        `${process.env.REACT_APP_API_URL}/api/v1/sub-agent/flights/${selectedPriceItem.id}/fare-rules`,
        {
          fareSupplier: selectedPriceItem.fareSupplier,
        },
      );

      return data;
    },
    {
      enabled: showRules,
    },
  );

  const renderFareSummaryForType = (type: 'adult' | 'child' | 'infant') => {
    return (
      <>
        <tr>
          <td colSpan={3} className='px-4 py-1 text-sm text-gray-500'>
            Fare Details for {capitalize(type)}
          </td>
        </tr>
        <tr>
          <td className='px-4 py-2'>Base Price</td>
          <td className='px-2 py-2 text-sm whitespace-nowrap'>
            {formatCurrency(
              selectedPriceItem.fareDescription[type].fareComponent.base,
            )}
            <span className='mx-1'>x</span>
            {passengers[type]}
          </td>
          <td className='px-2 py-2 text-sm whitespace-nowrap'>
            {formatCurrency(
              selectedPriceItem.fareDescription[type].fareComponent.base *
                passengers[type],
            )}
          </td>
        </tr>
        <tr>
          <td className='px-4 py-2'>Taxes and fees</td>
          <td className='px-2 py-2 text-sm whitespace-nowrap'>
            {formatCurrency(
              selectedPriceItem.fareDescription[type].fareComponent
                .taxesAndFees,
            )}
            <span className='mx-1'>x</span>
            {passengers[type]}
          </td>
          <td className='px-4 py-2 whitespace-nowrap'>
            {formatCurrency(
              selectedPriceItem.fareDescription[type].fareComponent
                .taxesAndFees * passengers[type],
            )}
          </td>
        </tr>
        <tr>
          <td className='pb-3'></td>
        </tr>
      </>
    );
  };

  const renderBaggage = (key: 'checkIn' | 'cabin') => {
    let str = `Adult: ${selectedPriceItem.fareDescription.adult.baggage[key]}`;

    if (selectedPriceItem.fareDescription.child)
      str += `,  Child: ${selectedPriceItem.fareDescription.child.baggage[key]}`;

    if (selectedPriceItem.fareDescription.infant)
      str += `,  Child: ${selectedPriceItem.fareDescription.infant.baggage[key]}`;

    return str;
  };

  const renderFareRules = () => {
    if (fareRulesQuery.isLoading)
      return (
        <div className='text-center'>
          <Loader />
        </div>
      );

    if (fareRulesQuery.isError)
      return <p>Please contact support for fare rules.</p>;

    // for series fare : fare rule will be a string
    if (selectedPriceItem.fareSupplier === '0')
      return <p className='whitespace-pre-wrap'>{fareRulesQuery.data}</p>;
    if (selectedPriceItem.fareSupplier === '2')
      return <p className='whitespace-pre-wrap'>{fareRulesQuery.data}</p>;

    // for normal fare : fare rule will be an array of objects
    if (fareRulesQuery.data.length === 0)
      return <p>Please contact support for fare rules.</p>;

    return fareRulesQuery.data.map((fight: any, fightIndex: number) => (
      <div key={fightIndex}>
        <h6 className='font-bold mb-4'>{fight.flightName}</h6>
        <div className='grid grid-cols-4 gap-4'>
          {fight.rules.map((rule: any, ruleIndex: number) => (
            <div key={fightIndex + '' + ruleIndex} className='col-span-4'>
              <div className='font-medium mb-2'>
                {getFormattedFareRuleKey(rule.key)}
              </div>

              <div>
                {rule.rows.map((row: any, rowIndex: number) => (
                  <div
                    key={fightIndex + '' + ruleIndex + '' + rowIndex}
                    className='flex gap-8 text-gray-600 text-sm '
                  >
                    <div className='w-1/6'>
                      {getFormattedFareTypeKey(row.type)}
                    </div>
                    <div className='flex-1 leading-6'>{row.value}</div>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    ));
  };

  return (
    <AnimatePresence initial={false} mode='wait'>
      {show && (
        <>
          <motion.div
            {...drawerAnimation}
            className='fixed top-0 left-0  bottom-0 w-screen h-screen  z-50 bg-[#EEEEEE] overflow-auto '
          >
            <div className='w-full bg-white shadow-md p-4 flex gap-4 items-center sticky top-0 z-20'>
              <button
                type='button'
                className=' w-7 h-7 flex justify-center items-center text-gray-400 border-none cursor-pointer'
                // onClick={onClose}
                onClick={closeModal}
              >
                <CrossIcon width={20} height={20} />
              </button>
              <div className='text-base font-semibold text-slate-600'>
                View Details
              </div>
            </div>

            <div className='px-4 mt-4 '>
              {segments.map((segment, i) => (
                <div
                  key={i}
                  className='card p-4 mt-4 flex flex-col gap-6 relative'
                >
                  {/* airline details */}
                  <div className='flex items-center gap-2'>
                    <img
                      crossOrigin='anonymous'
                      className='w-8 h-8'
                      src={segment.flight.logo}
                      alt=''
                    />
                    <div className='overflow-hidden'>
                      <div className='text-base font-semibold text-ellipsis whitespace-nowrap overflow-hidden'>
                        {segment.flight.name}
                      </div>
                      <div className='text-xs text-black/70 mt-[2px]'>
                        {`${segment.flight.code} ${segment.flight.number}`}
                      </div>
                    </div>
                  </div>

                  <div className='flex justify-between'>
                    {/* left */}
                    <div>
                      <div className='font-semibold'>
                        {segment.departure.airport.code}
                      </div>
                      <div>{segment.departure.airport.city}</div>

                      <div className='font-medium'>{`${formatDate(segment.departure.date)}`}</div>
                      <div className='font-medium'>{`${formatTime(segment.departure.time)}`}</div>

                      <div className='text-xs font-normal text-black/70 mt-[2px]'>
                        {segment.departure.airport.name}
                      </div>
                      <div className='text-xs font-normal text-black/70 mt-[2px]'>
                        {segment.departure.airport.terminal}
                      </div>
                    </div>
                    {/* bar */}
                    <div className='self-center'>
                      <div className='flex flex-col items-center justify-center mt-1'>
                        <div className='text-xs font-normal text-black/70'>
                          {formatMinutesToTime(segment.duration)}
                        </div>
                        {/* Line */}
                        <div className='bg-[#ECAE0E] h-1  min-w-[4rem] rounded-lg mt-1' />
                        {/*  */}
                        <div className='text-xs font-normal text-black/70 mt-1'>
                          {getFormattedStops(segment.stops)}

                          <span className='ml-2'>
                            {segment.stopsOverAirports.length > 0 ? (
                              <span className='inline-flex gap-1'>
                                (via{' '}
                                {segment.stopsOverAirports.map(
                                  (a, index: number) =>
                                    `${a.cityCode} ${
                                      index ===
                                      segment.stopsOverAirports.length - 1
                                        ? ''
                                        : ','
                                    }   `,
                                )}
                                )
                              </span>
                            ) : (
                              ''
                            )}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className='flex flex-col items-end'>
                      <div className='font-semibold text-right'>
                        {segment.arrival.airport.code}
                      </div>
                      <div className='text-right'>
                        {segment.arrival.airport.city}
                      </div>
                      <div className='font-medium  text-right'>{`${formatDate(
                        segment.arrival.date,
                      )}`}</div>
                      <div className='font-medium  text-right'>{`${formatTime(
                        segment.arrival.time,
                      )}`}</div>

                      <div className='text-xs font-normal text-black/70 mt-[2px] text-right '>
                        {segment.arrival.airport.name}
                      </div>
                      <div className='text-xs font-normal text-black/70 mt-[2px] text-right '>
                        {segment.arrival.airport.terminal}
                      </div>
                    </div>
                  </div>
                  {segment.layoverDuration !== 0 && (
                    <div className='mx-auto w-60 bg-gray-100 rounded-full  py-2 text-sm text-center absolute -bottom-6 z-10 left-[50%] translate-x-[-50%]'>
                      Layover Time -{' '}
                      <span className='font-semibold'>
                        {formatMinutesToTime(segment.layoverDuration)}
                      </span>
                    </div>
                  )}
                </div>
              ))}
            </div>

            <div className='card p-4 mt-4 flex flex-col gap-6 mx-4'>
              <table className='w-full'>
                <thead>
                  <tr>
                    <th className='px-4 py-2 text-left'>Check-in</th>
                    <th className='px-4 py-2 text-left'>Cabin</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className='px-4 py-2 text-gray-500'>
                      {renderBaggage('checkIn')}
                    </td>
                    <td className='px-4 py-2 text-gray-500'>
                      {renderBaggage('cabin')}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div className='card p-4 mt-4 flex flex-col gap-6 mx-4'>
              <table className='w-full'>
                <thead>
                  <tr>
                    <th className='px-4 py-2 text-left'>Type</th>
                    <th className='px-4 py-2 text-left'>Fare</th>
                    <th className='px-4 py-2 text-left'>Total</th>
                  </tr>
                </thead>
                <tbody>
                  {renderFareSummaryForType('adult')}
                  {selectedPriceItem.fareDescription.child &&
                    renderFareSummaryForType('child')}
                  {selectedPriceItem.fareDescription.infant &&
                    renderFareSummaryForType('infant')}
                  <tr>
                    <td colSpan={2} className='px-4 py-2'>
                      <div className='font-semibold'>Total</div>
                    </td>
                    <td className='px-4 py-2'>
                      <div className='font-semibold whitespace-nowrap'>
                        {formatCurrency(selectedPriceItem.totalFare)}
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div className='card p-4 mt-4 flex flex-col gap-6 mx-4 mb-2'>
              {showRules ? (
                renderFareRules()
              ) : (
                // eslint-disable-next-line jsx-a11y/anchor-is-valid
                <a className='text-blue-500' onClick={() => setShowRules(true)}>
                  View Rules
                </a>
              )}
            </div>
            {/* <div
                            onClick={onSubmit}
                            className="sticky bottom-0 left-0  btn-primary w-full rounded-none flex justify-center py-3 font-bold cursor-pointer"
                        >
                            Book
                        </div> */}
          </motion.div>
        </>
      )}
    </AnimatePresence>
  );
};

export default FlightDetailModal;
