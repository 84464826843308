import { Dispatch, FC, SetStateAction } from 'react';

interface TransactionNavProps {
  activeTab: string;
  setActiveTab: Dispatch<SetStateAction<string>>;
}

const TransactionNav: FC<TransactionNavProps> = (props) => {
  const { activeTab, setActiveTab } = props;

  return (
    <ul className='flex text-lg font-normal px-4 sm:px-9 gap-6'>
      <li className='tab-item'>
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <a
          className={`nav-tab  ${
            activeTab === '1'
              ? 'font-bold cursor-default text-primary underline underline-offset-4'
              : ''
          }`}
          onClick={() => setActiveTab('1')}
        >
          Ledger
        </a>
      </li>

      <li className='tab-item'>
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <a
          className={`nav-tab   ${
            activeTab === '2'
              ? 'font-bold cursor-default text-primary underline underline-offset-4'
              : ''
          }`}
          onClick={() => setActiveTab('2')}
        >
          Payment Requests
        </a>
      </li>
    </ul>
  );
};

export default TransactionNav;
