import { useQuery } from '@tanstack/react-query';
import Loader from 'app/components/Loader/Loader';
import StatusBadge from 'app/components/StatusBadge/StatusBadge';
import http from 'app/config/http';
import {
  getAmendmentStatusColor,
  getAmendmentStatusText,
} from 'app/utils/common-utils';
import capitalize from 'lodash.capitalize';
import moment from 'moment';
import React, { FC } from 'react';

interface RequestTableProps {
  bookingId: string;
  addTab: any;
}

const RequestTable: FC<RequestTableProps> = (props) => {
  const { bookingId, addTab } = props;

  const amendmentQuery = useQuery(['amendment'], async () => {
    const { data } = await http.get(
      `${process.env.REACT_APP_API_URL}/api/v1/sub-agent/bookings/${bookingId}/amendments`,
    );
    return data;
  });

  const getFirstPassengerName = (passengers: any[]) => {
    const firstPassenger = passengers[0];

    return `${firstPassenger.title} ${firstPassenger.firstName} ${firstPassenger.lastName}`;
  };

  if (amendmentQuery.isLoading)
    return (
      <div className='flex  justify-center items-center pt-24'>
        <Loader />
      </div>
    );

  return (
    <table className='w-full'>
      <thead>
        <tr>
          <td className='td th'>Request ID</td>
          <td className='td th'>Request Date & Time</td>
          <td className='td th'>Change Type</td>
          <td className='td th'>Passenger(s)</td>
          <td className='td th'>Change Amount</td>
          <td className='td th'>Status</td>
        </tr>
      </thead>
      <tbody>
        {amendmentQuery.data.map((amendment: any) => (
          <tr key={amendment._id}>
            <td className='td tb'>
              <div>
                <div className='font-medium text-blue-500 cursor-pointer'>
                  <div onClick={() => addTab(amendment._id)}>
                    {amendment._id}
                  </div>
                </div>
                {amendment.actionNeeded && (
                  <StatusBadge className='mt-1' color={'red'}>
                    Action needed
                  </StatusBadge>
                )}
              </div>
            </td>
            <td className='td tb'>
              <div className='font-semibold'>
                <div>
                  {moment(amendment.createdAt, 'YYYYMMDDHHmmss').format(
                    'DD MMM, YYYY',
                  )}
                </div>
                <div className='text-xs font-medium text-gray-500'>
                  {moment(amendment.createdAt, 'YYYYMMDDHHmmss').format(
                    'hh:mm A',
                  )}
                </div>
              </div>
            </td>

            <td className='td tb'>
              <div className='font-semibold'>
                {capitalize(amendment.type.toLowerCase())}
              </div>
            </td>
            <td className='td tb'>
              {amendment.all === true
                ? 'All'
                : getFirstPassengerName(amendment.detail.passengers)}

              {amendment.all !== true &&
                amendment.detail.passengers.lenght > 1 && (
                  <div className='bg-gray-100 px-3 py-1 rounded-full'>
                    + {amendment.detail.passengers.lenght - 1}
                  </div>
                )}
            </td>
            <td className='td tb'>
              <div className='font-medium'>
                {amendment.amount ? amendment.amount : '-'}
              </div>
            </td>
            <td>
              <StatusBadge
                className='mt-1'
                color={getAmendmentStatusColor(amendment.status)}
              >
                {getAmendmentStatusText(amendment.status)}
              </StatusBadge>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default RequestTable;
