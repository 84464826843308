import { DiscoverIcon, MaestroIcon, MastercardIcon, VisaIcon } from 'app/icons';
import { FC } from 'react';

interface IOnlinePaymentCard {
  handleOnlinePayment: () => void;
  disableOnlinePayment: boolean;
}

const OnlinePaymentCard: FC<IOnlinePaymentCard> = (props) => {
  const { handleOnlinePayment, disableOnlinePayment } = props;
  return (
    <div className='card px-8 py-7'>
      <div className='text-xl font-bold'>Online Payment</div>
      <div className='flex flex-col gap-4 sm:items-start sm:flex-row mt-10'>
        <div className='flex gap-1'>
          <MastercardIcon />
          <MaestroIcon />
          <VisaIcon />
          <DiscoverIcon />
        </div>
        <div className='flex flex-col sm:items-start gap-3 sm:ml-24'>
          <button
            className='btn btn-primary'
            onClick={handleOnlinePayment}
            // onClick={() => reviewDialog.show(PaymentTypeEnum.ONLINE)}
            disabled={disableOnlinePayment}
          >
            Review and Pay Online
          </button>
          {/* check if any payment option is available */}
          {/* <span className='text-sm font-medium text-red-600'>
            Online payment not enabled
          </span> */}
        </div>
      </div>
    </div>
  );
};

export default OnlinePaymentCard;
