import { FC } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import failedLottie from 'assets/lotties/failed.json';
import Lottie from 'lottie-react';

const modalAnimation = {
  variants: {
    hidden: {
      opacity: 0,
    },
    visible: {
      opacity: 1,
    },
  },
  initial: 'hidden',
  animate: 'visible',
  exit: 'hidden',
};

interface IBookingFailedAnimationProps {
  show: boolean;
  onClose: () => void;
}

const BookingFailedAnimation: FC<IBookingFailedAnimationProps> = (props) => {
  const { show, onClose } = props;

  return (
    <AnimatePresence initial={false} mode='wait'>
      {show && (
        <motion.div
          className='fixed top-0 left-0 w-full h-full bg-white/80 z-50 '
          {...modalAnimation}
          onClick={onClose}
        >
          <div className='flex w-full'>
            <div className='w-96 mx-auto mt-32 flex flex-col items-center'>
              <Lottie
                animationData={failedLottie}
                loop={false}
                className='w-72'
              />
              <h6 className='font-semibold text-center -mt-4'>
                Flight booking failed. Please try again later.
              </h6>
            </div>
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};



export default BookingFailedAnimation;
