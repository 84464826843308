import { FC } from 'react';
import loaderLottie from 'assets/lotties/loader.json';
import LottieLoader from 'app/components/LottieLoader/LottieLoader';

interface FlightPageLoaderProps {}

const FlightPageLoader: FC<FlightPageLoaderProps> = (props) => {
  return (
    <div className='fixed top-0 left-0 w-screen h-screen flex bg-white/100 z-10'>
      <div className='m-auto flex flex-col justify-center items-center'>
        <div className='w-56'>
          <LottieLoader animationData={loaderLottie} />
          <p className='text-black mt-10 text-center'>
            Please wait while the details are being confirmed
          </p>
        </div>
      </div>
    </div>
  );
};

export default FlightPageLoader;
