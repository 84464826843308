import http from 'app/config/http';
import { FC, useEffect } from 'react';
import { toast } from 'react-toastify';
import { TRootState } from 'app/store';
import { useSelector } from 'react-redux';
import useDialog from 'app/hooks/use-dialog';
import useNavTab from 'app/hooks/use-nav-tab';
import { useQuery } from '@tanstack/react-query';
import { useSearchParams } from 'react-router-dom';
import Payments from './components/Payments/Payments';
import BgOverlay from 'app/components/BgOverlay/BgOverlay';
import { formatCurrency } from '../../utils/currency-utils';
import PageLoader from 'app/components/PageLoader/PageLoader';
import Transactions from './components/Transactions/Transactions';
import BankAccounts from './components/BankAccounts/BankAccounts';
import MobileTopBar from 'app/components/MobileTopBar/MobileTopBar';
import AccountingNav from './components/AccountingNav/AccountingNav';
import TransactionNav from './components/TransactionNav/TransactionNav';
import { DiscoverIcon, MaestroIcon, MastercardIcon, VisaIcon } from 'app/icons';
import PaymentAddDialog from './components/PaymentAddDialog/backup/PaymentAddDialog2';
interface AccountProps {}

const Account: FC<AccountProps> = (props) => {
  const [activeTabLedger, setActiveTabLedger] = useNavTab('1', {
    queryName: 'ledgerTab',
  });
  const [activeTab, setActiveTab] = useNavTab('1', { queryName: 'accountTab' });

  const { creditLimit, availableCredit, availableCashBalance } = useSelector(
    (state: TRootState) => state.account,
  );

  const AddPaymentDialog = useDialog();
  const [searchParams, setSearchParams] = useSearchParams();

  const transactionQuery = useQuery(['transactions'], async () => {
    const { data } = await http.get(
      `${process.env.REACT_APP_API_URL}/api/v1/sub-agent/sub-agency/transactions`,
    );

    return data;
  });

  const bankListQuery = useQuery(['bankList'], async () => {
    const { data } = await http.get(
      `${process.env.REACT_APP_API_URL}/api/v1/sub-agent/sub-agency/agency-banks`,
    );

    return data;
  });

  let availableBalance = availableCredit + availableCashBalance;
  let amountDue = creditLimit - availableCredit - availableCashBalance;

  if (availableBalance < 0) {
    availableBalance = 0;
  }

  if (amountDue < 0) {
    amountDue = 0;
  }

  useEffect(() => {
    if (Boolean(searchParams.get('showDialog')) === true) {
      AddPaymentDialog.show();
      searchParams.delete('showDialog');
      setSearchParams(searchParams);
    }

    const status = searchParams.get('status');
    if (status !== null) {
      if (status === 'failed') {
        toast.error('Transaction Failed');
      }
      if (status === 'success') {
        toast.success('Transaction Successful');
      }
      if (status === 'aborted') {
        toast.error('Transaction Aborted');
      }
      searchParams.delete('status');
      setSearchParams(searchParams);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  useEffect(() => {
    if (activeTabLedger !== searchParams.get('ledgerTab')) {
      ['fromDate', 'toDate'].forEach((filterKey) => {
        searchParams.delete(filterKey);
      });
      searchParams.set('ledgerTab', activeTabLedger.toString());
      setSearchParams(searchParams, {
        replace: true,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTabLedger]);

  if (transactionQuery.isLoading || bankListQuery.isLoading)
    return <PageLoader />;

  return (
    <div>
      <MobileTopBar />
      <BgOverlay />

      <div className='container min-h-full'>
        <div className='flex py-8'>
          <div className='title text-secondary-fg'>Accounts</div>
        </div>

        {/* main continer */}
        <div>
          <div className='card py-9 mb-4'>
            <AccountingNav activeTab={activeTab} setActiveTab={setActiveTab} />

            <div className='mt-9'>
              {activeTab === '1' && (
                <div className='flex flex-col lg:flex-row lg:justify-between text-sm leading-4 px-9 gap-3'>
                  <div className='flex flex-col lg:flex-row lg:justify-between'>
                    <div className='overflow-auto pb-2'>
                      <div className='grid grid-cols-2 md:grid-cols-4 gap-2 md:gap-4'>
                        <div className='col-span-1'>
                          <p>Credit Limit</p>
                          <div className='text-lg font-semibold mt-1'>
                            {formatCurrency(creditLimit)}
                          </div>
                        </div>
                        <div className='col-span-1'>
                          <p>Available Credit Limit</p>
                          <div className='text-lg font-semibold mt-1'>
                            {formatCurrency(availableCredit)}
                          </div>
                        </div>
                        <div className='col-span-1'>
                          <p>Amount Due</p>
                          <div className='text-lg font-semibold mt-1'>
                            {formatCurrency(amountDue)}
                          </div>
                        </div>
                        <div className='col-span-1'>
                          <p>Available Balance</p>
                          <div className='text-lg font-extrabold mt-1'>
                            {formatCurrency(availableBalance)}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div>
                      <button
                        onClick={AddPaymentDialog.show}
                        className='btn lg:ml-8 mt-2 btn-primary '
                      >
                        Make Payment
                      </button>
                    </div>
                  </div>

                  <div className='flex flex-col lg:items-end gap-1'>
                    <div>Accepted</div>
                    <div className='flex gap-1'>
                      <MastercardIcon />
                      <MaestroIcon />
                      <VisaIcon />
                      <DiscoverIcon />
                    </div>
                  </div>
                </div>
              )}

              {activeTab === '2' && (
                <BankAccounts accounts={bankListQuery.data} />
              )}
            </div>
          </div>

          <div className='card py-9 overflow-auto'>
            <TransactionNav
              activeTab={activeTabLedger}
              setActiveTab={setActiveTabLedger}
            />

            {activeTabLedger === '1' && (
              <Transactions transactions={transactionQuery.data} />
            )}
            {activeTabLedger === '2' && <Payments />}
          </div>
        </div>
      </div>

      {AddPaymentDialog.isOpen && (
        <PaymentAddDialog
          show={AddPaymentDialog.isOpen}
          onSubmit={AddPaymentDialog.hide}
          onClose={AddPaymentDialog.hide}
          bankList={bankListQuery.data}
        />
      )}
    </div>
  );
};

export default Account;
