/* eslint-disable jsx-a11y/anchor-is-valid */
import useDialog from 'app/hooks/use-dialog';
import { formatCurrency } from 'app/utils/currency-utils';
import { formatDate } from 'app/utils/date-utils';
import moment from 'moment';
import { FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import PaymentReceiptDialog from '../PaymentReceiptDialog/PaymentReceiptDialog';

interface TransactionsProps {
  transactions: any;
}

const transactionTypeMessage: any = {
  ADD_MONEY_TO_WALLET: 'Money added to wallet',
  FLIGHT_BOOKING: 'Flight Booking',
  OPENING_BALANCE: 'Opening Balance',
};

const Transactions: FC<TransactionsProps> = (props) => {
  const { transactions } = props;

  const paymentDialog = useDialog();
  const navigate = useNavigate();

  const [selectedTransactionId, setselectedTransactionId] =
    useState<string>(null);

  const handleDialogOpenner = (tranType: string, _id: string, ref: any) => {
    if (tranType === 'PAYMENT') {
      paymentDialog.show();
      setselectedTransactionId(_id);
    }

    if (tranType === 'INVOICE') {
      // window.open(`/bookings/${ref}/invoice`, '_blank');
      navigate(`/my-series/bookings/${ref}/invoice`);
    }
  };

  const handleSubmit = () => {
    paymentDialog.hide();
    setselectedTransactionId(null);
  };

  return (
    // <div className="card mb-4">
    <div className='overflow-auto'>
      {/* card heading  */}
      <div className='flex px-9 justify-between items-end'>
        <div className='text-xl font-bold'>Ledger</div>
        {/* <a href="#" className="text-sm font-normal">
                    View all transactions
                </a> */}
      </div>

      {/* transction table */}
      <div className='mt-8 pb-10'>
        <table className='w-full min-w-[1200px]'>
          <thead>
            <tr>
              <td className='bg-[#F9FAFB] text-sm font-semibold py-5 pl-10'>
                Date
              </td>
              <td className='bg-[#F9FAFB] text-sm font-semibold py-5'>
                Transaction Type
              </td>
              <td className='bg-[#F9FAFB] text-sm font-semibold py-5'>
                Refrence ID
              </td>
              <td className='bg-[#F9FAFB] text-sm font-semibold py-5'>
                Credit
              </td>
              <td className='bg-[#F9FAFB] text-sm font-semibold py-5'>Debit</td>
              <td className='bg-[#F9FAFB] text-sm font-semibold py-5 text-right pr-10'>
                Balance
              </td>
            </tr>
          </thead>
          <tbody>
            {transactions.map((row: any, i: number) => {
              return (
                <tr key={i}>
                  <td className='text-sm font-normal py-4 pl-10'>
                    {`${formatDate(row.createdAt)} ${moment(
                      row.createdAt,
                      'YYYYMMDDHHmmss',
                    ).format('hh:mm a')}`}{' '}
                  </td>
                  <td className='text-sm font-normal py-4 '>
                    {transactionTypeMessage[row.type]}
                  </td>
                  <td className='text-sm font-normal py-4 '>
                    {row.id || '-'}
                    <a
                      onClick={() =>
                        handleDialogOpenner(
                          row.category,
                          row._id,
                          row.referenceId,
                        )
                      }
                      className='text-blue-500 ml-2 cursor-pointer'
                    >
                      {row.category === 'PAYMENT' ? 'View Receipt' : ''}
                      {row.category === 'INVOICE' ? 'View Invoice' : ''}
                    </a>
                  </td>
                  <td className='text-sm font-semibold py-4'>
                    {row.category === 'INVOICE' ||
                    row.category === 'OPENING_BALANCE' ? (
                      row.category === 'OPENING_BALANCE' && row.amount > 0 ? (
                        <span className='text-green-500'>
                          {formatCurrency(row.amount)}{' '}
                        </span>
                      ) : row.category === 'INVOICE' ? (
                        <span className='text-green-500'>
                          {formatCurrency(row.amount)}{' '}
                        </span>
                      ) : (
                        '-'
                      )
                    ) : (
                      '-'
                    )}
                  </td>
                  <td className='text-sm font-semibold py-4'>
                    {row.category === 'PAYMENT' ||
                    row.category === 'OPENING_BALANCE' ? (
                      row.category === 'OPENING_BALANCE' && row.amount <= 0 ? (
                        <span className='text-red-500'>
                          {formatCurrency(row.amount)}{' '}
                        </span>
                      ) : row.category === 'PAYMENT' ? (
                        <span className='text-red-500'>
                          {formatCurrency(row.amount)}{' '}
                        </span>
                      ) : (
                        '-'
                      )
                    ) : (
                      '-'
                    )}
                  </td>

                  <td className='text-sm font-semibold py-4 text-right pr-10'>
                    {row.balance < 0 && '-'} {formatCurrency(row.balance)}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>

      {selectedTransactionId && (
        <PaymentReceiptDialog
          show={paymentDialog.isOpen}
          onClose={paymentDialog.hide}
          onSubmit={handleSubmit}
          transactionId={selectedTransactionId}
        />
      )}
    </div>
  );
};

export default Transactions;
