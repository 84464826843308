import http from 'app/config/http';
import { TRootState } from 'app/store';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { rgbToHex } from 'app/utils/color-utils';
import { AddPaymentTypeEnum } from './PaymentAddDialog';
import { useMutation, useQueryClient } from '@tanstack/react-query';

const useTopup = () => {
  const queryClient = useQueryClient();
  const { user } = useSelector((state: TRootState) => state.auth);

  // bank cheque and cash
  const makePaymentMutation = useMutation(async (payload: any) => {
    const { data } = await http.post(
      `${process.env.REACT_APP_API_URL}/api/v1/sub-agent/payments`,
      payload,
    );

    return data;
  });

  // pre razor pay
  const initiateOnlinePayment = useMutation(async (payload: any) => {
    const { data } = await http.post(
      `${process.env.REACT_APP_API_URL}/api/v1/sub-agent/payments/razorpay/initiate`,
      payload,
    );

    return data;
  });

  const onlinePaymentMutation = useMutation(async (payload: any) => {
    const { data } = await http.post(
      `${process.env.REACT_APP_API_URL}/api/v1/sub-agent/payments/${payload.id}/razorpay`,
      payload,
    );

    return data;
  });

  const paymentOptions = [
    {
      label: 'Credit Cards / Debit Cards / UPI / Net Banking',
      value: AddPaymentTypeEnum.CREDIT,
    },
    {
      label: 'Bank Transfer',
      value: AddPaymentTypeEnum.BANK_TRANSFER,
    },
    {
      label: 'Cheque / Cash',
      value: AddPaymentTypeEnum.CHEQUE_OR_CASH,
    },
  ];

  const payWithRazorpay = (id: any, res: any, onClose: () => void) => {
    const razorPayOptions: any = {
      key: res.rzpKey,
      amount: res.rzpAmount, // amount should be in paise format to display Rs 1255 without decimal point
      currency: res.rzpCurrency,
      name: user.agency.name, // company name or product name
      image: user.agency.logoUrl, // company logo or product image
      order_id: res.rzpOrderId, // order_id created by you in backend
      modal: {
        escape: false, // We should prevent closing of the form when esc key is pressed.
      },
      prefill: {
        email: user.email,
        contact: user.phoneNumber,
      },
      theme: {
        // hex color here
        color: rgbToHex(
          document.documentElement.style.getPropertyValue('--primary'),
        ),
      },
      handler: async (response: any) => {
        const data = {
          razorpayPaymentId: response.razorpay_payment_id,
          razorpayOrderId: response.razorpay_order_id,
          razorpaySignature: response.razorpay_signature,
        };
        try {
          await onlinePaymentMutation.mutateAsync({
            rzpOrderId: data.razorpayOrderId,
            rzpPaymentId: data.razorpayPaymentId,
            rzpSignature: data.razorpaySignature,
            id: id,
          });
          queryClient.fetchQuery(['accountData']);
          queryClient.fetchQuery(['transactions']);

          toast.success('Payment completed Success fully');

          onClose();
        } catch (ex: any) {
          toast.error(
            ex?.data?.message || 'Some error occured, please try again.',
          );
          console.log(ex);
        }
      },
    };

    const rzp = new (window as any).Razorpay(razorPayOptions);

    rzp.on('payment.failed', function (response: any) {
      console.log('Payment failed...');
    });

    rzp.open();
  };

  return {
    initiateOnlinePayment,
    paymentOptions,
    payWithRazorpay,
    onlinePaymentMutation,
    makePaymentMutation,
  };
};

export default useTopup;
