import http from 'app/config/http';
import { IBooking } from 'app/types';
import { TRootState } from 'app/store';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import useDialog from 'app/hooks/use-dialog';
import { PaymentTypeEnum } from './PaymentRef';
import { useState } from 'react';

const useBookingPayment = (id: string) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [html, setHtml] = useState('');
  const [showDialog, setShowDialog] = useState(false);

  const reviewDialog = useDialog<PaymentTypeEnum>();
  const warningDialog = useDialog({
    id: '',
    errorMessage: '',
  });

  const { availableCashBalance, availableCredit } = useSelector(
    (state: TRootState) => state.account,
  );
  const availableBalance = availableCredit + availableCashBalance;

  const bookingQuery = useQuery<IBooking>(['booking', id], async () => {
    const { data } = await http.get(
      `${process.env.REACT_APP_API_URL}/api/v1/sub-agent/bookings/${id}`,
    );

    return data;
  });

  const creditPaymentMutation = useMutation(async () => {
    const { data } = await http.post(
      `${process.env.REACT_APP_API_URL}/api/v1/sub-agent/bookings/${id}/payment/credit-limit`,
    );

    return data;
  });

  const ccAvenuePaymentMutation = useMutation(async (token: string) => {
    const { data } = await http.get(
      `${process.env.REACT_APP_API_URL}/api/v1/sub-agent/bookings/${id}/payment/ccavenue/initiate`,
      {
        params: {
          width: window.screen.width,
          height: window.screen.height,
          token: token,
        },
      },
    );
    return data;
  });

  const handleCreditPayment = async () => {
    try {
      const res = await creditPaymentMutation.mutateAsync();
      queryClient.fetchQuery(['accountData']);
      navigate(`/confirmation/${res._id}`);
    } catch (ex: any) {
      // toast.error(ex?.data?.message || 'Some error occurred, please try again.');
      console.log(ex);
      reviewDialog.hide();
      console.log({ here: ex });
      warningDialog.show({
        id,
        errorMessage: ex?.data?.message,
      });
    }
  };

  const handleCcAvenuePayment = async (token: string) => {
    const data = await ccAvenuePaymentMutation.mutateAsync(token);
    setHtml(data);
    setShowDialog(true);
    reviewDialog.hide();
  };

  return {
    isLoading: bookingQuery.isLoading,
    booking: bookingQuery.data,
    availableBalance,
    reviewDialog,
    warningDialog,
    handleCreditPayment,
    paymentProcessing: creditPaymentMutation.isLoading,
    handleCcAvenuePayment,
    html,
    showDialog,
  };
};

export default useBookingPayment;
