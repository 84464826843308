import { FC } from 'react';
import Loader from '../Loader/Loader';

interface PageLoaderProps {
  color?: string;
}

const PageLoader: FC<PageLoaderProps> = (props) => {
  return (
    <div className='fixed top-0 left-0 w-screen h-screen flex bg-white/100 z-10'>
      <div className='m-auto flex flex-col justify-center items-center'>
        <Loader color={props.color} />
      </div>
    </div>
  );
};

PageLoader.defaultProps = {
  color: 'rgb(var(--primary))',
};

export default PageLoader;
