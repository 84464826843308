import React, { FC, ReactNode } from 'react';
import Tooltip from '../Tooltip/Tooltip';

interface ConditionalTooltipProps {
  showTooltip: boolean;
  children: ReactNode;
  floatingContent: ReactNode;
  placement?:
    | 'bottom'
    | 'bottom-end'
    | 'bottom-start'
    | 'left'
    | 'left-end'
    | 'left-start'
    | 'right'
    | 'right-end'
    | 'right-start'
    | 'top'
    | 'top-end'
    | 'top-start';
}

const ConditionalTooltip: FC<ConditionalTooltipProps> = (props) => {
  const { floatingContent, placement, children, showTooltip } = props;
  return (
    <>
      {showTooltip ? (
        <Tooltip content={floatingContent} placement={placement}>
          {children}
        </Tooltip>
      ) : (
        children
      )}
    </>
  );
};

ConditionalTooltip.defaultProps = {
  placement: 'bottom-end',
};

export default ConditionalTooltip;
