import { ErrorMessage, Field, Form, Formik } from 'formik';
import React, { FC, useCallback } from 'react';
import * as Yup from 'yup';
import LoaderButton from 'app/components/LoaderButton/LoaderButton';
import { IUserForm } from '../../types';
import { useMutation } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import http from 'app/config/http';
import { useNavigate } from 'react-router-dom';
import debounce from 'lodash.debounce';
import { SubAgencyUserStatusEnum } from 'app/enums/sub-agency-user-status';
import capitalize from 'lodash.capitalize';

const SubAgentFormSchema = Yup.object({
  name: Yup.string().trim().required('This field is required'),
  email: Yup.string()
    .trim()
    .required('This field is required')
    .email('Please enter a valid email address'),
  phoneNumber: Yup.string()
    .trim()
    .required('This field is required')
    .matches(/^\d{10}$/, 'Please enter a valid mobile number'),
  username: Yup.string()
    .required('This field is required')
    .test('no-space-in-username', 'Username cannot contain spaces', (value) => {
      let result = true;
      for (let i = 0; i < value.length; i++) {
        if (value[i] === ' ') {
          result = false;
          break;
        }
      }
      return result;
    }),
  password: Yup.string()
    .required('This field is required')
    .test('no-space-in-username', 'Password cannot contain spaces', (value) => {
      let result = true;
      for (let i = 0; i < value.length; i++) {
        if (value[i] === ' ') {
          result = false;
          break;
        }
      }
      return result;
    }),
  designation: Yup.string().trim().required('This field is required'),
});

const initialValues: IUserForm = {
  name: '',
  email: '',
  password: '',
  phoneNumber: '',
  username: '',
  designation: '',
  status: SubAgencyUserStatusEnum.DISABLED,
};
interface SubAgencyUserAddFormProps {}

const SubAgencyUserAddForm: FC<SubAgencyUserAddFormProps> = (props) => {
  const navigate = useNavigate();

  const mutation = useMutation(async (payload: any) => {
    const { data } = await http.post(
      `${process.env.REACT_APP_API_URL}/api/v1/sub-agent/sub-agency/sub-agency-users/`,
      payload,
    );

    return data;
  });

  const handleSubmit = async (values: IUserForm) => {
    try {
      await mutation.mutateAsync(values);

      toast.success('Sub Agent added successfully.');
      navigate(`/users`);
    } catch (ex: any) {
      console.log(ex);
      toast.error(ex?.data?.message || 'Some error occured, please try again.');
    }
  };

  const uniqueEmailValidator = useCallback(
    debounce(async (value) => {
      // if the value is equal to the initial value, don't do anything
      if (value === initialValues.email) return;

      try {
        await http.post(
          `${process.env.REACT_APP_API_URL}/api/v1/sub-agent/sub-agency/sub-agency-users/email`,
          {
            email: value,
          },
        );
      } catch (err: any) {
        if (err.status === 400) {
          return err.data.message;
        }
      }
    }, 500),
    [],
  );

  const uniqueUsernameValidator = useCallback(
    debounce(async (value) => {
      // if the value is equal to the initial value, don't do anything
      if (value === initialValues.email) return;

      try {
        await http.post(
          `${process.env.REACT_APP_API_URL}/api/v1/sub-agent/sub-agency/sub-agency-users/username`,
          {
            username: value,
          },
        );
      } catch (err: any) {
        if (err.status === 400) {
          return err.data.message;
        }
      }
    }, 500),
    [],
  );

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize
      validationSchema={SubAgentFormSchema}
      onSubmit={handleSubmit}
    >
      <Form>
        <div className='grid grid-cols-12 gap-6 mt-8'>
          <div className=' col-span-12  md:col-span-4'>
            <label className='text-xs mb-2' htmlFor='name'>
              NAME
            </label>
            <Field type='text' id='name' name='name' className='form-control' />
            <div className='form-error'>
              <ErrorMessage name='name' />
            </div>
          </div>

          <div className=' col-span-12  md:col-span-4'>
            <label className='text-xs mb-2' htmlFor='email'>
              EMAIL
            </label>
            <Field
              type='email'
              id='email'
              name='email'
              className='form-control'
              validate={(val: string) => uniqueEmailValidator(val)}
            />
            <div className='form-error'>
              <ErrorMessage name='email' />
            </div>
          </div>

          <div className=' col-span-12  md:col-span-4'>
            <label className='text-xs mb-2' htmlFor='phoneNumber'>
              PHONE NUMBER
            </label>
            <div className='input-group'>
              <Field
                type='text'
                id='phoneNumber'
                name='phoneNumber'
                className='form-control'
              />
            </div>
            <div className='form-error'>
              <ErrorMessage name='phoneNumber' />
            </div>
          </div>

          <div className=' col-span-12  md:col-span-4'>
            <label className='text-xs mb-2' htmlFor='username'>
              USERNAME
            </label>
            <Field
              type='text'
              id='username'
              name='username'
              className='form-control'
              validate={(val: string) => uniqueUsernameValidator(val)}
            />
            <div className='form-error'>
              <ErrorMessage name='username' />
            </div>
          </div>

          <div className=' col-span-12  md:col-span-4'>
            <label className='text-xs mb-2' htmlFor='password'>
              PASSWORD
            </label>
            <Field
              type='password'
              id='password'
              name='password'
              className='form-control'
            />
            <div className='form-error'>
              <ErrorMessage name='password' />
            </div>
          </div>

          <div className='col-span-12  md:col-span-4'>
            <label className='text-xs mb-2' htmlFor='designation'>
              DESIGNATION
            </label>
            <Field
              type='text'
              id='designation'
              name='designation'
              className='form-control'
            />
            <div className='form-error'>
              <ErrorMessage name='designation' />
            </div>
          </div>

          <div className='col-span-12  md:col-span-4'>
            <label className='text-xs mb-2' htmlFor='status'>
              Status
            </label>
            <Field
              as='select'
              name='status'
              type='text'
              id='status'
              className='form-control'
            >
              {Object.values(SubAgencyUserStatusEnum).map((status) => (
                <option key={status} value={status}>
                  {capitalize(status)}
                </option>
              ))}
            </Field>
            <div className='form-error'>
              <ErrorMessage name='status' />
            </div>
          </div>
        </div>

        <LoaderButton
          type='submit'
          className='bg-primary text-white text-sm font-semibold rounded-lg w-full sm:w-56 mt-12'
          isLoading={mutation.isLoading}
          disabled={mutation.isLoading}
        >
          Add User
        </LoaderButton>
      </Form>
    </Formik>
  );
};

export default SubAgencyUserAddForm;
