import http from 'app/config/http';
import { BackIcon } from 'app/icons';
import { useQuery } from '@tanstack/react-query';
import { useNavigate, useParams } from 'react-router-dom';
import BgOverlay from 'app/components/BgOverlay/BgOverlay';
import PageLoader from 'app/components/PageLoader/PageLoader';
import EditPassengerForm from './components/EditPassengerForm/EditPassengerForm';
import { IPassenger } from '../types/Passenger';

const EditPassenger = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const query = useQuery(['passenger', id], async () => {
    const { data } = await http.get(
      `${process.env.REACT_APP_API_URL}/api/v1/sub-agent/customers/${id}`,
    );

    return data.customer as IPassenger;
  });

  if (query.isLoading) return <PageLoader />;

  return (
    <>
      {' '}
      <BgOverlay />
      <div className='container min-h-full'>
        <div className='flex py-8'>
          <div className='title text-secondary-fg flex items-center gap-3'>
            <span className='md:hidden' onClick={() => navigate(-1)}>
              <BackIcon />
            </span>
            Edit Passenger
          </div>
        </div>
        <div className='card p-9 mb-4'>
          <EditPassengerForm passenger={query.data} />
        </div>
      </div>
    </>
  );
};

export default EditPassenger;
