const Icon = (props: any) => (
  <svg
    width={24}
    height={24}
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    stroke='currentColor'
    {...props}
  >
    <path
      d='M5.25 7.583v0A2.333 2.333 0 017.583 5.25h13.334c.155 0 .232 0 .296.017a.5.5 0 01.353.354c.017.063.017.14.017.296v0c0 .93 0 1.395-.102 1.776a3 3 0 01-2.121 2.121c-.382.103-.847.103-1.777.103H17.5M5.25 7.583v0a2.333 2.333 0 002.333 2.334H20.75c.943 0 1.414 0 1.707.293.293.292.293.764.293 1.707v2.666m-17.5-7V18.75c0 1.886 0 2.828.586 3.414.586.586 1.528.586 3.414.586h11.5c.943 0 1.414 0 1.707-.293.293-.293.293-.764.293-1.707v-1.5m0 0h-2.667c-.942 0-1.414 0-1.707-.293-.293-.293-.293-.764-.293-1.707v-.667c0-.942 0-1.414.293-1.707.293-.293.764-.293 1.707-.293h2.667m0 4.667v-4.667'
      stroke='currentColor'
      opacity={0.5}
    />
  </svg>
);

export default Icon;
