// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Loader_lds-ring__XccjE {\n  position: relative;\n  display: inline-block;\n  height: 3.5rem;\n  width: 3.5rem;\n}\n\n.Loader_lds-ring__XccjE div {\n  position: absolute;\n  display: block;\n  height: 100%;\n  width: 100%;\n  box-sizing: border-box;\n  border: 6px solid transparent;\n  border-radius: 50%;\n  animation: Loader_lds-ring__XccjE 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;\n  border-color: var(--primary) transparent transparent transparent;\n}\n\n.Loader_lds-ring__XccjE div:nth-child(1) {\n  animation-delay: -0.45s;\n}\n\n.Loader_lds-ring__XccjE div:nth-child(2) {\n  animation-delay: -0.3s;\n}\n\n.Loader_lds-ring__XccjE div:nth-child(3) {\n  animation-delay: -0.15s;\n}\n\n@keyframes Loader_lds-ring__XccjE {\n  0% {\n    transform: rotate(0deg);\n  }\n  100% {\n    transform: rotate(360deg);\n  }\n}", "",{"version":3,"sources":["webpack://./src/app/components/Loader/Loader.module.scss"],"names":[],"mappings":"AACE;EAAA,kBAAA;EAAA,qBAAA;EAAA,cAAA;EAAA;AAAA;;AAIA;EAAA,kBAAA;EAAA,cAAA;EAAA,YAAA;EAAA,WAAA;EACA,sBAAA;EACA,6BAAA;EACA,kBAAA;EACA,4EAAA;EACA;AALA;;AAQF;EACE,uBAAA;AACF;;AAEA;EACE,sBAAA;AACF;;AAEA;EACE,uBAAA;AACF;;AAEA;EACE;IACE,uBAAA;EACF;EACA;IACE,yBAAA;EACF;AACF","sourcesContent":[".lds-ring {\n  @apply inline-block relative w-14 h-14;\n}\n\n.lds-ring div {\n  @apply block absolute w-full h-full;\n  box-sizing: border-box;\n  border: 6px solid transparent;\n  border-radius: 50%;\n  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;\n  border-color: var(--primary) transparent transparent transparent;\n}\n\n.lds-ring div:nth-child(1) {\n  animation-delay: -0.45s;\n}\n\n.lds-ring div:nth-child(2) {\n  animation-delay: -0.3s;\n}\n\n.lds-ring div:nth-child(3) {\n  animation-delay: -0.15s;\n}\n\n@keyframes lds-ring {\n  0% {\n    transform: rotate(0deg);\n  }\n  100% {\n    transform: rotate(360deg);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"lds-ring": "Loader_lds-ring__XccjE"
};
export default ___CSS_LOADER_EXPORT___;
