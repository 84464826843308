import { IBooking } from 'app/types';

export const extractFieldFromBooking = (booking: IBooking) => {
  return {
    departureCity: booking.departure.airport.city,
    arrivalCity: booking.arrival.airport.city,
    cabinWeight: booking.priceDetail.fareDescription.adult.baggage.cabin,
    checkInWeight: booking.priceDetail.fareDescription.adult.baggage.checkIn,
    refundableType: booking.priceDetail.fareDescription.adult.refundableType,
    cabinClass: booking.priceDetail.fareDescription.adult.cabinClass,
    fareType: booking.fareSupplier === '0' ? 'Series' : 'Normal',
    isInternational:
      booking.departure.airport.countryCode !==
      booking.arrival.airport.countryCode,
  };
};
