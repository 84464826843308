import { getInitials } from 'app/utils/common-utils';
import React, {
  DetailedHTMLProps,
  FC,
  ForwardedRef,
  forwardRef,
  HTMLAttributes,
} from 'react';
import { twMerge } from 'tailwind-merge';

interface AvatarInitialProps
  extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  ref?: ForwardedRef<HTMLDivElement>;
  children: string;
}

const AvatarInitial: FC<AvatarInitialProps> = forwardRef((props, ref) => {
  const { className, children, ...rest } = props;

  return (
    <div
      {...rest}
      ref={ref}
      className={twMerge(
        'w-12 h-12 bg-primary/10 rounded-full flex items-center justify-center text-primary text-lg font-semibold',
        className,
      )}
    >
      {getInitials(children)}
    </div>
  );
});

export default AvatarInitial;
