import { FC } from 'react';
import { IBooking } from 'app/types';
import Modal from 'app/components/Modal/Modal';
import { formatCurrency } from 'app/utils/currency-utils';
import LoaderButton from 'app/components/LoaderButton/LoaderButton';
import FlightDetails from 'app/modules/booking/components/FlightDetails/FlightDetails';
import PassengerDetails from 'app/modules/booking/components/PassengerDetails/PassengerDetails';

interface ReviewModalProps {
  show: boolean;
  onClose: () => void;
  booking: IBooking;
  submitLabel: string;
  onSubmit: () => void;
  isLoading: boolean;
  // paymentGatewaySelected: IPaymentGateway;
}

const ReviewModal: FC<ReviewModalProps> = (props) => {
  const {
    show,
    booking,
    onClose,
    submitLabel,
    onSubmit,
    isLoading,
    // paymentGatewaySelected,
  } = props;
  return (
    <Modal show={show} onClose={onClose} className='px-0'>
      <FlightDetails booking={booking} />
      <div className='h-[1px] bg-gray-200 mt-10 mb-8' />
      <PassengerDetails booking={booking} />
      <div className='h-[1px] bg-gray-200 mt-10 mb-8' />
      <div className='px-8'>
        <div className='text-xl font-bold'>Fare Summary</div>
        <div className='mt-7'>
          <div className='flex justify-between items-center'>
            <div>Base Fare</div>
            <div>{formatCurrency(booking.priceDetail.totalBaseFare)}</div>
          </div>
          <div className='flex justify-between items-center mt-4'>
            <div>Fees & Surcharges</div>
            <div>{formatCurrency(booking.priceDetail.totalTaxesAndFees)}</div>
          </div>
          {booking.isTaxed && (
            <div className='flex justify-between items-center mt-4'>
              <div>Mgt. Fee Total</div>
              <div>
                {formatCurrency(booking.taxDetails.managementFeesTotal)}
              </div>
            </div>
          )}
        </div>
        {submitLabel === 'Pay Online' ? (
          <div className='font-semibold flex justify-between items-center mt-8'>
            <div>Total Amount</div>
            <div>{formatCurrency(booking.priceDetail.totalFare)}</div>
          </div>
        ) : (
          // <>
          //   <div className='flex justify-between items-center mt-4'>
          //     <div>Booking Amount</div>
          //     <div className='font-semibold'>
          //       {formatCurrency(paymentGatewaySelected.bookingAmount)}
          //     </div>
          //   </div>

          //   <div className='flex justify-between items-center mt-4'>
          //     <div>Gateway Charges</div>
          //     <div className='text-red-500'>
          //       {formatCurrency(paymentGatewaySelected.gatewayCharges)}
          //     </div>
          //   </div>

          //   <div className='font-semibold flex justify-between items-center mt-8'>
          //     <div>Total Amount</div>
          //     <div>
          //       {formatCurrency(paymentGatewaySelected.totalOrderAmount)}
          //     </div>
          //   </div>
          // </>
          <div className='font-semibold flex justify-between items-center mt-8'>
            <div>Total Amount</div>
            <div>{formatCurrency(booking.priceDetail.totalFare)}</div>
          </div>
        )}
      </div>
      <LoaderButton
        onClick={onSubmit}
        className='btn btn-primary mt-9  min-w-[12rem] mx-8'
        isLoading={isLoading}
        disabled={isLoading}
      >
        {submitLabel}
      </LoaderButton>
    </Modal>
  );
};

export default ReviewModal;
