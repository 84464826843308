import * as React from 'react';

const Icon = (props: any) => {
  return (
    <svg
      width={170}
      height={27}
      viewBox='0 0 170 27'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <g clipPath='url(#clip0_75_905)'>
        <path d='M20.893 0H0v6.337h20.893V0z' fill='#F5163C' />
        <path d='M14.553 0v20.882h6.34V0h-6.34z' fill='#F5163C' />
        <path
          d='M13.19 3.112L-.001 16.295l4.484 4.481 13.19-13.183-4.483-4.481z'
          fill='#F5163C'
        />
        <path
          d='M32.217 20.69h-4.4L35.191.235h4.76l7.358 20.455h-4.188l-1.244-3.52h-8.412l-1.248 3.52zm5.48-15.256h-.067l-2.834 7.98h5.742l-2.841-7.98zM58.942 7.437V5.594h3.777v14.517a6.647 6.647 0 01-1.957 4.956c-1.304 1.286-3.081 1.93-5.33 1.933-2.135 0-3.793-.567-4.975-1.702a5.992 5.992 0 01-1.958-3.802h4.095c.26 1.325 1.23 1.987 2.908 1.987a3.231 3.231 0 002.348-.782 2.86 2.86 0 00.807-2.16v-2.104c-.979 1.304-2.45 1.956-4.412 1.956a6.304 6.304 0 01-4.986-2.175 8.603 8.603 0 010-10.801 6.3 6.3 0 014.986-2.175c2.132.005 3.698.737 4.697 2.195zm-1.21 8.27a4.63 4.63 0 000-5.763 3.758 3.758 0 00-5.303 0 4.614 4.614 0 000 5.762 3.758 3.758 0 005.304 0zM79.522 14.322h-10.96c.07.909.482 1.758 1.152 2.378.707.573 1.6.865 2.509.821a3.752 3.752 0 001.773-.418 2.442 2.442 0 001.139-1.225h4.121a6.85 6.85 0 01-2.61 3.732 7.47 7.47 0 01-4.54 1.424 7.263 7.263 0 01-5.48-2.218c-1.42-1.478-2.131-3.38-2.134-5.703a7.849 7.849 0 012.133-5.618 7.175 7.175 0 015.449-2.245 7.072 7.072 0 015.405 2.23 7.927 7.927 0 012.09 5.633l-.047 1.209zm-7.476-5.79a3.383 3.383 0 00-2.278.782 3.563 3.563 0 00-1.154 2.09h6.861a3.442 3.442 0 00-1.123-2.074 3.379 3.379 0 00-2.306-.798zM85.257 5.594v1.843c.782-1.46 2.281-2.19 4.497-2.187 1.79 0 3.164.542 4.121 1.627.958 1.085 1.44 2.55 1.445 4.393v9.42h-4.094v-8.958c0-1.98-.856-2.97-2.568-2.97a2.929 2.929 0 00-2.274.88 3.398 3.398 0 00-.81 2.403v8.645h-4.09V5.594h3.773zM105.148 17.06v3.544a8.52 8.52 0 01-1.934.172c-3.092 0-4.638-1.536-4.638-4.608V8.82h-2.047V5.594h2.047V1.85h4.09v3.744h2.509v3.227h-2.509v6.913c0 .98.51 1.47 1.53 1.47l.952-.144zM120.393 16.829v3.861h-13.288V.235h4.208v16.594h9.08zM133.799 18.746c-1.464 1.536-3.377 2.304-5.738 2.304-2.362 0-4.273-.763-5.735-2.288a8.262 8.262 0 010-11.208c1.462-1.526 3.373-2.289 5.735-2.289 2.361 0 4.274.763 5.738 2.289a8.271 8.271 0 010 11.208v-.016zm-8.49-8.743a5.086 5.086 0 000 6.259 3.86 3.86 0 004.244.854 3.86 3.86 0 001.259-.854 5.086 5.086 0 000-6.26 3.859 3.859 0 00-5.503 0z'
          fill='#000'
        />
        <path
          d='M148.582 7.437V5.594h3.777v14.517a6.649 6.649 0 01-1.957 4.956c-1.305 1.286-3.082 1.93-5.331 1.933-2.132 0-3.789-.567-4.971-1.702a6.014 6.014 0 01-1.976-3.802h4.094c.261 1.325 1.231 1.987 2.912 1.987a3.22 3.22 0 002.348-.782 2.843 2.843 0 00.806-2.16v-2.104c-.978 1.304-2.447 1.956-4.407 1.956a6.299 6.299 0 01-4.986-2.175 8.602 8.602 0 010-10.801 6.291 6.291 0 014.986-2.175c2.137.005 3.706.737 4.705 2.195zm-1.209 8.27a4.62 4.62 0 000-5.763 3.76 3.76 0 00-5.304 0 4.614 4.614 0 000 5.762 3.759 3.759 0 005.304 0zM167.808 18.746c-1.461 1.536-3.374 2.304-5.738 2.304-2.364 0-4.277-.763-5.738-2.288a8.27 8.27 0 010-11.208c1.461-1.526 3.374-2.289 5.738-2.289 2.364 0 4.275.763 5.734 2.289a8.257 8.257 0 010 11.208l.004-.016zm-8.478-8.743a5.085 5.085 0 000 6.259 3.853 3.853 0 004.244.854 3.86 3.86 0 001.259-.854 5.08 5.08 0 000-6.26 3.859 3.859 0 00-5.503 0z'
          fill='#000'
        />
      </g>
      <defs>
        <clipPath id='clip0_75_905'>
          <path fill='#fff' d='M0 0H170V27H0z' />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Icon;
