import React, { FC } from 'react';
import { useQuery } from '@tanstack/react-query';
import http from 'app/config/http';
import Loader from 'app/components/Loader/Loader';
import { getInitials } from 'app/utils/common-utils';
import { Link } from 'react-router-dom';
import { EditPencilIcon } from 'app/icons';

interface AgencyUsersProps {}

const AgencyUsers: FC<AgencyUsersProps> = (props) => {
  const agencyUsersQuery = useQuery(['agencyUsers'], async () => {
    const { data } = await http.get(
      `${process.env.REACT_APP_API_URL}/api/v1/sub-agent/sub-agency/sub-agency-users`,
    );

    return data;
  });

  return (
    <div>
      <div className='flex justify-between items-center'>
        <div className='text-base font-bold'>Agency Users </div>
        <Link to={'/users/add'} className='text-sm font-semibold text-blue-500'>
          Add User
        </Link>
      </div>
      <div className='mt-5'>
        {agencyUsersQuery.isLoading ? (
          <div className='flex items-center justify-center pt-5'>
            <Loader />
          </div>
        ) : (
          <div className='overflow-auto'>
            <table className='w-full'>
              <thead className=''>
                <tr className='bg-[#EAECF0]'>
                  <th className='text-gray-500 text-sm font-medium p-3 text-left'>
                    <div className='ml-3'>Name</div>
                  </th>
                  <th className='text-gray-500 text-sm font-medium p-3 text-left'>
                    Email address
                  </th>
                  <th className='text-gray-500 text-sm font-medium p-3 text-left' />
                </tr>
              </thead>
              <tbody>
                {agencyUsersQuery.data.map((row: any, i: number) => (
                  <tr
                    key={i}
                    className='border-b border-black/10 last:border-none'
                  >
                    <td className='text-sm font-normal text-gray-500 py-4 px-3'>
                      <div className='flex items-center gap-3'>
                        {/* <img
                                                    src={dp}
                                                    className="w-10 h-10 rounded-full object-cover "
                                                    alt="diplay images"
                                                /> */}
                        <div className='w-12 h-12 bg-primary/10 rounded-full flex items-center justify-center'>
                          <div className='text-primary text-lg font-semibold'>
                            {getInitials(row.name)}
                          </div>
                        </div>
                        <div>
                          <div className='text-sm font-semibold text-gray-900'>
                            {row.name}
                          </div>
                          <div className='text-sm font-normal text-[#667085]'>{`@${row.username}`}</div>
                        </div>
                      </div>
                    </td>
                    <td className='text-sm font-normal text-gray-500 py-4 px-3'>
                      {row.email}
                    </td>
                    <td className='text-sm font-normal text-gray-500 py-4 px-3'>
                      <Link to={`/users/${row._id}/edit`}>
                        <EditPencilIcon />
                      </Link>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </div>
  );
};

export default AgencyUsers;
