/* eslint-disable jsx-a11y/anchor-is-valid */
import moment from 'moment';
import http from 'app/config/http';
import { IBooking } from 'app/types';
import { useParams } from 'react-router-dom';
import useDialog from 'app/hooks/use-dialog';
import { FC, useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { formatCurrency } from 'app/utils/currency-utils';
import BgOverlay from 'app/components/BgOverlay/BgOverlay';
import PageLoader from 'app/components/PageLoader/PageLoader';
import MarkupModal from './components/MarkupModal/MarkupModal';
import { BookingStatusEnum } from 'app/enums/ booking-status.enum';
import { createSearchParams, useNavigate } from 'react-router-dom';
import PrintOptionModal from './components/PrintOptionModal/PrintOptionModal';
import SuccessAnimation from './components/SuccessAnimation/SuccessAnimation';
import FlightDetails from 'app/modules/booking/components/FlightDetails/FlightDetails';
import BookingFailedAnimation from './components/BookingFailedAnimation/BookingFailedAnimation';
import PassengerDetails from 'app/modules/booking/components/PassengerDetails/PassengerDetails';
import ConfirmationPendingAnimation from './components/ConfirmationPendingAnimation/ConfirmationPendingAnimation';
import BookedTicketPriceDetails from 'app/components/BookedTicketPriceDetails/BookedTicketPriceDetails';

interface ConfirmationProps {}

const Confirmation: FC<ConfirmationProps> = (props) => {
  const { id } = useParams();
  const [showAnimation, setShowAnimation] = useState<boolean>(true);
  const navigate = useNavigate();

  const printDialog = useDialog();
  const markupDialog = useDialog();

  useEffect(() => {
    setTimeout(() => setShowAnimation(false), 5000);
  }, []);

  useEffect(() => {
    const handleBackButton = (event: PopStateEvent) => {
      navigate('/', { replace: true });
    };

    window.addEventListener('popstate', handleBackButton);
    return () => {
      setTimeout(() => {
        window.removeEventListener('popstate', handleBackButton);
      }, 100);
    };
  }, []);

  const bookingQuery = useQuery<IBooking>(['booking', id], async () => {
    const { data } = await http.get(
      `${process.env.REACT_APP_API_URL}/api/v1/sub-agent/bookings/${id}`,
    );

    return data;
  });

  // const printMutation = useMutation(async () => {
  //     const { data } = await http.get(
  //         `${process.env.REACT_APP_API_URL}/api/v1/sub-agent/bookings/${id}/print-ticket`,
  //         {
  //             responseType: 'blob',
  //         }
  //     );
  //     return data;
  // });

  // const handlePrintTicket = async () => {
  //     try {
  //         const res = await printMutation.mutateAsync();
  //         const url = window.URL.createObjectURL(new Blob([res]));
  //         const link = document.createElement('a');
  //         link.href = url;
  //         link.setAttribute('download', `eTicket-${id}.pdf`);
  //         // Append to html link element page
  //         document.body.appendChild(link);

  //         // Start download
  //         link.click();

  //         // Clean up and remove the link
  //         link.parentNode.removeChild(link);
  //     } catch (err) {
  //         console.log(err);
  //     }
  // };

  const handlePrintTicket = (values: any) => {
    printDialog.hide();
    navigate({
      pathname: `/bookings/${booking._id}/ticket`,
      search: createSearchParams({
        ...values,
      }).toString(),
    });
  };

  const booking = bookingQuery.data;
  if (bookingQuery.isLoading) return <PageLoader />;

  const getBackgroundColor = (status: string) => {
    if (status === 'AWAITING_PNR') return 'bg-yellow-500';
    if (status === 'IN_PROCESS') return 'bg-yellow-500';
    if (status === 'FAILED') return 'bg-red-500';
    if (status === 'COMPLETED') return 'bg-success';
  };

  const isCancelledOrPending =
    booking.status === 'CANCELLED' || booking.status === 'PENDING';

    return (
      <div>
        <BgOverlay bgClass={getBackgroundColor(booking.status)} />

        {(booking.status === 'AWAITING_PNR' ||
          booking.status === 'IN_PROCESS') && (
          <ConfirmationPendingAnimation
            show={showAnimation}
            onClose={() => setShowAnimation(false)}
          />
        )}
        {booking.status === 'COMPLETED' && (
          <SuccessAnimation
            show={showAnimation}
            onClose={() => setShowAnimation(false)}
          />
        )}
        {booking.status === BookingStatusEnum.FAILED && (
          <BookingFailedAnimation
            show={showAnimation}
            onClose={() => setShowAnimation(false)}
          />
        )}

        <div className='container min-h-full'>
          <div className='flex flex-col gap-2  sm:flex-row sm:justify-between sm:items-center py-8'>
            <div className='title text-white'>
              {/* {`Your flight booking is ${booking.status === 'AWAITING_PNR'  ? 'pending' : 'confirmed'}`} */}
              {booking.status === 'AWAITING_PNR' &&
                'Your flight booking is pending'}
              {booking.status === 'FAILED' && 'Your flight booking has failed'}
              {booking.status === 'COMPLETED' &&
                'Your flight booking is confirmed'}
            </div>

            <div className='text-sm text-white'>
              Booking ID :{' '}
              <span className='font-semibold mx-1'>{booking._id}</span> | Booked
              On :{' '}
              <span className='font-semibold mx-1'>
                {moment(booking.updatedAt).format('DD MMM, YYYY')}
              </span>
            </div>
          </div>

          <div className='grid grid-cols-3 gap-9'>
            <div className='col-span-3 lg:col-span-2'>
              <FlightDetails booking={booking} />
              <PassengerDetails booking={booking} />
            </div>
            {/* side cards */}
            <div className=' col-span-3 lg:col-span-1'>
              {/* ticked Share , print , donwload */}
              <div className='card p-4 mb-4 '>
                <div className='text-sm font-semibold'>Ticket(s)</div>

                {/* <a className={`block text-sm font-normal text-primary mt-8`}>Share Ticket</a>
              <a className={`block text-sm font-normal text-primary mt-4`}>Download Ticket</a> */}
                {/* <Link
                to={`/bookings/${booking._id}/ticket`}
                // target="_blank"
                className='block text-sm text-primary cursor-pointer mt-4'
              >
                Print Ticket
              </Link> */}

                <button
                  onClick={printDialog.show}
                  className='block text-sm text-blue-500 cursor-pointer mt-4'
                >
                  Print Ticket
                </button>

                {/* {printMutation.isLoading ? (
                <div className='mt-4'>
                  <Spinner size={32} />
                </div>
              ) : (
                <a
                  className={`block text-sm font-normal text-blue-500 mt-4 cursor-pointer`}
                  onClick={handlePrintTicket}
                >
                  Print Ticket
                </a>
              )} */}
              </div>

              {/* ticked change / cancel */}
              {/* <div className="card p-4 mb-4">
                  <div className="text-sm font-semibold">Change Ticket</div>
                  <a className={`block text-sm font-normal text-primary mt-8`}>Request Change</a>
                  <a className={`block text-sm font-normal text-danger mt-4`}>Cancel Ticket</a>
                </div> */}

              {booking.status !== 'FAILED' && (
                // <div className='card p-4 mb-4'>
                //   <div className='text-sm font-semibold'>Fare Summary</div>
                //   <div className='mt-8'>
                //     <div className='flex justify-between'>
                //       <div className='text-sm  font-normal'>Base Fare</div>
                //       <div className='text-sm font-normal'>
                //         {formatCurrency(booking.priceDetail.totalBaseFare)}
                //       </div>
                //     </div>
                //     <div className='mt-4 flex justify-between'>
                //       <div className='text-sm  font-normal'>
                //         Fees & Surcharges
                //       </div>
                //       <div className='text-sm  font-normal'>
                //         {formatCurrency(
                //           booking.priceDetail.totalTaxesAndFees +
                //             booking.priceDetail.additionalMarkup,
                //           // + booking.markup
                //         )}
                //       </div>
                //     </div>
                //   </div>
                //   <div className='mt-8 text-base  font-semibold flex justify-between'>
                //     <div>
                //       Total Amount{' '}
                //       <span
                //         className='text-xs font-light text-blue-500 cursor-pointer ml-2 '
                //         onClick={markupDialog.show}
                //       >
                //         Markup
                //       </span>
                //     </div>
                //     <div>
                //       {formatCurrency(
                //         booking.priceDetail.totalFare +
                //           booking.priceDetail.additionalMarkup,
                //       )}
                //     </div>
                //   </div>
                //   <div className='mt-3 text-base  font-semibold flex justify-between'>
                //     <div>Payment (AGY BAL)</div>
                //     <div className='text-success'>
                //       -
                //       {formatCurrency(
                //         booking.priceDetail.totalFare +
                //           booking.priceDetail.additionalMarkup,
                //       )}
                //     </div>
                //   </div>
                //   <div className='mt-8 text-base  font-semibold flex justify-between'>
                //     <div>Balance</div>
                //     <div className='text-success'>₹ 0</div>
                //   </div>
                // </div>

                <BookedTicketPriceDetails
                  booking={booking}
                  isCancelledOrPending={isCancelledOrPending}
                />
              )}
            </div>
          </div>
        </div>
        <PrintOptionModal
          show={printDialog.isOpen}
          onClose={printDialog.hide}
          onSubmit={(value: any) => handlePrintTicket(value)}
        />
        <MarkupModal
          show={markupDialog.isOpen}
          onClose={markupDialog.hide}
          bookingId={id}
          currentMarkup={booking.priceDetail.additionalMarkup}
        />
      </div>
    );
};

export default Confirmation;
