import { FC, useEffect, useState } from 'react';
import http from 'app/config/http';
import { toast } from 'react-toastify';
import { Link, useSearchParams } from 'react-router-dom';
import { MenuDotIcon } from 'app/icons';
import useDialog from 'app/hooks/use-dialog';
import { formatDate } from 'app/utils/date-utils';
import { formatCurrency } from 'app/utils/currency-utils';
import StatusBadge from 'app/components/StatusBadge/StatusBadge';
import DeleteModal from 'app/components/DeleteModal/DeleteModal';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
  Dropdown,
  DropdownItem,
  DropdownList,
} from 'app/components/Dropdown/Dropdown';
import { bookingNavTabEnum } from '../../Bookings';
import Spinner from 'app/components/Spinner/Spinner';
import PaginationRange from 'app/components/PaginationRange/PaginationRange';

interface UpcomingProps {
  bookingNavTab: bookingNavTabEnum;
}

const Upcoming: FC<UpcomingProps> = (props) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [pageCount, setPageCount] = useState<number>(0);

  let page = +searchParams.get('page') || 1;

  const handlePageChange = (val: any) => {
    let newPage = val.selected + 1;
    searchParams.set('page', newPage);
    setSearchParams(searchParams, { replace: true });
  };

  const { bookingNavTab } = props;
  const deleteDialog = useDialog('');
  const queryClient = useQueryClient();

  const { data, isLoading } = useQuery(
    ['booking', bookingNavTab, page],
    async () => {
      const { data } = await http.get(
        `${process.env.REACT_APP_API_URL}/api/v1/sub-agent/bookings?displayTab=${bookingNavTab}&limit=30&page=${page}`,
      );
      setPageCount(data.pageCount);
      return data;
    },
  );

  const abortTicketMutation = useMutation(async (bookingId: string) => {
    const { data } = await http.put(
      `${process.env.REACT_APP_API_URL}/api/v1/sub-agent/bookings/${bookingId}/abort`,
    );

    return data;
  });

  const handleTicketAbort = async (bookingId: string) => {
    try {
      await abortTicketMutation.mutateAsync(bookingId);
      queryClient.fetchQuery(['booking']);
      deleteDialog.hide();
      toast.success('Booking Aborted');
    } catch (ex: any) {
      console.log(ex);
      toast.error(ex?.data?.message || 'Some error occured, please try again.');
    }
  };

  const getFirstPassengerName = (booking: any) => {
    const firstPassenger = booking.passengers[0];

    return `${firstPassenger.title} ${firstPassenger.firstName} ${firstPassenger.lastName}`;
  };

  const getStatusText = (status: string) => {
    switch (status) {
      case 'PENDING':
        return 'Pending';
      case 'IN_PROCESS':
        return 'Processing';
      case 'AWAITING_PNR':
        return 'Awaiting PNR';
      case 'SUBMISSION_PENDING':
      case 'COMPLETED':
        return 'Completed';
      case 'CANCELLED':
        return 'Cancelled';
      case 'FAILED':
        return 'Failed';
    }
  };

  const getStatusClass = (status: string) => {
    switch (status) {
      case 'PENDING':
      case 'AWAITING_PNR':
      case 'IN_PROCESS':
        return 'yellow';
      case 'SUBMISSION_PENDING':
      case 'COMPLETED':
        return 'green';
      case 'CANCELLED':
        return 'red';
      case 'FAILED':
        return 'red';
    }
  };

  return (
    <>
      <table className='w-full'>
        <thead>
          <tr>
            <td className='td th'>ID</td>
            <td className='td th'>Travel Date</td>
            <td className='td th'>Origin & Destination</td>
            <td className='td th'>Flight Details</td>
            <td className='td th'>Passenger(s)</td>
            <td className='td th'>Airline PNR</td>
            <td className='td th'>Fare</td>
            <td className='td th'>Booking Date</td>
            <td className='td th'></td>
          </tr>
        </thead>

        <tbody>
          {!isLoading && data.bookings.length === 0 ? (
            <tr>
              <td
                align='center'
                colSpan={10}
                className='font-medium text-base text-[#98A2B3]'
              >
                - No data available -
              </td>
            </tr>
          ) : (
            !isLoading &&
            data.bookings.map((booking: any, index: number) => {
              return (
                <tr key={index}>
                  <td className='td tb'>
                    <div className='flex flex-col gap-1 '>
                      <Link
                        className='text-blue-500 font-semibold'
                        to={`/bookings/${booking._id}`}
                      >
                        {booking._id}
                      </Link>
                    </div>
                  </td>
                  <td className='td tb'>
                    <div className='flex flex-col gap-1 '>
                      <div>{formatDate(booking.trip.departure.date)}</div>
                      <div>
                        <StatusBadge
                          className='mt-1'
                          color={getStatusClass(booking.status)}
                        >
                          {getStatusText(booking.status)}
                        </StatusBadge>
                      </div>
                    </div>
                  </td>
                  <td className='td tb'>
                    {booking.trip.departure.airport.city} to{' '}
                    {booking.trip.arrival.airport.city}
                  </td>
                  <td className='td tb'>
                    <div className='flex flex-col sm:flex-row items-center gap-2'>
                      <img
                        className='w-12 h-12'
                        crossOrigin='anonymous'
                        src={booking.flight.logo}
                        alt='airline logo'
                      />
                      <div>
                        <div>{booking.flight.name}</div>
                        <div className='text-gray-500'>{`${booking.flight.code} ${booking.flight.number}`}</div>
                      </div>
                    </div>
                  </td>
                  <td className='td tb'>
                    <div className='flex items-center gap-2'>
                      {getFirstPassengerName(booking)}

                      {booking.passengers.length > 1 && (
                        <div className='bg-gray-100 px-3 py-1 rounded-full'>
                          + {booking.passengers.length - 1}
                        </div>
                      )}
                    </div>
                  </td>
                  <td className='td tb'>
                    {booking.passengers[0]?.pnrs[0]?.number}
                  </td>
                  <td className='td tb whitespace-nowrap '>
                    {formatCurrency(booking.payment.amount)}
                  </td>
                  <td className='td tb'>{formatDate(booking.bookingDate)}</td>
                  <td className='td tb'>
                    <Dropdown
                      placement={'bottom-end'}
                      target={
                        <div className='dropdown-action'>
                          <MenuDotIcon />
                        </div>
                      }
                      menu={
                        <DropdownList>
                          <DropdownItem to={`/bookings/${booking._id}`}>
                            <span className='pr-10 text-primary '>View</span>
                          </DropdownItem>
                          {bookingNavTab === bookingNavTabEnum.PENDING && (
                            <DropdownItem to={`/payment/${booking._id}`}>
                              <span className='pr-10 text-blue-500 '>
                                Proceed
                              </span>
                            </DropdownItem>
                          )}
                          {bookingNavTab === bookingNavTabEnum.PENDING && (
                            <DropdownItem
                              onClick={() => deleteDialog.show(booking._id)}
                            >
                              <span className='pr-10 text-red-500 '>Abort</span>
                            </DropdownItem>
                          )}
                        </DropdownList>
                      }
                    />
                  </td>
                </tr>
              );
            })
          )}
        </tbody>
      </table>

      {isLoading && (
        <div className='flex justify-center items-center min-h-[500px]'>
          <Spinner />
        </div>
      )}

      <div className='px-8'>
        <PaginationRange
          handlePageChange={handlePageChange}
          pageCount={pageCount}
          PageNumber={page - 1}
          // initialPage={5}
          key={bookingNavTab}
        />
      </div>

      <DeleteModal
        message='Abort Booking'
        onClose={deleteDialog.hide}
        onConfirm={() => handleTicketAbort(deleteDialog.data)}
        show={deleteDialog.isOpen}
        isLoading={abortTicketMutation.isLoading}
        approveActionMessage='Abort'
        cancelActionMessage='Cancel'
      />
    </>
  );
};

export default Upcoming;
