import BgOverlay from 'app/components/BgOverlay/BgOverlay';
import LoaderButton from 'app/components/LoaderButton/LoaderButton';
import StatusBadge from 'app/components/StatusBadge/StatusBadge';
import { FC } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { formatDate } from 'app/utils/date-utils';
import { formatCurrency } from 'app/utils/currency-utils';
import {
  Dropdown,
  DropdownItem,
  DropdownList,
} from 'app/components/Dropdown/Dropdown';
import { BackIcon, MenuDotIcon } from 'app/icons';
import moment from 'moment';
import Currency from 'app/components/Currency/Currency';
import useDialog from 'app/hooks/use-dialog';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import http from 'app/config/http';
import PageLoader from 'app/components/PageLoader/PageLoader';
import { toast } from 'react-toastify';
import StatusUpdateModal from './components/StatusUpdateModal/StatusUpdateModal';

interface SeriesInventoryBookingListProps {}

const SeriesInventoryBookingList: FC<SeriesInventoryBookingListProps> = (
  props,
) => {
  const { inventoryId } = useParams();
  const statusUpdateDialog = useDialog<string>();
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const query = useQuery(['seriesInventoryTicket'], async () => {
    const { data } = await http.get(
      `${process.env.REACT_APP_API_URL}/api/v1/sub-agent/series-tickets/${inventoryId}`,
    );

    return data;
  });

  const downloadExcelMutation = useMutation(async () => {
    const { data } = await http.get(
      `${process.env.REACT_APP_API_URL}/api/v1/sub-agent/series-tickets/${inventoryId}/airline-excel`,
      {
        responseType: 'blob',
      },
    );

    return data;
  });

  const getStatusClass = (status: string) => {
    switch (status) {
      case 'PENDING':
        return 'yellow';
      case 'COMPLETED':
      case 'SUBMITTED':
        return 'green';
      case 'CANCELLED':
        return 'red';
    }
  };

  const getStatusText = (status: string) => {
    switch (status) {
      case 'PENDING':
        return 'Naming Pending';
      case 'COMPLETED':
        return 'Completed';
      case 'SUBMITTED':
        return 'Submitted';
      case 'CANCELLED':
        return 'Cancelled';
    }
  };

  const handleDownloadExcel = async () => {
    try {
      const res = await downloadExcelMutation.mutateAsync();

      const url = window.URL.createObjectURL(
        new Blob([res], { type: 'application/pdf' }),
      );
      const link = document.createElement('a');

      link.setAttribute('href', url);
      link.setAttribute('download', `${inventoryId}-airline-excel.xlsx`);

      // Append to html link element page
      document.body.appendChild(link);

      // Start download
      link.click();

      window.URL.revokeObjectURL(url);
      // Clean up and remove the link
      link.parentNode.removeChild(link);
    } catch (ex: any) {
      toast.error(ex?.data?.message || 'Some error occured, please try again.');
      console.log(ex);
    }
  };

  const updateStatusMutation = useMutation(async () => {
    const { data } = await http.put(
      `${process.env.REACT_APP_API_URL}/api/v1/sub-agent/series-tickets/${inventoryId}/submit`,
    );

    return data;
  });

  const handleUpdateStatus = async () => {
    try {
      await updateStatusMutation.mutateAsync();

      toast.success('Status updated successfully.');

      // queryClient.setQueryData(['agencyDetail'], (prev: any) => {
      //     return update(prev, {
      //         status: 'SUBMITTED',
      //     });
      // });

      queryClient.invalidateQueries({ queryKey: ['seriesInventoryTicket'] });
    } catch (ex: any) {
      toast.error(ex?.data?.message || 'Some error occured, please try again.');
      console.log(ex);
    } finally {
      statusUpdateDialog.hide();
    }
  };

  if (query.isLoading) return <PageLoader />;

  const ticket = query.data;

  return (
    <div>
      <BgOverlay />

      <div className='container min-h-full'>
        <div className='flex py-8'>
          <div className='title text-secondary-fg flex gap-2 items-center'>
            <span className='md:hidden' onClick={() => navigate(-1)}>
              <BackIcon />
            </span>
            Bookings
          </div>
        </div>

        {/* main continer */}
        <div className='card py-9 mb-4'>
          {/* top bar with buttons */}
          <div className='flex flex-col sm:flex-row items-start gap-3      px-6'>
            <div className='font-bold'>Bookings - {ticket._id}</div>

            <StatusBadge className='mt-1' color={getStatusClass(ticket.status)}>
              {getStatusText(ticket.status)}
            </StatusBadge>
            <button
              disabled={ticket.status === 'SUBMITTED'}
              onClick={() => statusUpdateDialog.show(inventoryId)}
              className='text-center bg-primary text-white text-sm font-semibold px-4 py-3 rounded-lg w-full sm:w-56'
            >
              Mark Submitted
            </button>
            <LoaderButton
              className='bg-[#1D6F42] text-white text-sm font-semibold px-6 rounded-lg text-center w-full sm:w-56'
              onClick={handleDownloadExcel}
              isLoading={downloadExcelMutation.isLoading}
            >
              Download Excel for Airline
            </LoaderButton>
          </div>
          {/* flight details */}
          <div className='px-6 mt-10'>
            <div className='grid grid-cols-4 gap-6'>
              <div className='col-span-2 sm:col-span-1'>
                <div className='font-semibold'>
                  {ticket.trip.departure.airport.city} to{' '}
                  {ticket.trip.arrival.airport.city}
                </div>
                <p className='mt-1'>
                  {formatDate(ticket.trip.departure.date, 'DD MMM, YYYY')}
                </p>
              </div>
              <div className='col-span-2 sm:col-span-1'>
                <div className='font-semibold'>
                  {ticket.bookingCount} / {ticket.totalSeats} Seats Sold
                </div>
              </div>
              <div className='col-span-2 sm:col-span-1'>
                <div className='flex items-center gap-3'>
                  <img
                    className='w-9 h-9'
                    crossOrigin='anonymous'
                    src={ticket.flight.logo}
                    alt=''
                  />
                  <div>
                    <div className='font-semibold'>{ticket.flight.name}</div>
                    <p className='mt-1'>
                      {ticket.flight.code} {ticket.flight.number}
                    </p>
                  </div>
                </div>
              </div>
              <div className='col-span-2 sm:col-span-1'>
                <div className='font-semibold'>{ticket.pnr}</div>
              </div>
            </div>
          </div>

          {/* table */}

          <table className='w-full mt-10'>
            <thead>
              <tr>
                <td className='td th'>Trip ID</td>
                <td className='td th'>Traveller(s)</td>
                <td className='td th'>Created At</td>
                <td className='td th'> </td>
              </tr>
            </thead>

            <tbody>
              {/* <td className="td tb">{booking.passengers[0]?.pnrs[0]?.number}</td> */}
              {ticket.bookings.length > 0 ? (
                ticket.bookings.map((row: any, i: number) => (
                  <tr key={i} className='border-b border-gray-200'>
                    <td className='td tb pl-10'>
                      <div className='font-semibold'>{row.booking._id}</div>
                    </td>
                    <td className='td tb'>
                      <div className='font-semibold'>
                        {row.title} {row.firstName} {row.lastName}
                      </div>
                    </td>
                    <td className='td tb'>
                      <div className='min-w-max'>
                        <p>
                          {moment(
                            row.booking.createdAt,
                            'YYYYMMDDHHmmss',
                          ).format('DD MMM, YYYY')}
                        </p>
                        <p>
                          {moment(
                            row.booking.createdAt,
                            'YYYYMMDDHHmmss',
                          ).format('hh:mm A')}
                        </p>
                      </div>
                    </td>
                    <td className='td tb'>
                      <Dropdown
                        target={
                          <div className='dropdown-action'>
                            <MenuDotIcon />
                          </div>
                        }
                        menu={
                          <DropdownList>
                            <DropdownItem
                              to={`/my-series/bookings/${row.booking._id}`}
                            >
                              View Ticket
                            </DropdownItem>
                            <DropdownItem
                              to={`/my-series/bookings/${row.booking._id}/invoice`}
                            >
                              Invoice
                            </DropdownItem>
                          </DropdownList>
                        }
                      />
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td className='td-1' colSpan={4}>
                    <p className='text-center'>No bookings yet</p>
                  </td>
                </tr>
              )}
            </tbody>
          </table>

          <table className='w-full mt-10'>
            <thead>
              <tr>
                <td className='td th'>TOTAL COST PRICE OF THE TICKET</td>
                <td className='td th'>TOTAL SALE PRICE</td>
                <td className='td th'>ESTIMATED PROFIT / LOSS</td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className='tb td text-lg'>
                  <div className='font-semibold'>
                    {formatCurrency(ticket.totalfare)}
                  </div>
                </td>
                <td className='tb td text-lg'>
                  <div className='font-semibold'>
                    {formatCurrency(ticket.selling.price)}
                  </div>
                </td>
                <td className='tb td text-lg'>
                  <div className='font-semibold'>
                    <Currency>{ticket.selling.earnings}</Currency>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <StatusUpdateModal
        show={statusUpdateDialog.isOpen}
        message={'Are you sure that you want to mark this ticket as submitted'}
        onClose={statusUpdateDialog.hide}
        onConfirm={handleUpdateStatus}
        isLoading={updateStatusMutation.isLoading}
      />
    </div>
  );
};

export default SeriesInventoryBookingList;
