// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Navbar_shadow__YIGqH {\n  filter: drop-shadow(2px 4px 6px rgba(0, 0, 0, 0.05));\n}", "",{"version":3,"sources":["webpack://./src/app/components/Navbar/Navbar.module.scss"],"names":[],"mappings":"AAAA;EACE,oDAAA;AACF","sourcesContent":[".shadow {\n  filter: drop-shadow(2px 4px 6px rgba(0, 0, 0, 0.05));\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"shadow": "Navbar_shadow__YIGqH"
};
export default ___CSS_LOADER_EXPORT___;
