import { useQuery } from '@tanstack/react-query';
import BgOverlay from 'app/components/BgOverlay/BgOverlay';
import Spinner from 'app/components/Spinner/Spinner';
import StatsCard from 'app/components/StatsCard/StatsCard';
import http from 'app/config/http';
import { ChevronRightIcon } from 'app/icons';
import { ISeriesTicket } from 'app/types/series-ticket';
import { formatCurrency } from 'app/utils/currency-utils';
import { FC } from 'react';
import { Link } from 'react-router-dom';
import SalesChart from './components/SalesChart/SalesChart';
import TopAirlineList from './components/TopAirlineList/TopAirlineList';
import UpcomingDepartureTable from './components/UpcomingDepartureTable/UpcomingDepartureTable';

interface DashboardProps {}

const Dashboard: FC<DashboardProps> = (props) => {
  const statsQuery = useQuery<any>(['stats-series'], async () => {
    const { data } = await http.get(
      `${process.env.REACT_APP_API_URL}/api/v1/sub-agent/series-ticket-dashboard/stats`,
    );

    return data;
  });

  const salesChartQuery = useQuery<any[]>(['salesChart'], async () => {
    const { data } = await http.get(
      `${process.env.REACT_APP_API_URL}/api/v1/sub-agent/series-ticket-dashboard/sales-chart`,
    );

    return data;
  });

  const topAirlinesQuery = useQuery<any[]>(['topAirlines'], async () => {
    const { data } = await http.get(
      `${process.env.REACT_APP_API_URL}/api/v1/sub-agent/series-ticket-dashboard/top-airlines`,
    );

    return data;
  });

  const upcomingBookingsQuery = useQuery<ISeriesTicket[]>(
    ['upcomingBookings'],
    async () => {
      const { data } = await http.get(
        `${process.env.REACT_APP_API_URL}/api/v1/sub-agent/series-ticket-dashboard/upcoming-bookings`,
      );

      return data;
    },
  );

  return (
    <div>
      <BgOverlay />
      <div className='container min-h-full'>
        {/* cards */}
        <div className=' grid grid-cols-4 gap-6 py-8'>
          <div className='col-span-4 sm:col-span-2 xl:col-span-1'>
            <StatsCard
              title='Today’s Sale'
              content={
                statsQuery.isLoading ? (
                  <Spinner size={24} />
                ) : (
                  formatCurrency(statsQuery.data.sale.today)
                )
              }
            />
          </div>
          <div className='col-span-4 sm:col-span-2 xl:col-span-1'>
            <StatsCard
              title='Total Sales'
              content={
                statsQuery.isLoading ? (
                  <Spinner size={24} />
                ) : (
                  formatCurrency(statsQuery.data.sale.total)
                )
              }
            />
          </div>
          <div className='col-span-4 sm:col-span-2 xl:col-span-1'>
            <StatsCard
              title='Seats listed'
              content={
                statsQuery.isLoading ? (
                  <Spinner size={24} />
                ) : (
                  statsQuery.data.subAgencyTickets.totalSeats
                )
              }
            />
          </div>
          <div className='col-span-4 sm:col-span-2 xl:col-span-1'>
            <StatsCard
              title='seats sold'
              content={
                statsQuery.isLoading ? (
                  <Spinner size={24} />
                ) : (
                  statsQuery.data.subAgencyTickets.totalSoldSeats
                )
              }
            />
          </div>
        </div>
        <div className='grid grid-cols-3 gap-9'>
          {/* left section */}
          <div className='col-span-3 lg:col-span-2'>
            {/* chart */}
            <div className='card px-6 pt-5 pb-8 mb-6  '>
              <div className='hidden sm:flex justify-between items-center'>
                <div className='font-bold'>Sales Report</div>

                <ul className='flex items-center'>
                  <li>
                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                    <a className='px-3 py-[0.4rem] text-xs font-semibold rounded-md text-gray-500'>
                      This Month
                    </a>
                  </li>
                  {/* <li>
                                    <a className="px-3 py-[0.4rem] text-xs font-semibold rounded-md border border-gray-300">
                                        12 Months
                                    </a>
                                </li>
                                <li>
                                    <a className="px-3 py-[0.4rem] text-xs font-semibold rounded-md border border-transparent text-gray-500">
                                        6 Months
                                    </a>
                                </li>
                                <li>
                                    <a className="px-3 py-[0.4rem] text-xs font-semibold rounded-md border border-transparent text-gray-500">
                                        30 Days
                                    </a>
                                </li>
                                <li>
                                    <a className="px-3 py-[0.4rem] text-xs font-semibold rounded-md border border-transparent text-gray-500">
                                        7 Days
                                    </a>
                                </li> */}
                </ul>

                {/* <button className="border border-gray-300 px-4 py-[0.4rem] text-xs font-semibold rounded-md">
                                Export PDF
                            </button> */}
              </div>

              <div className='mt-8 hidden sm:block'>
                {salesChartQuery.isLoading ? (
                  <div className='text-center'>
                    <Spinner />
                  </div>
                ) : (
                  <SalesChart data={salesChartQuery.data} />
                )}
              </div>
            </div>
            {/* upcomming departure */}
            <div className='card mb-6'>
              <div className='flex flex-col  sm:flex-row   justify-between items-start px-6 pt-5'>
                <div>
                  <div className='font-bold'>Upcoming Series Departures</div>
                  <p className='mt-2'>
                    All upcoming departures for series fare on your account
                  </p>
                </div>

                <Link
                  to='/my-series/bookings'
                  className='inline-flex text-blue-500 items-center gap-2  text-sm mt-2 font-medium'
                >
                  See All Departures <ChevronRightIcon />
                </Link>
              </div>

              <div className='mt-6'>
                {upcomingBookingsQuery.isLoading ? (
                  <div className='text-center pb-5'>
                    <Spinner />
                  </div>
                ) : (
                  <UpcomingDepartureTable
                    bookings={upcomingBookingsQuery.data}
                  />
                )}
              </div>
            </div>
          </div>
          {/* right section */}
          <div className=' col-span-3 lg:col-span-1'>
            <div className='card px-6 pt-5 pb-8 mb-6'>
              <div className='flex justify-between items-start'>
                <div className='font-bold'>Top Airlines by booking</div>

                {/* <select className="text-sm px-1 outline-none">
                                <option value="">Last 7 Days</option>
                            </select> */}
              </div>

              <div className='mt-8 '>
                {topAirlinesQuery.isLoading ? (
                  <div className='text-center'>
                    <Spinner />
                  </div>
                ) : (
                  <TopAirlineList airlines={topAirlinesQuery.data} />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
