import { FC, ReactNode } from 'react';
import LoaderButton from '../LoaderButton/LoaderButton';
import Modal from '../Modal/Modal';

interface DeleteModalProps {
  show: boolean;
  message: ReactNode;
  onClose: () => void;
  onConfirm: () => void;
  isLoading?: boolean;
  approveActionMessage?: string;
  cancelActionMessage?: string;
}

const DeleteModal: FC<DeleteModalProps> = (props) => {
  return (
    <Modal
      className='px-10 py-10 w-[520px]'
      show={props.show}
      onClose={props.onClose}
    >
      <div className='text-gray-900 mt-4 text-center'>{props.message}</div>
      <div className='flex justify-center gap-4 mt-6'>
        <LoaderButton
          className='bg-red-500 text-white px-6 text-sm rounded-lg'
          onClick={props.onConfirm}
          isLoading={props.isLoading}
        >
          {props.approveActionMessage}
        </LoaderButton>
        <button
          className='bg-gray-200 text-gray-600 px-6 text-sm rounded-lg'
          onClick={props.onClose}
        >
          {props.cancelActionMessage}
        </button>
      </div>
    </Modal>
  );
};

DeleteModal.defaultProps = {
  isLoading: false,
  approveActionMessage: 'Yes, Delete',
  cancelActionMessage: 'No, keep it',
};

export default DeleteModal;
