import moment from 'moment';
import { FC } from 'react';
import http from 'app/config/http';
import { toast } from 'react-toastify';
import { MenuDotIcon } from 'app/icons';
import update from 'immutability-helper';
import useDialog from 'app/hooks/use-dialog';
import { useNavigate } from 'react-router-dom';
import { ISeriesTicket } from 'app/types/series-ticket';
import { formatDate } from 'app/utils/date-utils';
import { formatCurrency } from 'app/utils/currency-utils';
import InfoModal from 'app/components/InfoModal/InfoModal';
import DeleteModal from 'app/components/DeleteModal/DeleteModal';
import StatusBadge from 'app/components/StatusBadge/StatusBadge';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import ConditionalTooltip from 'app/components/ConditionalTooltip/ConditionalTooltip';
import {
  Dropdown,
  DropdownItem,
  DropdownList,
} from 'app/components/Dropdown/Dropdown';

interface SeriesInventoryTableProps {
  seriesTickets: ISeriesTicket[];
}

const SeriesInventoryTable: FC<SeriesInventoryTableProps> = (props) => {
  const { seriesTickets } = props;
  const navigate = useNavigate();
  const deleteDialog = useDialog<string>();
  const infoDialog = useDialog<string>();

  const queryClient = useQueryClient();
  const deleteMutation = useMutation(async (values: any) => {
    // delete api here this needs to be checked
    await http.delete(
      `${process.env.REACT_APP_API_URL}/api/v1/sub-agent/series-tickets/${values._id}`,
    );
  });

  const handleUserDelete = async () => {
    try {
      const _id = deleteDialog.data;

      // uncommit this
      await deleteMutation.mutateAsync({ _id });

      toast.success('Series deleted successfully.');

      const index = seriesTickets.findIndex((r) => r._id === _id);

      queryClient.setQueryData(['seriesTicketList'], (prev: any) => {
        return update(prev, {
          $splice: [[index, 1]],
        });
      });
    } catch (ex: any) {
      console.log(ex);
      infoDialog.show(ex.data.message);
    } finally {
      deleteDialog.hide();
    }
  };

  const changeLiveNetworkMutation = useMutation(async (values: any) => {
    const { data } = await http.put(
      `${process.env.REACT_APP_API_URL}/api/v1/sub-agent/series-tickets/${values._id}/is-live-on-network`,
      { isLiveOnNetwork: values.isLiveOnNetwork },
    );
    return data;
  });

  const handleLiveOnNetworkChange = async (
    row: ISeriesTicket,
    isChecked: boolean,
  ) => {
    if (row.isDisabledByAgency) {
      toast.error('Inventory disabled by Agency');
      return;
    }

    queryClient.setQueryData(['seriesTicketList'], (prevValues: any) => {
      const index = prevValues.findIndex((val: any) => val._id === row._id);

      return update(prevValues, {
        [index]: { isLiveOnNetwork: { $set: isChecked } },
      });
    });

    try {
      await changeLiveNetworkMutation.mutateAsync({
        _id: row._id,
        isLiveOnNetwork: isChecked,
      });

      toast.success(
        `Series inventory  ${isChecked ? 'enabled' : 'disabled'} from network`,
      );
    } catch (ex: any) {
      console.log(ex);
      toast.error(ex?.data?.message || 'Some error occured, please try again.');
      queryClient.setQueryData(['seriesTicketList'], (prevValues: any) => {
        const index = prevValues.findIndex((val: any) => val._id === row._id);

        return update(prevValues, {
          [index]: { isLiveOnNetwork: { $set: !isChecked } },
        });
      });
    }
  };

  const getStatusClass = (status: string) => {
    switch (status) {
      case 'PENDING':
        return 'yellow';
      case 'COMPLETED':
      case 'SUBMITTED':
        return 'green';
      case 'CANCELLED':
        return 'red';
    }
  };

  const getStatusText = (status: string) => {
    switch (status) {
      case 'PENDING':
        return 'Naming Pending';
      case 'COMPLETED':
        return 'Completed';
      case 'SUBMITTED':
        return 'Submitted';
      case 'CANCELLED':
        return 'Cancelled';
    }
  };

  return (
    <>
      <table className='w-full min-w-[960px]'>
        <thead>
          <tr>
            <td className=' td th'>Inventory ID</td>
            <td className=' td th'>Travel & Date</td>
            <td className=' td th'>Fare & Seats</td>
            <td className=' td th'>Flight Details</td>
            <td className=' td th'>Airline PNR</td>
            <td className=' td th'>Net Selling Price</td>
            <td className=' td th'>Created At </td>
            <td className=' td th'>Live On Network</td>
            <td className=' td th'></td>
          </tr>
        </thead>
        <tbody>
          {/*
                            <td className=" td tb">{row.phoneNumber}</td>
                            <td className=" td tb  text-success">
                                <EditPencilIcon onClick={() => navigate(`/users/${row._id}/edit`)} />
                            </td> 
                */}
          {seriesTickets.map((row) => (
            <tr
              key={row._id}
              className='border-b border-gray-200 last:border-none'
            >
              <td className='td tb'>
                <div
                  className='font-semibold cursor-pointer text-blue-500'
                  onClick={() =>
                    navigate(`/my-series/series-inventory/${row._id}/bookings`)
                  }
                >
                  {row._id}
                </div>
                <StatusBadge
                  className='mt-1'
                  color={getStatusClass(row.status)}
                >
                  {getStatusText(row.status)}
                </StatusBadge>
              </td>
              <td className='td tb'>
                <div className='font-semibold'>
                  {row.trip.departure.airport.city} to{' '}
                  {row.trip.arrival.airport.city}
                </div>
                <p className='mt-1'>
                  {formatDate(row.trip.departure.date, 'DD MMM, YYYY')}
                </p>
              </td>
              <td className='td tb'>
                <div className='font-semibold'>
                  {formatCurrency(row.farePerSeat)}/ Seat{' '}
                </div>
                <p className='mt-1'>
                  {`${row.bookingCount} of ${row.totalSeats} seats sold`}
                  {/* {`${row.bookingCount} ${row.bookingCount === 1 ? 'seat' : 'seats'} sold out of ${
                                        row.totalSeats
                                    }`} */}
                </p>
              </td>
              <td className='td tb'>
                <div className='flex items-center gap-3'>
                  <img
                    className='w-9 h-9'
                    crossOrigin='anonymous'
                    src={row.flight.logo}
                    alt=''
                  />
                  <div>
                    <div className='font-semibold'>{row.flight.name}</div>
                    <p className='mt-1'>
                      {row.flight.code} {row.flight.number}
                    </p>
                  </div>
                </div>
              </td>
              <td className='td tb'>
                <div className='font-semibold'>{row.pnr}</div>
              </td>
              <td className='td tb'>
                {row.selling ? (
                  <>
                    <div className='font-semibold'>
                      {formatCurrency(row.selling.price)}
                    </div>
                    <p className='mt-1'>
                      {row.selling.earnings >= 0 ? (
                        <span className='text-green-500'>
                          + {formatCurrency(row.selling.earnings)}
                        </span>
                      ) : (
                        <span className='text-red-500'>
                          {formatCurrency(row.selling.earnings)}
                        </span>
                      )}
                    </p>
                  </>
                ) : (
                  <span className='font-bold'>-</span>
                )}
              </td>
              <td className='td tb'>
                <div className='min-w-max'>
                  <p>
                    {moment(row.createdAt, 'YYYYMMDDHHmmss').format(
                      'DD MMM, YYYY',
                    )}
                  </p>
                  <p>
                    {moment(row.createdAt, 'YYYYMMDDHHmmss').format('hh:mm A')}
                  </p>
                </div>
              </td>
              <td className='td tb'>
                <ConditionalTooltip
                  showTooltip={row.isDisabledByAgency}
                  floatingContent={
                    <div className='text-xs px-2 py-3 pt-3 text-red-400 font-normal '>
                      {' '}
                      Disabled by Agency
                    </div>
                  }
                >
                  <div className='flex items-center gap-2 mt-4'>
                    <div className='form-switch success'>
                      <input
                        checked={row.isLiveOnNetwork}
                        type='checkbox'
                        role='switch'
                        onChange={(e) =>
                          handleLiveOnNetworkChange(row, e.target.checked)
                        }
                        disabled={row.isDisabledByAgency}
                      />
                    </div>
                  </div>
                </ConditionalTooltip>
                {/* <div className="font-semibold">
                                    <input
                                        type="checkbox"
                                        className="form-switch success"
                                        checked={row.isLiveOnNetwork}
                                        // onChange={(e) => handleLiveOnNetworkChange(row, e.target.checked)}
                                    />
                                </div> */}
              </td>
              <td className='td tb'>
                <Dropdown
                  target={
                    <div className='dropdown-action'>
                      <MenuDotIcon />{' '}
                    </div>
                  }
                  menu={
                    <DropdownList>
                      <DropdownItem
                        to={`/my-series/series-inventory/${row._id}/bookings`}
                      >
                        View Bookings
                      </DropdownItem>
                      <DropdownItem
                        to={`/my-series/series-inventory/${row._id}/edit`}
                      >
                        Edit
                      </DropdownItem>
                      <DropdownItem
                        to={`/my-series/series-inventory/${row._id}/revise-markup`}
                      >
                        <span className='text-blue-500'>Revise Markup</span>
                      </DropdownItem>
                      <DropdownItem onClick={() => deleteDialog.show(row._id)}>
                        <span className='text-red-500'>Delete</span>
                      </DropdownItem>
                    </DropdownList>
                  }
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <DeleteModal
        show={deleteDialog.isOpen}
        message={'Are you sure you want to delete this series?'}
        onClose={deleteDialog.hide}
        onConfirm={handleUserDelete}
        isLoading={deleteMutation.isLoading}
      />
      <InfoModal
        message={infoDialog.data}
        show={infoDialog.isOpen}
        onClose={infoDialog.hide}
      />
    </>
  );
};

export default SeriesInventoryTable;
