/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from 'react';
import * as Yup from 'yup';
import moment from 'moment';
import { DOB_MANDATORY } from 'app/config';
import indiaFlag from 'assets/icons/in-flag.svg';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import PassengerDetail from '../PassengerDetail/PassengerDetail';
import LoaderButton from 'app/components/LoaderButton/LoaderButton';
import { IBookingForm } from 'app/modules/booking/pages/PassengerBooking/types';

interface BookingFormProps {
  initialValues: IBookingForm;
  onSubmit: (values: IBookingForm) => void;
  isLoading: boolean;
  tripDate: string;
}

const BookingForm: FC<BookingFormProps> = (props) => {
  const { initialValues, onSubmit, isLoading, tripDate } = props;

  const PassportSchema = Yup.object().when('isInternational', {
    is: true,
    then: Yup.object({
      nationality: Yup.string().trim().required('This field is required'),
      number: Yup.string().trim().required('This field is required'),
      issueDate: Yup.string()
        .trim()
        .required('This field is required')
        .test(
          'is-passport-issue-date-in-future',
          'Enter valid Passport issue date.',
          (value) => {
            const issueDate = moment(value, 'YYYYMMDD');
            return issueDate <= moment();
          },
        ),
      expiry: Yup.string()
        .trim()
        .required('This field is required')
        .test('is-passport-expired', 'Expired Passport.', (value) => {
          const exp = moment(value, 'YYYYMMDD');
          return exp > moment().subtract(1, 'day');
        })
        .test(
          'is-passport-expiring-within-6-months',
          'Passport should not expire within 6 months from travel date.',
          (value) => {
            const trip = moment(tripDate, 'YYYYMMDD');
            const exp = moment(value, 'YYYYMMDD');
            return trip.add(6, 'months') < exp;
          },
        ),
    }),
  });

  const PassengerListSchema = (paxType: 'adults' | 'children' | 'infants') => {
    return Yup.array().of(
      Yup.object().shape({
        title: Yup.string().trim().required('This field is required'),
        firstName: Yup.string()
          .trim()
          .required('This field is required')
          .min(2, 'First Name must be at least 2 characters'),
        lastName: Yup.string()
          .trim()
          .required('This field is required')
          .min(2, 'Last Name must be at least 2 characters'),
        isInfant: Yup.boolean().required('This field is required'),
        isInternational: Yup.boolean().required('This feild is required'),
        dob: DOB_MANDATORY
          ? Yup.string().required('This field is required')
          : Yup.string().when(['isInfant', 'isInternational'], {
              is: (isInfant: any, isInternational: any) =>
                isInfant || isInternational,
              then: Yup.string().required('This field is required'),
            }),
        passport: PassportSchema,
      }),
    );
  };

  const PassengerFormSchema = Yup.object().shape({
    adults: PassengerListSchema('adults'),
    children: PassengerListSchema('children'),
    infants: PassengerListSchema('infants'),
    // infants: InfantsPassengerListSchema,
    mobileNumber: Yup.string()
      .trim()
      .max(10, 'Phone number can be 10 digits only')
      .min(10, 'Phone number must be 10 digits ')
      .required('This field is required')
      .matches(/^\d+$/, 'Enter a valid mobile number'),
    email: Yup.string()
      .trim()
      .required('This field is required')
      .email('Please enter a valid email'),
  });

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={PassengerFormSchema}
      enableReinitialize
      onSubmit={onSubmit}
    >
      {({ values, setFieldValue, errors }) => (
        <Form>
          {/* {passenger detail card} */}
          <div className='card mb-4 px-8 py-7'>
            <div className='text-xl font-bold'>Traveller Details</div>
            {/* Adult form fields */}
            {initialValues.adults.map((passenger, index) => (
              <div key={index}>
                <div className='mt-6 text-base leading-5 font-bold'>
                  Adult {index + 1}
                </div>
                <PassengerDetail
                  tripDate={tripDate}
                  key={`adult ${index}`}
                  index={index}
                  pessenger={passenger}
                  passengerType={'adults'}
                />
              </div>
            ))}

            {/* child form fields */}
            {initialValues.children.map((passenger, index) => (
              <div key={index}>
                <div className='mt-6 text-base leading-5 font-bold'>
                  Child {index + 1}
                </div>
                <div className='mt-2'>
                  <PassengerDetail
                    tripDate={tripDate}
                    key={`child ${index}`}
                    index={index}
                    pessenger={passenger}
                    passengerType={'children'}
                  />
                </div>
              </div>
            ))}

            {/* infant form fields */}
            {initialValues.infants.map((passenger, index) => (
              <div key={index}>
                <div className='mt-6 text-base leading-5 font-bold'>
                  Infant {index + 1}
                </div>
                <PassengerDetail
                  tripDate={tripDate}
                  key={`infants ${index}`}
                  index={index}
                  pessenger={passenger}
                  passengerType={'infants'}
                />
              </div>
            ))}
          </div>

          {/* {conatact card} */}
          <div className='card px-8 py-7 mb-4'>
            <div className='text-xl font-bold'>Contact Details</div>

            <div className='mt-6 text-xs'>
              Enter contact details of the main traveller
            </div>

            <div className='mt-2'>
              <div className='grid grid-col-1 sm:grid-cols-2 gap-4'>
                <div className='col-span-1'>
                  <div className='flex items-center relative'>
                    <img className='absolute h-4 pl-3' src={indiaFlag} alt='' />
                    <Field
                      type='text'
                      name='mobileNumber'
                      placeholder='Mobile Number'
                      className='form-control pl-10'
                    />
                  </div>
                  <div className='form-error'>
                    <ErrorMessage name='mobileNumber' />
                  </div>
                </div>
                <div className='col-span-1'>
                  <Field
                    type='email'
                    name='email'
                    placeholder='Email Address'
                    className='form-control'
                  />
                  <div className='form-error'>
                    <ErrorMessage name='email' />
                  </div>
                </div>
              </div>
            </div>

            <p className='mt-8 text-sm leading-6'>
              By clicking on the Proceed to payment button below to proceed with
              the booking, I confirm that I have read and I accept the{' '}
              <a href='#' className='text-primary font-semibold'>
                Fare Rules
              </a>
              , the{' '}
              <a href='#' className='text-primary font-semibold'>
                Privacy Policy
              </a>{' '}
              , the{' '}
              <a href='#' className='text-primary font-semibold'>
                User Agreement
              </a>{' '}
              and{' '}
              <a href='#' className='text-primary font-semibold'>
                Terms of Service
              </a>
              .
            </p>
            <LoaderButton
              type='submit'
              className='btn btn-primary px-5 py-3 mt-8 min-w-[11rem]'
              isLoading={isLoading}
              disabled={isLoading}
            >
              Proceed to payment
            </LoaderButton>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default BookingForm;