import http from 'app/config/http';
import { FC, useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import PageLoader from 'app/components/PageLoader/PageLoader';
import { useParams, useSearchParams } from 'react-router-dom';
import FareDetails from './components/FareDetails/FareDetails';
import TickerHeader from './components/TickerHeader/TickerHeader';
import PassengerList from './components/PassengerList/PassengerList';
import FlightItinerary from './components/FlightItinerary/FlightItinerary';
import PrintLayoutWrapper from 'app/components/PrintLayoutWrapper/PrintLayoutWrapper';
import TicketInformationList from './components/TicketInformationList/TicketInformationList';
import { IBooking } from 'app/types';

interface BookingTicketProps {}

const BookingTicket: FC<BookingTicketProps> = (props) => {
  const { bookingId } = useParams();
  const [searchParams] = useSearchParams();
  const [show, setShow] = useState({
    price: false,
    agency: false,
    gst: false,
  });

  useEffect(() => {
    const price = searchParams.get('price') === 'true' ? true : false;
    const agency = searchParams.get('agency') === 'true' ? true : false;
    const gst = searchParams.get('gst') === 'true' ? true : false;
    setShow({
      price,
      agency,
      gst,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const query = useQuery<IBooking>(['bookingTicket'], async () => {
    const { data } = await http.get(
      `${process.env.REACT_APP_API_URL}/api/v1/sub-agent/bookings/${bookingId}/print-ticket`,
    );
    return data;
  });

  if (query.isLoading) return <PageLoader />;

  const booking = query.data;

  return (
    <PrintLayoutWrapper>
      <div>
        {/* <div className='flex flex-col min-h-screen'> */}

        {show.agency && (
          <TickerHeader agency={booking.agency} subAgency={booking.subAgency} />
        )}

        <FlightItinerary
          segments={booking.segments}
          cabinClass={booking.priceDetail.fareDescription.adult.cabinClass}
          refundableType={
            booking.priceDetail.fareDescription.adult.refundableType
          }
        />

        <PassengerList booking={booking} />

        {show.price && <FareDetails booking={booking} />}

        <TicketInformationList htmlContent={booking.tnc.content} />

        {show.agency && (
          <div className=' px-10 mb-12'>
            <div className='text-sm font-semibold text-primary'>
              {booking.subAgency.email}
            </div>
          </div>
        )}
      </div>
    </PrintLayoutWrapper>
  );
};

export default BookingTicket;
