import * as Yup from 'yup';
import moment from 'moment';
import { FC } from 'react';
import useTopup from './useTopup';
import { toast } from 'react-toastify';
import Modal from 'app/components/Modal/Modal';
import Spinner from 'app/components/Spinner/Spinner';
import { useQueryClient } from '@tanstack/react-query';
import { formatCurrency } from 'app/utils/currency-utils';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import LoaderButton from 'app/components/LoaderButton/LoaderButton';

export enum AddPaymentTypeEnum {
  CREDIT = 'CREDIT',
  BANK_TRANSFER = 'BANK_TRANSFER',
  CHEQUE_OR_CASH = 'CHEQUE_OR_CASH',
}

const PaymentFormSchema = Yup.object().shape({
  method: Yup.string().trim().required('This field is required'),
  // amount: Yup.string().trim().required('This field is required').matches(/^\d+$/, 'Enter a valid amount'),
  amount: Yup.number()
    .typeError('You must specify a number')
    .required('This field is required')
    .min(1, 'The amount must be greater than Zero'),
  remarks: Yup.string().trim(),
  bankAccount: Yup.string()
    .trim()
    .when('method', {
      is: (method: any) => method === AddPaymentTypeEnum.BANK_TRANSFER,
      then: Yup.string().trim().required('Field is required'),
    }),
  bankTransactionId: Yup.string()
    .trim()
    .when('method', {
      is: (method: any) => method === AddPaymentTypeEnum.BANK_TRANSFER,
      then: Yup.string().trim().required('Field is required'),
    }),
  transactionDate: Yup.string()
    .trim()
    .when('method', {
      is: (method: any) =>
        method === AddPaymentTypeEnum.BANK_TRANSFER ||
        method === AddPaymentTypeEnum.CHEQUE_OR_CASH,
      then: Yup.string().trim().required('Field is required'),
    }),
  tandc: Yup.boolean().required(),
});

interface PaymentAddDialogProps {
  show: boolean;
  onClose: () => void;
  onSubmit: () => void;
  bankList: [];
}

const PaymentAddDialog: FC<PaymentAddDialogProps> = (props) => {
  const { show, onClose, bankList } = props;
  const queryClient = useQueryClient();
  // const { user } = useSelector((state: TRootState) => state.auth);

  const paymentInitialValues = {
    amount: '',
    method: AddPaymentTypeEnum.BANK_TRANSFER,
    bankAccount: '',
    transactionDate: moment().format('YYYY-MM-DD'),
    remarks: '',
    details: {},
    bankTransactionId: '',
    tandc: false,
  };

  // bank cheque and cash
  // const makePaymentMutation = useMutation(async (payload: any) => {
  //   const { data } = await http.post(
  //     `${process.env.REACT_APP_API_URL}/api/v1/sub-agent/payments`,
  //     payload,
  //   );

  //   return data;
  // });

  // pre razor pay
  // const initiateOnlinePayment = useMutation(async (payload: any) => {
  //   const { data } = await http.post(
  //     `${process.env.REACT_APP_API_URL}/api/v1/sub-agent/payments/razorpay/initiate`,
  //     payload,
  //   );

  //   return data;
  // });

  // const onlinePaymentMutation = useMutation(async (values: any) => {
  //     const { data } = await http.post(
  //         `${process.env.REACT_APP_API_URL}/api/v1/sub-agent/bookings/${booking._id}/payment/razorpay`,
  //         values
  //     );

  //     return data;
  // });

  // const onlinePaymentMutation = useMutation(async (payload: any) => {
  //   const { data } = await http.post(
  //     `${process.env.REACT_APP_API_URL}/api/v1/sub-agent/payments/${payload.id}/razorpay`,
  //     payload,
  //   );

  //   return data;
  // });

  // const paymentOptions = [
  //   {
  //     label: 'Credit Cards / Debit Cards / UPI / Net Banking',
  //     value: AddPaymentTypeEnum.CREDIT,
  //   },
  //   {
  //     label: 'Bank Transfer',
  //     value: AddPaymentTypeEnum.BANK_TRANSFER,
  //   },
  //   {
  //     label: 'Cheque / Cash',
  //     value: AddPaymentTypeEnum.CHEQUE_OR_CASH,
  //   },
  // ];

  // const payWithRazorpay = (id: any, res: any) => {
  //   const razorPayOptions: any = {
  //     key: res.rzpKey,
  //     amount: res.rzpAmount, // amount should be in paise format to display Rs 1255 without decimal point
  //     currency: res.rzpCurrency,
  //     name: user.agency.name, // company name or product name
  //     image: user.agency.logoUrl, // company logo or product image
  //     order_id: res.rzpOrderId, // order_id created by you in backend
  //     modal: {
  //       escape: false, // We should prevent closing of the form when esc key is pressed.
  //     },
  //     prefill: {
  //       email: user.email,
  //       contact: user.phoneNumber,
  //     },
  //     theme: {
  //       // hex color here
  //       color: rgbToHex(
  //         document.documentElement.style.getPropertyValue('--primary'),
  //       ),
  //     },
  //     handler: async (response: any) => {
  //       const data = {
  //         razorpayPaymentId: response.razorpay_payment_id,
  //         razorpayOrderId: response.razorpay_order_id,
  //         razorpaySignature: response.razorpay_signature,
  //       };
  //       try {
  //         await onlinePaymentMutation.mutateAsync({
  //           rzpOrderId: data.razorpayOrderId,
  //           rzpPaymentId: data.razorpayPaymentId,
  //           rzpSignature: data.razorpaySignature,
  //           id: id,
  //         });
  //         queryClient.fetchQuery(['accountData']);
  //         queryClient.fetchQuery(['transactions']);

  //         toast.success('Payment completed Success fully');

  //         onClose();
  //       } catch (ex: any) {
  //         toast.error(
  //           ex?.data?.message || 'Some error occured, please try again.',
  //         );
  //         console.log(ex);
  //       }
  //     },
  //   };

  //   const rzp = new (window as any).Razorpay(razorPayOptions);

  //   rzp.on('payment.failed', function (response: any) {
  //     console.log('Payment failed...');
  //   });

  //   rzp.open();
  // };

  // const onSubmit = async (values: any) => {
  //   let payload: any = {
  //     amount: values.amount,
  //     method: values.method,
  //     subAgentRemark: values.remarks.trim(),
  //     details: {
  //       transactionDate: moment(values.transactionDate).format('YYYYMMDD'),
  //     },
  //   };
  //   switch (values.method) {
  //     case AddPaymentTypeEnum.CHEQUE_OR_CASH:
  //       break;
  //     case AddPaymentTypeEnum.BANK_TRANSFER:
  //       payload.details.bank = bankList.find(
  //         (bank: any) => values.bankAccount === bank._id,
  //       );
  //       payload.details.transactionId = values.bankTransactionId;
  //       break;
  //   }

  //   if (values.method === AddPaymentTypeEnum.CREDIT) {
  //     try {
  //       handleCcAvenuePayment(
  //         {
  //           token: selectedOnlinePaymentOption.token,
  //           topupAmount: +payload.amount,
  //           subAgentRemark: payload.subAgentRemark,
  //         },
  //         onClose,
  //       );
  //       const newData = {
  //         amount: values.amount,
  //         subAgentRemark: values.remarks.trim(),
  //       };
  //       const res = await initiateOnlinePayment.mutateAsync({ ...newData });
  //       payWithRazorpay(res.payment._id, res.razorpayOrder, onClose);
  //     } catch (ex: any) {
  //       toast.error(
  //         ex?.data?.message || 'Some error occured, please try again.',
  //       );
  //       console.log(ex);
  //     }
  //   } else {
  //     try {
  //       await makePaymentMutation.mutateAsync(payload);
  //       queryClient.fetchQuery(['accountData']);
  //       queryClient.fetchQuery(['payments']);
  //       toast.success('Payment request sent successfully');
  //       onClose();
  //     } catch (ex: any) {
  //       console.log(ex);
  //       toast.error(
  //         ex?.data?.message || 'Some error occured, please try again.',
  //       );
  //     }
  //   }
  // };

  // const {
  //   initiateOnlinePayment,
  //   makePaymentMutation,
  //   onlinePaymentMutation,
  //   payWithRazorpay,
  //   paymentOptions,
  //   selectedOnlinePaymentOption,
  //   handleAmountChange,
  //   isLoading,
  //   html,
  //   showPaymentDialog,
  //   handleCcAvenuePayment,
  // } = useTopup();

  return (
    <>
      {
        // showPaymentDialog
        true
          ? '.'
          : // <div className='bg-gray-100 w-full h-full absolute top-0 z-[999]  overscroll-contain '>
            //   <div dangerouslySetInnerHTML={{ __html: html }} className='mt-4' />
            //   {html === '' && (
            //     <div className='flex justify-center items-center h-screen w-screen'>
            //       <Spinner />
            //     </div>
            //   )}
            // </div>
            '.'
        // <Modal show={show} onClose={onClose} className='w-[520px]'>
        //   <div className='text-2xl font-bold'>Make Payment</div>

        //   <Formik
        //     initialValues={paymentInitialValues}
        //     validationSchema={PaymentFormSchema}
        //     enableReinitialize
        //     onSubmit={onSubmit}
        //   >
        //     {({ values, setFieldValue, errors }) => (
        //       <Form>
        //         <div className='mt-5'>
        //           <label className='text-xs mb-2 block' htmlFor='Amount'>
        //             Payment Amount
        //           </label>
        //           <div className='flex items-center relative'>
        //             <div className='absolute pl-4'> ₹ </div>
        //             <Field
        //               name='amount'
        //               type='number'
        //               id='Amount'
        //               className='form-control pl-8'
        //               onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
        //                 setFieldValue('amount', e.target.value);
        //                 handleAmountChange(+e.target.value);
        //                 setFieldValue('tandc', false);
        //               }}
        //             />
        //           </div>
        //           <div className='form-error'>
        //             <ErrorMessage name='amount' />
        //           </div>
        //         </div>

        //         <div className='mt-5'>
        //           <label className='text-xs mb-4 block' htmlFor='Amount'>
        //             Select Payment Method
        //           </label>
        //           {paymentOptions.map((row) => (
        //             <div key={row.value} className='flex gap-3 mb-4'>
        //               <Field
        //                 type='radio'
        //                 id={row.value}
        //                 name='method'
        //                 onChange={() => setFieldValue('method', row.value)}
        //                 checked={row.value === values.method}
        //                 className='accent-primary'
        //                 // disabled={row.value === AddPaymentTypeEnum.CREDIT}
        //                 disabled={
        //                   row.value === AddPaymentTypeEnum.CREDIT &&
        //                   selectedOnlinePaymentOption === null
        //                 }
        //               />
        //               <label
        //                 className={
        //                   'text-sm block ' +
        //                   (row.value === AddPaymentTypeEnum.CREDIT &&
        //                   selectedOnlinePaymentOption === null
        //                     ? 'text-gray-400'
        //                     : '')
        //                 }
        //                 htmlFor={row.value}
        //               >
        //                 {row.label}
        //               </label>
        //             </div>
        //           ))}
        //         </div>

        //         {values.method === AddPaymentTypeEnum.BANK_TRANSFER && (
        //           <>
        //             <div className='mt-5'>
        //               <label
        //                 className='text-xs mb-2 block'
        //                 htmlFor='bankAccount'
        //               >
        //                 Select Account
        //               </label>
        //               <Field
        //                 as='select'
        //                 name='bankAccount'
        //                 type='text'
        //                 id='bankAccount'
        //                 className='form-control '
        //               >
        //                 <option value=''>Select Bank Account</option>
        //                 {bankList.map((row: any) => (
        //                   <option key={row._id} value={row._id}>
        //                     {`${row.name} ${row.account.number}`}
        //                   </option>
        //                 ))}
        //               </Field>
        //               <div className='form-error'>
        //                 <ErrorMessage name='bankAccount' />
        //               </div>
        //             </div>
        //             <div className='mt-5'>
        //               <label
        //                 className='text-xs mb-2 block'
        //                 htmlFor='BankTransactionId'
        //               >
        //                 Transaction ID
        //               </label>
        //               <Field
        //                 name='bankTransactionId'
        //                 type='text'
        //                 id='BankTransactionId'
        //                 className='form-control'
        //               />
        //               <div className='form-error'>
        //                 <ErrorMessage name='bankTransactionId' />
        //               </div>
        //             </div>
        //             <div className='mt-5'>
        //               <label
        //                 className='text-xs mb-2 block'
        //                 htmlFor='TransactionDate'
        //               >
        //                 Transaction Date
        //               </label>
        //               <Field
        //                 name='transactionDate'
        //                 type='date'
        //                 id='TransactionDate'
        //                 className='form-control '
        //               />
        //               <div className='form-error'>
        //                 <ErrorMessage name='transactionDate' />
        //               </div>
        //             </div>
        //           </>
        //         )}

        //         {values.method === AddPaymentTypeEnum.CHEQUE_OR_CASH && (
        //           <>
        //             <div className='mt-5'>
        //               <label
        //                 className='text-xs mb-2 block'
        //                 htmlFor='TransactionDate'
        //               >
        //                 Transaction Date
        //               </label>
        //               <Field
        //                 name='transactionDate'
        //                 type='date'
        //                 id='TransactionDate'
        //                 className='form-control '
        //               />
        //               <div className='form-error'>
        //                 <ErrorMessage name='transactionDate' />
        //               </div>
        //             </div>
        //           </>
        //         )}

        //         <div className='mt-5'>
        //           <label className='text-xs mb-2 block' htmlFor='Remarks'>
        //             Remarks
        //           </label>
        //           <Field
        //             as='textarea'
        //             name='remarks'
        //             type='text'
        //             id='Remarks'
        //             className='form-control'
        //           />
        //           <div className='form-error'>
        //             <ErrorMessage name='remarks' />
        //           </div>
        //         </div>
        //         {values.method === AddPaymentTypeEnum.CREDIT && (
        //           <div className='text-xs font-normal'>
        //             Please note: You may be redirected to bank page to complete
        //             your transaction. By making this payment. You agree to our
        //             Terms of Use and Privacy Policy
        //           </div>
        //         )}

        //         <div className=' mt-8 text-center inline-block '>
        //           {values.method === AddPaymentTypeEnum.CREDIT &&
        //             selectedOnlinePaymentOption &&
        //             +values.amount > 0 && (
        //               <div className='text-xs mb-1'>
        //                 {`Payment Fee ${isLoading && !selectedOnlinePaymentOption.gatewayCharges ? <Spinner /> : formatCurrency(selectedOnlinePaymentOption.gatewayCharges)}`}
        //               </div>
        //             )}
        //           <LoaderButton
        //             type='submit'
        //             className='btn btn-primary min-w-[12rem]'
        //             isLoading={
        //               makePaymentMutation.isLoading ||
        //               initiateOnlinePayment.isLoading ||
        //               onlinePaymentMutation.isLoading ||
        //               isLoading
        //             }
        //             disabled={
        //               makePaymentMutation.isLoading ||
        //               initiateOnlinePayment.isLoading ||
        //               onlinePaymentMutation.isLoading ||
        //               isLoading ||
        //               (values.tandc === false &&
        //                 values.method === AddPaymentTypeEnum.CREDIT)
        //             }
        //           >
        //             {AddPaymentTypeEnum.CREDIT === values.method
        //               ? +values.amount > 0
        //                 ? `Pay  ${formatCurrency(selectedOnlinePaymentOption.totalOrderAmount)}`
        //                 : 'Pay'
        //               : ' Proceed'}
        //           </LoaderButton>
        //         </div>
        //         {values.method === AddPaymentTypeEnum.CREDIT && (
        //           <div className='flex items-center mt-2 gap-2'>
        //             <input
        //               className='form-checkbox'
        //               type='checkbox'
        //               id='saveCustomer'
        //               checked={values.tandc}
        //               onChange={(e) => setFieldValue(`tandc`, e.target.checked)}
        //               disabled={isLoading}
        //             />
        //             <label className='text-xs' htmlFor='saveCustomer'>
        //               I accept terms & conditions{' '}
        //             </label>
        //           </div>
        //         )}
        //       </Form>
        //     )}
        //   </Formik>
        // </Modal>
      }
    </>
  );
};

export default PaymentAddDialog;
