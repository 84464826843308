import React, { FC } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import LottieLoader from 'app/components/LottieLoader/LottieLoader';
import successLottie from 'assets/lotties/success.json';

const modalAnimation = {
  variants: {
    hidden: {
      opacity: 0,
    },
    visible: {
      opacity: 1,
    },
  },
  initial: 'hidden',
  animate: 'visible',
  exit: 'hidden',
};

interface SuccessAnimationProps {
  show: boolean;
  onClose: () => void;
}

const SuccessAnimation: FC<SuccessAnimationProps> = (props) => {
  const { show, onClose } = props;

  return (
    <AnimatePresence initial={false} mode='wait'>
      {show && (
        <motion.div
          className='fixed top-0 left-0 w-full h-full bg-white/80 z-50'
          {...modalAnimation}
          onClick={onClose}
        >
          <div className='flex w-full'>
            <div className='w-96 mx-auto mt-32'>
              {/* <Lottie options={defaultOptions} /> */}
              <LottieLoader animationData={successLottie} />

              <h6 className='font-semibold text-center -mt-20'>
                Your payment has been received and your booking has been
                confirmed.
              </h6>
            </div>
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default SuccessAnimation;
