import MobileTopBar from 'app/components/MobileTopBar/MobileTopBar';
import React, { FC } from 'react';
import { NavLink, useLocation } from 'react-router-dom';

interface InventoryNavBarProps {}

const InventoryNavBar: FC<InventoryNavBarProps> = (props) => {
  const { pathname } = useLocation();

  return (
    <>
      <div className='bg-primary text-white  z-20 fixed w-full overflow-auto'>
        <div className='bg-white'>
          <MobileTopBar />
        </div>
        <div className='pt-2 pb-3'>
          <ul className=' flex justify-between sm:justify-start sm:gap-8 px-4 sm:px-9'>
            <li className='hidden sm:inline-block'>
              <NavLink
                className='text-sm font-normal hover:cursor-default'
                to={'#'}
              >
                MySeries
              </NavLink>
            </li>
            <li>
              <NavLink
                className={`text-sm ${
                  pathname === '/my-series'
                    ? 'font-bold underline underline-offset-4 sm:no-underline decoration-2'
                    : 'font-normal'
                } `}
                to={'/my-series'}
              >
                Dashboard
              </NavLink>
            </li>
            <li>
              <NavLink
                className={({ isActive }) =>
                  `text-sm ${
                    isActive
                      ? 'font-bold underline underline-offset-4 sm:no-underline decoration-2'
                      : 'font-normal'
                  } `
                }
                to={'/my-series/series-inventory'}
              >
                Inventory
              </NavLink>
            </li>
            <li>
              <NavLink
                className={({ isActive }) =>
                  `text-sm ${
                    isActive
                      ? 'font-bold underline underline-offset-4 sm:no-underline decoration-2'
                      : 'font-normal'
                  } `
                }
                to={'/my-series/bookings'}
              >
                Bookings
              </NavLink>
            </li>
            <li>
              <NavLink
                className={({ isActive }) =>
                  `text-sm ${
                    isActive
                      ? 'font-bold underline underline-offset-4 sm:no-underline decoration-2'
                      : 'font-normal'
                  } `
                }
                to={'/my-series/account'}
              >
                Accounts
              </NavLink>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default InventoryNavBar;
