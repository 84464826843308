import { useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { IGateWay, IPaymentGatewayOption } from './types/gateway-with-charges';
import { getOnlinePaymentCharges } from 'app/api/services/online-payment.service';

const useOnlineGatewayandMethod = (baseAmount: number) => {
  const [activeTab, setActiveTab] = useState(0);
  const [tcAccepted, setTcAccepted] = useState(false);
  const [selectedGateway, setSelectedGateway] = useState<IGateWay>(null);

  const { data, isLoading } = useQuery<IPaymentGatewayOption[]>(
    ['ONLINE_PAYMENT', baseAmount, 'CHARGES'],
    () => getOnlinePaymentCharges(baseAmount),
  );

  useEffect(() => {
    if (data) {
      setTcAccepted(false);
      setSelectedGateway(data[activeTab].gateways[0]);
    }
  }, [activeTab, data]);

  return {
    data,
    isLoading,
    activeTab,
    setActiveTab,
    tcAccepted,
    setTcAccepted,
    selectedGateway,
    setSelectedGateway,
  };
};

export default useOnlineGatewayandMethod;
