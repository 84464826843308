import { useMutation } from '@tanstack/react-query';
import BgOverlay from 'app/components/BgOverlay/BgOverlay';
import LoaderButton from 'app/components/LoaderButton/LoaderButton';
import http from 'app/config/http';
import { CalendarIcon } from 'app/icons';
import { DatePicker } from 'app/library/datepicker';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import moment from 'moment';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

const TourQueryFormSchema = Yup.object({
  arrivalDate: Yup.date().required('This field is required'),
  departureDate: Yup.date()
    .required('This field is required')
    .test(
      'is-arrival-date-after-departure-date',
      'Arrival date should be after departure date',
      function (departureDate) {
        const { arrivalDate } = this.parent;
        const tripReturn = moment(departureDate, 'YYYY-MM-DD');
        const tripTravel = moment(arrivalDate, 'YYYY-MM-DD');
        return tripReturn.isSameOrAfter(tripTravel);
      },
    ),
  hotelCategory: Yup.string().required('This field is required'),
  adults: Yup.number()
    .required('This field is required')
    .min(1)
    .integer('This field must be an integer'),
  children: Yup.number()
    .required('This field is required')
    .min(0)
    .integer('This field must be an integer'),
  totalRooms: Yup.number()
    .required('This field is required')
    .min(1)
    .integer('This field must be an integer'),
  extraBed: Yup.number()
    .required('This field is required')
    .min(0)
    .integer('This field must be an integer')
    .test(
      ' extra-beds-doesnt-exeed',
      'Extra beds cannot exceed number of rooms',
      function (extraBed) {
        const { totalRooms } = this.parent;
        return extraBed <= totalRooms;
      },
    ),
  transportNeeded: Yup.boolean(),
  extraInformation: Yup.string(),
});

interface TourQueryAddProps {}

const TourQueryAdd: FC<TourQueryAddProps> = (props) => {
  const navigate = useNavigate();
  const hotelCategory = [
    {
      label: 'Budget',
      value: 'BUDGET',
    },
    {
      label: 'Standard',
      value: 'STANDARD',
    },
    {
      label: 'Luxury',
      value: 'LUXURY',
    },
  ];

  const initialValues = {
    arrivalDate: new Date(),
    departureDate: new Date(),
    hotelCategory: hotelCategory[0].value,
    adults: 1,
    children: 0,
    totalRooms: 1,
    extraBed: 0,
    transportNeeded: false,
    extraInformation: '',
  };

  const addTourQueryMutation = useMutation(async (values: any) => {
    const { data } = await http.post(
      `${process.env.REACT_APP_API_URL}/api/v1/sub-agent/tour-queries`,
      values,
    );
    return data;
  });

  const handleSubmit = async (values: any) => {
    // make backend request here

    const arrivalDate = moment(values.arrivalDate).format('YYYYMMDD');
    const departureDate = moment(values.departureDate).format('YYYYMMDD');

    try {
      await addTourQueryMutation.mutateAsync({
        ...values,
        arrivalDate,
        departureDate,
      });
      navigate('/tour-query');
    } catch (ex: any) {
      toast.error(ex?.data?.message || 'Some error occured, please try again.');
    }
  };

  return (
    <>
      <BgOverlay />
      <div className='container min-h-full'>
        <div className='flex py-8'>
          <div className='title text-secondary-fg'>Add Tour Query</div>
        </div>
        <div className='card p-9 mb-4'>
          <Formik
            initialValues={initialValues}
            enableReinitialize
            validationSchema={TourQueryFormSchema}
            onSubmit={handleSubmit}
          >
            {({ values, setFieldValue }) => (
              <Form>
                <div className='grid grid-cols-12 gap-y-6  sm:gap-6'>
                  <div className='col-span-12 md:col-span-6'>
                    <label
                      htmlFor='travelDate'
                      className='form-label text-secondary-fg'
                    >
                      Arrival Date
                    </label>

                    <div className='relative flex items-center'>
                      <div className='absolute left-4 z-10'>
                        <CalendarIcon />
                      </div>

                      <DatePicker
                        className='form-control pl-12 cursor-pointer caret-transparent z-20!'
                        value={values.arrivalDate}
                        onChange={(val) => setFieldValue('arrivalDate', val)}
                        minDate={new Date()}
                        format='DD MM YYYY'
                      />
                    </div>
                    <div className='form-error text-secondary-fg/60'>
                      <ErrorMessage name='arrivalDate' />
                    </div>
                  </div>
                  <div className='col-span-12 md:col-span-6'>
                    <label
                      htmlFor='travelDate'
                      className='form-label text-secondary-fg'
                    >
                      Departure Date
                    </label>

                    <div className='relative flex items-center'>
                      <div className='absolute left-4 z-10'>
                        <CalendarIcon />
                      </div>

                      <DatePicker
                        className='form-control pl-12 cursor-pointer caret-transparent z-20!'
                        value={values.departureDate}
                        onChange={(val) => setFieldValue('departureDate', val)}
                        minDate={new Date()}
                        format='DD MM YYYY'
                      />
                    </div>
                    <div className='form-error text-secondary-fg/60'>
                      <ErrorMessage name='departureDate' />
                    </div>
                  </div>
                  <div className='col-span-12 md:col-span-6'>
                    <div className='w-full'>
                      <label
                        className='form-label text-secondary-fg'
                        htmlFor='cabin-class'
                      >
                        Hotel Category
                      </label>
                      <Field
                        as='select'
                        name='hotelCategory'
                        type='text'
                        id='cabin-class'
                        className='form-control'
                      >
                        {hotelCategory.map((row) => (
                          <option key={row.value} value={row.value}>
                            {`${row.label}`}
                          </option>
                        ))}
                      </Field>
                      <div className='form-error'>
                        <ErrorMessage name='cabinClass' />
                      </div>
                    </div>
                  </div>
                  <div className='col-span-12 md:col-span-6'>
                    <div className=' grid grid-cols-2 gap-6'>
                      <div className='col-span-1'>
                        <div>
                          <label
                            htmlFor='adults'
                            className='form-label text-secondary-fg'
                          >
                            Adults
                          </label>
                          <Field
                            type='number'
                            name={'adults'}
                            className='form-control'
                          />
                          <div className='form-error'>
                            <ErrorMessage name={'adults'} />
                          </div>
                        </div>
                      </div>
                      <div className='col-span-1'>
                        <div>
                          <label
                            htmlFor='children'
                            className='form-label text-secondary-fg'
                          >
                            Childrens
                          </label>
                          <Field
                            type='number'
                            name={'children'}
                            className='form-control'
                          />
                          <div className='form-error'>
                            <ErrorMessage name={'children'} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='col-span-12 md:col-span-6'>
                    <div>
                      <label
                        htmlFor='totalRooms'
                        className='form-label text-secondary-fg'
                      >
                        Rooms
                      </label>
                      <Field
                        type='number'
                        name={'totalRooms'}
                        className='form-control'
                      />
                      <div className='form-error'>
                        <ErrorMessage name={'totalRooms'} />
                      </div>
                    </div>
                  </div>
                  <div className='col-span-12 md:col-span-6'>
                    <div>
                      <label
                        htmlFor='extraBed'
                        className='form-label text-secondary-fg'
                      >
                        Extra Beds{' '}
                      </label>
                      <Field
                        type='number'
                        name={'extraBed'}
                        className='form-control'
                      />
                      <div className='form-error'>
                        <ErrorMessage name={'extraBed'} />
                      </div>
                    </div>
                  </div>
                  <div className='col-span-12'>
                    <div>
                      <label
                        className='form-label text-secondary-fg'
                        htmlFor='extraInformation'
                      >
                        Extra Information
                      </label>
                      <Field
                        as='textarea'
                        name='extraInformation'
                        type='text'
                        id='extraInformation'
                        className='form-control'
                        rows='4'
                      />
                      <div className='form-error'>
                        <ErrorMessage
                          name='extraInformation
'
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className='mt-8'>
                  <div className='flex items-center gap-2'>
                    <div className='form-switch success'>
                      <Field
                        name='transportNeeded'
                        id='transportNeeded'
                        type='checkbox'
                        role='switch'
                      />
                    </div>
                    <label
                      htmlFor='transportNeeded'
                      className='form-label text-secondary-fg mb-0 '
                    >
                      Transport Needed
                    </label>
                  </div>
                </div>

                <LoaderButton
                  type='submit'
                  className='bg-primary text-white text-sm font-semibold rounded-lg w-56 mt-12'
                  isLoading={addTourQueryMutation.isLoading}
                  disabled={addTourQueryMutation.isLoading}
                >
                  Add Query
                </LoaderButton>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
};

export default TourQueryAdd;
