import * as Yup from 'yup';
import { FC, useState } from 'react';
import moment from 'moment';
import useTopup from '../useTopup';
import { toast } from 'react-toastify';
import Modal from 'app/components/Modal/Modal';
import { useQueryClient } from '@tanstack/react-query';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import LoaderButton from 'app/components/LoaderButton/LoaderButton';
import useOnlinePayment from 'app/hooks/use-online-payment';

export enum AddPaymentTypeEnum {
  CREDIT = 'CREDIT',
  BANK_TRANSFER = 'BANK_TRANSFER',
  CHEQUE_OR_CASH = 'CHEQUE_OR_CASH',
}

const PaymentFormSchema = Yup.object().shape({
  method: Yup.string().trim().required('This field is required'),
  amount: Yup.number()
    .typeError('You must specify a number')
    .required('This field is required')
    .min(1, 'The amount must be greater than Zero'),
  remarks: Yup.string().trim(),
  bankAccount: Yup.string()
    .trim()
    .when('method', {
      is: (method: any) => method === AddPaymentTypeEnum.BANK_TRANSFER,
      then: Yup.string().trim().required('Field is required'),
    }),
  bankTransactionId: Yup.string()
    .trim()
    .when('method', {
      is: (method: any) => method === AddPaymentTypeEnum.BANK_TRANSFER,
      then: Yup.string().trim().required('Field is required'),
    }),
  transactionDate: Yup.string()
    .trim()
    .when('method', {
      is: (method: any) =>
        method === AddPaymentTypeEnum.BANK_TRANSFER ||
        method === AddPaymentTypeEnum.CHEQUE_OR_CASH,
      then: Yup.string().trim().required('Field is required'),
    }),
});

interface PaymentAddDialogProps {
  show: boolean;
  onClose: () => void;
  onSubmit: () => void;
  bankList: [];
}

const PaymentAddDialog: FC<PaymentAddDialogProps> = (props) => {
  const { show, onClose, bankList } = props;
  const queryClient = useQueryClient();

  const [formVisible, setFormVisible] = useState(true);

  const paymentInitialValues = {
    amount: '',
    method: AddPaymentTypeEnum.BANK_TRANSFER,
    bankAccount: '',
    transactionDate: moment().format('YYYY-MM-DD'),
    remarks: '',
    details: {},
    bankTransactionId: '',
  };

  const {
    UiComponent,
    show: showOnlinePaymentOptions,
    onlinePaymentAvailable,
  } = useOnlinePayment({
    // url: `/api/v1/sub-agent/payments/payment/ccavenue/initiate`,
    url: {
      CCAVENUE_PAYMENT: `/api/v1/sub-agent/payments/payment/ccavenue/initiate`,
      HDFC_GATEWAY_PAYMENT: '/api/v1/sub-agent/payments/payment/hdfc/initiate',
    },
    handleOuster: () => {
      // onClose();
      setFormVisible(false);
    },
  });

  const onSubmit = async (values: any) => {
    let payload: any = {
      amount: values.amount,
      method: values.method,
      subAgentRemark: values.remarks.trim(),
      details: {
        transactionDate: moment(values.transactionDate).format('YYYYMMDD'),
      },
    };
    switch (values.method) {
      case AddPaymentTypeEnum.CHEQUE_OR_CASH:
        break;
      case AddPaymentTypeEnum.BANK_TRANSFER:
        payload.details.bank = bankList.find(
          (bank: any) => values.bankAccount === bank._id,
        );
        payload.details.transactionId = values.bankTransactionId;
        break;
    }

    if (values.method === AddPaymentTypeEnum.CREDIT) {
      try {
        showOnlinePaymentOptions(+payload.amount, {
          subAgentRemark: payload.subAgentRemark,
          topupAmount: +payload.amount,
        });
      } catch (ex: any) {
        toast.error(
          ex?.data?.message || 'Some error occured, please try again.',
        );
        console.log(ex);
      }
    } else {
      try {
        await makePaymentMutation.mutateAsync(payload);
        queryClient.fetchQuery(['accountData']);
        queryClient.fetchQuery(['payments']);
        toast.success('Payment request sent successfully');
        onClose();
      } catch (ex: any) {
        console.log(ex);
        toast.error(
          ex?.data?.message || 'Some error occured, please try again.',
        );
      }
    }
  };

  const {
    initiateOnlinePayment,
    makePaymentMutation,
    onlinePaymentMutation,
    paymentOptions,
  } = useTopup();

  return (
    <>
      {UiComponent()}
      {formVisible && (
        <Modal show={show} onClose={onClose} className='w-[520px]'>
          <div className='text-2xl font-bold'>Make Payment</div>

          <Formik
            initialValues={paymentInitialValues}
            validationSchema={PaymentFormSchema}
            enableReinitialize
            onSubmit={onSubmit}
          >
            {({ values, setFieldValue, errors }) => (
              <Form>
                <div className='mt-5'>
                  <label className='text-xs mb-2 block' htmlFor='Amount'>
                    Payment Amount
                  </label>
                  <div className='flex items-center relative'>
                    <div className='absolute pl-4'> ₹ </div>
                    <Field
                      name='amount'
                      type='number'
                      id='Amount'
                      className='form-control pl-8'
                      // onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      //   setFieldValue('amount', e.target.value);
                      //   handleAmountChange(+e.target.value);
                      //   setFieldValue('tandc', false);
                      // }}
                    />
                  </div>
                  <div className='form-error'>
                    <ErrorMessage name='amount' />
                  </div>
                </div>

                <div className='mt-5'>
                  <label className='text-xs mb-4 block' htmlFor='Amount'>
                    Select Payment Method
                  </label>
                  {paymentOptions.map((row) => (
                    <div key={row.value} className='flex gap-3 mb-4'>
                      <Field
                        type='radio'
                        id={row.value}
                        name='method'
                        onChange={() => setFieldValue('method', row.value)}
                        checked={row.value === values.method}
                        className='accent-primary'
                        disabled={
                          row.value === AddPaymentTypeEnum.CREDIT &&
                          !onlinePaymentAvailable
                        }
                      />
                      <label
                        className={
                          'text-sm block ' +
                          (row.value === AddPaymentTypeEnum.CREDIT &&
                          !onlinePaymentAvailable
                            ? 'text-gray-400'
                            : '')
                        }
                        htmlFor={row.value}
                      >
                        {row.label}
                      </label>
                    </div>
                  ))}
                </div>

                {values.method === AddPaymentTypeEnum.BANK_TRANSFER && (
                  <>
                    <div className='mt-5'>
                      <label
                        className='text-xs mb-2 block'
                        htmlFor='bankAccount'
                      >
                        Select Account
                      </label>
                      <Field
                        as='select'
                        name='bankAccount'
                        type='text'
                        id='bankAccount'
                        className='form-control '
                      >
                        <option value=''>Select Bank Account</option>
                        {bankList.map((row: any) => (
                          <option key={row._id} value={row._id}>
                            {`${row.name} ${row.account.number}`}
                          </option>
                        ))}
                      </Field>
                      <div className='form-error'>
                        <ErrorMessage name='bankAccount' />
                      </div>
                    </div>
                    <div className='mt-5'>
                      <label
                        className='text-xs mb-2 block'
                        htmlFor='BankTransactionId'
                      >
                        Transaction ID
                      </label>
                      <Field
                        name='bankTransactionId'
                        type='text'
                        id='BankTransactionId'
                        className='form-control'
                      />
                      <div className='form-error'>
                        <ErrorMessage name='bankTransactionId' />
                      </div>
                    </div>
                    <div className='mt-5'>
                      <label
                        className='text-xs mb-2 block'
                        htmlFor='TransactionDate'
                      >
                        Transaction Date
                      </label>
                      <Field
                        name='transactionDate'
                        type='date'
                        id='TransactionDate'
                        className='form-control '
                      />
                      <div className='form-error'>
                        <ErrorMessage name='transactionDate' />
                      </div>
                    </div>
                  </>
                )}

                {values.method === AddPaymentTypeEnum.CHEQUE_OR_CASH && (
                  <>
                    <div className='mt-5'>
                      <label
                        className='text-xs mb-2 block'
                        htmlFor='TransactionDate'
                      >
                        Transaction Date
                      </label>
                      <Field
                        name='transactionDate'
                        type='date'
                        id='TransactionDate'
                        className='form-control '
                      />
                      <div className='form-error'>
                        <ErrorMessage name='transactionDate' />
                      </div>
                    </div>
                  </>
                )}

                <div className='mt-5'>
                  <label className='text-xs mb-2 block' htmlFor='Remarks'>
                    Remarks
                  </label>
                  <Field
                    as='textarea'
                    name='remarks'
                    type='text'
                    id='Remarks'
                    className='form-control'
                  />
                  <div className='form-error'>
                    <ErrorMessage name='remarks' />
                  </div>
                </div>

                <div className=' mt-8 text-center inline-block w-full  sm:w-52  '>
                  <LoaderButton
                    type='submit'
                    className='btn btn-primary w-full'
                    isLoading={
                      makePaymentMutation.isLoading ||
                      initiateOnlinePayment.isLoading ||
                      onlinePaymentMutation.isLoading
                    }
                    disabled={
                      makePaymentMutation.isLoading ||
                      initiateOnlinePayment.isLoading ||
                      onlinePaymentMutation.isLoading
                    }
                  >
                    Pay
                  </LoaderButton>
                </div>
              </Form>
            )}
          </Formik>
        </Modal>
      )}
    </>
  );
};

export default PaymentAddDialog;
