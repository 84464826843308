/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC } from 'react';
// import { IBooking, IBookingPassenger } from 'app/types';
import { formatDate } from 'app/utils/date-utils';
import { formatCurrency } from 'app/utils/currency-utils';
import StatusBadge from 'app/components/StatusBadge/StatusBadge';
import { Dropdown, DropdownList } from 'app/components/Dropdown/Dropdown';
import { MenuDotIcon } from 'app/icons';
import { DropdownItem } from 'app/components/Dropdown/Dropdown';
import Tooltip from 'app/components/Tooltip/Tooltip';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';

interface BookingTableProps {
  bookings: any[];
}

const BookingTable: FC<BookingTableProps> = (props) => {
  const { bookings } = props;

  const navigate = useNavigate();

  const getFirstPassengerName = (booking: any) => {
    const firstPassenger = booking.passengers[0];

    return `${firstPassenger.title} ${firstPassenger.firstName} ${firstPassenger.lastName}`;
  };

  const getPassengerName = (passenger: any) => {
    return `${passenger.title} ${passenger.firstName} ${passenger.lastName}`;
  };

  // const getFareSupplier = (fareSupplier: string) => {
  //     switch (fareSupplier) {
  //         case '0':
  //             return 'Series Fare';
  //         case '1':
  //             return 'Tripjack API';
  //         case '2':
  //             return 'Series Fare';
  //     }
  // };

  const getStatusClass = (status: string) => {
    switch (status) {
      case 'PENDING':
      case 'SUBMISSION_PENDING':
        return 'yellow';
      case 'COMPLETED':
        return 'green';
      case 'CANCELLED':
        return 'red';
    }
  };

  const getStatusText = (status: string) => {
    switch (status) {
      case 'PENDING':
        return 'Pending';
      case 'SUBMISSION_PENDING':
        return 'Submission Pending';
      case 'COMPLETED':
        return 'Completed';
      case 'CANCELLED':
        return 'Cancelled';
    }
  };

  return (
    <table className='w-full min-w-[1200px]'>
      <thead>
        <tr className='bg-gray-50'>
          <td className='td th'>Trip ID</td>
          <td className='td th'>Travel & Date</td>
          <td className='td th'>Traveller(s)</td>
          <td className='td th'>Flight Details</td>
          <td className='td th'>Fare Details</td>
          <td className='td th'>Created At</td>
          <td className='td th'></td>
        </tr>
      </thead>
      <tbody>
        {bookings.map((row) => (
          <tr
            key={row._id}
            className='border-b border-gray-200 last:border-none'
          >
            <td className='td tb'>
              <div
                className='font-semibold text-blue-500 cursor-pointer'
                onClick={() => navigate(`/my-series/bookings/${row._id}`)}
              >
                {row._id}
              </div>
              <StatusBadge className='mt-1' color={getStatusClass(row.status)}>
                {getStatusText(row.status)}
              </StatusBadge>
            </td>
            <td className='td tb'>
              <div className='font-semibold'>
                {row.trip.departure.airport.city} to{' '}
                {row.trip.arrival.airport.city}
              </div>
              <p className='mt-1'>
                {formatDate(row.trip.departure.date, 'DD MMM, YYYY')}
              </p>
            </td>
            <td className='td tb'>
              <div className='font-semibold'>{getFirstPassengerName(row)}</div>
              {row.passengers.length > 1 && (
                <Tooltip
                  content={
                    <div className='px-6 py-4 w-48'>
                      <ul className='space-y-4'>
                        {row.passengers.map((row: any, i: any) => (
                          <li className='text-sm text-gray-500' key={i}>
                            {getPassengerName(row)}
                          </li>
                        ))}
                      </ul>
                    </div>
                  }
                  placement='bottom-start'
                  // triggers={['click']}
                >
                  <p className='mt-1'>
                    + {row.passengers.length - 1} Passengers
                  </p>
                </Tooltip>
              )}
            </td>
            <td className='td tb'>
              <div className='flex items-center gap-3 min-w-max'>
                <img
                  className='w-9 h-9'
                  crossOrigin='anonymous'
                  src={row.flight.logo}
                  alt=''
                />
                <div>
                  <div className='font-semibold'>{row.flight.name}</div>
                  <p className='mt-1'>
                    {row.flight.code} {row.flight.number}
                  </p>
                </div>
              </div>
            </td>
            <td className='td tb'>
              <div className='font-semibold'>
                {formatCurrency(row.payment.amount)}
              </div>
              <p className='mt-1'>Total</p>
            </td>

            <td className='td tb'>
              <div className='min-w-max'>
                <p>
                  {moment(row.createdAt, 'YYYYMMDDHHmmss').format(
                    'DD MMM, YYYY',
                  )}
                </p>
                <p>
                  {moment(row.createdAt, 'YYYYMMDDHHmmss').format('hh:mm A')}
                </p>
              </div>
            </td>
            <td className='td tb'>
              <Dropdown
                target={
                  <div className='dropdown-action'>
                    <MenuDotIcon />{' '}
                  </div>
                }
                menu={
                  <DropdownList>
                    <DropdownItem to={`/my-series/bookings/${row._id}`}>
                      View Ticket
                    </DropdownItem>
                    <DropdownItem to={`/my-series/bookings/${row._id}/invoice`}>
                      Invoice
                    </DropdownItem>
                    <DropdownItem
                      to={`/my-series/series-inventory/${row.ticketId}/bookings`}
                    >
                      View Series Ticket
                    </DropdownItem>
                  </DropdownList>
                }
              />
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default BookingTable;
