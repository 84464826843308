import React from 'react';

type ICard<C extends React.ElementType> = {
  as?: C;
};

type Props<C extends React.ElementType> = React.PropsWithChildren<ICard<C>> &
  Omit<React.ComponentPropsWithoutRef<C>, keyof ICard<C>>;

const Card = <C extends React.ElementType = 'div'>({
  as,
  children,
  ...restProps
}: Props<C>) => {
  const Component = as || 'div';

  return (
    <Component className='card px-8 py-7 mb-4' {...restProps}>
      {children}
    </Component>
  );
};

export default Card;
