import { FC, useMemo } from 'react';
import { Months } from '../utils/constants';
import PickerHeader from './PickerHeader';

interface MonthPickerProps {
  selectedYear: number;
  selectedMonth: number;
  viewYear: number;
  onViewYearChange: (year: number) => void;
  onViewMonthChange: (month: number) => void;
  onYearPickerSelect: () => void;
  onDayPickerSelect: () => void;
  minYear?: number;
  maxYear?: number;
  minMonth?: number;
  maxMonth?: number;
}

const MonthPicker: FC<MonthPickerProps> = (props) => {
  const {
    selectedYear,
    selectedMonth,
    viewYear,
    onViewMonthChange,
    onViewYearChange,
    onYearPickerSelect,
    onDayPickerSelect,
    minYear,
    maxMonth,
    minMonth,
    maxYear,
  } = props;

  const prevDisabled = useMemo(() => {
    if (!minYear) return false;

    return viewYear <= minYear;
  }, [minYear, viewYear]);

  const nextDisabled = useMemo(() => {
    if (!maxYear) return false;

    return viewYear >= maxYear;
  }, [maxYear, viewYear]);

  const isSameYearAndMonthAsSelected = (month: number) => {
    return selectedMonth === month && selectedYear === viewYear;
  };

  const isLessThanMinDate = (month: number) => {
    if (!minYear || !minMonth) return false;

    return viewYear <= minYear && month < minMonth;
  };

  const isGreaterThanMaxDate = (month: number) => {
    if (!maxYear || !maxMonth) return false;

    return viewYear >= maxYear && month > maxMonth;
  };

  const handlePrevClick = () => {
    if (prevDisabled) {
      return;
    }

    onViewYearChange(viewYear - 1);
  };

  const handleNextClick = () => {
    if (nextDisabled) {
      return;
    }

    onViewYearChange(viewYear + 1);
  };

  const handleSelect = (month: number) => {
    if (isGreaterThanMaxDate(month) || isLessThanMinDate(month)) {
      return;
    }

    onViewMonthChange(month);

    onDayPickerSelect();
  };

  const dynamicClass = (month: number) => {
    let className = 'bg-white text-gray-900 cursor-pointer hover:bg-primary/5';

    if (isSameYearAndMonthAsSelected(month)) {
      className = 'bg-primary text-white cursor-pointer hover:bg-primary';
    }

    if (isGreaterThanMaxDate(month) || isLessThanMinDate(month)) {
      className = 'bg-white text-gray-400 cursor-not-allowed ';
    }

    return className;
  };

  return (
    <>
      <PickerHeader
        content={
          <div
            role='button'
            className='grid place-items-center h-6 px-3 rounded-md hover:bg-primary/5'
            onClick={onYearPickerSelect}
          >
            {viewYear}
          </div>
        }
        isPrevDisabled={prevDisabled}
        onPrevClick={handlePrevClick}
        isNextDisabled={nextDisabled}
        onNextClick={handleNextClick}
      />

      <div className='grid grid-cols-3 gap-2 mt-3'>
        {Months.map((month, i) => (
          <div key={i} className='col-span-1'>
            <div
              className={[
                'flex items-center justify-center h-9 text-[13px] font-medium rounded-lg',
                dynamicClass(i),
              ].join(' ')}
              onClick={() => handleSelect(i)}
            >
              {month}
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default MonthPicker;
