import { useQuery } from '@tanstack/react-query';
import Modal from 'app/components/Modal/Modal';
import Spinner from 'app/components/Spinner/Spinner';
import http from 'app/config/http';
import { formatCurrency } from 'app/utils/currency-utils';
import { formatDate, formatTime } from 'app/utils/date-utils';
import { FC } from 'react';

interface PaymentReceiptDialogProps {
  show: boolean;
  onClose: () => void;
  onSubmit: () => void;
  transactionId: string;
}

const PaymentReceiptDialog: FC<PaymentReceiptDialogProps> = (props) => {
  const { show, onClose, transactionId } = props;

  const transaction = useQuery(
    ['paymentRecipt', transactionId],
    async () => {
      const { data } = await http.get(
        `${process.env.REACT_APP_API_URL}/api/v1/sub-agent/payments/${transactionId}/reciept`,
      );

      return data;
    },
    {
      enabled: show,
    },
  );

  return (
    <Modal show={show} onClose={onClose}>
      <div className='text-2xl font-bold'>Payment Receipt </div>

      {transaction.isLoading ? (
        <div className='flex justify-center items-center'>
          <Spinner />
        </div>
      ) : (
        <div>
          <div className='flex flex-col mt-7 gap-4'>
            <div className='text-xs font-normal text-gray-500'>
              Receipt Number
            </div>
            <div className='text-lg font-semibold'>{transaction.data.id}</div>
          </div>

          <div className='grid grid-cols-4 gap-8 mt-7 mb-6'>
            <div className='col-span-1'>
              <div className='text-xs font-normal text-gray-500'>
                Date & Time
              </div>
              <div className='text-lg font-semibold mt-4 '>
                {formatDate(transaction.data.createdAt)}{' '}
                {formatTime(transaction.data.createdAt)}
              </div>
            </div>
            <div className='col-span-1'>
              <div className='text-xs font-normal text-gray-500'>Amount</div>
              <div className='text-lg font-semibold mt-4'>
                {formatCurrency(transaction.data.amount)}
              </div>
            </div>
            <div className='col-span-1'>
              <div className='text-xs font-normal text-gray-500'>
                Payment Method
              </div>
              <div className='text-lg font-semibold mt-4'>
                {transaction.data.method}
              </div>
            </div>
            <div className='col-span-1'>
              <div className='text-xs font-normal text-gray-500'>
                Bank Transaction ID
              </div>
              <div className='text-lg font-semibold mt-4'>
                {transaction.data.details.transactionId}
              </div>
            </div>
          </div>
        </div>
      )}
    </Modal>
  );
};

export default PaymentReceiptDialog;
