import BgOverlay from 'app/components/BgOverlay/BgOverlay';
import React, { FC } from 'react';
import { EditPencilIcon } from 'app/icons';
import { useQuery } from '@tanstack/react-query';
import http from 'app/config/http';
import { formatDate } from 'app/utils/date-utils';
import PageLoader from 'app/components/PageLoader/PageLoader';
import { Link, useNavigate } from 'react-router-dom';
import MobileTopBar from 'app/components/MobileTopBar/MobileTopBar';

interface SubAgencyUsersProps {}

const SubAgencyUsers: FC<SubAgencyUsersProps> = (props) => {
  const navigate = useNavigate();

  const agencyUsersQuery = useQuery(['agencyUsers'], async () => {
    const { data } = await http.get(
      `${process.env.REACT_APP_API_URL}/api/v1/sub-agent/sub-agency/sub-agency-users`,
    );

    return data;
  });

  if (agencyUsersQuery.isLoading) return <PageLoader />;

  return (
    <div>
      <MobileTopBar />
      <BgOverlay />

      <div className='container min-h-full'>
        <div className='flex py-8'>
          <div className='title text-secondary-fg'>Users</div>
        </div>
        <div className='card mb-4'>
          {/* card heading  */}
          <div className='mx-9 pt-9 flex justify-between items-end'>
            <div className='text-xl font-bold'>All Users</div>
            <Link
              to={'/users/add'}
              className='text-sm font-semibold text-blue-500'
            >
              Add User
            </Link>
          </div>
          <div className='mt-9 pb-10 overflow-auto'>
            <div className='min-w-[650px]'>
              <table className='w-full'>
                <thead>
                  <tr>
                    <td className=' td th'>Date Added</td>
                    <td className=' td th'>Name</td>
                    <td className=' td th'>Email</td>
                    <td className=' td th'>Contact Number</td>
                    <td className=' td th'></td>
                  </tr>
                </thead>
                <tbody>
                  {agencyUsersQuery.data.map((row: any, index: number) => {
                    return (
                      <tr key={index}>
                        <td className=' td tb'>{formatDate(row.createdAt)}</td>
                        <td className=' td tb '>{row.name}</td>
                        <td className=' td tb '>{row.email}</td>
                        <td className=' td tb'>{row.phoneNumber}</td>
                        <td className=' td tb  text-success'>
                          <EditPencilIcon
                            onClick={() => navigate(`/users/${row._id}/edit`)}
                          />
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SubAgencyUsers;
