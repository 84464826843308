import { IPaymentGatewayOption } from 'app/components/OnlinePaymentGatewayAndMethodSelectionDialog/types/gateway-with-charges';
import http from 'app/config/http';

export const getOnlinePaymentCharges = async (baseAmount: number) => {
  const { data } = await http.get(
    `${process.env.REACT_APP_API_URL}/api/v1/sub-agent/payments/payment-gateways`,
    {
      params: {
        'base-amount': baseAmount,
      },
    },
  );
  return data;
};
