import { useQuery } from '@tanstack/react-query';
import http from 'app/config/http';
import { CrossIcon, LeftIcon, RightIcon } from 'app/icons';
import moment from 'moment';
import { FC, useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';

interface AnnouncementModalProps {
  onClose: () => void;
}

const AnnouncementModal: FC<AnnouncementModalProps> = (props) => {
  const { onClose } = props;

  const navigationPrevRef = useRef(null);
  const navigationNextRef = useRef(null);

  const announcementQuery = useQuery(['announcement'], async () => {
    const { data } = await http.get(
      `${process.env.REACT_APP_API_URL}/api/v1/sub-agent/announcements`,
    );

    return data;
  });

  if (announcementQuery.isLoading) return null;

  const announcements = announcementQuery.data;

  if (announcementQuery.isError) {
    onClose();
    return null;
  }

  if (announcements.length === 0) return null;

  return (
    <div
      className={
        'fixed top-0 left-0 w-full h-full flex justify-center items-center z-[997] bg-black/40'
      }
      onClick={onClose}
    >
      <div
        className={
          'fixed left-0 top-0 w-full h-full overflow-x-hidden overflow-y-auto z-[998]'
        }
      >
        <div
          className={
            'flex items-center justify-center w-auto m-4 min-h-[calc(100%-2rem)]'
          }
        >
          <div
            className='absolute z-[9999] max-w-full w-[800px] flex justify-between px-6'
            onClick={(e) => e.stopPropagation()}
          >
            <button
              type='button'
              className='disabled:opacity-0'
              ref={navigationPrevRef}
            >
              <LeftIcon width={32} />
            </button>

            <button
              type='button'
              className='disabled:opacity-0'
              ref={navigationNextRef}
            >
              <RightIcon width={32} />
            </button>
          </div>

          <Swiper
            slidesPerView={1}
            navigation={{
              prevEl: navigationPrevRef.current,
              nextEl: navigationNextRef.current,
            }}
            centeredSlides={true}
            onBeforeInit={(swiper: any) => {
              swiper.params.navigation.prevEl = navigationPrevRef.current;
              swiper.params.navigation.nextEl = navigationNextRef.current;
            }}
            autoHeight={true}
          >
            {announcements.map((row: any) => (
              <SwiperSlide key={row.id}>
                <div
                  className={`relative card z-[9999] max-w-full overflow-hidden w-[800px] rounded-xl mx-auto`}
                  onClick={(e) => e.stopPropagation()}
                >
                  <button
                    type='button'
                    className='absolute top-4 right-3 w-10 h-10 flex justify-center items-center text-gray-400 border-none cursor-pointer z-[999] rounded-full bg-white'
                    onClick={onClose}
                  >
                    <CrossIcon width={20} height={20} />
                  </button>

                  <div className='relative group'>
                    <div>
                      <img
                        src={row.imageUrl}
                        alt='offers'
                        className='rounded-t-lg w-full object-cover aspect-video'
                      />
                    </div>

                    <div className='px-8 py-7'>
                      <h5 className='font-bold'>{row.heading}</h5>
                      <div className='font-normal whitespace-pre-line mt-3'>
                        {row.description}
                      </div>
                      <div className='text-black/50 text-sm mt-4'>
                        Posted on {moment(row.updatedAt).format('D MMMM, YYYY')}{' '}
                        {' at '}
                        {moment(row.updatedAt).format('h:m a')}
                      </div>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </div>
  );
};

export default AnnouncementModal;
