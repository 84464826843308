import { useState, useEffect, useCallback, useRef, RefObject } from 'react';

interface UseCtrlShiftOHoverResult {
  ref: RefObject<HTMLDivElement>;
  isCtrlShiftOHover: boolean;
}

const useCtrlShiftOHover = (): UseCtrlShiftOHoverResult => {
  const [isCtrlShiftOHover, setIsCtrlShiftOHover] = useState(false);
  const ref = useRef<HTMLDivElement>(null);

  const handleKeyDown = useCallback((event: KeyboardEvent) => {
    if (
      event.ctrlKey &&
      event.shiftKey &&
      (event.key === 'O' || event.key === 'o')
    ) {
      setIsCtrlShiftOHover(true);
    }
  }, []);

  const handleKeyUp = useCallback((event: KeyboardEvent) => {
    if (
      event.key === 'Control' ||
      event.key === 'Shift' ||
      event.key === 'O' ||
      event.key === 'o'
    ) {
      setIsCtrlShiftOHover(false);
    }
  }, []);

  const handleMouseOver = useCallback(() => {
    if (isCtrlShiftOHover) {
      console.log('Control + Shift + O + hover detected!');
      setIsCtrlShiftOHover(false); // Reset the state
    }
  }, [isCtrlShiftOHover]);

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);
    document.addEventListener('keyup', handleKeyUp);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
      document.removeEventListener('keyup', handleKeyUp);
    };
  }, [handleKeyDown, handleKeyUp]);

  useEffect(() => {
    const element = ref.current;
    if (element) {
      element.addEventListener('mouseover', handleMouseOver);
      return () => {
        element.removeEventListener('mouseover', handleMouseOver);
      };
    }
  }, [ref, handleMouseOver]);

  return { ref, isCtrlShiftOHover };
};

export default useCtrlShiftOHover;
