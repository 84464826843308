import { FC } from 'react';
import { TRootState } from 'app/store';
import { ChevronIcon } from 'app/icons';
import styles from './../Navbar.module.scss';
import { authActions } from 'app/store/auth';
import { useDispatch, useSelector } from 'react-redux';
import { formatCurrency } from 'app/utils/currency-utils';
import useAgencyBalance from 'app/hooks/use-agency-balance';

import { createSearchParams, NavLink, useNavigate } from 'react-router-dom';
import { INavLink } from '../NavBarTwo';
import Tooltip from 'app/components/Tooltip/Tooltip';
import NavLinkItem from './NavLinkItem/NavLinkItem';
import {
  Dropdown,
  DropdownItem,
  DropdownList,
} from 'app/components/Dropdown/Dropdown';
import AvatarInitial from 'app/components/AvatarInitial/AvatarInitial';

interface IDestopNavBarProps {
  navItems: INavLink[];
}

const DestopNavBar: FC<IDestopNavBarProps> = (props) => {
  const { navItems } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user } = useSelector((state: TRootState) => state.auth);
  const { creditLimit, amountDue, availableBalance } = useAgencyBalance();

  return (
    <nav
      className={`fixed bg-white w-full z-50 flex items-center px-8 h-[var(--navbar-height)] ${styles['shadow']}`}
    >
      <NavLink to={'/'}>
        {/* <img src={user.agency.logoUrl} className='h-7' alt='' /> */}
        <img
          // src='https://sub-agent-api.farepod.co/agency-logos/TC1233A-1714890449399.png'
          src={user.agency.logoUrl}
          className='h-7'
          alt=''
        />
      </NavLink>
      <ul className='ml-20 flex gap-x-10 gap-y-4 items-center'>
        {navItems.map((navItem) => (
          <NavLinkItem navItem={navItem} key={navItem.label} />
        ))}
      </ul>

      <div className='ml-auto flex justify-center items-center'>
        <div>
          <Tooltip
            content={
              <div className='px-6 py-1 w-48'>
                <ul className='divide-y divide-gray-200'>
                  <li className='py-3'>
                    <div className='text-[0.7rem] font-light text-right'>
                      Credit Limit
                    </div>
                    <h5 className='text-base font-bold text-right'>
                      {formatCurrency(creditLimit)}
                    </h5>
                  </li>
                  <li className='py-3'>
                    <div className='text-[0.7rem] font-light text-right'>
                      Amount Due
                    </div>
                    <h5 className='text-base font-bold text-right'>
                      {formatCurrency(amountDue)}
                    </h5>
                  </li>
                </ul>
              </div>
            }
            placement='bottom-end'
          >
            <div className=' text-[0.7rem] font-light text-right'>
              Available Balance
            </div>
            <h5 className='text-base font-bold text-right whitespace-nowrap '>
              {formatCurrency(availableBalance)}
            </h5>
          </Tooltip>
        </div>
        <button
          className='btn btn-primary ml-6'
          onClick={() => {
            navigate({
              pathname: '/my-agency/account',
              search: createSearchParams({
                showDialog: 'true',
              }).toString(),
            });
          }}
        >
          Make Payment
        </button>
        <Dropdown
          target={
            <div className='flex items-center gap-4 ml-8 cursor-pointer'>
              <AvatarInitial>{user.name}</AvatarInitial>
              <div className='flex flex-col items-start justify-center text-xs font-semibold'>
                <div className=''>{user.name}</div>
                <div className=' text-primary  truncate w-36  '>
                  {user.subAgency.name}
                </div>
                <div className='text-[0.7rem] font-normal leading-4 text-gray-600'>
                  {user.subAgency._id}
                </div>
              </div>
              <ChevronIcon height={6} />
            </div>
          }
          menu={
            <DropdownList>
              <DropdownItem to='/profile'>
                <span className='pr-28 '>Profile</span>
              </DropdownItem>
              <DropdownItem onClick={() => dispatch(authActions.logout())}>
                <span className='pr-28  text-red-500 '>Logout</span>
              </DropdownItem>
            </DropdownList>
          }
        />
      </div>
    </nav>
  );
};

export default DestopNavBar;
