import { useQuery } from '@tanstack/react-query';

import BgOverlay from 'app/components/BgOverlay/BgOverlay';
import PageLoader from 'app/components/PageLoader/PageLoader';
import http from 'app/config/http';
import { BackIcon } from 'app/icons';
import { ITourQuery } from 'app/types';
import { formatDate } from 'app/utils/date-utils';
import { FC } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

interface TourQueryViewProps {}

const TourQueryView: FC<TourQueryViewProps> = (props) => {
  const { id } = useParams();
  const navigate = useNavigate();

  const tourQuery = useQuery<ITourQuery>(['tour-query', id], async () => {
    const { data } = await http.get(
      `${process.env.REACT_APP_API_URL}/api/v1/sub-agent/tour-queries/${id}`,
    );
    return data;
  });

  if (tourQuery.isLoading) return <PageLoader />;

  const tourData = tourQuery.data;

  const getHotelLabel = (val: string) => {
    let label = '';
    switch (val) {
      case 'BUDGET':
        label = 'Budget';
        break;
      case 'STANDARD':
        label = 'Standard';
        break;
      case 'LUXURY':
        label = 'Luxury';
        break;
    }
    return label;
  };

  return (
    <div>
      <BgOverlay />

      <div className='container min-h-full'>
        <div className='flex py-8'>
          <div className='title text-secondary-fg flex items-center gap-3 '>
            {' '}
            <span className='md:hidden' onClick={() => navigate(-1)}>
              <BackIcon />
            </span>{' '}
            Tour Queries
          </div>
        </div>

        {/* // transaction table */}
        <div className='card py-9'>
          <div className='px-8'>
            <div className='font-bold'>Tour Details</div>
            <div className='grid grid-cols-12 gap-6 mt-8'>
              <div className='col-span-4'>
                <label className='text-xs mb-2'>Arrival Date</label>
                <div className='font-semibold py-2'>
                  {formatDate(tourData.arrivalDate, 'ddd, DD MMM YYYY')}
                </div>
              </div>
              <div className='col-span-4'>
                <label className='text-xs mb-2'>Departure Date</label>
                <div className='font-semibold py-2 flex gap-2'>
                  {formatDate(tourData.departureDate, 'ddd, DD MMM YYYY')}
                </div>
              </div>

              <div className='col-span-4'>
                <label className='text-xs mb-2'>Hotel Category</label>
                <div className='font-semibold py-2 flex gap-2'>
                  {getHotelLabel(tourData.hotelCategory)}
                </div>
              </div>
              <div className='col-span-4'>
                <label className='text-xs mb-2'>Adults</label>
                <div className='font-semibold py-2 flex gap-2'>
                  {tourData.adults}
                </div>
              </div>

              <div className='col-span-4'>
                <label className='text-xs mb-2'>Children</label>
                <div className='font-semibold py-2 flex gap-2'>
                  {tourData.children}
                </div>
              </div>

              <div className='col-span-4'>
                <label className='text-xs mb-2'>Rooms</label>
                <div className='font-semibold py-2 flex gap-2'>
                  {tourData.totalRooms}
                </div>
              </div>

              <div className='col-span-4'>
                <label className='text-xs mb-2'>Extra Beds</label>
                <div className='font-semibold py-2 flex gap-2'>
                  {tourData.extraBed}
                </div>
              </div>

              <div className='col-span-4'>
                <label className='text-xs mb-2'> Created By</label>
                <div className='font-semibold py-2 flex gap-2 '>
                  {tourData.subAgencyUser.name}
                </div>
              </div>

              <div className='col-span-4 flex flex-col'>
                <label className='text-xs mb-2'>Transport Needed</label>

                <div className='form-switch success'>
                  <input
                    id='transportNeeded'
                    type='checkbox'
                    role='switch'
                    checked={tourData.transportNeeded}
                  />
                </div>
              </div>

              <div className='col-span-12'>
                <label className='text-xs mb-2'>More Details</label>

                <textarea
                  className='form-control'
                  value={tourData.extraInformation}
                  rows={5}
                  disabled
                ></textarea>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TourQueryView;
