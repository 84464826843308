import React, { FC } from 'react';
import * as Yup from 'yup';
import { ErrorMessage, Field, Form, Formik, FormikHelpers } from 'formik';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import indiaFlag from 'assets/icons/in-flag.svg';
import { toast } from 'react-toastify';
import http from 'app/config/http';

const ProfileFormSchema = Yup.object().shape({
  email: Yup.string()
    .trim()
    .required('This field is required')
    .email('Please enter a valid email'),
  mobileNumber: Yup.string()
    .trim()
    .required('This field is required')
    .matches(/^\d+$/, 'Enter a valid mobile number'),
});

interface ProfileFormProps {
  profile: any;
}

const ProfileForm: FC<ProfileFormProps> = (props) => {
  const { profile } = props;
  const queryClient = useQueryClient();
  const profileFormInitialValues = {
    email: profile.email,
    mobileNumber: profile.phoneNumber,
  };

  const profileUpdateMutation = useMutation(async (payload: any) => {
    const { data } = await http.put(
      `${process.env.REACT_APP_API_URL}/api/v1/sub-agent/profile`,
      payload,
    );

    return data;
  });

  const onSubmit = async (values: any, formikHelpers: FormikHelpers<any>) => {
    try {
      const res = await profileUpdateMutation.mutateAsync({
        name: profile.name,
        // email: values.email,
        phoneNumber: values.mobileNumber,
      });

      queryClient.setQueryData(['profile'], () => {
        return {
          ...res,
        };
      });

      toast.success('Profile updated successfully');
    } catch (ex: any) {
      toast.error(ex?.data?.message || 'Some error occured, please try again.');
      console.log(ex);
    }
  };

  return (
    <div className='mt-10'>
      <div className='text-base font-semibold'>Personal Details</div>
      <Formik
        initialValues={profileFormInitialValues}
        validationSchema={ProfileFormSchema}
        enableReinitialize
        onSubmit={onSubmit}
      >
        <Form>
          <div className='mt-5'>
            <label className='text-xs mb-2 block' htmlFor='Email'>
              Email Address
            </label>
            <Field
              disabled
              name='email'
              type='email'
              id='Email'
              className='form-control md:w-3/4'
            />
            <div className='form-error'>
              <ErrorMessage name='email' />
            </div>
          </div>

          <div className='mt-5'>
            <label className='text-xs mb-2 block' htmlFor='ContactNum'>
              Contact Number
            </label>

            <div className='flex items-center relative'>
              <img className='absolute h-4 pl-3' src={indiaFlag} alt='' />
              <Field
                type='text'
                id='ContactNum'
                name='mobileNumber'
                placeholder='Mobile Number'
                className='form-control pl-10 md:w-3/4'
              />
            </div>

            <div className='form-error'>
              <ErrorMessage name='mobileNumber' />
            </div>
          </div>

          <button type='submit' className='btn btn-primary mt-8'>
            Save
          </button>
        </Form>
      </Formik>
    </div>
  );
};

export default ProfileForm;
