import { useQuery } from '@tanstack/react-query';
import BgOverlay from 'app/components/BgOverlay/BgOverlay';
import {
  Dropdown,
  DropdownItem,
  DropdownList,
} from 'app/components/Dropdown/Dropdown';
import PageLoader from 'app/components/PageLoader/PageLoader';
import http from 'app/config/http';
import { MenuDotIcon } from 'app/icons';
import { formatDate } from 'app/utils/date-utils';
import moment from 'moment';
import React, { FC } from 'react';
import { Link } from 'react-router-dom';

interface ITourQuery {
  adults: number;
  arrivalDate: string;
  children: number;
  createdAt: string;
  departureDate: string;
  extraBed: number;
  extraInformation: '';
  hotelCategory: string;
  id: string;
  totalRooms: number;
  transportNeeded: false;
  _id: string;
  subAgency: {
    _id: string;
    name: string;
    email: string;
    phoneNumber: string;
    logoUrl: string;
    id: string;
  };
  agency: {
    _id: string;
    name: string;
    email: string;
    phoneNumber: string;
    logoUrl: string;
    id: string;
  };
}

interface TourQueryListProps {}

const TourQueryList: FC<TourQueryListProps> = (props) => {
  const tourQuery = useQuery<ITourQuery[]>(['tourQuery'], async () => {
    const { data } = await http.get(
      `${process.env.REACT_APP_API_URL}/api/v1/sub-agent/tour-queries`,
    );
    return data;
  });

  if (tourQuery.isLoading) {
    return <PageLoader />;
  }

  const tourQueries = tourQuery.data;

  return (
    <div>
      <BgOverlay />

      <div className='container min-h-full'>
        <div className='flex py-8'>
          <div className='title text-secondary-fg'>Tour Queries</div>
        </div>

        {/* // transaction table */}
        <div className='card py-9'>
          {/* card heading  */}
          <div className='mx-9 pt-9 flex justify-between items-end'>
            <div className='text-xl font-bold'>Tour Queries</div>
            <Link
              to={'/tour-query/add'}
              className='text-sm font-semibold text-blue-500'
            >
              Add Tour Query
            </Link>
          </div>
          <div className='mt-8 pb-10 overflow-auto'>
            <table className='w-full'>
              <thead>
                <tr>
                  <td className='td th'>Tour Query Id</td>
                  <td className='td th'>Arrival Date</td>
                  <td className='td th'>Departure Date</td>
                  <td className='td th'>Adults</td>
                  <td className='td th'>Children</td>
                  <td className='td th'>Request on</td>
                  <td className='td th'></td>
                </tr>
              </thead>
              <tbody>
                {tourQueries.length === 0 && (
                  <tr>
                    <td
                      align='center'
                      colSpan={10}
                      className='py-12 font-medium text-base text-[#98A2B3]'
                    >
                      - No data available -
                    </td>
                  </tr>
                )}

                {tourQueries.map((row, i: number) => {
                  return (
                    <tr key={i}>
                      <td className='td tb'>
                        {
                          <Link
                            className='text-blue-500'
                            to={`/tour-query/${row._id}`}
                          >
                            {row._id}
                          </Link>
                        }
                      </td>
                      <td className='td tb'>
                        {formatDate(row.arrivalDate, 'DD MMM, YYYY')}
                      </td>
                      <td className='td tb'>
                        {formatDate(row.departureDate, 'DD MMM, YYYY')}
                      </td>
                      <td className='td tb'>{row.adults}</td>
                      <td className='td tb'>{row.children}</td>
                      <td className='td tb'>
                        <div className='min-w-max'>
                          <p>
                            {moment(row.createdAt, 'YYYYMMDDHHmmss').format(
                              'DD MMM, YYYY',
                            )}
                          </p>
                          <p>
                            {moment(row.createdAt, 'YYYYMMDDHHmmss').format(
                              'hh:mm A',
                            )}
                          </p>
                        </div>
                      </td>
                      <td className='td tb'>
                        <Dropdown
                          target={
                            <div className='dropdown-action'>
                              <MenuDotIcon />{' '}
                            </div>
                          }
                          menu={
                            <DropdownList>
                              <DropdownItem to={`/tour-query/${row._id}`}>
                                View Tour Query
                              </DropdownItem>
                            </DropdownList>
                          }
                        />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TourQueryList;
