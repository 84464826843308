import React, { FC } from 'react';

interface TopAirlineListProps {
  airlines: any[];
}

const TopAirlineList: FC<TopAirlineListProps> = (props) => {
  return (
    <div className='space-y-6'>
      {props.airlines.map((row, i) => (
        <div key={i}>
          <div className='flex justify-between text-sm'>
            <div>{row.name}</div>
            <div>{row.count}</div>
          </div>

          <div className='bg-gray-100 w-full h-2 rounded-full mt-3'>
            <div
              className='bg-primary h-full rounded-full'
              style={{ width: (row.count / row.totalCount) * 100 + '%' }}
            />
          </div>
        </div>
      ))}
    </div>
  );
};

export default TopAirlineList;
