import Modal from 'app/components/Modal/Modal';
import { IBank } from 'app/types';
import React, { FC } from 'react';

interface BankAccountDetailModalProps {
  show: boolean;
  onClose: () => void;
  bankAccount: IBank;
}

const BankAccountDetailModal: FC<BankAccountDetailModalProps> = (props) => {
  const { onClose, show, bankAccount } = props;

  return (
    <Modal show={show} onClose={onClose}>
      <div className='text-2xl font-bold'>Bank Account Details </div>
      <div>
        <div className='grid grid-cols-4 gap-8 mt-7 mb-6'>
          <div className='col-span-1'>
            <div className='text-xs font-normal text-gray-500'>BANK</div>
            <div className='text-lg font-semibold mt-4 '>
              {bankAccount.name}
            </div>
          </div>
          <div className='col-span-1'>
            <div className='text-xs font-normal text-gray-500'>IFSC</div>
            <div className='text-lg font-semibold mt-4 '>
              {bankAccount.ifsc}
            </div>
          </div>
          <div className='col-span-2'></div>
        </div>

        <div className='grid grid-cols-4 gap-8 mt-7 mb-6'>
          <div className='col-span-1'>
            <div className='text-xs font-normal text-gray-500'>
              ACCOUNT HOLDER
            </div>
            <div className='text-lg font-semibold mt-4 '>
              {bankAccount.account.holderName}
            </div>
          </div>
          <div className='col-span-1'>
            <div className='text-xs font-normal text-gray-500'>TYPE</div>
            <div className='text-lg font-semibold mt-4 '>
              {bankAccount.account.type}
            </div>
          </div>
          <div className='col-span-2'>
            <div className='text-xs font-normal text-gray-500'>
              ACCOUNT NUMBER
            </div>
            <div className='text-lg font-semibold mt-4 '>
              {bankAccount.account.number}
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default BankAccountDetailModal;
