import { IAirport } from 'app/types';

const fromAirportSuggestions: IAirport[] = [
  {
    code: 'SXR',
    name: 'Srinagar Arpt',
    city: 'Srinagar',
    country: 'India',
    cityCode: 'SXR',
    countryCode: 'IN',
  },
  {
    code: 'IXJ',
    name: 'Satwari Arpt',
    city: 'Jammu',
    country: 'India',
    cityCode: 'IXJ',
    countryCode: 'IN',
  },
  {
    code: 'DEL',
    name: 'Delhi Indira Gandhi Intl',
    city: 'Delhi',
    country: 'India',
    cityCode: 'DEL',
    countryCode: 'IN',
  },
  {
    code: 'ATQ',
    name: 'Raja Sansi Arpt',
    city: 'Amritsar',
    country: 'India',
    cityCode: 'ATQ',
    countryCode: 'IN',
  },
  {
    code: 'BLR',
    name: 'Bengaluru Intl Arpt',
    city: 'Bengaluru',
    country: 'India',
    cityCode: 'BLR',
    countryCode: 'IN',
  },
  {
    code: 'IXC',
    name: 'Chandigarh Arpt',
    city: 'Chandigarh',
    country: 'India',
    cityCode: 'IXC',
    countryCode: 'IN',
  },
  {
    code: 'BOM',
    name: 'Chhatrapati Shivaji',
    city: 'Mumbai',
    country: 'India',
    cityCode: 'BOM',
    countryCode: 'IN',
  },
  {
    code: 'IXL',
    name: 'Leh Kushok Bakula Rimpochee Arpt',
    city: 'Leh IN',
    country: 'India',
    cityCode: 'IXL',
    countryCode: 'IN',
  },
];

export default fromAirportSuggestions;
