const Icon = (props: any) => (
  <svg
    width='20px'
    height='14px'
    viewBox='0 0 20 14'
    version='1.1'
    xmlns='http://www.w3.org/2000/svg'
    xmlnsXlink='http://www.w3.org/1999/xlink'
    {...props}
  >
    <g id='Main' stroke='none' strokeWidth={1} fill='none' fillRule='evenodd'>
      <g
        id='Config-Request-2'
        transform='translate(-134.000000, -117.000000)'
        fill='currentColor'
        fillRule='nonzero'
      >
        <g id='Group-15' transform='translate(134.000000, 116.000000)'>
          <g id='Group-2'>
            <g
              id='icons/search-copy-2'
              transform='translate(0.000000, 1.000000)'
            >
              <path
                d='M6.67000361,0.410744349 L0.744077682,6.33667027 L0.6815392,6.40700795 C0.418374192,6.7449338 0.446132732,7.21723663 0.744077682,7.51518158 L6.67000361,13.4411075 L6.74034128,13.503646 C7.07826713,13.766811 7.55056996,13.7390525 7.84851491,13.4411075 L7.91105339,13.3707698 L7.96300054,13.2964129 C8.16887439,12.9662617 8.12517808,12.5392594 7.84851491,12.2625962 L3.34466667,7.758 L14.6666582,7.75925926 L18.7540129,7.75449746 C19.1816557,7.70510962 19.5,7.35001996 19.5,6.92592593 L19.4956976,6.84072243 C19.4530226,6.42050904 19.0981391,6.09259259 18.6666667,6.09259259 L3.34333333,6.092 L7.84849766,1.5892729 L7.91105339,1.51891797 C8.1742184,1.18099213 8.14645986,0.708689299 7.84851491,0.410744349 C7.523078,0.0853074392 6.99544052,0.0853074392 6.67000361,0.410744349 Z'
                id='Path'
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default Icon;
