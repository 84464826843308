import React, { FC, ReactNode } from 'react';

interface AsideCardProps {
  title: string;
  value: string;
  delta?: ReactNode;
}

const AsideCard: FC<AsideCardProps> = (props) => {
  return (
    <div className='card max-w-full lg:max-w-[280px] w-full border-2 border-solid border-[#ECE9E9] py-4 px-6'>
      <div className='text-sm leading-5 font-medium opacity-60 tracking-wider'>
        {props.title}
      </div>
      <div className='font-semibold text-2xl leading-10 opacity-80 mt-4'>
        {props.value}{' '}
        {props.delta && <span className='text-sm ml-1'>{props.delta}</span>}
      </div>
    </div>
  );
};

export default AsideCard;
