const Icon = (props: any) => (
  <svg
    width={18}
    height={18}
    viewBox='0 0 18 18'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <g clipPath='url(#clip0_2786_1612)'>
      <path
        d='M3 13.5v2.25h2.25V13.5h7.5v2.25H15v-4.5H3v2.25zm11.25-6h2.25v2.25h-2.25V7.5zM1.5 7.5h2.25v2.25H1.5V7.5zm11.25 2.25h-7.5v-6c0-.825.675-1.5 1.5-1.5h4.5c.825 0 1.5.675 1.5 1.5v6z'
        fill='#C4C4C4'
      />
    </g>
    <defs>
      <clipPath id='clip0_2786_1612'>
        <path fill='#fff' d='M0 0H18V18H0z' />
      </clipPath>
    </defs>
  </svg>
);

export default Icon;
