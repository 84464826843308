import { FC } from 'react';
import { IBank } from 'app/types';
import http from 'app/config/http';
import useNavTab from 'app/hooks/use-nav-tab';
import { useQuery } from '@tanstack/react-query';
import BgOverlay from 'app/components/BgOverlay/BgOverlay';
import EditAgency from './components/EditAgency/EditAgency';
import PageLoader from 'app/components/PageLoader/PageLoader';
import EditDetails from './components/EditDetails/EditDetails';
import AgencyUsers from './components/AgencyUsers/AgencyUsers';
import MobileTopBar from 'app/components/MobileTopBar/MobileTopBar';
import BankAccountList from './components/BankAccountList/BankAccountList';
import ProfileNav from 'app/pages/Profile/components/ProfileNav/ProfileNav';

interface ProfileProps {}

const Profile: FC<ProfileProps> = (props) => {
  const [activeTab, setActiveTab] = useNavTab('1');

  const profileQuery = useQuery(['profile'], async () => {
    const { data } = await http.get(
      `${process.env.REACT_APP_API_URL}/api/v1/sub-agent/profile`,
    );

    return data;
  });

  const subAgencyQuery = useQuery(['subAgency'], async () => {
    const { data } = await http.get(
      `${process.env.REACT_APP_API_URL}/api/v1/sub-agent/sub-agency`,
    );

    return data;
  });

  const subAgencyBankQuery = useQuery<IBank[]>(
    ['subAgency', 'bankList'],
    async () => {
      const { data } = await http.get(
        `${process.env.REACT_APP_API_URL}/api/v1/sub-agent/sub-agency/banks`,
      );

      return data;
    },
  );

  if (
    profileQuery.isLoading ||
    subAgencyQuery.isLoading ||
    subAgencyBankQuery.isLoading
  )
    return <PageLoader />;

  return (
    <div>
      <MobileTopBar />
      <BgOverlay />

      <div className='container min-h-full'>
        <div className='flex justify-between py-8'>
          <div className='title text-secondary-fg'>Profile</div>
        </div>

        <div>
          <div className='grid grid-cols-2 gap-5'>
            <div className='col-span-2 lg:col-span-1'>
              <div className='card p-9'>
                <ProfileNav activeTab={activeTab} setActiveTab={setActiveTab} />

                <div className='mt-11'>
                  {activeTab === '1' && (
                    <EditDetails profile={profileQuery.data} />
                  )}

                  {activeTab === '2' && (
                    <EditAgency profile={subAgencyQuery.data} />
                  )}

                  {activeTab === '3' && (
                    <BankAccountList accounts={subAgencyBankQuery.data} />
                  )}
                </div>
              </div>
            </div>

            <div className='hidden sm:block col-span-2 lg:col-span-1'>
              <div className='card p-9 h-full'>
                <AgencyUsers />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
