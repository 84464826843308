import useBoundingClientRect from 'app/hooks/use-bounding-client-rect';
import { FC, useState } from 'react';
import AmendmentNav from '../AmendmentNav/AmendmentNav';
import RequestConversation from '../RequestConversation/RequestConversation';
import RequestTable from '../RequestTable/RequestTable';

interface AmendmentProps {
  id: string;
  passengers: any[];
  onClose: () => void;
}

const Amendment: FC<AmendmentProps> = (props) => {
  const { id } = props;
  const [activeTab, setActiveTab] = useState(2);
  const [requestIds, setRequestIds] = useState([]);

  const [navRefSetter, navBoundingClientRect] = useBoundingClientRect();

  const handleAddNewTab = (id: string) => {
    if (requestIds.some((oldIds) => id === oldIds)) {
      let tempActive = requestIds.findIndex((existingId) => existingId === id);
      setActiveTab(3 + tempActive);
      return;
    }

    setRequestIds((oldIds) => {
      return [...oldIds, id];
    });
    setActiveTab(() => {
      return 3 + requestIds.length;
    });
  };

  const handleCloseTab = (indexRec: number) => {
    let removeIndex = requestIds.findIndex((_, index) => index === indexRec);
    setRequestIds((oldIds) => {
      const idsCopy = [...oldIds];
      idsCopy.splice(removeIndex, 1);
      return idsCopy;
    });
    setActiveTab(() => 2);
  };

  return (
    <>
      <div ref={navRefSetter}>
        <AmendmentNav
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          requestIds={requestIds}
          closeTab={handleCloseTab}
        />
      </div>

      {/* {activeTab === 1 && <NewRequest passengers={passengers} id={id} onClose={props.onClose} />} */}
      {activeTab === 2 && (
        <div className='mt-5'>
          <RequestTable bookingId={id} addTab={handleAddNewTab} />
        </div>
      )}

      {activeTab > 2 && (
        <div
          className='mt-5'
          style={{
            height: `calc(100vh - ${navBoundingClientRect.top + navBoundingClientRect.height}px - 3rem)`,
          }}
        >
          <RequestConversation
            bookingId={id}
            ammendmentId={requestIds[activeTab - 3]}
          />
        </div>
      )}
    </>
  );
};

export default Amendment;
