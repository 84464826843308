const currencyFormatter = new Intl.NumberFormat('en-US', {
  currency: 'INR',
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

const currencyFormatterWithOutDecimal = new Intl.NumberFormat('en-US', {
  currency: 'INR',
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
});

export const formatCurrency = (number: number) => {
  return '₹ ' + currencyFormatter.format(Math.abs(number));
};

export const formatCurrencyWithOutDecimal = (number: number) => {
  return '₹ ' + currencyFormatterWithOutDecimal.format(Math.abs(number));
};
