import { FC, useEffect } from 'react';
import Modal from '../Modal/Modal';
import Spinner from '../Spinner/Spinner';
import LoaderButton from '../LoaderButton/LoaderButton';
import { formatCurrency } from 'app/utils/currency-utils';
import { GatewayPaymentModeEnum } from 'app/enums/gateway-payment-mode.enum';
import useOnlineGatewayandMethod from './useOnlineGatewayandMethod';
import { IGateWay } from './types/gateway-with-charges';

interface IOnlinePaymentGatewayAndMethodSelectionDialog {
  show: boolean;
  baseAmount: number;
  onClose: () => void;
  onSelection: (selectedGateway: IGateWay) => void;
  isLoading: boolean;
}

const getGatewayPaymentModeEnumLabel = (
  onlinePaymentMode: GatewayPaymentModeEnum,
): string => {
  let label: string;

  switch (onlinePaymentMode) {
    case GatewayPaymentModeEnum.CREDIT_CARD:
      label = 'Credit Card';
      break;
    case GatewayPaymentModeEnum.DEBIT_CARD:
      label = 'Debit Card';
      break;
    case GatewayPaymentModeEnum.NET_BANKING:
      label = 'Net Banking';
      break;
    case GatewayPaymentModeEnum.UPI:
      label = 'UPI';
      break;
  }
  return label;
};

const OnlinePaymentGatewayAndMethodSelectionDialog: FC<
  IOnlinePaymentGatewayAndMethodSelectionDialog
> = (props) => {
  const {
    show,
    onClose,
    baseAmount,
    onSelection,
    isLoading: postSelectionLoading,
  } = props;

  const {
    data,
    isLoading,
    activeTab,
    selectedGateway,
    setActiveTab,
    setSelectedGateway,
    setTcAccepted,
    tcAccepted,
  } = useOnlineGatewayandMethod(baseAmount);

  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, []);

  if (isLoading)
    return (
      <Modal show={show} onClose={onClose} className='w-[520px]'>
        <Spinner />
      </Modal>
    );

  const onlinePaymentData = data;

  return (
    <Modal
      show={show}
      onClose={onClose}
      className='w-[520px] max-w-full pb-2 overflow-auto !h-full'
    >
      <div className='my-2 '>
        <div className='grid grid-cols-1 lg:grid-cols-12  lg:min-h-[30rem] gap-2'>
          <div className='col-span-1  lg:col-span-3'>
            <ul className='hidden lg:block'>
              {onlinePaymentData.map((paymentOption, index) => (
                <li key={paymentOption.paymentMode}>
                  <button
                    onClick={() => setActiveTab(index)}
                    className={`w-full h-full text-start  px-4 py-4 text-base font-semibold shadow-xs mb-1 hover:bg-secondary/30 disabled:bg-slate-200                      
                    ${activeTab === index && 'border-l-4 border-secondary pl-3 font-bold hover:bg-transparent'}`}
                    disabled={paymentOption.gateways.length === 0}
                  >
                    {getGatewayPaymentModeEnumLabel(paymentOption.paymentMode)}
                  </button>
                </li>
              ))}
            </ul>
            <div className='lg:hidden'>
              <select
                className=' form-field border border-gray-300 rounded-md !text-sm !pl-4 !pr-8 !py-2 !outline-none font-medium min-w-[200px]'
                onChange={(e) => setActiveTab(+e.target.value)}
              >
                {onlinePaymentData.map((paymentOption, index) => (
                  <option
                    key={paymentOption.paymentMode}
                    disabled={paymentOption.gateways.length === 0}
                    value={index}
                  >
                    {getGatewayPaymentModeEnumLabel(paymentOption.paymentMode)}
                  </option>
                ))}
              </select>
            </div>
          </div>

          <div className='col-span-1  lg:col-span-9  flex flex-col justify-between  min-h-[30rem]  '>
            <div className='overflow-auto'>
              <div className=' text-xs lg:text-2xl font-bold'>
                Please Select Preferred Option to Proceed
              </div>
              <div className='grid  grid-cols-1 lg:grid-cols-3 gap-4 p-2 mt-2      '>
                {onlinePaymentData[activeTab].gateways.map((gateway) => (
                  <div
                    key={gateway.name}
                    className={`
                      border-2 px-2 py-2  flex gap-2 items-center shadow-lg rounded-lg   
                     ${selectedGateway?.token === gateway.token ? 'border-primary' : 'border-primary/10'}`}
                    onClick={() => setSelectedGateway(gateway)}
                  >
                    <input
                      type='radio'
                      name='method'
                      className='accent-primary w-6 border-2'
                      checked={selectedGateway?.token === gateway.token}
                    />
                    <img
                      src={gateway.logoUrl}
                      alt={gateway.name}
                      className='w-36 my-auto '
                    />
                  </div>
                ))}
              </div>
            </div>
            <div className='  flex flex-col items-center  mt-auto '>
              {selectedGateway && (
                <>
                  <div className='text-xs font-normal'>
                    Please note: You may be redirected to bank page to complete
                    your transaction. By making this payment. You agree to our
                    Terms of Use and Privacy Policy
                  </div>
                  <div className=' mt-8 text-center inline-block '>
                    {
                      <div className='text-xs font-semibold mb-1'>
                        Adding {formatCurrency(selectedGateway.baseAmount)}
                        {' | '} Payment Fee{' '}
                        {` ${formatCurrency(selectedGateway?.gatewayCharges)}`}
                      </div>
                    }
                    <LoaderButton
                      type='button'
                      className='btn btn-primary min-w-[18rem] '
                      isLoading={postSelectionLoading}
                      disabled={!tcAccepted || postSelectionLoading}
                      onClick={() => onSelection(selectedGateway)}
                    >
                      Pay {formatCurrency(selectedGateway.totalOrderAmount)}
                    </LoaderButton>

                    <div className='flex items-center mt-2 gap-2'>
                      <input
                        className='form-checkbox'
                        type='checkbox'
                        id='saveCustomer'
                        disabled={postSelectionLoading || isLoading}
                        checked={tcAccepted}
                        onChange={(e) => setTcAccepted(e.target.checked)}
                      />
                      <label className='text-xs' htmlFor='saveCustomer'>
                        I accept terms & conditions
                      </label>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default OnlinePaymentGatewayAndMethodSelectionDialog;
