import http from 'app/config/http';
import { TRootState } from 'app/store';
import React, { FC, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useRouteError } from 'react-router-dom';

interface ErrorBoundaryProps {}

const ErrorBoundary: FC<ErrorBoundaryProps> = (props) => {
  const error: any = useRouteError();
  const navigate = useNavigate();

  const { user } = useSelector((state: TRootState) => state.auth);

  const logo = user ? user.agency.logoUrl : '';
  const email = user?.agency.email;

  useEffect(() => {
    if (process.env.NODE_ENV === 'development') {
      // Log error to console
      console.log(error);
    } else {
      // Log error to server
      http
        .post(`${process.env.REACT_APP_API_URL}/api/v1/sub-agent/errors`, {
          message: error.message,
          stack: error.stack,
          path: window.location.pathname,
          platform: 'WEB',
        })
        .catch(() => {});
    }
  }, [error]);

  const goBack = () => {
    navigate('/');
  };

  return (
    <div className='flex flex-col items-center justify-center h-[calc(100vh-var(--navbar-height))]'>
      <img className='h-10' src={logo} alt='' />
      <h3 className='mt-8'>Oops, something went wrong</h3>
      <p className='mt-6'>
        An error occurred while processing your request. Please try again.
        <br />
        {email ? (
          <>
            If this continues, please email us at{' '}
            <a className='text-blue-600' href={`mailto:${email}`}>
              {email}
            </a>
          </>
        ) : (
          ''
        )}
      </p>
      <button className='text-primary font-medium mt-8' onClick={goBack}>
        Go Back Home
      </button>
    </div>
  );
};

export default ErrorBoundary;
