// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Tooltip_shadow__JHipR {\n  filter: drop-shadow(2px 4px 6px rgba(0, 0, 0, 0.09)) drop-shadow(-2px -4px 6px rgba(0, 0, 0, 0.09));\n}", "",{"version":3,"sources":["webpack://./src/app/components/Tooltip/Tooltip.module.scss"],"names":[],"mappings":"AAAA;EACE,mGAAA;AACF","sourcesContent":[".shadow {\n  filter: drop-shadow(2px 4px 6px rgba(0, 0, 0, 0.09))\n    drop-shadow(-2px -4px 6px rgba(0, 0, 0, 0.09));\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"shadow": "Tooltip_shadow__JHipR"
};
export default ___CSS_LOADER_EXPORT___;
