import { ButtonHTMLAttributes, FC } from 'react';
import Spinner from '../Spinner/Spinner';

interface LoaderButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  isLoading?: boolean;
  spinnerColor?: string;
  disabled?: boolean;
}

const LoaderButton: FC<LoaderButtonProps> = ({
  children,
  className,
  isLoading,
  spinnerColor,
  disabled,
  ...rest
}) => {
  return (
    <button
      {...rest}
      disabled={disabled}
      className={`flex justify-center items-center h-12 px-4 ${className}`}
    >
      {isLoading ? <Spinner color={spinnerColor} size={20} /> : children}
    </button>
  );
};

LoaderButton.defaultProps = {
  isLoading: false,
  spinnerColor: '#fff',
  disabled: false,
};

export default LoaderButton;
