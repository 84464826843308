import { FC } from 'react';
import { useNavigate } from 'react-router-dom';

interface ErrorPageProps {}

const ErrorPage: FC<ErrorPageProps> = (props) => {
  const navigate = useNavigate();

  const goToFlightsPage = () => {
    navigate(-1);
  };

  return (
    <div className='w-full h-[calc(100vh-var(--navbar-height)-var(--footer-height)-10px)]'>
      <div className='flex flex-col items-center justify-center w-full h-full text-center'>
        <h5 className='leading-normal mx-48'>
          {/* Something went wrong. <br /> */}
          Something went wrong while confirming your ticket with the airline.{' '}
          <br /> This might be due to non-availability of the flight or the fare
          has changed. Please try again after sometime.
        </h5>

        <button
          onClick={goToFlightsPage}
          className='btn btn-primary px-6 py-3 mt-8'
        >
          Back to flights
        </button>
      </div>
    </div>
  );
};

export default ErrorPage;
