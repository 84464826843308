import { useState, useEffect, Dispatch, SetStateAction } from 'react';
import { useSearchParams } from 'react-router-dom';

interface NavTabOptions {
  queryName?: string;
}

const navTabOptionsDefault = {
  queryName: 'tab',
};

const useNavTab = (
  initialTab: string,
  options: NavTabOptions = navTabOptionsDefault,
): [string, Dispatch<SetStateAction<string>>] => {
  const { queryName = 'tab' } = options;

  const [searchParams, setSearchParams] = useSearchParams();
  const active = searchParams.has(queryName)
    ? searchParams.get(queryName)
    : initialTab;

  const [activeTab, setActiveTab] = useState<string>(active);

  useEffect(() => {
    searchParams.set(queryName, activeTab);
    setSearchParams(searchParams, {
      replace: true,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab]);

  return [activeTab, setActiveTab];
};

export default useNavTab;
