import { FC } from 'react';
import styles from './HamburgerMenu.module.scss';

interface HamburgerMenuProps {
  isOpen: boolean;
  toggleOpen: () => void;
}

const HamburgerMenu: FC<HamburgerMenuProps> = (props) => {
  const { isOpen, toggleOpen } = props;

  return (
    <div
      className={[styles['hamburger-menu'], isOpen ? styles['open'] : ''].join(
        ' ',
      )}
      onClick={toggleOpen}
    >
      <span></span>
      <span></span>
      <span></span>
      <span></span>
    </div>
  );
};

export default HamburgerMenu;
