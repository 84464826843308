import { FC } from 'react';
import { IFlightPriceDetail } from 'app/types';

interface IBaggageInformation {
  selectedPriceItem: IFlightPriceDetail;
}

const BaggageInformation: FC<IBaggageInformation> = (props) => {
  const { selectedPriceItem } = props;

  const renderBaggage = (key: 'checkIn' | 'cabin') => {
    let str = `Adult: ${selectedPriceItem.fareDescription.adult.baggage[key]}`;

    if (selectedPriceItem.fareDescription.child)
      str += `, Child: ${selectedPriceItem.fareDescription.child.baggage[key]}`;

    if (selectedPriceItem.fareDescription.infant)
      str += `, Child: ${selectedPriceItem.fareDescription.infant.baggage[key]}`;

    return str;
  };

  return (
    <table className='w-full'>
      <thead>
        <tr>
          <th className='px-4 py-2 text-left'>Check-in</th>
          <th className='px-4 py-2 text-left'>Cabin</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td className='px-4 py-2 text-gray-500'>
            {renderBaggage('checkIn')}
          </td>
          <td className='px-4 py-2 text-gray-500'>{renderBaggage('cabin')}</td>
        </tr>
      </tbody>
    </table>
  );
};

export default BaggageInformation;
