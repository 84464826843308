import { useQuery } from '@tanstack/react-query';
import PageLoader from 'app/components/PageLoader/PageLoader';
import http from 'app/config/http';
import PageNotFound from 'app/pages/PageNotFound/PageNotFound';
import React, { FC } from 'react';

interface PluginGuardProps {
  pluginId: string;
  page: any;
}

const PluginGuard: FC<PluginGuardProps> = (props) => {
  const navItemsQuery = useQuery(['plugin'], async () => {
    const { data } = await http.get(
      `${process.env.REACT_APP_API_URL}/api/v1/sub-agent/plugins/nav-items`,
    );
    return data;
  });

  if (navItemsQuery.isLoading) return <PageLoader />;
  const activePlugins = navItemsQuery.data;

  if (activePlugins.find((plugin: any) => plugin._id === props.pluginId))
    return props.page;

  return <PageNotFound />;
};

export default PluginGuard;
