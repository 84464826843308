import React, { FC, useState } from 'react';
import moment from 'moment';

const txDate = (date: string) => {
  if (!date) return '';

  return moment(date, 'YYYY-MM-DD').format('YYYYMMDD');
};

interface TableDateFilterProps {
  onChange?: (date1: string, date2: string) => void;
}

const TableDateFilter: FC<TableDateFilterProps> = (props) => {
  const { onChange } = props;

  const [date1, setDate1] = useState<string>('');
  const [date2, setDate2] = useState<string>('');

  const handleDate1Change = (e: any) => {
    const val = e.target.value;
    setDate1(val);

    onChange?.(txDate(val), txDate(date2));
  };

  const handleDate2Change = (e: any) => {
    const val = e.target.value;
    setDate2(val);

    onChange?.(txDate(date1), txDate(val));
  };

  return (
    <div className='flex flex-col sm:flex-row  w-full sm:w-auto  sm:items-center gap-4'>
      <label className='text-xs text-slate-500  whitespace-nowrap' htmlFor=''>
        FILTER BY DATE
      </label>
      {/* <div className='flex  items-center gap-4'> */}
      <input
        type='date'
        className=' form-field !w-auto border border-gray-300 rounded-md text-sm px-4 py-2 outline-none font-medium'
        value={date1}
        onChange={handleDate1Change}
      />

      <label className='text-xs' htmlFor=''>
        TO
      </label>
      <input
        type='date'
        className='form-field !w-auto border border-gray-300 rounded-md text-sm px-4 py-2 outline-none font-medium'
        value={date2}
        onChange={handleDate2Change}
      />
      {/* </div> */}
    </div>
  );
};

export default TableDateFilter;
