import { BackIcon } from 'app/icons';
import { useNavigate } from 'react-router-dom';
import BgOverlay from 'app/components/BgOverlay/BgOverlay';
import AddPassengerForm from './components/AddPassengerForm';

const AddPassenger = () => {
  const navigate = useNavigate();

  return (
    <>
      {' '}
      <BgOverlay />
      <div className='container min-h-full'>
        <div className='flex py-8'>
          <div className='title text-secondary-fg flex items-center gap-3'>
            <span className='md:hidden' onClick={() => navigate(-1)}>
              <BackIcon />
            </span>{' '}
            Add Passenger
          </div>
        </div>
        <div className='card p-9 mb-4'>
          <AddPassengerForm />
        </div>
      </div>
    </>
  );
};

export default AddPassenger;
