import { useEffect } from 'react';
const useBackButtonWithModal = (show: boolean, onClose: () => void) => {
  useEffect(() => {
    if (show) {
      window.history.pushState('backPressed', null, null);
      window.history.pushState('dummy', null, null);
      window.addEventListener('popstate', hideModal, { once: true });
    }

    return () => {
      window.removeEventListener('popstate', hideModal);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);

  const hideModal = (event: PopStateEvent) => {
    if (event.state === 'backPressed') {
      window.history.back();
      onClose();
    }
  };

  const closeModal = () => {
    window.history.back();
    onClose();
  };

  return closeModal;
};

export default useBackButtonWithModal;
