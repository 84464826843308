import { useEffect } from 'react';
import Lottie from 'lottie-react';
import { Navigate, useLocation } from 'react-router-dom';
import redirectLoaderLottie from 'assets/lotties/redirect-loader.json';

const Redirection = () => {
  const location = useLocation();

  useEffect(() => {
    if (location.state.externalUrl) {
      console.log({ x: location.state.externalUrl });
      window.location.replace(location.state.externalUrl);
    }
  }, []);

  if (!location.state?.externalUrl)
    return <Navigate to={'/404'} replace={true} />;

  return (
    <div className='bg-gray-200 h-screen w-screen flex flex-col justify-center items-center gap-2'>
      <div className='w-36 h-36 '>
        <Lottie
          animationData={redirectLoaderLottie}
          autoPlay={true}
          loop={true}
        />
      </div>

      <h3>Redirecting</h3>
      <p className='text-base font-medium'>
        Please wait a while you are being redirected
      </p>
    </div>
  );
};

export default Redirection;
