import * as Yup from 'yup';
import moment from 'moment';
import http from 'app/config/http';
import { FC, useMemo } from 'react';
import contryList from 'country-list';
import { toast } from 'react-toastify';
import { CalendarIcon } from 'app/icons';
import capitalize from 'lodash.capitalize';
import { useNavigate } from 'react-router-dom';
import { useMutation } from '@tanstack/react-query';
import { DatePicker } from 'app/library/datepicker';

import { PassengerEnum } from 'app/enums/passenger-enum';
import { titles } from 'app/modules/shared/passenger-title';
import LoaderButton from 'app/components/LoaderButton/LoaderButton';
import { ErrorMessage, Field, Form, Formik, FormikProps } from 'formik';
import {
  IPassenger,
  TSavePassenger,
} from 'app/modules/SavedPassenger/types/Passenger';

const SubAgentFormSchema = Yup.object({
  paxType: Yup.string().trim().required('This field is required'),
  title: Yup.string().trim().required('This field is required'),
  firstName: Yup.string().trim().required('This field is required'),
  lastName: Yup.string().trim().required('This field is required'),
  dob: Yup.string().trim().required('This field is required'),
});

interface IEditPassengerForm {
  passenger: IPassenger;
}

const EditPassengerForm: FC<IEditPassengerForm> = (props) => {
  const { passenger } = props;
  const navigate = useNavigate();
  const nations = useMemo(() => contryList.getNameList(), []);

  const initialValues: TSavePassenger = {
    firstName: passenger.firstName,
    lastName: passenger.lastName,
    dob: passenger.dob,
    title: passenger.title,
    passport: {
      expiry: passenger.passport.expiry,
      issueDate: passenger.passport.issueDate,
      nationality:
        passenger.passport.nationality && passenger.passport.nationality !== ''
          ? passenger.passport.nationality
          : 'IN',
      number: passenger.passport.number,
    },
    paxType: passenger.paxType,
    // ...passenger,
  };

  const mutation = useMutation(async (payload: any) => {
    const { data } = await http.put(
      `${process.env.REACT_APP_API_URL}/api/v1/sub-agent/customers/${passenger._id}`,
      payload,
    );
    return data;
  });

  const handleSubmit = async (values: TSavePassenger) => {
    try {
      await mutation.mutateAsync(values);
      toast.success('Passenger added successfully.');
      navigate(`/my-agency/Passengers`);
    } catch (ex: any) {
      console.log(ex);
      toast.error(ex?.data?.message || 'Some error occured, please try again.');
    }
  };

  const paxTypeToTitleConverter = (paxType: PassengerEnum) => {
    if (paxType === PassengerEnum.ADULT) return 'adults';
    if (paxType === PassengerEnum.CHILD) return 'children';
    if (paxType === PassengerEnum.INFANT) return 'infants';
  };

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize
      validationSchema={SubAgentFormSchema}
      onSubmit={handleSubmit}
    >
      {({ values, setFieldValue }: FormikProps<TSavePassenger>) => (
        <Form>
          <button type='button' onClick={() => console.log({ values })}>
            Form Data
          </button>
          <div className='grid grid-cols-12 gap-6 mt-8'>
            <div className=' col-span-12  md:col-span-2'>
              <label className='text-xs mb-2 label-required' htmlFor='paxType'>
                Passenger Type
              </label>
              <Field
                as='select'
                name='paxType'
                type='text'
                id='paxType'
                className='form-control'
                onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                  if (e.target.value === values.paxType) return;

                  setFieldValue('paxType', e.target.value);
                  setFieldValue(
                    'title',
                    titles[
                      paxTypeToTitleConverter(e.target.value as PassengerEnum)
                    ][0],
                  );
                }}
              >
                {Object.values(PassengerEnum).map((status) => (
                  <option key={status} value={status}>
                    {capitalize(status)}
                  </option>
                ))}
              </Field>
              <div className='form-error'>
                <ErrorMessage name='paxType' />
              </div>
            </div>

            <div className=' col-span-12  md:col-span-2'>
              <label className='text-xs mb-2 label-required' htmlFor='title'>
                Title
              </label>
              <Field
                as='select'
                name='title'
                type='text'
                id='title'
                className='form-control'
              >
                {Object.values(
                  titles[paxTypeToTitleConverter(values.paxType)],
                ).map((status) => (
                  <option key={status} value={status}>
                    {capitalize(status)}
                  </option>
                ))}
              </Field>
              <div className='form-error'>
                <ErrorMessage name='title' />
              </div>
            </div>

            <div className=' col-span-12  md:col-span-4'>
              <label
                className='text-xs mb-2 label-required '
                htmlFor='firstName'
              >
                First Name
              </label>
              <Field
                type='text'
                id='firstName'
                name='firstName'
                className='form-control'
              />
              <div className='form-error'>
                <ErrorMessage name='firstName' />
              </div>
            </div>
            <div className=' col-span-12  md:col-span-4'>
              <label className='text-xs mb-2 label-required' htmlFor='lastName'>
                Last Name
              </label>
              <Field
                type='text'
                id='lastName'
                name='lastName'
                className='form-control'
              />
              <div className='form-error'>
                <ErrorMessage name='lastName' />
              </div>
            </div>

            <div className=' col-span-12  md:col-span-4'>
              <label htmlFor='dob' className='text-xs mb-2 label-required '>
                Date Of Birth
              </label>
              <div className='relative flex items-center'>
                <div className='absolute left-4 z-10'>
                  <CalendarIcon />
                </div>
                <DatePicker
                  format='DD MM YYYY'
                  className='form-control pl-12 cursor-pointer  z-20!'
                  value={
                    values.dob === ''
                      ? null
                      : moment(values.dob, 'YYYYMMDD').toDate()
                  }
                  onChange={(val) => {
                    setFieldValue('dob', moment(val).format('YYYYMMDD'));
                  }}
                  maxDate={moment().toDate()}
                  placeholder='Date of Birth'
                />
              </div>
              <div className='form-error'>
                <ErrorMessage name={`dob`} />
              </div>
            </div>
          </div>
          <div className='grid grid-cols-12 gap-6 mt-8'>
            <div className=' col-span-12  md:col-span-4'>
              <label className='text-xs mb-2' htmlFor='passport.number'>
                Passport Number
              </label>
              <Field
                type='text'
                id='passport.number'
                name='passport.number'
                className='form-control'
              />
              <div className='form-error'>
                <ErrorMessage name='passport.number' />
              </div>
            </div>
            <div className=' col-span-12  md:col-span-4'>
              <label className='text-xs mb-2' htmlFor='passport.nationality'>
                Nationality
              </label>
              <Field
                as='select'
                name='passport.nationality'
                type='text'
                id='passport.nationality'
                className='form-control'
              >
                {Object.keys(nations).map((val) => (
                  <option key={val} value={nations[val]}>
                    {val.charAt(0).toUpperCase() + val.slice(1)}
                  </option>
                ))}
              </Field>
              <div className='form-error'>
                <ErrorMessage name='passport.nationality' />
              </div>
            </div>

            <div className=' col-span-12  md:col-span-4'>
              <label htmlFor='passport.issueDate' className='text-xs mb-2'>
                Passport Issue Date
              </label>
              <div className='relative flex items-center'>
                <div className='absolute left-4 z-10'>
                  <CalendarIcon />
                </div>
                <DatePicker
                  format='DD MM YYYY'
                  className='form-control pl-12 cursor-pointer  z-20!'
                  value={
                    values.passport.issueDate === ''
                      ? null
                      : moment(values.passport.issueDate, 'YYYYMMDD').toDate()
                  }
                  onChange={(val) => {
                    setFieldValue(
                      'passport.issueDate',
                      moment(val).format('YYYYMMDD'),
                    );
                  }}
                  maxDate={moment().toDate()}
                  placeholder='passport.issueDate'
                />
              </div>
              <div className='form-error'>
                <ErrorMessage name={`passport.issueDate`} />
              </div>
            </div>

            <div className=' col-span-12  md:col-span-4'>
              <label htmlFor='passport.expiry' className='text-xs mb-2'>
                Passport Expiry Date
              </label>
              <div className='relative flex items-center'>
                <div className='absolute left-4 z-10'>
                  <CalendarIcon />
                </div>
                <DatePicker
                  format='DD MM YYYY'
                  className='form-control pl-12 cursor-pointer  z-20!'
                  value={
                    values.passport.expiry === ''
                      ? null
                      : moment(values.passport.expiry, 'YYYYMMDD').toDate()
                  }
                  onChange={(val) => {
                    setFieldValue(
                      'passport.expiry',
                      moment(val).format('YYYYMMDD'),
                    );
                  }}
                  placeholder='passport.expiry'
                />
              </div>
              <div className='form-error'>
                <ErrorMessage name={`passport.expiry`} />
              </div>
            </div>
          </div>

          <LoaderButton
            type='submit'
            className='bg-primary text-white text-sm font-semibold rounded-lg w-full md:w-56 mt-12'
            isLoading={mutation.isLoading}
            disabled={mutation.isLoading}
          >
            Save Passenger
          </LoaderButton>
        </Form>
      )}
    </Formik>
  );
};

export default EditPassengerForm;
