const Icon = (props: any) => {
  return (
    <svg
      height={12}
      viewBox='0 0 8 5'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M7 1L4 4 1 1'
        stroke='#98A2B3'
        strokeWidth={2}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default Icon;
