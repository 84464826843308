import * as React from 'react';

const Icon = (props: any) => {
  return (
    <svg
      width={12}
      height={8}
      viewBox='0 0 12 8'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M10.727 6.026l-4.28-4.905a.338.338 0 00-.111-.09.298.298 0 00-.266 0 .338.338 0 00-.11.09L1.68 6.026'
        stroke='#000'
        strokeWidth={2}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default Icon;
