import * as React from 'react';

const Icon = (props: any) => {
  return (
    <svg
      width={50}
      height={32}
      viewBox='0 0 50 32'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <g clipPath='url(#clip0_164_2569)'>
        <rect
          x={0.5}
          y={0.499985}
          width={49}
          height={31}
          rx={2.5}
          fill='#fff'
          stroke='#E7EAEE'
        />
        <path
          d='M22.466 21.535h-3.078L21.314 10.5h3.078l-1.926 11.035zM33.626 10.77a8.113 8.113 0 00-2.761-.47c-3.04 0-5.181 1.503-5.194 3.65-.025 1.586 1.533 2.466 2.698 2.995 1.19.54 1.596.892 1.596 1.374-.013.739-.963 1.08-1.85 1.08-1.228 0-1.887-.176-2.887-.587l-.406-.177-.43 2.478c.722.305 2.052.575 3.433.587 3.23 0 5.332-1.48 5.357-3.769.013-1.256-.81-2.219-2.584-3.005-1.076-.505-1.735-.846-1.735-1.362.012-.47.557-.95 1.773-.95 1-.025 1.735.198 2.293.422l.278.117.419-2.383zM37.717 17.626l1.229-3.088c-.013.024.253-.645.405-1.056l.215.95s.583 2.642.71 3.194h-2.56zm3.8-7.126h-2.382c-.734 0-1.292.2-1.608.915l-4.573 10.12h3.23l.646-1.655h3.952c.089.387.368 1.655.368 1.655H44L41.516 10.5zM16.817 10.5l-3.014 7.525-.33-1.526c-.557-1.761-2.305-3.674-4.256-4.626l2.762 9.65h3.255L20.072 10.5h-3.255z'
          fill='#00579F'
        />
        <path
          d='M11.003 10.5H6.051L6 10.723c3.863.916 6.422 3.123 7.473 5.776l-1.076-5.071c-.178-.705-.722-.905-1.394-.928z'
          fill='#FAA61A'
        />
      </g>
      <defs>
        <clipPath id='clip0_164_2569'>
          <path fill='#fff' d='M0 0H50V32H0z' />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Icon;
