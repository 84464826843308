import { TRootState } from 'app/store';
import { useSelector } from 'react-redux';

const useAgencyBalance = () => {
  const { creditLimit, availableCredit, availableCashBalance } = useSelector(
    (state: TRootState) => state.account,
  );

  let availableBalance = availableCredit + availableCashBalance;

  let amountDue = creditLimit - availableCredit - availableCashBalance;

  if (availableBalance < 0) {
    availableBalance = 0;
  }

  if (amountDue < 0) {
    amountDue = 0;
  }

  return {
    creditLimit,
    availableCredit,
    availableCashBalance,
    availableBalance,
    amountDue,
  };
};

export default useAgencyBalance;
