import http from 'app/config/http';
import { IAirport } from 'app/types';
import debounce from 'lodash.debounce';
import Autosuggest from 'react-autosuggest';
import { useQuery } from '@tanstack/react-query';
import React, { useState, useCallback, useEffect } from 'react';

interface AirportSearchProps {
  setField: (airport: IAirport) => void;
  selectedAirport: IAirport | null;
  placeholder: string;
  initialAirportSuggestion: IAirport[];
}

// Function to fetch suggestions from the API
const fetchAirports = async (query: string) => {
  const { data } = await http.get(
    `${process.env.REACT_APP_API_URL}/api/v1/sub-agent/airports`,
    {
      params: { q: query },
    },
  );
  return data;
};

const AirportSearch: React.FC<AirportSearchProps> = ({
  setField,
  selectedAirport,
  placeholder,
  initialAirportSuggestion,
}) => {
  const [value, setValue] = useState(
    selectedAirport ? selectedAirport.name : '',
  );
  const [suggestions, setSuggestions] = useState<IAirport[]>([]);
  const [firstFocus, setFirstFocus] = useState(true);

  useEffect(() => {
    if (selectedAirport) {
      setValue(`${selectedAirport.code} - ${selectedAirport.city}`);
    }
  }, [selectedAirport]);

  const debouncedFetchAirports = useCallback(
    debounce((query) => {
      refetch();
    }, 300),
    [],
  );

  const { refetch } = useQuery(
    ['airports', value],
    () => fetchAirports(value),
    {
      enabled: false,
      onSuccess: (data) => {
        setSuggestions(data);
      },
    },
  );

  const onSuggestionsFetchRequested = ({ value }: { value: string }) => {
    setValue(value);
    if (firstFocus) {
      setSuggestions(initialAirportSuggestion);
      setFirstFocus(false);
    } else {
      debouncedFetchAirports(value);
    }
  };

  const onSuggestionsClearRequested = () => {
    setSuggestions([]);
  };

  const getSuggestionValue = (suggestion: IAirport) =>
    `${suggestion.code} - ${suggestion.city}`;

  const renderSuggestion = (suggestion: IAirport) => (
    // <div>{`${suggestion.code} - ${suggestion.city} QQQ`}</div>
    <div className='flex justify-between items-center'>
      <div>
        <div className='text-lg font-semibold'>
          {suggestion.city}, {suggestion.country}
        </div>
        <div className='text-sm '>{suggestion.name}</div>
      </div>
      <div className='text-lg font-bold'>{suggestion.code}</div>
    </div>
  );

  const onChange = (event: any, { newValue }: { newValue: string }) => {
    setValue(newValue);
  };

  const onSuggestionSelected = (
    event: any,
    { suggestion }: { suggestion: IAirport },
  ) => {
    setField(suggestion);
  };

  const inputProps = {
    placeholder,
    value,
    onChange,
    onFocus: () => {
      if (firstFocus) {
        setSuggestions(initialAirportSuggestion);
      }
    },
  };

  return (
    <Autosuggest
      suggestions={suggestions}
      onSuggestionsFetchRequested={onSuggestionsFetchRequested}
      onSuggestionsClearRequested={onSuggestionsClearRequested}
      getSuggestionValue={getSuggestionValue}
      renderSuggestion={renderSuggestion}
      inputProps={inputProps}
      onSuggestionSelected={onSuggestionSelected}
      shouldRenderSuggestions={(value, reason) => {
        if (reason === 'input-blurred') {
          if (selectedAirport) {
            setValue(`${selectedAirport.code} - ${selectedAirport.city}`);
          } else {
            setValue('');
          }
        }
        return true;
      }}
    />
  );
};

export default AirportSearch;
