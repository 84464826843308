import { FC } from 'react';
import { SeperatorDot } from 'app/icons';
import capitalize from 'lodash.capitalize';
import Card from 'app/components/shared/Card/Card';
import { IBooking, IFlightSegment } from 'app/types';
import { getRefundTypeBackgroundColor } from 'app/utils/common-utils';
import {
  formatDate,
  formatMinutesToTime,
  formatTime,
} from 'app/utils/date-utils';
import {
  calculateStops,
  getFormattedRefundableType,
  getFormattedStops,
} from 'app/utils/flight-utils';
import { extractFieldFromBooking } from '../../utils/booking-utils';

interface IFlightDetails {
  booking: IBooking;
}

const FlightDetails: FC<IFlightDetails> = (props) => {
  const { booking } = props;

  const {
    cabinClass,
    fareType,
    refundableType,
    arrivalCity,
    departureCity,
    cabinWeight,
    checkInWeight,
  } = extractFieldFromBooking(booking);

  return (
    <Card className='card px-8 py-7 mb-4'>
      <div className='text-xl font-bold'>Flight Details</div>

      <div className='mt-8'>
        <div className='flex justify-between gap-2'>
          <div>
            <div className='font-semibold'>
              {departureCity} <span className='font-light'> to </span>{' '}
              {arrivalCity}
            </div>
            <div className='flex items-center gap-2 text-sm mt-2'>
              <div>{formatDate(booking.departure.date, 'dddd, MMM D')}</div>
              <SeperatorDot />
              <div>
                <span className='mr-2'>
                  {getFormattedStops(calculateStops(booking.segments))}
                </span>
                <span>{formatMinutesToTime(booking.duration)}</span>
              </div>
              {booking.status === 'CANCELLED' && (
                <div className='rounded-full py-[0.4rem] px-3 leading-none text-white text-xs font-medium bg-danger'>
                  Cancelled
                </div>
              )}
            </div>
          </div>

          <div className='flex flex-col items-end'>
            {refundableType ? (
              <div
                className={`rounded-full py-[0.4rem] px-3 leading-none text-white text-xs font-medium ${getRefundTypeBackgroundColor(
                  refundableType,
                )}`}
              >
                {getFormattedRefundableType(refundableType)}
              </div>
            ) : (
              <div className='py-[0.7rem]'></div>
            )}
            <div className='flex items-center gap-2 text-sm mt-2'>
              <div>{capitalize(cabinClass.toLowerCase())}</div>
              <SeperatorDot />
              <div>{fareType} Fare</div>
            </div>
          </div>
        </div>

        <div className='mt-7 space-y-6 overflow-auto'>
          {booking.segments.map((segment, i) => (
            <FlightSegmentRender
              segment={segment}
              cabin={cabinWeight}
              checkIn={checkInWeight}
            />
          ))}
        </div>
      </div>
    </Card>
  );
};

interface IFlightSegmentRender {
  segment: IFlightSegment;
  checkIn?: string;
  cabin?: string;
}

const FlightSegmentRender: FC<IFlightSegmentRender> = (props) => {
  const { segment, cabin = '-', checkIn = '-' } = props;
  return (
    <div>
      <div className='flex justify-between items-center bg-white rounded-md gap-6'>
        <div className='flex items-center gap-2 min-w-max'>
          <img
            crossOrigin='anonymous'
            className='w-9 h-9'
            src={segment.flight.logo}
            alt=''
          />
          <div>
            <div className='text-base font-semibold'>{segment.flight.name}</div>
            <div className='text-xs text-black/70 mt-[2px]'>{`${segment.flight.code} ${segment.flight.number}`}</div>
          </div>
        </div>
        <div className='flex justify-center gap-6 min-w-max'>
          <div>
            <div className='flex gap-2'>
              <div className='font-medium'>
                {formatDate(segment.departure.date)}
              </div>
              <div className='font-semibold'>
                {formatTime(segment.departure.time)}
              </div>
            </div>
            <div className='text-xs font-normal text-black/70 mt-[2px] w-28'>
              {segment.departure.airport.name}
              {segment.departure.airport.terminal &&
                ', ' + segment.departure.airport.terminal}
            </div>
          </div>
          <div className='flex flex-col items-center mt-1'>
            <div className='text-xs font-normal text-black/70'>
              {formatMinutesToTime(segment.duration)}
            </div>
            <div className='bg-[#ECAE0E] h-1 w-24 rounded-lg mt-1' />
            {segment.stopsOverAirports.length > 0 ? (
              <span className='text-xs font-normal text-black/70 inline-flex gap-1 mt-1'>
                (via {segment.stopsOverAirports.map((a) => a.cityCode)})
              </span>
            ) : (
              ''
            )}
          </div>
          <div>
            <div className='flex gap-2'>
              <div className='font-medium'>
                {formatDate(segment.arrival.date)}
              </div>
              <div className='font-semibold'>
                {formatTime(segment.arrival.time)}
              </div>
            </div>
            <div className='text-xs font-normal text-black/70 mt-[2px] w-28'>
              {segment.arrival.airport.name}{' '}
              {segment.arrival.airport.terminal &&
                ', ' + segment.arrival.airport.terminal}
            </div>
          </div>
        </div>
        <div className='min-w-max'>
          <div className='font-semibold'>{checkIn}</div>
          <div className='text-xs font-normal text-black/70 mt-[2px]'>
            Check-in
          </div>
        </div>
        <div className='min-w-max'>
          <div className='font-semibold'>{cabin}</div>
          <div className='text-xs font-normal text-black/70 mt-[2px]'>
            Cabin
          </div>
        </div>
      </div>
      {segment.layoverDuration !== 0 && (
        <div className='mx-auto w-60 mt-6 bg-gray-100 rounded-full px-8 py-2 text-sm text-center'>
          Layover Time -{' '}
          <span className='font-semibold'>
            {formatMinutesToTime(segment.layoverDuration)}
          </span>
        </div>
      )}
    </div>
  );
};

export default FlightDetails;
