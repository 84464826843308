/* eslint-disable jsx-a11y/anchor-is-valid */
import { SeperatorDot } from 'app/icons';
import { extractFieldFromBooking } from 'app/modules/booking/utils/booking-utils';
import { IBooking, IFlightSingle } from 'app/types';
import {
  formatDate,
  formatMinutesToTime,
  formatTime,
} from 'app/utils/date-utils';
import {
  calculateStops,
  getFormattedFareRuleKey,
  getFormattedFareTypeKey,
  getFormattedRefundableType,
  getFormattedStops,
} from 'app/utils/flight-utils';
import capitalize from 'lodash.capitalize';
import { FC, useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { useQuery } from '@tanstack/react-query';
import http from 'app/config/http';
import { useParams, useSearchParams } from 'react-router-dom';
import Loader from 'app/components/Loader/Loader';
import { getRefundTypeBackgroundColor } from 'app/utils/common-utils';

const collapseAnimation = {
  variants: {
    collapsed: {
      opacity: 0,
      height: 0,
    },
    expanded: {
      opacity: 1,
      height: 'auto',
    },
  },
  initial: 'collapsed',
  animate: 'expanded',
  exit: 'collapsed',
};

interface IFlightDetailWithFareRules {
  flight: IFlightSingle;
}

const FlightDetailWithFareRules: FC<IFlightDetailWithFareRules> = (props) => {
  const { flight } = props;

  // const { fareId } = useParams();

  const { fareId: rawfareId } = useParams();
  const fareId = encodeURIComponent(rawfareId);

  const [searchParams] = useSearchParams();
  const [showFareRules, setShowFareRules] = useState<boolean>(false);

  const {
    cabinClass,
    fareType,
    refundableType,
    arrivalCity,
    departureCity,
    cabinWeight,
    checkInWeight,
  } = extractFieldFromBooking(flight as IBooking);

  const fareRulesQuery = useQuery(['fareRulesById', searchParams], async () => {
    const { data } = await http.post(
      `${process.env.REACT_APP_API_URL}/api/v1/sub-agent/flights/${fareId}/fare-rules`,
      {
        fareSupplier: searchParams.get('type'),
      },
    );

    return data;
  });

  const toggleShowFareRules = () => {
    setShowFareRules((prev) => !prev);
  };

  const renderFareRules = () => {
    if (fareRulesQuery.isLoading)
      return (
        <div className='text-center'>
          <Loader />
        </div>
      );

    if (fareRulesQuery.isError)
      return <p>Please contact support for fare rules.</p>;

    // for series fare : fare rule will be a string
    if (flight.fareSupplier === '0')
      return <p className='whitespace-pre-wrap'>{fareRulesQuery.data}</p>;
    if (flight.fareSupplier === '2')
      return <p className='whitespace-pre-wrap'>{fareRulesQuery.data}</p>;
    if (flight.fareSupplier === '3') {
      if (fareRulesQuery.data)
        return <p className='whitespace-pre-wrap'>{fareRulesQuery.data}</p>;
      else return <p>Please contact support for fare rules.</p>;
    }

    // for normal fare : fare rule will be an array of objects
    if (fareRulesQuery.data.length === 0)
      return <p>Please contact support for fare rules.</p>;

    return fareRulesQuery.data.map((fight: any, fightIndex: number) => (
      <div key={fightIndex}>
        <h6 className='font-bold mb-4'>{fight.flightName}</h6>
        <div className='grid grid-cols-4 gap-4'>
          {fight.rules.map((rule: any, ruleIndex: number) => (
            <div key={fightIndex + '' + ruleIndex} className='col-span-4'>
              <div className='font-medium mb-2'>
                {getFormattedFareRuleKey(rule.key)}
              </div>

              <div>
                {rule.rows.map((row: any, rowIndex: number) => (
                  <div
                    key={fightIndex + '' + ruleIndex + '' + rowIndex}
                    className='flex gap-8 text-gray-600 text-sm '
                  >
                    <div className='w-1/6'>
                      {getFormattedFareTypeKey(row.type)}
                    </div>
                    <div className='flex-1 leading-6'>{row.value}</div>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    ));
  };

  return (
    <div className='card px-8 py-7 mb-4'>
      <div className='text-xl font-bold'>Flight Details</div>

      <div className='mt-8'>
        <div className='flex flex-col items-start md:flex-row md:justify-between gap-2'>
          <div>
            <div className='font-semibold'>
              {departureCity}
              <span className='font-light'>to</span>
              {arrivalCity}
            </div>

            <div className='flex items-center gap-2 text-sm mt-2'>
              <div>{formatDate(flight.departure.date, 'dddd, MMM D')}</div>

              <SeperatorDot />
              {/* <div>Non Stop 1hr 30mn</div> */}
              <div>
                <span className='mr-2'>
                  {getFormattedStops(calculateStops(flight.segments))}
                </span>
                <span>{formatMinutesToTime(flight.duration)}</span>
              </div>
            </div>
          </div>

          <div className='flex flex-row gap-4  md:flex-col md:gap-0 items-center  md:items-end overflow-x-auto hide-scrollbar w-full md:w-auto'>
            {refundableType ? (
              <div
                className={`rounded-full py-[0.4rem] px-3 leading-none text-white text-xs font-medium ${getRefundTypeBackgroundColor(
                  refundableType,
                )}`}
              >
                {getFormattedRefundableType(refundableType)}
              </div>
            ) : (
              <div className='py-[0.7rem]'></div>
            )}
            <div className='flex items-center gap-2 text-sm mt-2'>
              <div>{capitalize(cabinClass.toLowerCase())}</div>
              <SeperatorDot />
              <div>{fareType} Fare</div>
              <SeperatorDot />
              <a
                className='text-primary font-semibold cursor-pointer'
                onClick={toggleShowFareRules}
              >
                View fare rules
              </a>
            </div>
          </div>
        </div>

        <AnimatePresence initial={false}>
          {showFareRules && (
            <motion.div {...collapseAnimation}>
              <div className='mt-7 border border-gray-100 rounded-md py-4 px-6'>
                {renderFareRules()}
              </div>
            </motion.div>
          )}
        </AnimatePresence>

        <div className='mt-7 space-y-8 overflow-auto'>
          {flight.segments.map((segment, i) => (
            <div key={i}>
              <div className='flex  flex-col md:flex-row md:justify-between md:items-center  bg-white rounded-md gap-6'>
                <div className='flex  items-center gap-2 min-w-max'>
                  <img
                    crossOrigin='anonymous'
                    className='w-9 h-9'
                    src={segment.flight.logo}
                    alt=''
                  />
                  <div>
                    <div className='text-base font-semibold'>
                      {segment.flight.name}
                    </div>
                    <div className='text-xs text-black/70 mt-[2px]'>{`${segment.flight.code} ${segment.flight.number}`}</div>
                  </div>
                </div>
                <div className='flex justify-start md:justify-center gap-6 min-w-max'>
                  <div>
                    <div className='flex gap-2'>
                      <div className='font-medium'>
                        {formatDate(segment.departure.date)}
                      </div>
                      <div className='font-semibold'>
                        {formatTime(segment.departure.time)}
                      </div>
                    </div>
                    <div className='text-xs font-normal text-black/70 mt-[2px] w-28'>
                      {segment.departure.airport.name}
                      {segment.departure.airport.terminal &&
                        ', ' + segment.departure.airport.terminal}
                    </div>
                  </div>
                  <div className='flex flex-col items-center mt-1'>
                    <div className='text-xs font-normal text-black/70'>
                      {formatMinutesToTime(segment.duration)}
                    </div>
                    {/* Line */}
                    <div className='bg-[#ECAE0E] h-1 w-28 rounded-lg mt-1' />
                    {/*  */}

                    {segment.stopsOverAirports.length > 0 ? (
                      <span className='text-xs font-normal text-black/70 inline-flex gap-1 mt-1'>
                        (via {segment.stopsOverAirports.map((a) => a.cityCode)})
                      </span>
                    ) : (
                      ''
                    )}
                  </div>
                  <div>
                    <div className='flex gap-2'>
                      <div className='font-medium'>
                        {formatDate(segment.arrival.date)}
                      </div>
                      <div className='font-semibold'>
                        {formatTime(segment.arrival.time)}
                      </div>
                    </div>
                    <div className='text-xs font-normal text-black/70 mt-[2px] w-28'>
                      {segment.arrival.airport.name}{' '}
                      {segment.arrival.airport.terminal &&
                        ', ' + segment.arrival.airport.terminal}
                    </div>
                  </div>
                </div>
                <div className='flex-grow flex gap-4 justify-start md:justify-around'>
                  <div>
                    <div className='font-semibold'>{checkInWeight}</div>
                    <div className='text-xs font-normal text-black/70 mt-[2px]'>
                      Check-in
                    </div>
                  </div>

                  <div>
                    <div className='font-semibold'>{cabinWeight}</div>
                    <div className='text-xs font-normal text-black/70 mt-[2px]'>
                      Cabin
                    </div>
                  </div>
                </div>
                <div className='min-w-max'></div>

                {/* <div className="min-w-max">
                <div className="font-semibold">
                    {flight.priceDetail.fareDescription.adult.baggage.checkIn}
                </div>
                <div className="text-xs font-normal text-black/70 mt-[2px]">
                    Check-in
                </div>
            </div>
            <div className="min-w-max">
                <div className="font-semibold">
                    {flight.priceDetail.fareDescription.adult.baggage.cabin}
                </div>
                <div className="text-xs font-normal text-black/70 mt-[2px]">
                    Cabin
                </div>
            </div> */}
              </div>
              {segment.layoverDuration !== 0 && (
                <div className='mx-auto w-60 mt-8 bg-gray-100 rounded-full px-8 py-2 text-sm text-center'>
                  Layover Time -{' '}
                  <span className='font-semibold'>
                    {formatMinutesToTime(segment.layoverDuration)}
                  </span>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default FlightDetailWithFareRules;
