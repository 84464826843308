import { FC, useContext } from 'react';
import capitalize from 'lodash.capitalize';
import { IFlightPriceDetail } from 'app/types';
import { formatCurrency } from 'app/utils/currency-utils';
import FlightsContext from 'app/pages/Flights/context/flights-context';

interface IFareSummary {
  selectedPriceItem: IFlightPriceDetail;
}
const FareSummary: FC<IFareSummary> = (props) => {
  const { passengers } = useContext(FlightsContext);
  const { selectedPriceItem } = props;

  const renderFareSummaryForType = (type: 'adult' | 'child' | 'infant') => {
    return (
      <>
        <tr>
          <td colSpan={3} className='px-4 py-1 text-sm text-gray-500'>
            Fare Details for {capitalize(type)}
          </td>
        </tr>
        <tr>
          <td className='px-4 py-2'>Base Price</td>
          <td className='px-4 py-2'>
            {formatCurrency(
              selectedPriceItem.fareDescription[type].fareComponent.base,
            )}
            <span className='mx-2'>x</span>
            {passengers[type]}
          </td>
          <td className='px-4 py-2'>
            {formatCurrency(
              selectedPriceItem.fareDescription[type].fareComponent.base *
                passengers[type],
            )}
          </td>
        </tr>
        <tr>
          <td className='px-4 py-2'>Taxes and fees</td>
          <td className='px-4 py-2'>
            {formatCurrency(
              selectedPriceItem.fareDescription[type].fareComponent
                .taxesAndFees,
            )}
            <span className='mx-2'>x</span>
            {passengers[type]}
          </td>
          <td className='px-4 py-2'>
            {formatCurrency(
              selectedPriceItem.fareDescription[type].fareComponent
                .taxesAndFees * passengers[type],
            )}
          </td>
        </tr>
        <tr>
          <td className='pb-3'></td>
        </tr>
      </>
    );
  };

  return (
    <table className='w-full'>
      <thead>
        <tr>
          <th className='px-4 py-2 text-left'>Type</th>
          <th className='px-4 py-2 text-left'>Fare</th>
          <th className='px-4 py-2 text-left'>Total</th>
        </tr>
      </thead>
      <tbody>
        {renderFareSummaryForType('adult')}
        {selectedPriceItem.fareDescription.child &&
          renderFareSummaryForType('child')}
        {selectedPriceItem.fareDescription.infant &&
          renderFareSummaryForType('infant')}
        {selectedPriceItem.commissionV2 && (
          <>
            <tr>
              <td colSpan={2} className='px-4 py-2 pt-6'>
                <div className='font-semibold'>Total Fare Charges </div>
              </td>
              <td className='px-4 py-2 pt-6'>
                <div className='font-semibold'>
                  {formatCurrency(selectedPriceItem.originalFare)}
                </div>
              </td>
            </tr>
            {/* <tr>
              <td colSpan={3} className='px-4 py-1 text-sm text-gray-500 pt-4'>
                Other Charges
              </td>
            </tr> */}
            <tr>
              <td colSpan={2} className='px-4 py-2 pt-6'>
                Management Fee
              </td>
              <td className='px-4 py-2 pt-6'>
                {formatCurrency(selectedPriceItem.managementFees)}
              </td>
            </tr>
            <tr>
              <td colSpan={2} className='px-4 py-2'>
                GST on Management Fee
              </td>
              <td className='px-4 py-2'>
                {formatCurrency(selectedPriceItem.managementFeesGST)}
              </td>
            </tr>
            <tr>
              <td colSpan={2} className='px-4 py-2 pt-6'>
                <div className='font-semibold'>Total Management Fee</div>
              </td>
              <td className='px-4 py-2 pt-6'>
                <div className='font-bold'>
                  {formatCurrency(selectedPriceItem.managementFeesTotal)}
                </div>
              </td>
            </tr>
          </>
        )}
        <tr>
          <td colSpan={2} className='px-4 py-2 pt-10 '>
            <div className='font-bold'>Total Booking Amount </div>
          </td>
          <td className='px-4 py-2 pt-10'>
            <div className='font-bold'>
              {formatCurrency(selectedPriceItem.totalFare)}
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  );
};

export default FareSummary;
