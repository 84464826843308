import { FC } from 'react';

interface ProcessHeadingProps {
  title: string;
  activeIndex: number;
}

const processStages = [
  'Select Flight',
  'Passenger Details',
  'Payment Details',
  'Confirmation',
];

const ProcessHeading: FC<ProcessHeadingProps> = (props) => {
  const { title, activeIndex } = props;

  const textClass = (index: number): string => {
    if (index < activeIndex) return 'text-secondary-fg';
    else if (index === activeIndex) return 'text-secondary-fg font-semibold';
    else return 'text-secondary-fg/60';
  };

  return (
    <div className='flex flex-col gap-2  md:flex-row  md:justify-between md:items-center py-8'>
      <div className='title text-secondary-fg'>{title}</div>

      <div className='text-sm md:text-right whitespace-nowrap flex flex-wrap'>
        {processStages.map((row, i) => (
          <span key={i}>
            <span className={textClass(i)}>{row}</span>
            {processStages.length - 1 > i && (
              <span className='text-secondary-fg mx-2'>/</span>
            )}
          </span>
        ))}
      </div>
    </div>
  );
};

export default ProcessHeading;
