import React, { FC } from 'react';
import { IBooking } from 'app/types';
import useDialog from 'app/hooks/use-dialog';
import { formatCurrency } from 'app/utils/currency-utils';
import MarkupModal from './components/MarkupModal/MarkupModal';
import FareSummarySideDrawer from './components/FareSummarySideDrawer/FareSummarySideDrawer';

interface IBookedTicketPriceDetailsProps {
  booking: IBooking;
  isCancelledOrPending: boolean;
}

const BookedTicketPriceDetails: FC<IBookedTicketPriceDetailsProps> = (
  props,
) => {
  const { booking, isCancelledOrPending } = props;
  const markupDialog = useDialog();
  const chargesDialog = useDialog();

  return (
    <>
      {booking.isTaxed && (
        <div className='sticky top-[calc(var(--navbar-height)+16px)]'>
          <div className='card px-5 py-4 mb-4  max-h-[calc(100vh-var(--navbar-height)-32px)] overflow-auto no-scrollbar'>
            <div className='flex  items-center justify-between'>
              <div className='font-semibold'>Fare Summary </div>
              <div
                onClick={chargesDialog.show}
                className='text-blue-500 text-sm font-bold cursor-pointer'
              >
                View Fare Details
              </div>
            </div>

            <div className='mt-8'>
              <div className='flex justify-between items-center text-sm'>
                <div>Base Fare</div>
                <div>{formatCurrency(booking.taxDetails.totalBaseFare)}</div>
              </div>
              <div className='flex justify-between items-center text-sm mt-4'>
                <div>Fee & Surcharges</div>
                <div>
                  {formatCurrency(
                    booking.taxDetails.totalTaxesAndFees +
                      booking.priceDetail.additionalMarkup,
                  )}
                </div>
              </div>

              <div className='flex justify-between items-center text-sm mt-4 font-semibold'>
                <div>Original Fare</div>
                <div>
                  {formatCurrency(
                    booking.taxDetails.originalFare +
                      booking.priceDetail.additionalMarkup,
                  )}
                </div>
              </div>

              <div className='flex justify-between items-center text-sm mt-4'>
                <div>Mgt. Fee</div>
                <div>{formatCurrency(booking.taxDetails.managementFees)}</div>
              </div>
              <div className='flex justify-between items-center text-sm mt-4 '>
                <div>Mgt. Fee GST</div>
                <div>
                  {formatCurrency(booking.taxDetails.managementFeesGST)}
                </div>
              </div>
              <div className='flex justify-between items-center text-sm mt-4 font-semibold'>
                <div>Mgt. Fee Total</div>
                <div>
                  {formatCurrency(booking.taxDetails.managementFeesTotal)}
                </div>
              </div>
            </div>

            <div className='font-semibold flex justify-between items-center mt-8'>
              <div>
                Total Amount{' '}
                <span
                  className={`ml text-xs font-light text-blue-500 ${
                    isCancelledOrPending ? '' : 'cursor-pointer'
                  } `}
                  onClick={markupDialog.show}
                >
                  Markup
                </span>
              </div>
              <div>
                {formatCurrency(
                  booking.taxDetails.totalFare +
                    booking.priceDetail.additionalMarkup,
                )}
              </div>
            </div>
          </div>
        </div>
      )}
      {!booking.isTaxed && (
        <div className='card p-4 mb-4'>
          <div className='text-sm font-semibold'>Fare Summary</div>
          <div className='mt-8'>
            <div className='flex justify-between'>
              <div className='text-sm  font-normal'>Base Fare</div>
              <div className='text-sm font-normal'>
                {formatCurrency(booking.priceDetail.totalBaseFare)}
              </div>
            </div>
            <div className='mt-4 flex justify-between'>
              <div className='text-sm  font-normal'>Fees & Surcharges</div>
              <div className='text-sm  font-normal'>
                {formatCurrency(
                  booking.priceDetail.totalTaxesAndFees +
                    booking.priceDetail.additionalMarkup,
                )}
              </div>
            </div>
            {/* 
            <div className="mt-4 flex justify-between">
                <div className="text-sm  font-normal">Discounts</div>
                <div className="text-sm  font-normal text-primary">₹ 123</div>
            </div> 
        */}
          </div>
          <div className='mt-8 text-base  font-semibold flex justify-between'>
            <div>
              Total Amount
              {!isCancelledOrPending && (
                <span
                  className={`ml-2 text-xs font-light text-blue-500 ${
                    isCancelledOrPending ? '' : 'cursor-pointer'
                  } `}
                  onClick={markupDialog.show}
                >
                  Markup
                </span>
              )}
            </div>
            <div>
              {formatCurrency(
                booking.priceDetail.totalFare +
                  booking.priceDetail.additionalMarkup,
              )}
            </div>
          </div>
          <div className='mt-3 text-base  font-semibold flex justify-between'>
            <div>Payment (AGY BAL)</div>
            <div className='text-success'>
              -
              {formatCurrency(
                booking.priceDetail.totalFare +
                  booking.priceDetail.additionalMarkup,
              )}
            </div>
          </div>
          <div className='mt-8 text-base  font-semibold flex justify-between'>
            <div>Balance</div>
            <div className='text-success'>₹ 0</div>
          </div>
          {booking.priceDetail.changeFee.map((amount: number, index) => {
            return (
              <div
                key={index}
                className='mt-8 text-base  font-semibold flex justify-between'
              >
                {/* <div>Settle Amount</div> */}
                <div>{`Change Fee - ${amount < 0 ? 'Charged' : 'Refund Issued'}`}</div>
                <div className=''>{formatCurrency(amount)}</div>
                {/* <div className="">{formatCurrency(-230)}</div> */}
              </div>
            );
          })}
        </div>
      )}
      <MarkupModal
        bookingId={booking._id}
        onClose={markupDialog.hide}
        show={!isCancelledOrPending && markupDialog.isOpen}
        currentMarkup={booking.priceDetail.additionalMarkup}
      />
      <FareSummarySideDrawer
        onClose={chargesDialog.hide}
        show={chargesDialog.isOpen}
        booking={booking}
      />
    </>
  );
};

export default BookedTicketPriceDetails;
