import { FC } from 'react';
import Modal from 'app/components/Modal/Modal';
import { Formik, Form } from 'formik';

import * as Yup from 'yup';
import LoaderButton from 'app/components/LoaderButton/LoaderButton';

const PrintFormSchema = Yup.object({
  price: Yup.boolean(),
  agency: Yup.boolean(),
  gst: Yup.boolean(),
});

const initialValues = {
  price: true,
  agency: true,
  gst: false,
};

interface PrintOptionModalProps {
  show: boolean;
  onClose: () => void;
  onSubmit: (val: any) => void;
}

const PrintOptionModal: FC<PrintOptionModalProps> = (props) => {
  const { show, onClose, onSubmit } = props;

  const handleSubmit = (values: any) => {
    // navigate({
    //     pathname: '/my-agency/account',
    //     search: createSearchParams({
    //         showDialog: 'true',
    //     }).toString(),
    // });
    onSubmit(values);
  };

  return (
    <Modal show={show} onClose={onClose} className='w-[520px]'>
      <div className='text-2xl font-bold mb-8'>Print Ticket </div>
      <Formik
        initialValues={initialValues}
        enableReinitialize
        validationSchema={PrintFormSchema}
        onSubmit={handleSubmit}
      >
        {({ values, setFieldValue }) => (
          <Form>
            <div className='grid grid-cols-4 sm:grid-cols-6'>
              {/* price */}
              <div className='col-span-2'>
                <div className='flex items-center'>
                  <input
                    className='form-checkbox'
                    type='checkbox'
                    id='test'
                    checked={values.price}
                    onChange={(e: any) =>
                      setFieldValue('price', e.target.checked)
                    }
                  />
                  <label className='ml-2' htmlFor='With Price'>
                    With Price
                  </label>
                </div>
              </div>
              {/* with Agency */}
              <div className='col-span-2'>
                <div className='flex items-center'>
                  <input
                    className='form-checkbox'
                    type='checkbox'
                    id='test'
                    checked={values.agency}
                    onChange={(e: any) =>
                      setFieldValue('agency', e.target.checked)
                    }
                  />
                  <label className='ml-2' htmlFor='With Price'>
                    With Agency
                  </label>
                </div>
              </div>
              {/* with GST */}

              <div className='col-span-2'>
                {/* <div className="flex items-center">
                                    <input
                                        className="form-checkbox"
                                        type="checkbox"
                                        id="test"
                                        checked={values.gst}
                                        onChange={(e: any) => setFieldValue('gst', e.target.checked)}
                                    />
                                    <label className="ml-2" htmlFor="With Price">
                                        With GST
                                    </label>
                                </div> */}
              </div>
            </div>
            <LoaderButton className='bg-primary text-white text-sm font-semibold rounded-lg px-8 mt-8'>
              Print
            </LoaderButton>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default PrintOptionModal;
