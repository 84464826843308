import dayjs, { Dayjs } from 'dayjs';
import { FC, useState } from 'react';
import { PickerType } from '../enums/picker-type';
import DayPicker from './DayPicker';
import MonthPicker from './MonthPicker';
import YearPicker from './YearPicker';

const getInitialViewDate = (value: Dayjs, maxDate: Dayjs) => {
  if (value) return value;

  if (!maxDate) return dayjs();

  return dayjs.min(dayjs(), maxDate);
};

interface PickerProps {
  value: Dayjs;
  onChange: (date: Dayjs) => void;
  minDate?: Dayjs;
  maxDate?: Dayjs;
}

const Picker: FC<PickerProps> = (props) => {
  const { value, onChange, minDate, maxDate } = props;

  const _initialViewDate = getInitialViewDate(value, maxDate);

  const [viewDate, setViewDate] = useState(_initialViewDate);
  const [picker, setPicker] = useState(PickerType.DAY);

  const handleViewDateChange = (date: Dayjs) => {
    setViewDate(date);
  };

  const handleViewMonthChange = (month: number) => {
    setViewDate((prev) =>
      dayjs().year(prev.year()).month(month).date(prev.date()),
    );
  };

  const handleViewYearChange = (year: number) => {
    setViewDate((prev) =>
      dayjs().year(year).month(prev.month()).date(prev.date()),
    );
  };

  return (
    <div className='bg-white p-4 rounded-xl shadow-[0px_1px_16px_2px_rgba(13,16,33,0.08),1px_6px_32px_rgba(5,22,35,0.06)] w-[326px] max-w-full min-h-[240px] select-none'>
      {picker === PickerType.DAY && (
        <DayPicker
          selectedDate={value}
          onSelectedDateChange={onChange}
          viewDate={viewDate}
          onViewDateChange={handleViewDateChange}
          onYearPickerSelect={() => setPicker(PickerType.YEAR)}
          onMonthPickerSelect={() => setPicker(PickerType.MONTH)}
          minDate={minDate}
          maxDate={maxDate}
        />
      )}

      {picker === PickerType.MONTH && (
        <MonthPicker
          selectedYear={value?.year()}
          selectedMonth={value?.month()}
          viewYear={viewDate.year()}
          onViewYearChange={handleViewYearChange}
          onViewMonthChange={handleViewMonthChange}
          onYearPickerSelect={() => setPicker(PickerType.YEAR)}
          onDayPickerSelect={() => setPicker(PickerType.DAY)}
          minYear={minDate?.year()}
          maxYear={maxDate?.year()}
          minMonth={minDate?.month()}
          maxMonth={maxDate?.month()}
        />
      )}

      {picker === PickerType.YEAR && (
        <YearPicker
          selectedYear={value?.month()}
          viewYear={viewDate.year()}
          onViewYearChange={handleViewYearChange}
          onMonthPickerSelect={() => setPicker(PickerType.MONTH)}
          minYear={minDate?.year()}
          maxYear={maxDate?.year()}
        />
      )}
    </div>
  );
};

export default Picker;
