/* eslint-disable jsx-a11y/anchor-is-valid */
import { useMutation, useQueryClient } from '@tanstack/react-query';
import LoaderButton from 'app/components/LoaderButton/LoaderButton';
import http from 'app/config/http';
import { IMarkupControlForm } from 'app/pages/SeriesInventory/Pages/ReviseMarkup/types';
import { formatCurrency } from 'app/utils/currency-utils';
import { ErrorMessage, Field, Form, Formik, FormikProps } from 'formik';
import { FC, useMemo } from 'react';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

const MarkupControlFormSchema = Yup.object({
  markup: Yup.string().trim().required('This field is required'),
});

interface MarkupControlFormProps {
  ticket: any;
}

const MarkupControlForm: FC<MarkupControlFormProps> = (props) => {
  const { ticket } = props;

  const queryClient = useQueryClient();

  const mutation = useMutation(async (payload: any) => {
    const { data } = await http.put(
      `${process.env.REACT_APP_API_URL}/api/v1/sub-agent/series-tickets/${ticket._id}/markup`,
      payload,
    );

    return data;
  });

  const initialValues: IMarkupControlForm = useMemo(() => {
    if (!ticket)
      return {
        markup: 0,
      };

    return {
      markup: ticket.markup,
    };
  }, [ticket]);

  const handleSubmit = async (values: IMarkupControlForm) => {
    const payload = {
      markup: +values.markup,
    };

    try {
      await mutation.mutateAsync(payload);

      queryClient.fetchQuery(['markupHistory']);

      toast.success('Markup updated successfully.');
    } catch (ex: any) {
      toast.error(ex?.data?.message || 'Some error occured, please try again.');
      console.log(ex);
    }
  };

  const getTotalCostPrice = (): number => {
    return (+ticket.costPrice + +ticket.tax) * +ticket.totalSeats;
  };

  const getTotalSellingPrice = (markup: number): number => {
    return (+ticket.costPrice + +ticket.tax + +markup) * +ticket.totalSeats;
  };

  const getEarnings = (markup: number): number => {
    return getTotalSellingPrice(markup) - getTotalCostPrice();
  };

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize
      validationSchema={MarkupControlFormSchema}
      onSubmit={handleSubmit}
    >
      {({ values }: FormikProps<IMarkupControlForm>) => (
        <Form>
          <div className='grid grid-cols-4 gap-6'>
            <div className='col-span-1'>
              <label className='text-xs mb-2' htmlFor='markup'>
                CURRENT MARKUP PER SEAT
              </label>
              <div className='input-group flex items-center'>
                <div className='input-group-prepend'>₹</div>
                <Field
                  type='text'
                  id='markup'
                  name='markup'
                  className='form-control pl-8'
                />
              </div>
              <div className='form-error'>
                <ErrorMessage name='markup' />
              </div>
            </div>
            <div className='col-span-1'>
              <label className='text-xs mb-2' htmlFor='flightNumber'>
                TOTAL COST PRICE OF THE TICKET
              </label>
              <div className='py-2 text-lg font-medium'>
                {formatCurrency(getTotalCostPrice())}
              </div>
            </div>
            <div className='col-span-1'>
              <label className='text-xs mb-2' htmlFor='departureTime'>
                TOTAL SELLING PRICE OF THE TICKET
              </label>
              <div className='py-2 text-lg font-medium'>
                {formatCurrency(getTotalSellingPrice(+values.markup))}
              </div>
            </div>
            <div className='col-span-1'>
              <label className='text-xs mb-2' htmlFor='arrivalTime'>
                ESTIMATED PROFIT / LOSS
              </label>
              <div className='py-2 text-lg font-medium'>
                {getEarnings(+values.markup) >= 0 ? (
                  <span className='text-green-500'>
                    + {formatCurrency(getEarnings(+values.markup))}
                  </span>
                ) : (
                  <span className='text-red-500'>
                    {formatCurrency(getEarnings(+values.markup))}
                  </span>
                )}
              </div>
            </div>
          </div>

          <LoaderButton
            type='submit'
            className='bg-primary text-white text-sm font-semibold rounded-lg w-56 mt-8'
            isLoading={mutation.isLoading}
          >
            Save Changes
          </LoaderButton>
        </Form>
      )}
    </Formik>
  );
};

export default MarkupControlForm;
