const CrossIcon = (props: any) => {
  return (
    <svg
      width='18px'
      height='18px'
      viewBox='0 0 18 18'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M12 3.75c.475 0 .865.36.912.823l.005.094-.001 6.416h6.417a.917.917 0 01.094 1.829l-.094.005-6.417-.001v6.417a.917.917 0 01-1.828.094l-.005-.094v-6.417H4.667a.917.917 0 01-.094-1.828l.094-.005h6.416V4.667c0-.507.41-.917.917-.917z'
        transform='translate(-1332 -782) translate(1329 779) rotate(45 12 12)'
        fill='currentColor'
        fillRule='nonzero'
        stroke='none'
        strokeWidth={1}
      />
    </svg>
  );
};

export default CrossIcon;
