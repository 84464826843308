import React, { FC } from 'react';
import { formatDate } from 'app/utils/date-utils';
import { getGenderFromTitle } from 'app/utils/common-utils';
import { formatCurrency } from 'app/utils/currency-utils';
import { useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import http from 'app/config/http';
import styles from './BookingInvoice.module.scss';
import Spinner from 'app/components/Spinner/Spinner';
import moment from 'moment';
import BackLink from 'app/components/BackLink/BackLink';

interface BookingInvoiceProps {}

const BookingInvoice: FC<BookingInvoiceProps> = (props) => {
  const { bookingId } = useParams();

  const invoiceQuery = useQuery(['bookingInvoice', bookingId], async () => {
    const { data } = await http.get(
      `${process.env.REACT_APP_API_URL}/api/v1/sub-agent/series-ticket-bookings/${bookingId}/invoice`,
    );

    return data;
  });

  const getFormattedAddress = (address: any) => {
    return `${address.line}, ${address.city}, ${address.state}`;
  };

  if (invoiceQuery.isLoading)
    return (
      <div className='fixed top-0 left-0 right-0 h-screen flex justify-center items-center'>
        <Spinner />
      </div>
    );

  const invoice = invoiceQuery.data;

  return (
    <>
      <div className={`${styles['print-btn-wrapper']} w-[643px] mx-auto mt-6`}>
        <div className='mb-5'>
          <BackLink />
        </div>
        <button
          className='btn-primary px-8 py-2 rounded-lg text-sm font-medium w-36 max-w-full'
          onClick={window.print}
        >
          Print
        </button>
      </div>

      <div
        className={`${styles['page-wrapper']} flex min-h-[calc(100vh)] h-full`}
      >
        <div className='flex flex-col'>
          <div className='px-10 pt-8'>
            <div className='flex justify-between'>
              <div>
                <img className='h-6' src={invoice.from.logoUrl} alt='' />
                <div className='mt-2'>
                  <div className='font-semibold text-primary text-sm'>
                    {invoice.from.name}
                  </div>
                  <div className='font-medium text-sm mt-1'>
                    {getFormattedAddress(invoice.from.address)}
                  </div>
                  <div className='font-medium text-sm mt-1'>
                    {invoice.from.document.name} :{' '}
                    {invoice.from.document.number}
                  </div>
                </div>
              </div>
              <div>
                <div>
                  <div className='text-primary font-medium text-xs'>
                    Invoice Number
                  </div>
                  <div className='font-medium text-sm mt-1'>{invoice._id}</div>
                </div>
                <div className='mt-6'>
                  <div className='text-primary font-medium text-xs'>
                    Invoice Date
                  </div>
                  <div className='font-medium text-sm mt-1'>
                    {moment(invoice.createdAt, 'YYYYMMDD').format(
                      'DD MMM, YYYY',
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Passenger & Flight Details */}
          <div className='mt-14'>
            <table className='w-full'>
              <thead>
                <tr>
                  <th
                    colSpan={4}
                    className='text-sm text-left font-normal px-10 py-2 align-middle bg-primary/20'
                  >
                    Passenger & Flight Details
                  </th>
                </tr>
              </thead>
              <tbody className='text-sm'>
                {invoice.details.passengers.adults.map(
                  (row: any, i: number) => (
                    <tr key={i}>
                      <td className='pt-5 px-3 align-middle first:pl-10 last:pr-10'>
                        <div className='flex flex-col'>
                          <div className='font-semibold'>
                            {row.title} {row.firstName} {row.lastName}
                          </div>
                          <div className='text-xs text-gray-500'>
                            {getGenderFromTitle(row.title)}, Adult
                          </div>
                        </div>
                      </td>
                      <td className='pt-5 px-3 align-middle first:pl-10 last:pr-10'>
                        <div className='space-y-1'>
                          {row.pnrs.map((pnr: any, i: number) => (
                            <div key={i}>
                              <div className='font-semibold'>{pnr.number}</div>
                              <div className='text-[0.7rem] leading-none text-gray-500'>
                                {pnr.sector}
                              </div>
                            </div>
                          ))}
                        </div>
                      </td>
                      <td className='pt-5 px-3 align-middle first:pl-10 last:pr-10'>
                        <div className='font-semibold'>
                          {invoice.details.departure.airport.city}
                          <span className='font-light'> to </span>{' '}
                          {invoice.details.arrival.airport.city}
                        </div>
                        <div className='text-xs text-gray-500'>
                          {formatDate(
                            invoice.details.departure.date,
                            'dddd, MMM D',
                          )}
                        </div>
                      </td>
                      <td className='pt-5 px-3 align-middle first:pl-10 last:pr-10'>
                        <div className='flex items-center gap-2 '>
                          <img
                            crossOrigin='anonymous'
                            className='w-8 h-8'
                            src={invoice.details.arrival.flight.logo}
                            alt={invoice.details.arrival.flight.name}
                          />
                          <div>
                            <div className='font-semibold'>
                              {invoice.details.arrival.flight.name}
                            </div>
                            <div className='text-xs text-gray-500'>
                              {`${invoice.details.arrival.flight.code} ${invoice.details.arrival.flight.number}`}
                            </div>
                          </div>
                        </div>
                      </td>
                    </tr>
                  ),
                )}
                {invoice.details.passengers.children.map(
                  (row: any, i: number) => (
                    <tr key={i}>
                      <td className='pt-5 px-3 align-middle first:pl-10 last:pr-10'>
                        <div className='flex flex-col'>
                          <div className='font-semibold'>
                            {row.title} {row.firstName} {row.lastName}
                          </div>
                          <div className='text-xs text-gray-500'>
                            {getGenderFromTitle(row.title)}, Child
                          </div>
                        </div>
                      </td>
                      <td className='pt-5 px-3 align-middle first:pl-10 last:pr-10'>
                        <div className='space-y-1'>
                          {row.pnrs.map((pnr: any, i: number) => (
                            <div key={i}>
                              <div className='font-semibold'>{pnr.number}</div>
                              <div className='text-[0.7rem] leading-none text-gray-500'>
                                {pnr.sector}
                              </div>
                            </div>
                          ))}
                        </div>
                      </td>
                      <td className='pt-5 px-3 align-middle first:pl-10 last:pr-10'>
                        <div className='font-semibold'>
                          {invoice.details.departure.airport.city}
                          <span className='font-light'> to </span>{' '}
                          {invoice.details.arrival.airport.city}
                        </div>
                        <div className='text-xs text-gray-500'>
                          {formatDate(
                            invoice.details.departure.date,
                            'dddd, MMM D',
                          )}
                        </div>
                      </td>
                      <td className='pt-5 px-3 align-middle first:pl-10 last:pr-10'>
                        <div className='flex items-center gap-2 '>
                          <img
                            crossOrigin='anonymous'
                            className='w-8 h-8'
                            src={invoice.details.arrival.flight.logo}
                            alt={invoice.details.arrival.flight.name}
                          />
                          <div>
                            <div className='font-semibold'>
                              {invoice.details.arrival.flight.name}
                            </div>
                            <div className='text-xs text-gray-500'>
                              {`${invoice.details.arrival.flight.code} ${invoice.details.arrival.flight.number}`}
                            </div>
                          </div>
                        </div>
                      </td>
                    </tr>
                  ),
                )}
                {invoice.details.passengers.infants.map(
                  (row: any, i: number) => (
                    <tr key={i}>
                      <td className='pt-5 px-3 align-middle first:pl-10 last:pr-10'>
                        <div className='flex flex-col'>
                          <div className='font-semibold'>
                            {row.title} {row.firstName} {row.lastName}
                          </div>
                          <div className='text-xs text-gray-500'>
                            {getGenderFromTitle(row.title)}, Infant
                          </div>
                        </div>
                      </td>
                      <td className='pt-5 px-3 align-middle first:pl-10 last:pr-10'>
                        <div className='space-y-1'>
                          {row.pnrs.map((pnr: any, i: number) => (
                            <div key={i}>
                              <div className='font-semibold'>{pnr.number}</div>
                              <div className='text-[0.7rem] leading-none text-gray-500'>
                                {pnr.sector}
                              </div>
                            </div>
                          ))}
                        </div>
                      </td>
                      <td className='pt-5 px-3 align-middle first:pl-10 last:pr-10'>
                        <div className='font-semibold'>
                          {invoice.details.departure.airport.city}
                          <span className='font-light'> to </span>{' '}
                          {invoice.details.arrival.airport.city}
                        </div>
                        <div className='text-xs text-gray-500'>
                          {formatDate(
                            invoice.details.departure.date,
                            'dddd, MMM D',
                          )}
                        </div>
                      </td>
                      <td className='pt-5 px-3 align-middle first:pl-10 last:pr-10'>
                        <div className='flex items-center gap-2 '>
                          <img
                            crossOrigin='anonymous'
                            className='w-8 h-8'
                            src={invoice.details.arrival.flight.logo}
                            alt={invoice.details.arrival.flight.name}
                          />
                          <div>
                            <div className='font-semibold'>
                              {invoice.details.arrival.flight.name}
                            </div>
                            <div className='text-xs text-gray-500'>
                              {`${invoice.details.arrival.flight.code} ${invoice.details.arrival.flight.number}`}
                            </div>
                          </div>
                        </div>
                      </td>
                    </tr>
                  ),
                )}
              </tbody>
            </table>
          </div>

          {/* Payment Details */}
          <div className='mt-10 px-10'>
            <div className='text-base font-semibold text-primary'>
              Payment Details
            </div>
            <div className='max-w-xs mt-4'>
              <div className='text-sm flex justify-between'>
                <div>Base Fare</div>
                <div>
                  {formatCurrency(invoice.details.priceDetail.totalBaseFare)}
                </div>
              </div>
              <div className='text-sm flex justify-between mt-2'>
                <div>Fees & Surcharges</div>
                <div>
                  {formatCurrency(
                    invoice.details.priceDetail.totalTaxesAndFees,
                  )}
                </div>
              </div>

              <div className=' text-sm flex justify-between mt-4 font-semibold'>
                <div>Total Amount</div>
                <div>
                  {formatCurrency(invoice.details.priceDetail.totalFare)}
                </div>
              </div>
            </div>
          </div>

          {/* Invoice issued by */}
          <div className='mt-10 px-10'>
            <table>
              <tbody>
                <tr>
                  <td className='text-sm py-2 px-3 first:pl-0 last:pr-0'>
                    <div>Invoice issued by</div>
                    <div className='text-primary font-medium mt-2'>
                      {invoice.from.name}
                    </div>
                  </td>
                  <td className='text-sm py-2 px-3 first:pl-0 last:pr-0'>
                    <div>HSN / SAC</div>
                    <div className='text-primary font-medium mt-2'>998551</div>
                  </td>
                  <td className='text-sm py-2 px-3 first:pl-0 last:pr-0'>
                    <div>Service Description</div>
                    <div className='text-primary font-medium mt-2'>
                      Reservation Services For Air Transportation
                    </div>
                  </td>
                </tr>
                <tr>
                  <td className='text-sm py-2 px-3 first:pl-0 last:pr-0'>
                    <div>{invoice.from.document.name}</div>
                    <div className='text-primary font-medium mt-2'>
                      {invoice.from.document.number}
                    </div>
                  </td>
                  <td
                    colSpan={2}
                    className='text-sm py-2 px-3 first:pl-0 last:pr-0'
                  >
                    <div>Registered Address</div>
                    <div className='text-primary font-medium mt-2'>
                      {getFormattedAddress(invoice.from.address)}
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          {/* {Important information} */}
          <div className='mt-12 px-10'>
            <div className='text-xs'>
              GST credit charged by the airline operator is only available
              against the invoice issued by the respective airline operator. If
              you are looking for the airline GST invoice, please check the
              airline website & download it from there.
            </div>
            <div className='text-xs mt-4'>
              This is not a valid travel document
            </div>
            <div className='text-xs mt-4'>
              This is a computer generated Invoice and does not require
              Signature/Stamp.
            </div>
          </div>

          {/* {Helpline} */}
          <div className='mt-10 px-10 pb-8'>
            <div className='text-sm font-semibold text-primary'>
              {invoice.from.email}
            </div>
          </div>

          {/* {Final Logo} */}
          {/* <div className="pb-8 mt-auto">
                        <div className="mt-2 px-10 flex flex-col items-end ">
                            <div className="text-[0.7rem]">Travel Technology Partner</div>
                            <img className="h-6 inline-block mt-2" src="/icons/logo.svg" alt="" />
                        </div>
                    </div> */}
        </div>
      </div>
    </>
  );
};

export default BookingInvoice;
