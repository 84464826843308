export enum PluginEnum {
  FAREPOD_PAY = 'FAREPOD_PAY',
  RAZORPAY_PAYMENT = 'RAZORPAY_PAYMENT',
  WHATSAPP_BOT = 'WHATSAPP_BOT',
  DYNAMIC_AI_MARKUP = 'DYNAMIC_AI_MARKUP',
  TWILIO_SMS = 'TWILIO_SMS ',
  ANNOUNCEMENTS = 'ANNOUNCEMENTS',
  LOGIN_IMAGES = 'LOGIN_IMAGES',
  OFFERS_AND_UPDATES = 'OFFERS_AND_UPDATES',
  MY_SERIES = 'MY_SERIES',
  OUTWARD_API = 'OUTWARD_API',
  CCAVENUE_PAYMENT = 'CCAVENUE_PAYMENT',
  HDFC_GATEWAY_PAYMENT = 'HDFC_GATEWAY_PAYMENT',
}

export enum PaymentGatewayPluginEnum {
  // RAZORPAY_PAYMENT = 'RAZORPAY_PAYMENT',
  CCAVENUE_PAYMENT = 'CCAVENUE_PAYMENT',
  HDFC_GATEWAY_PAYMENT = 'HDFC_GATEWAY_PAYMENT',
}
