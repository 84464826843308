import moment from 'moment';

export const formatTime = (time: string, format: string = 'HH:mm') => {
  return moment(time, 'HHmmss').format(format);
};

export const formatDate = (date: string, format: string = 'ddd, DD MMM') => {
  return moment(date, 'YYYYMMDD').format(format);
};

export const formatMinutesToTime = (minutes: number) => {
  return `${Math.floor(minutes / 60).toString()}hr ${Math.floor(minutes % 60)
    .toString()
    .padStart(2, '0')}mn`;
};

export const getDatesBetween = (
  startDate: string,
  endDate: string,
  format = 'YYYYMMDD',
): string[] => {
  let now = moment(startDate, format).clone();
  const lastDate = moment(endDate, format);

  const dates = [];

  while (now.isSameOrBefore(lastDate)) {
    dates.push(now.format(format));
    now.add(1, 'days');
  }

  return dates;
};
