import { useQuery } from '@tanstack/react-query';
import BgOverlay from 'app/components/BgOverlay/BgOverlay';
import PageLoader from 'app/components/PageLoader/PageLoader';
import http from 'app/config/http';
import { BackIcon } from 'app/icons';

import React, { FC } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import SubAgencyUserEditForm from './components/SubAgencyUserEditForm/SubAgencyUserEditForm';

interface SubAgencyUserEditProps {}

const SubAgencyUserEdit: FC<SubAgencyUserEditProps> = (props) => {
  const { userId } = useParams();
  const navigate = useNavigate();

  const query = useQuery(['agencyUsers', userId], async () => {
    const { data } = await http.get(
      `${process.env.REACT_APP_API_URL}/api/v1/sub-agent/sub-agency/sub-agency-users/${userId}`,
    );

    return data;
  });

  if (query.isLoading) return <PageLoader />;

  return (
    <>
      <BgOverlay />

      <div className='container min-h-full'>
        <div className='flex py-8'>
          <div className='title text-secondary-fg flex items-center gap-3'>
            <span className='md:hidden' onClick={() => navigate(-1)}>
              <BackIcon />
            </span>{' '}
            Edit User
          </div>
        </div>
        <div className='card p-9 mb-4'>
          <SubAgencyUserEditForm user={query.data} />
        </div>
      </div>
    </>
  );
};

export default SubAgencyUserEdit;
