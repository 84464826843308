import { FC, useEffect } from 'react';
import { toast } from 'react-toastify';
import BgOverlay from 'app/components/BgOverlay/BgOverlay';
import PageLoader from 'app/components/PageLoader/PageLoader';
import ReviewModal from './components/ReviewModal/ReviewModal';
import OnlinePaymentCard from './OnlinePaymentCard/OnlinePaymentCard';
import ProcessHeading from 'app/components/ProcessHeading/ProcessHeading';
import { useParams, useSearchParams } from 'react-router-dom';
import BookingErrorModal from 'app/components/BookingErrorModal/BookingErrorModal';
import BookingDetailsCard from './components/BookingDetailsCard/BookingDetailsCard';
import SubAgencyBalanceAndPay from './components/SubAgencyBalanceAndPay/SubAgencyBalanceAndPay';
import useBookingPayment from './useBookingPayment';
import useOnlinePayment from 'app/hooks/use-online-payment';
import BookedPartialTicketPriceDetails from 'app/components/BookedPartialTicketPriceDetails/BookedPartialTicketPriceDetails';

export enum PaymentTypeEnum {
  CREDIT = 'CREDIT',
  ONLINE = 'ONLINE',
}

export interface IPaymentGateway {
  name: string;
  percentageCharges: number;
  identifier: string;
  logoUrl: string;
  bookingAmount: number;
  gatewayCharges: number;
  totalOrderAmount: number;
  id: string;
  token: string;
}

interface PaymentProps {}

const PaymentRef: FC<PaymentProps> = (props) => {
  const { id } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    const status = searchParams.get('status');
    if (status !== null) {
      if (status === 'failed') {
        toast.error('Payment Failed');
      }
      searchParams.delete('status');
      setSearchParams(searchParams);
    }
  }, []);

  searchParams.get('status');

  const {
    booking,
    isLoading,
    availableBalance,
    reviewDialog,
    warningDialog,
    handleCreditPayment,
    paymentProcessing,
  } = useBookingPayment(id);

  const { UiComponent, show, onlinePaymentAvailable } = useOnlinePayment({
    // url: `/api/v1/sub-agent/bookings/${id}/payment/ccavenue/initiate`,
    url: {
      CCAVENUE_PAYMENT: `/api/v1/sub-agent/bookings/${id}/payment/ccavenue/initiate`,
      HDFC_GATEWAY_PAYMENT: `/api/v1/sub-agent/bookings/${id}/payment/hdfc/initiate`,
    },
    handleOuster: () => {
      reviewDialog.hide();
    },
  });

  const getSubmitLabel = () => {
    if (reviewDialog.data === PaymentTypeEnum.CREDIT)
      return 'Pay using Credit Balance';
    else if (reviewDialog.data === PaymentTypeEnum.ONLINE) return 'Pay Online';
  };

  const handleSubmit = () => {
    if (reviewDialog.data === PaymentTypeEnum.CREDIT) handleCreditPayment();
    else if (reviewDialog.data === PaymentTypeEnum.ONLINE)
      show(booking.priceDetail.totalFare);
  };

  if (isLoading) return <PageLoader />;

  return (
    <div>
      <BgOverlay />
      <div className='container'>
        <ProcessHeading title='Make payment for your booking' activeIndex={2} />

        <div className='grid grid-cols-3 gap-9'>
          <div className='col-span-3 lg:col-span-2'>
            {/* Sub Agency balance and pay using it  */}
            <SubAgencyBalanceAndPay
              availableBalance={availableBalance}
              handlePayment={() => reviewDialog.show(PaymentTypeEnum.CREDIT)}
            />
            {/* Online payment card */}
            <OnlinePaymentCard
              disableOnlinePayment={!onlinePaymentAvailable}
              handleOnlinePayment={() =>
                reviewDialog.show(PaymentTypeEnum.ONLINE)
              }
            />
          </div>

          {/* side cards */}
          <div className='col-span-3 lg:col-span-1'>
            <BookingDetailsCard booking={booking} />
            <BookedPartialTicketPriceDetails flightDetails={booking} />
          </div>
        </div>
      </div>

      {UiComponent()}

      <ReviewModal
        show={reviewDialog.isOpen}
        onClose={reviewDialog.hide}
        booking={booking}
        submitLabel={getSubmitLabel()}
        onSubmit={handleSubmit}
        isLoading={paymentProcessing}
      />
      <BookingErrorModal
        show={warningDialog.isOpen}
        onClose={warningDialog.hide}
        id={warningDialog.data.id}
        errorMessage={warningDialog.data.errorMessage}
      />
    </div>
  );
};

export default PaymentRef;
