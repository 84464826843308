import React, { FC } from 'react';

interface BankAccountsProps {
  accounts: any;
}

const BankAccounts: FC<BankAccountsProps> = (props) => {
  const { accounts } = props;

  return (
    <div className='mt-9 overflow-auto '>
      <table className='w-full min-w-[650px]'>
        <thead>
          <tr>
            <td className='bg-[#F9FAFB] text-sm font-semibold py-5 pl-10'>
              Account Holder
            </td>
            <td className='bg-[#F9FAFB] text-sm font-semibold py-5'>
              Account Number
            </td>
            <td className='bg-[#F9FAFB] text-sm font-semibold py-5'>Type</td>
            <td className='bg-[#F9FAFB] text-sm font-semibold py-5'>IFSC</td>
            <td className='bg-[#F9FAFB] text-sm font-semibold py-5'>Bank</td>
          </tr>
        </thead>
        <tbody>
          {accounts.map((row: any, i: number) => {
            return (
              <tr key={i}>
                <td className='text-sm font-normal py-4 pl-10'>
                  {row.account.holderName}
                </td>
                <td className='text-sm font-normal py-4 '>
                  {row.account.number}
                </td>
                <td className='text-sm font-normal py-4 '>
                  {row.account.type}
                </td>
                <td className='text-sm font-normal py-4 '>{row.ifsc}</td>
                <td className='text-sm font-normal py-4  pr-10'>{row.name}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default BankAccounts;
