import React, { FC } from 'react';
import { Outlet } from 'react-router-dom';
import InventoryNavBar from './components/InventoryNavBar/InventoryNavBar';

interface SeriesInventoryProps {}

const SeriesInventory: FC<SeriesInventoryProps> = (props) => {
  return (
    <div className='relative'>
      <InventoryNavBar />
      <div className='pt-[94px] md:pt-[44px]'>
        <Outlet />
      </div>
    </div>
  );
};

export default SeriesInventory;
