import React, { FC } from 'react';
import {
  Dropdown,
  DropdownList,
  DropdownItem,
} from 'app/components/Dropdown/Dropdown';
import { MenuDotIcon } from 'app/icons';
import StatusBadge from 'app/components/StatusBadge/StatusBadge';
import { formatDate } from 'app/utils/date-utils';
import { formatCurrency } from 'app/utils/currency-utils';
import { ISeriesTicket } from 'app/types/series-ticket';
import { useNavigate } from 'react-router-dom';

interface UpcomingDepartureTableProps {
  bookings: ISeriesTicket[];
}

const UpcomingDepartureTable: FC<UpcomingDepartureTableProps> = (props) => {
  const navigate = useNavigate();

  const getStatusClass = (status: string) => {
    switch (status) {
      case 'PENDING':
        return 'yellow';
      case 'COMPLETED':
      case 'SUBMITTED':
        return 'green';
      case 'CANCELLED':
        return 'red';
    }
  };

  const getStatusText = (status: string) => {
    switch (status) {
      case 'PENDING':
        return 'Naming Pending';
      case 'COMPLETED':
        return 'Completed';
      case 'SUBMITTED':
        return 'Submitted';
      case 'CANCELLED':
        return 'Cancelled';
    }
  };

  // const getStatusClass = (status: string) => {
  //     switch (status) {
  //         case 'PENDING':
  //             return 'yellow';
  //         case 'COMPLETED':
  //             return 'green';
  //         case 'CANCELLED':
  //             return 'red';
  //     }
  // };

  // const getStatusText = (status: string) => {
  //     switch (status) {
  //         case 'PENDING':
  //             return 'Naming Pending';
  //         case 'COMPLETED':
  //             return 'Completed';
  //         case 'CANCELLED':
  //             return 'Cancelled';
  //     }
  // };

  return (
    <div className='overflow-auto px-3'>
      <table className='w-full min-w-[800px]'>
        <tbody>
          {props.bookings.map((row) => (
            <tr key={row._id} className=''>
              <td className='td tb'>
                <div
                  className='font-semibold cursor-pointer text-blue-500'
                  onClick={() =>
                    navigate(`/my-series/series-inventory/${row._id}/bookings`)
                  }
                >
                  {row._id}
                </div>
                <StatusBadge
                  className='mt-1'
                  color={getStatusClass(row.status)}
                >
                  {getStatusText(row.status)}
                </StatusBadge>
              </td>
              <td className='td tb'>
                <div className='font-semibold'>
                  {row.trip.departure.airport.city} to{' '}
                  {row.trip.arrival.airport.city}
                </div>
                <p className='mt-1'>
                  {formatDate(row.trip.departure.date, 'DD MMM, YYYY')}
                </p>
              </td>
              <td className='td tb'>
                <div className='font-semibold'>
                  {formatCurrency(row.totalfare)}
                </div>
                <p className='mt-1'>
                  {row.bookings.length} / {row.totalSeats} Sold
                </p>
              </td>
              <td className='td tb'>
                <div className='flex items-center gap-3'>
                  <img
                    className='w-9 h-9'
                    crossOrigin='anonymous'
                    src={row.flight.logo}
                    alt=''
                  />
                  <div>
                    <div className='font-semibold'>{row.flight.name}</div>
                    <p className='mt-1'>
                      {row.flight.code} {row.flight.number}
                    </p>
                  </div>
                </div>
              </td>
              <td className='td tb mx-4'>
                <Dropdown
                  target={
                    <div className='dropdown-action'>
                      <MenuDotIcon />
                    </div>
                  }
                  menu={
                    <DropdownList>
                      <DropdownItem
                        to={`/my-series/series-inventory/${row._id}/bookings`}
                      >
                        View Ticket
                      </DropdownItem>
                      {/*<DropdownItem onClick={() => window.open(`/bookings/${row._id}/invoice`)}>
                                                Invoice
                                            </DropdownItem> */}
                    </DropdownList>
                  }
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default UpcomingDepartureTable;
