import { getGenderFromTitle } from 'app/utils/common-utils';
import { calculateStops, getFormattedStops } from 'app/utils/flight-utils';
import {
  formatDate,
  formatMinutesToTime,
  formatTime,
} from 'app/utils/date-utils';
import { FC } from 'react';
import { IBooking } from 'app/types';

interface IBookingDetailsCard {
  booking: IBooking;
}

const BookingDetailsCard: FC<IBookingDetailsCard> = (props) => {
  const { booking } = props;

  return (
    <div className='card px-5 py-4 mb-4'>
      <div className='font-semibold'>Your Booking</div>
      <div className='mt-8 flex justify-between gap-4'>
        <div className='flex items-center gap-2'>
          <img
            crossOrigin='anonymous'
            className='w-9 h-9'
            src={booking.segments[0].flight.logo}
            alt=''
          />
          <div>
            <div className='flex gap-2'>
              <div className='font-medium'>
                {formatDate(booking.departure.date, 'DD MMM')}
              </div>
              <div className='font-semibold'>
                {formatTime(booking.departure.time)}
              </div>
            </div>
            <div className='text-xs text-black/50'>
              {booking.departure.airport.city}
            </div>
          </div>
        </div>

        <div>
          <div className='text-xs font-semibold text-black/50'>
            {formatMinutesToTime(booking.duration)}
          </div>
          <div className='text-xs font-semibold text-black/50 mt-1'>
            {getFormattedStops(calculateStops(booking.segments))}
          </div>
        </div>

        <div>
          <div className='flex gap-2'>
            <div className='font-medium'>
              {formatDate(booking.arrival.date, 'DD MMM')}
            </div>
            <div className='font-semibold'>
              {formatTime(booking.arrival.time)}
            </div>
          </div>
          <div className='text-xs text-black/50'>
            {booking.arrival.airport.city}
          </div>
        </div>
      </div>

      {/* Travel Details */}
      <div className='mt-8 font-semibold'>Traveller</div>
      {/* Travlers Details */}
      <div className='mt-4'>
        <ul className='list-decimal pl-4 text-sm font-semibold space-y-2'>
          {booking.passengers.adults.map((row, i) => (
            <li key={i}>
              <div>
                {row.title} {row.firstName} {row.lastName} -{' '}
                {getGenderFromTitle(row.title)[0]}, Adult
              </div>
            </li>
          ))}
          {booking.passengers.children.map((row, i) => (
            <li key={i}>
              <div>
                {row.title} {row.firstName} {row.lastName} -{' '}
                {getGenderFromTitle(row.title)[0]}, Child
              </div>
            </li>
          ))}
          {booking.passengers.infants.map((row, i) => (
            <li key={i}>
              <div>
                {row.title} {row.firstName} {row.lastName} -{' '}
                {getGenderFromTitle(row.title)[0]}, Infant
              </div>
            </li>
          ))}
        </ul>
        <div className='text-sm font-normal mt-3'>
          {booking.contactDetails.mobileNumber}
        </div>
      </div>
    </div>
  );
};

export default BookingDetailsCard;
