import { IStaticPage } from 'app/types';
import { FC } from 'react';
import { NavLink } from 'react-router-dom';

interface StaticNavProps {
  staticPages: IStaticPage[];
  name: string;
}

const StaticNav: FC<StaticNavProps> = (props) => {
  const { staticPages, name } = props;

  return (
    <div className='mx-8'>
      <div className='flex flex-col items-center sm:flex-row justify-center text-sm mt-8 font-normal text-secondary-fg'>
        {staticPages.map((staticPage) => {
          return (
            <NavLink
              key={staticPage.route}
              // to={staticPage.route}
              to={`/static/${staticPage._id}`}
              className={({ isActive }) =>
                ` text-sm py-1  ${isActive ? 'font-semibold' : 'font-normal'} `
              }
            >
              <div className='flex gap-2 items-center px-2 shadow-black '>
                {staticPage.name}
              </div>
            </NavLink>
          );
        })}
      </div>
      <div className='flex justify-center text-sm mt-2 font-normal text-secondary-fg pb-6 '>
        © Copyright {name} {new Date().getFullYear()}. All rights reserved.
      </div>
    </div>
  );
};

export default StaticNav;
