import {
  MobileNavAccountsIcon,
  MobileNavBookingIcon,
  MobileNavFlightsIcon,
  MobileNavSeriesIcon,
  MobileNavUsersIcon,
} from 'app/icons';
import { TRootState } from 'app/store';
import React, { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { NavLink, useLocation } from 'react-router-dom';
import Tooltip from '../Tooltip/Tooltip';
import { useQuery } from '@tanstack/react-query';
import http from 'app/config/http';
import PageLoader from '../PageLoader/PageLoader';

interface FooterProps {}

const Footer: FC<FooterProps> = (props) => {
  const { user } = useSelector((state: TRootState) => state.auth);
  const [nestedPath, setNestedPath] = useState(false);
  const location = useLocation();

  const isNestedPath = () => {
    const path = location.pathname;

    if (location.pathname === '/flights' || location.pathname === '/flights/')
      return true;

    //  -1 instead of 0 considering root always starts with "/"
    let count = -1;

    if (path[path.length - 1] === '/') {
      count -= 1;
    }

    // for series invetory plugin
    if (path.startsWith('/my-series/')) {
      count -= 1;
    }
    for (var i = 0; i < path.length; i++) {
      if (path[i] === '/') {
        count++;
      }
    }

    return count === 0 ? false : true;
  };

  let baseNavItems = [
    {
      to: '/mobile-search',
      label: 'Flights',
      icon: <MobileNavFlightsIcon />,
    },
    {
      to: '/bookings',
      label: 'Bookings',
      icon: <MobileNavBookingIcon />,
    },
    {
      to: 'my-agency/account',
      label: 'Accounts',
      icon: <MobileNavAccountsIcon />,
    },
    {
      to: '/users',
      label: 'Users',
      icon: <MobileNavUsersIcon />,
    },
  ];

  useEffect(() => {
    if (isNestedPath()) {
      setNestedPath(true);
    } else {
      setNestedPath(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const navItemsQuery = useQuery(['plugin'], async () => {
    const { data } = await http.get(
      `${process.env.REACT_APP_API_URL}/api/v1/sub-agent/plugins/nav-items`,
    );
    return data;
  });

  if (navItemsQuery.isLoading) return <PageLoader />;

  const activePlugins = navItemsQuery.data;

  const availablePlugins: any = {
    'my-series': {
      to: '/my-series',
      label: 'MySeries',
      icon: <MobileNavSeriesIcon />,
    },
  };

  activePlugins.forEach((row: any) => {
    baseNavItems.push(availablePlugins[row._id]);
  });

  return (
    <>
      {!nestedPath && (
        <div className='md:hidden z-[110] px-7 pb-4 fixed bottom-0 left-0 right-0 bg-white shadow-2xl shadow-black'>
          <div className='flex justify-between gap-4'>
            {baseNavItems.map((item) => {
              return (
                <NavLink
                  key={item.to}
                  to={item.to}
                  className={({ isActive }) =>
                    `pt-4 basis-1/4 text-xs relative leading-normal hide-tapColor          ${
                      isActive
                        ? ' before:content-[""] before:inline-block before:h-[6px] before:bg-secondary before:w-full before:absolute before:top-0 font-bold text-black '
                        : 'font-normal text-black/50'
                    }`
                  }
                >
                  <div className='flex flex-col items-center justify-between  '>
                    <div>{item.icon}</div>
                    <div className=''>{item.label}</div>
                  </div>
                </NavLink>
              );
            })}
          </div>
        </div>
      )}

      <div className='hidden md:flex flex-col md:flex-row px-8 py-4 h-[var(--footer-height)] items-center text-sm font-normal justify-between absolute bottom-0 left-0 right-0'>
        <div className='w-1'></div>

        <div className=''>
          Copyright © {new Date().getFullYear()} {user.agency.name}
        </div>

        <div className=' text-sm font-semibold'>
          <Tooltip
            content={
              <div className='px-6 py-1'>
                <ul className='divide-y divide-gray-200'>
                  <li className='py-3'>
                    <div className='text-sm font-medium text-right'>
                      {user.agency.email}
                    </div>
                  </li>
                  <li className='py-3'>
                    <div className='text-sm font-medium text-right'>
                      {user.agency.phoneNumber}
                    </div>
                  </li>
                </ul>
              </div>
            }
            placement='bottom-end'
          >
            Support
          </Tooltip>
        </div>
      </div>
    </>
  );
};

export default Footer;
