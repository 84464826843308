import { useQuery } from '@tanstack/react-query';
import BgOverlay from 'app/components/BgOverlay/BgOverlay';
import PageLoader from 'app/components/PageLoader/PageLoader';
import http from 'app/config/http';
import React, { FC } from 'react';
import { useParams } from 'react-router-dom';
import ChangeSeatsForm from './components/ChangeSeatsForm/ChangeSeatsForm';
import SeriesInventoryEditForm from './components/SeriesInventoryEditForm/SeriesInventoryEditForm';

interface SeriesInventoryEditProps {}

const SeriesInventoryEdit: FC<SeriesInventoryEditProps> = (props) => {
  const { inventoryId } = useParams();

  const query = useQuery(['seriesInventoryTicket'], async () => {
    const { data } = await http.get(
      `${process.env.REACT_APP_API_URL}/api/v1/sub-agent/series-tickets/${inventoryId}`,
    );

    return data;
  });

  if (query.isLoading) return <PageLoader />;

  const ticket = query.data;

  return (
    <>
      {' '}
      <BgOverlay />
      <div className='container min-h-full'>
        <div className='flex py-8'>
          <div className='title text-secondary-fg'>Edit Series Inventory</div>
        </div>
        <SeriesInventoryEditForm ticket={ticket} />
        <ChangeSeatsForm ticketId={ticket._id} totalSeats={ticket.totalSeats} />
      </div>
    </>
  );
};

export default SeriesInventoryEdit;
