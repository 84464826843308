import http from 'app/config/http';
import { INavLink } from './NavBarTwo';
import { useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';

interface INavRes {
  _id: string;
  name: string;
}

export const baseNavLinks: INavLink[] = [
  {
    to: '/',
    label: 'Flights',
    children: [],
  },
  {
    to: '/bookings',
    label: 'My Bookings',
    children: [],
  },
  {
    to: '/my-agency',
    label: 'My Agency',
    children: [
      {
        to: '/my-agency/account',
        label: 'Accounts',
        children: [],
      },
      {
        to: '/my-agency/Passengers',
        label: 'Passenger',
        children: [],
      },
    ],
  },
  {
    to: '/tour-query',
    label: 'Tour Queries',
    children: [],
  },
];

const useNavLink = () => {
  const [navItems, setNavItems] = useState<INavLink[]>([...baseNavLinks]);

  const { data, isLoading } = useQuery<INavRes[]>(['plugin'], async () => {
    const { data } = await http.get(
      `${process.env.REACT_APP_API_URL}/api/v1/sub-agent/plugins/nav-items`,
    );
    return data;
  });

  const handleMySeriesNavItem = (navItem: INavRes): INavLink => ({
    to: navItem._id,
    label: navItem.name,
    children: [
      {
        to: '/my-series',
        label: 'Dashboard',
        children: [],
      },
      {
        to: '/my-series/series-inventory',
        label: 'Inventory',
        children: [],
      },
      {
        to: '/my-series/bookings',
        label: 'Bookings',
        children: [],
      },
      {
        to: '/my-series/account',
        label: 'Accounts',
        children: [],
      },
    ],
  });

  useEffect(() => {
    if (data) {
      const dynmaicNavItems = data.map((navItem) => {
        if (navItem._id === 'my-series') return handleMySeriesNavItem(navItem);
        return { to: navItem._id, label: navItem.name, children: [] };
      });

      setNavItems((previousNavItems) => [
        ...previousNavItems,
        ...dynmaicNavItems,
      ]);
    }
  }, [data, isLoading]);

  const getUniqueNavItems = (navItems: INavLink[]): INavLink[] => {
    const uniqueObjects: Record<string, boolean> = {};

    return navItems.filter((navItem) => {
      if (!uniqueObjects[navItem.label]) {
        uniqueObjects[navItem.label] = true;
        return true;
      }
      return false;
    });
  };

  return {
    // comment added
    isLoading,
    navItems: getUniqueNavItems(navItems),
  };
};

export default useNavLink;
