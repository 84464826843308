import { ChevronLeftIcon, ChevronRightIcon } from 'app/icons';
import { FC, ReactNode } from 'react';

interface PickerHeaderProps {
  isPrevDisabled: boolean;
  isNextDisabled: boolean;
  onPrevClick: () => void;
  onNextClick: () => void;
  content: ReactNode;
}

const PickerHeader: FC<PickerHeaderProps> = (props) => {
  const { isPrevDisabled, isNextDisabled, onPrevClick, onNextClick, content } =
    props;

  return (
    <div className='flex items-center justify-center gap-4'>
      <div
        role='button'
        aria-label='Previous'
        className={[
          'grid place-items-center w-6 h-6 rounded-md',
          !isPrevDisabled
            ? 'text-gray-700 border border-[#E9EBEE]/[8%] shadow-[0px_0px_2px_rgba(18,40,44,0.12),0px_2px_4px_rgba(18,40,44,0.1)] cursor-pointer bg-white '
            : 'text-gray-300',
        ].join(' ')}
        onClick={onPrevClick}
      >
        <ChevronLeftIcon width='5' height='8' />
      </div>

      <div className='flex items-center justify-center text-gray-900 text-sm font-medium text-center w-[152px]'>
        {content}
      </div>

      <div
        role='button'
        aria-label='Next'
        className={[
          'grid place-items-center w-6 h-6 rounded-md ',
          !isNextDisabled
            ? 'text-gray-700 border border-[#E9EBEE]/[8%] shadow-[0px_0px_2px_rgba(18,40,44,0.12),0px_2px_4px_rgba(18,40,44,0.1)] cursor-pointer bg-white '
            : 'text-gray-300',
        ].join(' ')}
        onClick={onNextClick}
      >
        <ChevronRightIcon width='5' height='8' />
      </div>
    </div>
  );
};

export default PickerHeader;
