import React, { FC, useState } from 'react';
import * as Yup from 'yup';
import {
  IDayFlight,
  ISeriesInventoryForm,
} from 'app/pages/SeriesInventory/Pages/SeriesInventoryAdd/types';
import moment from 'moment';
import {
  ErrorMessage,
  Field,
  FieldArray,
  Form,
  Formik,
  FormikProps,
} from 'formik';
import AirportSearchField from 'app/components/AirportSearchField/AirportSearchField';
import AirlineSearchField from 'app/components/AirlineSearchField/AirlineSearchField';
import { formatCurrency } from 'app/utils/currency-utils';
import LoaderButton from 'app/components/LoaderButton/LoaderButton';
import { IAirline, IAirport } from 'app/types';
import { getDatesBetween } from 'app/utils/date-utils';
import http from 'app/config/http';
import { useMutation, useQuery } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import PageLoader from 'app/components/PageLoader/PageLoader';
import { DatePicker } from 'app/library/datepicker';
import { CalendarIcon } from 'app/icons';
import { EXEMPT_PNR } from 'app/config';

interface SeriesInventoryAddFormProps {}

const InventoryFormSchema = Yup.object({
  from: Yup.object().nullable().required('This field is required.'),
  to: Yup.object().nullable().required('This field is required.'),
  departureDate: Yup.string().trim().required('This field is required.'),
  departureTime: Yup.string().trim().required('This field is required.'),
  arrivalDate: Yup.string()
    .trim()
    .required('This field is required.')
    .test(
      'is-arrival-date-after-departure-date',
      'Arrival date should be after departure date',
      function (arrivalDate) {
        const { departureDate } = this.parent;

        const arrival = moment(arrivalDate, 'YYYY-MM-DD');
        const departure = moment(departureDate, 'YYYY-MM-DD');

        return arrival.isSameOrAfter(departure);
      },
    ),
  arrivalTime: Yup.string()
    .trim()
    .required('This field is required.')
    .test(
      'is-arrival-time-after-departure-time',
      'Arrival time should be after departure time',
      function (arrivalTime) {
        const { departureDate, departureTime, arrivalDate } = this.parent;

        const arrival = moment(
          `${arrivalDate}${arrivalTime}`,
          'YYYY-MM-DDHH:mm',
        );
        const departure = moment(
          `${departureDate}${departureTime}`,
          'YYYY-MM-DDHH:mm',
        );

        return arrival.isAfter(departure);
      },
    ),
  fromDate: Yup.string().trim().required('This field is required.'),
  toDate: Yup.string()
    .trim()
    .required('This field is required.')
    .test(
      'is-arrival-date-after-departure-date',
      'This date should be after from date',
      function (arrivalDate) {
        const { departureDate } = this.parent;

        const arrival = moment(arrivalDate, 'YYYY-MM-DD');
        const departure = moment(departureDate, 'YYYY-MM-DD');

        return arrival.isSameOrAfter(departure);
      },
    ),
  flight: Yup.object().nullable().required('This field is required.'),
  flightNumber: Yup.string().trim().required('This field is required.'),
  // pnr: Yup.string().trim().required('This field is required.'),
  costPrice: Yup.number()
    .required('This field is required.')
    .typeError('Please specify a valid number.'),
  tax: Yup.number()
    .required('This field is required.')
    .typeError('Please specify a valid number.'),
  fareIdentifier: Yup.string().trim().required('This field is required.'),
  totalSeats: Yup.number()
    .required('This field is required.')
    .typeError('Please specify a valid number.'),
  isDirectFlight: Yup.string().trim().required('This field is required.'),
  markup: Yup.number()
    .required('This field is required.')
    .typeError('Please specify a valid number.'),
  isLiveOnNetwork: Yup.string().trim().required('This field is required.'),
  fareRules: Yup.string().trim().required('This field is required.'),
  baggage: Yup.object({
    checkIn: Yup.number()
      .required('This field is required.')
      .typeError('Please specify a valid number.'),
    cabin: Yup.number()
      .required('This field is required.')
      .typeError('Please specify a valid number.'),
  }),
  infant: Yup.object({
    costPrice: Yup.number()
      .required('This field is required.')
      .typeError('Please specify a valid number.'),
    tax: Yup.number()
      .required('This field is required.')
      .typeError('Please specify a valid number.'),
  }),
  stopsOverAirport: Yup.array().of(
    Yup.object().nullable().required('This field is required.'),
  ),
  // flightInfoArray: Yup.array().of(
  //     Yup.object().shape({
  //         date: Yup.string().trim().required('This field is required.'),
  //         pnr: Yup.string().trim().required('This field is required.'),
  //     })
  // )

  // validation for  OFFER_FARE_WITHOUT_PNR
  flightInfoArray: Yup.array().when('fareIdentifier', {
    is: function (fareIdentifier: string) {
      return fareIdentifier === EXEMPT_PNR;
    },
    then: Yup.array().of(
      Yup.object().shape({
        date: Yup.string().trim().required('This field is required.'),
        pnr: Yup.string().trim(),
      }),
    ),
    otherwise: Yup.array().of(
      Yup.object().shape({
        date: Yup.string().trim().required('This field is required.'),
        pnr: Yup.string().trim().required('This field is required.'),
      }),
    ),
  }),

  // .of(
  //     Yup.object().shape({
  //         date: Yup.string().trim().required('This field is required.'),
  //         pnr: Yup.string().trim().required('This field is required.'),
  //     })
  // ),
});

// pnr: Yup.string().when('fareIdentifier', {
//     is: function (fareIdentifier: string) {
//         console.log({ fareIdentifier });
//         return fareIdentifier === 'OFFER_FARE_WITHOUT_PNR';
//     },
//     then: Yup.string().notRequired(),
//     otherwise: Yup.string().trim().required('This field is required. qqq'),
// }),

const initialValues: ISeriesInventoryForm = {
  from: null,
  fromTerminal: '',
  to: null,
  toTerminal: '',
  departureDate: moment().format('YYYY-MM-DD'),
  departureTime: moment().format('HH:mm'),
  arrivalDate: moment().format('YYYY-MM-DD'),
  arrivalTime: moment().format('HH:mm'),
  fromDate: moment().format('YYYY-MM-DD'),
  toDate: moment().format('YYYY-MM-DD'),
  flight: null,
  flightNumber: '',
  costPrice: 0,
  tax: 0,
  fareIdentifier: '',
  totalSeats: 1,
  isDirectFlight: true,
  markup: 0,
  isLiveOnNetwork: true,
  fareRules: 'NON-REFUNDABLE,NON-CHANGEABLE',
  baggage: {
    checkIn: '15',
    cabin: '7',
  },
  infant: {
    costPrice: 0,
    tax: 0,
  },
  stopsOverAirport: [],
  flightInfoArray: [
    {
      date: moment().format('YYYY-MM-DD'),
      pnr: '',
    },
  ],
};

const SeriesInventoryAddForm: FC<SeriesInventoryAddFormProps> = (props) => {
  const navigate = useNavigate();
  const [isDynamicMarkupSelected, setIsDynamicMarkupSelected] =
    useState<boolean>(false);

  const fareIdentifierQuery = useQuery(['fareIdenfiers'], async () => {
    const { data } = await http.get(
      `${process.env.REACT_APP_API_URL}/api/v1/sub-agent/series-tickets/fare-identifiers`,
    );

    return data;
  });

  const mutation = useMutation(async (payload: any) => {
    const { data } = await http.post(
      `${process.env.REACT_APP_API_URL}/api/v1/sub-agent/series-tickets`,
      payload,
    );

    return data;
  });

  const handleSubmit = async (values: any) => {
    // const { departureDate, arrivalDate } = values;
    // const dates = getDatesBetween(
    //     moment(departureDate, 'YYYY-MM-DD').format('YYYYMMDD'),
    //     moment(arrivalDate, 'YYYY-MM-DD').format('YYYYMMDD')
    // );

    const payload = {
      // fromDate: moment(values.fromDate, 'YYYY-MM-DD').format('YYYYMMDD'),
      // toDate: moment(values.toDate, 'YYYY-MM-DD').format('YYYYMMDD'),
      departure: {
        date: moment(values.departureDate, 'YYYY-MM-DD').format('YYYYMMDD'),
        time: moment(values.departureTime, 'HH:mm').format('HHmmss'),
        airport: { ...values.from, terminal: values.fromTerminal || undefined },
      },
      arrival: {
        date: moment(values.arrivalDate, 'YYYY-MM-DD').format('YYYYMMDD'),
        time: moment(values.arrivalTime, 'HH:mm').format('HHmmss'),
        airport: { ...values.to, terminal: values.toTerminal || undefined },
      },
      flight: {
        name: values.flight.name,
        code: values.flight.code,
        number: values.flightNumber,
      },
      costPrice: +values.costPrice,
      markup: +values.markup,
      tax: +values.tax,
      totalSeats: values.totalSeats,
      fareIdentifier: values.fareIdentifier.trim(),
      isLiveOnNetwork: values.isLiveOnNetwork,
      isDirectFlight: values.isDirectFlight,
      baggage: {
        checkIn: values.baggage.checkIn.trim() + ' Kg',
        cabin: values.baggage.cabin.trim() + ' Kg',
      },
      infant: {
        costPrice: values.infant.costPrice,
        tax: values.infant.tax,
      },
      fareRules: values.fareRules,
      stopsOverAirport: values.stopsOverAirport,
      flightInfoArray: values.flightInfoArray.map((d: IDayFlight) => ({
        pnr:
          values.fareIdentifier === 'OFFER_FARE_WITHOUT_PNR'
            ? ''
            : d.pnr.trim(),
        date: moment(d.date, 'YYYY-MM-DD').format('YYYYMMDD'),
      })),
    };

    try {
      await mutation.mutateAsync(payload);
      toast.success('Series Inventory added successfully.');
      navigate('/my-series/series-inventory');
    } catch (ex: any) {
      toast.error(ex?.data?.message || 'Some error occured, please try again.');
      console.log(ex);
    }
  };

  const getTotalCostPrice = (values: ISeriesInventoryForm): number => {
    return (+values.costPrice + +values.tax) * +values.totalSeats;
  };

  const getTotalSellingPrice = (values: ISeriesInventoryForm): number => {
    return (
      (+values.costPrice + +values.tax + +values.markup) * +values.totalSeats
    );
  };

  const getEarnings = (values: ISeriesInventoryForm): number => {
    return getTotalSellingPrice(values) - getTotalCostPrice(values);
  };

  const handleDateRangeChange = async (
    field: string,
    value: Date,
    values: any,
    setFieldValue: any,
  ) => {
    let formattedDate = moment(value).format('YYYY-MM-DD');

    setFieldValue(field, formattedDate);

    let dateRange: any = [];

    const createDateRange = (fromDate: any, toDate: any) => {
      return getDatesBetween(fromDate, toDate, 'YYYY-MM-DD');
    };

    if (field === 'fromDate') {
      if (moment(values.toDate).isBefore(formattedDate)) {
        setFieldValue('toDate', formattedDate);
        dateRange = [formattedDate];
      } else {
        dateRange = createDateRange(formattedDate, values.toDate);
      }
    }

    if (field === 'toDate') {
      dateRange = createDateRange(values.fromDate, formattedDate);
    }

    setFieldValue(
      'flightInfoArray',
      dateRange.map((date: string) => ({ date, pnr: '' })),
    );
  };

  if (fareIdentifierQuery.isLoading) return <PageLoader />;

  const fareIdentifiers = fareIdentifierQuery.data;
  return (
    <>
      <Formik
        initialValues={initialValues}
        enableReinitialize
        validationSchema={InventoryFormSchema}
        onSubmit={handleSubmit}
      >
        {({
          values,
          setFieldValue,
          errors,
          touched,
        }: FormikProps<ISeriesInventoryForm>) => (
          <Form>
            {/* Fight Details Card */}

            <div className='card px-8 py-7 mb-4'>
              <div className='text-base font-bold mb-8'>Fight Details</div>
              <div className='grid grid-cols-2 gap-6 mt-8'>
                <div className='col-span-2 sm:col-span-1'>
                  <label
                    className='text-xs mb-2 label-required '
                    htmlFor='from'
                  >
                    FROM
                  </label>
                  <div className='flex flex-col gap-2  sm:flex-row sm:gap-0'>
                    <AirportSearchField
                      id='from'
                      className='form-control sm:rounded-r-none'
                      placeholder={'Select From Airport'}
                      selectedAirport={values.from}
                      onSelectedAirportChange={(airport: IAirport) =>
                        setFieldValue('from', airport)
                      }
                    />
                    <div>
                      <Field
                        type='text'
                        id='fromTerminal'
                        name='fromTerminal'
                        className='form-control sm:rounded-l-none'
                        placeholder='Terminal'
                      />
                    </div>
                  </div>
                  <div className='form-error'>
                    <ErrorMessage name='from' />
                  </div>
                </div>
                <div className='col-span-2 sm:col-span-1'>
                  <label className='text-xs mb-2 label-required ' htmlFor='to'>
                    TO
                  </label>
                  <div className='flex flex-col gap-2  sm:flex-row sm:gap-0'>
                    <AirportSearchField
                      id='to'
                      className='form-control sm:rounded-r-none'
                      placeholder={'Select From Airport'}
                      selectedAirport={values.to}
                      onSelectedAirportChange={(airport: IAirport) =>
                        setFieldValue('to', airport)
                      }
                    />
                    <div>
                      <Field
                        type='text'
                        id='toTerminal'
                        name='toTerminal'
                        className='form-control sm:rounded-l-none'
                        placeholder='Terminal'
                      />
                    </div>
                  </div>
                  <div className='form-error'>
                    <ErrorMessage name='to' />
                  </div>
                </div>
              </div>

              <div className='grid grid-cols-3 gap-6 mt-8'>
                <div className='col-span-3  sm:col-span-2 xl:col-span-1'>
                  {/* <div className="font-semibold">Stops</div> */}
                  <label className='text-xs mb-2' htmlFor='from'>
                    STOPS
                  </label>
                  <FieldArray
                    name='stopsOverAirport'
                    render={(arrayHelpers) => (
                      <div>
                        {values.stopsOverAirport.map((_, index) => (
                          <div
                            key={index}
                            className='flex gap-4 items-center mt-2'
                          >
                            <div className='w-full'>
                              <div className='flex gap-2 items-center'>
                                <AirportSearchField
                                  id='index'
                                  className='form-control '
                                  placeholder={'Select Airport'}
                                  selectedAirport={
                                    values.stopsOverAirport[index]
                                  }
                                  onSelectedAirportChange={(
                                    airport: IAirport,
                                  ) =>
                                    setFieldValue(
                                      `stopsOverAirport[${index}]`,
                                      airport,
                                    )
                                  }
                                />
                                <button
                                  type='button'
                                  className='text-3xl text-red-500'
                                  onClick={() => arrayHelpers.remove(index)}
                                >
                                  &times;
                                </button>
                              </div>

                              <div className='form-error'>
                                <ErrorMessage
                                  name={`stopsOverAirport[${index}]`}
                                />
                              </div>
                            </div>
                          </div>
                        ))}
                        <button
                          type='button'
                          className='text-sm text-blue-500 mt-4'
                          onClick={() => arrayHelpers.push(null)}
                        >
                          + Add Stop
                        </button>
                      </div>
                    )}
                  />
                </div>
              </div>

              <div className='grid grid-cols-2 gap-2 sm:gap-6 mt-8'>
                <div className='col-span-2 sm:col-span-1'>
                  <label className='text-xs mb-2 ' htmlFor='departureTime'>
                    DEPARTURE TIME
                  </label>
                  <Field
                    type='time'
                    id='departureTime'
                    name='departureTime'
                    className='form-control'
                  />
                  <div className='form-error'>
                    <ErrorMessage name='departureTime' />
                  </div>
                </div>

                <div className='col-span-2 sm:col-span-1'>
                  <label className='text-xs mb-2 ' htmlFor='arrivalTime'>
                    ARRIVAL TIME
                  </label>
                  <Field
                    type='time'
                    id='arrivalTime'
                    name='arrivalTime'
                    className='form-control'
                  />
                  <div className='form-error'>
                    <ErrorMessage name='arrivalTime' />
                  </div>
                </div>
              </div>

              <div className='grid grid-cols-3 gap-2 sm:gap-6 mt-6'>
                <div className='col-span-3 sm:col-span-1'>
                  <label
                    className='text-xs mb-2 label-required '
                    htmlFor='flight'
                  >
                    SELECT FLIGHT
                  </label>
                  <AirlineSearchField
                    id='flight'
                    className='form-control'
                    placeholder={'Select Flight'}
                    selectedAirline={values.flight}
                    onSelectedAirlineChange={(airline: IAirline) =>
                      setFieldValue('flight', airline)
                    }
                  />
                  <div className='form-error'>
                    <ErrorMessage name='flight' />
                  </div>
                </div>
                <div className='col-span-3 sm:col-span-1'>
                  <label
                    className='text-xs mb-2 label-required '
                    htmlFor='flightNumber'
                  >
                    ENTER FLIGHT NUMBER
                  </label>
                  <Field
                    type='text'
                    id='flightNumber'
                    name='flightNumber'
                    className='form-control'
                  />
                  <div className='form-error'>
                    <ErrorMessage name='flightNumber' />
                  </div>
                </div>

                {/* <div className="col-span-3 sm:col-span-1">
                                    <label className="text-xs mb-2 label-required" htmlFor="pnr">
                                        ENTER AIRLINE PNR
                                    </label>
                                    <Field type="text" id="pnr" name="pnr" className="form-control" />
                                    <div className="form-error">
                                        <ErrorMessage name="pnr" />
                                    </div>
                                </div> */}
              </div>
            </div>
            {/* Price Markup Card */}
            <div className='card px-8 py-7 mb-4'>
              <div className='text-base font-bold mb-8'>Price Markup</div>
              <div className='grid grid-cols-9 gap-6 mt-6'>
                <div className='col-span-9 sm:col-span-3 xl:col-span-2'>
                  <label className='text-xs mb-2  ' htmlFor='costPrice'>
                    COST PRICE PER SEAT
                  </label>
                  <div className='input-group flex items-center'>
                    <div className='input-group-prepend'>₹</div>
                    <Field
                      type='text'
                      id='costPrice'
                      name='costPrice'
                      className='form-control pl-8'
                    />
                  </div>
                  <div className='form-error'>
                    <ErrorMessage name='costPrice' />
                  </div>
                </div>
                <div className='col-span-9 sm:col-span-3 xl:col-span-2'>
                  <label className='text-xs mb-2 ' htmlFor='taxPerSeat'>
                    TAXES PER SEAT
                  </label>
                  <div className='input-group flex items-center'>
                    <div className='input-group-prepend'>₹</div>
                    <Field
                      type='text'
                      id='taxPerSeat'
                      name='tax'
                      className='form-control pl-8'
                    />
                  </div>
                  <div className='form-error'>
                    <ErrorMessage name='tax' />
                  </div>
                </div>
                <div className='col-span-9 sm:col-span-3 xl:col-span-2'>
                  <label
                    className='text-xs mb-2 label-required'
                    htmlFor='fareIdentifier'
                  >
                    FARE IDENTIFIER
                  </label>

                  <Field
                    as='select'
                    name='fareIdentifier'
                    placeholder='Mr'
                    className='form-control appearance-none'
                  >
                    <option disabled value=''>
                      Please Select
                    </option>
                    {fareIdentifiers.map((val: string) => (
                      <option key={val} value={val}>
                        {val}
                      </option>
                    ))}
                  </Field>
                  <div className='form-error'>
                    <ErrorMessage name='fareIdentifier' />
                  </div>
                </div>
                <div className='col-span-9 sm:col-span-3 xl:col-span-2'>
                  <label className='text-xs mb-2 ' htmlFor='totalSeats'>
                    TOTAL SEATS
                  </label>
                  <Field
                    id='totalSeats'
                    name='totalSeats'
                    className='form-control'
                  />
                  <div className='form-error'>
                    <ErrorMessage name='totalSeats' />
                  </div>
                </div>
                <div className='col-span-9 sm:col-span-3  xl:col-span-2 self-center'>
                  <div className='flex items-center gap-2 mt-4'>
                    <div className='form-switch success'>
                      <Field
                        id='directFlight'
                        name='isDirectFlight'
                        type='checkbox'
                        role='switch'
                      />
                    </div>
                    <label htmlFor='directFlight'>Direct Flight</label>
                  </div>
                </div>
              </div>
              {isDynamicMarkupSelected ? (
                <>
                  <ul className='flex items-center gap-4 mt-8'>
                    <li className='text-primary font-bold'>
                      Dynamic AI Markup
                    </li>
                    <li>
                      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                      <a
                        className='text-blue-500 text-sm cursor-pointer'
                        onClick={() => setIsDynamicMarkupSelected(false)}
                      >
                        Back
                      </a>
                    </li>
                  </ul>

                  <div className='grid grid-cols-3 gap-6 mt-6'>
                    <div className='col-span-1'>
                      <label className='text-xs mb-2' htmlFor='fareLowerBy'>
                        KEEP MY PER SEAT FARE LOWER BY
                      </label>
                      <div className='input-group flex items-center'>
                        <div className='input-group-prepend'>₹</div>
                        <input
                          id='fareLowerBy'
                          type='text'
                          className='form-control pl-8'
                          defaultValue={'0'}
                        />
                        <div className='absolute right-1 py-2 px-3 text-gray-500 text-sm bg-white'>
                          than the lowest available fare
                        </div>
                      </div>
                    </div>
                    <div className='col-span-1'>
                      <label className='text-xs mb-2' htmlFor='stoploss'>
                        DO NOT PRICE BELOW (STOPLOSS)
                      </label>
                      <div className='input-group'>
                        <div className='input-group-prepend'>₹</div>
                        <input
                          id='stoploss'
                          type='text'
                          className='form-control pl-8'
                          defaultValue={'0'}
                        />
                        <div className='absolute right-1 py-2 px-3 text-gray-500 text-sm bg-white'>
                          per seat
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  {/* <ul className="flex items-center gap-4 mt-8"> */}
                  {/* <li className="text-gray-900 font-bold">Markup Control</li> */}
                  {/* <li>
                                        <a
                                            className="text-blue-500 text-sm cursor-pointer"
                                            onClick={() => setIsDynamicMarkupSelected(true)}
                                        >
                                            Switch to Dynamic Markup
                                        </a>
                                    </li> 
                                    </ul>*/}

                  <div className='grid grid-cols-8 gap-6 mt-6'>
                    <div className='col-span-8 sm:col-span-4 xl:col-span-2'>
                      <label className='text-xs mb-2 ' htmlFor='markup'>
                        INITIAL MARKUP PER SEAT
                      </label>
                      <div className='input-group flex items-center'>
                        <div className='input-group-prepend'>₹</div>
                        <Field
                          type='text'
                          id='markup'
                          name='markup'
                          className='form-control pl-8'
                        />
                      </div>
                      <div className='form-error'>
                        <ErrorMessage name='markup' />
                      </div>
                    </div>
                    <div className='col-span-8 sm:col-span-4 xl:col-span-2'>
                      <label className='text-xs mb-2' htmlFor='flightNumber'>
                        TOTAL COST PRICE OF THE TICKET
                      </label>
                      <div className='py-2 text-lg font-medium'>
                        {formatCurrency(getTotalCostPrice(values))}
                      </div>
                    </div>
                    <div className='col-span-8 sm:col-span-4 xl:col-span-2'>
                      <label className='text-xs mb-2' htmlFor='departureTime'>
                        TOTAL SELLING PRICE OF THE TICKET
                      </label>
                      <div className='py-2 text-lg font-medium'>
                        {formatCurrency(getTotalSellingPrice(values))}
                      </div>
                    </div>
                    <div className='col-span-8 sm:col-span-4 xl:col-span-2'>
                      <label className='text-xs mb-2' htmlFor='arrivalTime'>
                        ESTIMATED PROFIT / LOSS
                      </label>
                      <div className='py-2 text-lg font-medium'>
                        {getEarnings(values) >= 0 ? (
                          <span className='text-green-500'>
                            + {formatCurrency(getEarnings(values))}
                          </span>
                        ) : (
                          <span className='text-red-500'>
                            {formatCurrency(getEarnings(values))}
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>

            <div className='card px-8 py-7 mb-4'>
              <div className='text-base font-bold mb-8'>Availability</div>
              <div className='grid grid-cols-2 gap-6 mt-8'>
                <div className=' col-span-2 sm:col-span-1'>
                  <label className='text-xs mb-2 ' htmlFor='fromDate'>
                    START DATE
                  </label>
                  {/* <Field type="date" id="fromDate" name="fromDate" className="form-control" /> */}
                  <div className='relative flex items-center'>
                    <div className='absolute z-10 right-4 pointer-events-none'>
                      <CalendarIcon />
                    </div>
                    <DatePicker
                      className='form-control'
                      // onChange={(val) => setFieldValue('fromDate', val)}
                      // onChange={(val) => {
                      //     setFieldValue('fromDate', moment(val).format('YYYY-MM-DD'));
                      // }}

                      onChange={(value) => {
                        handleDateRangeChange(
                          'fromDate',
                          value,
                          values,
                          setFieldValue,
                        );
                      }}
                      value={moment(values.fromDate, 'YYYY-MM-DD').toDate()}
                      minDate={new Date()}
                      // maxDate={moment(values.toDate, 'YYYY-MM-DD').toDate()}
                      format={'DD/MM/YYYY'}
                    />
                  </div>

                  <div className='form-error'>
                    <ErrorMessage name='fromDate' />
                  </div>
                </div>

                <div className=' col-span-2 sm:col-span-1'>
                  <label className='text-xs mb-2 ' htmlFor='toDate'>
                    END DATE
                  </label>
                  {/* <Field type="date" id="toDate" name="toDate" className="form-control" /> */}
                  <div className='relative flex items-center'>
                    <div className='absolute -z-1 right-4 pointer-events-none'>
                      <CalendarIcon />
                    </div>
                    <DatePicker
                      className='form-control'
                      onChange={(value) => {
                        handleDateRangeChange(
                          'toDate',
                          value,
                          values,
                          setFieldValue,
                        );
                      }}
                      // onChange={(val) => {
                      //     setFieldValue('toDate', moment(val).format('YYYY-MM-DD'));
                      // }}
                      value={moment(values.toDate, 'YYYY-MM-DD').toDate()}
                      minDate={moment(values.fromDate, 'YYYY-MM-DD').toDate()}
                      format={'DD/MM/YYYY'}
                    />
                  </div>
                  <div className='form-error'>
                    <ErrorMessage name='toDate' />
                  </div>
                </div>
              </div>
              <div className='grid grid-cols-3 gap-6 mt-8'>
                <div className='col-span-3 md:col-span-2'>
                  <div className='font-semibold'>Flight Details</div>
                  <FieldArray
                    name='flightInfoArray'
                    render={(arrayHelpers) => (
                      <>
                        {values.flightInfoArray.map((_, index) => (
                          <div
                            key={index}
                            className='flex gap-2 md:gap-6 items-center md:mt-2'
                          >
                            <div>
                              <label
                                className='text-xs mb-2 '
                                htmlFor='fromDate'
                              >
                                DATE
                              </label>
                              <Field
                                type='text'
                                name={`flightInfoArray[${index}].date`}
                                className='form-control'
                                disabled
                              />
                              <div className='form-error'>
                                <ErrorMessage
                                  name={`flightInfoArray[${index}].date`}
                                />
                              </div>
                            </div>
                            <div>
                              <label className='text-xs mb-2'>PNR</label>
                              <Field
                                type='text'
                                name={`flightInfoArray[${index}].pnr`}
                                className='form-control'
                                disabled={
                                  values.fareIdentifier ===
                                  'OFFER_FARE_WITHOUT_PNR'
                                }
                              />
                              <div className='form-error'>
                                <ErrorMessage
                                  name={`flightInfoArray[${index}].pnr`}
                                />
                              </div>
                            </div>
                            {values.flightInfoArray.length > 1 && (
                              <button
                                type='button'
                                className='text-3xl text-red-500 flex justify-center items-center'
                                onClick={() => arrayHelpers.remove(index)}
                              >
                                &times;
                              </button>
                            )}
                          </div>
                        ))}
                      </>
                    )}
                  />
                </div>
              </div>
            </div>

            <div className='card px-8 py-7 mb-4'>
              <div className='text-base font-bold mb-8'>
                Baggage Information
              </div>
              <div className='mt-8'>
                {/* <div className="text-gray-900 font-bold">Baggage Information</div> */}

                <div className='grid grid-cols-6 gap:2 sm:gap-6 mt-4'>
                  <div className='col-span-6 sm:col-span-2'>
                    <label className='text-xs mb-2 ' htmlFor='cabin'>
                      CABIN
                    </label>
                    <div className='input-group relative '>
                      <Field
                        type='text'
                        id='cabin'
                        name='baggage.cabin'
                        className='form-control'
                      />

                      <div className='absolute right-1 top-1 py-0 text-lg font-medium px-3 bg-white'>
                        Kg
                      </div>
                    </div>

                    <div className='form-error'>
                      <ErrorMessage name='baggage.cabin' />
                    </div>
                  </div>
                  <div className='col-span-6 sm:col-span-2'>
                    <label className='text-xs mb-2 ' htmlFor='checkIn'>
                      CHECK IN
                    </label>
                    <div className='input-group relative'>
                      <Field
                        type='text'
                        id='checkIn'
                        name='baggage.checkIn'
                        className='form-control'
                      />

                      <div className='absolute right-1 top-1 py-0 text-lg font-medium px-3 bg-white'>
                        Kg
                      </div>
                    </div>

                    <div className='form-error'>
                      <ErrorMessage name='baggage.checkIn' />
                    </div>
                  </div>
                </div>

                <div className='grid grid-cols-12 gap-6 mt-3'>
                  <div className='col-span-12 sm:col-span-8'>
                    <div className='mt-4'>
                      <label className='text-xs mb-2 ' htmlFor='fareRules'>
                        FARE RULES
                      </label>
                      <Field
                        as='textarea'
                        id='name'
                        name='fareRules'
                        className='form-control'
                      />
                      <div className='form-error'>
                        <ErrorMessage name='fareRules' />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* infant info */}
            <div className='card px-8 py-7 mb-4'>
              <div className='text-base font-bold mb-8'>Infant Information</div>
              <div className='mt-8'>
                <div className='grid grid-cols-6 gap:2 sm:gap-6 mt-4'>
                  <div className='col-span-6 sm:col-span-2'>
                    <label className='text-xs mb-2 ' htmlFor='infant.costPrice'>
                      COST PRICE
                    </label>
                    <div className='input-group flex items-center'>
                      <div className='input-group-prepend'>₹</div>
                      <Field
                        type='text'
                        id='infant.costPrice'
                        name='infant.costPrice'
                        className='form-control pl-8'
                      />
                    </div>
                    <div className='form-error'>
                      <ErrorMessage name='infant.costPrice' />
                    </div>
                  </div>
                  <div className='col-span-6 sm:col-span-2'>
                    <label className='text-xs mb-2 ' htmlFor='infant.tax'>
                      TAX
                    </label>
                    <div className='input-group flex items-center'>
                      <div className='input-group-prepend'>₹</div>
                      <Field
                        type='text'
                        id='infant.tax'
                        name='infant.tax'
                        className='form-control pl-8'
                      />
                    </div>
                    <div className='form-error'>
                      <ErrorMessage name='infant.tax' />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='card px-8 py-7 mb-4 flex flex-col sm:flex-row justify-between sm:items-center'>
              <div className='mt-8'>
                <div className='flex items-center gap-2'>
                  <div className='form-switch success'>
                    <Field
                      id='liveOnNetwork'
                      name='isLiveOnNetwork'
                      type='checkbox'
                      role='switch'
                    />
                  </div>

                  <label htmlFor='liveOnNetwork'>Live on network</label>
                </div>
              </div>
              <LoaderButton
                type='submit'
                className='bg-primary text-white text-sm font-semibold rounded-lg w-56 mt-12'
                isLoading={mutation.isLoading}
              >
                Add Ticket
              </LoaderButton>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default SeriesInventoryAddForm;
