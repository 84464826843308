import { useMutation } from '@tanstack/react-query';
import LoaderButton from 'app/components/LoaderButton/LoaderButton';
import Modal from 'app/components/Modal/Modal';
import http from 'app/config/http';
import React, { FC } from 'react';
import { toast } from 'react-toastify';

interface CancelRequestDialogProps {
  show: boolean;
  onClose: () => void;
  passengers: any[];
  id: string;
}

const CancelRequestDialog: FC<CancelRequestDialogProps> = (props) => {
  const { id } = props;
  const addAmendmentMutation = useMutation(async (payload: any) => {
    const { data } = await http.post(
      `${process.env.REACT_APP_API_URL}/api/v1/sub-agent/bookings/${id}/amendments`,
      payload,
    );
    return data;
  });

  const handleConfirm = async () => {
    const data = {
      message: 'Ticket Cancellation',
      type: 'CANCELLATION',
      detail: {
        passengers: props.passengers,
      },
      noteType: 'CREDIT',
    };

    try {
      await addAmendmentMutation.mutateAsync(data);
      toast.success('Cancellation request sent successfully');
      props.onClose();
    } catch (e: any) {
      toast.error(e.data.message);
    }
  };

  return (
    <Modal
      className='px-10 py-10 w-[520px] '
      show={props.show}
      onClose={props.onClose}
    >
      <div className='text-gray-900 mt-4 text-center'>
        Are you sure you want to request cancellation for this ticket
      </div>
      <div className='flex justify-center gap-4 mt-6'>
        <LoaderButton
          className='bg-red-500 text-white px-8 text-sm rounded-lg'
          onClick={handleConfirm}
          isLoading={addAmendmentMutation.isLoading}
        >
          Yes
        </LoaderButton>
        <button
          className='bg-gray-200 text-gray-600 px-8 text-sm rounded-lg'
          onClick={props.onClose}
        >
          No
        </button>
      </div>
    </Modal>
  );
};

export default CancelRequestDialog;
