/* eslint-disable jsx-a11y/anchor-is-valid */
import { useQueries, useQuery } from '@tanstack/react-query';
import BgOverlay from 'app/components/BgOverlay/BgOverlay';
import http from 'app/config/http';
import { FC, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import FilterSidebar from './components/FilterSidebar/FilterSidebar';
import FlightSearchForm from './components/FlightSearchForm/FlightSearchForm';
import update from 'immutability-helper';
import FlightList from './components/FlightList/FlightList';
import FlightsContext from './context/flights-context';
import { IAirport, IFlight } from 'app/types';
import { StopTypeEnum, FareTypeEnum } from 'app/enums';
import { IFlightFilter } from './types';
import searchLoaderLottie from 'assets/lotties/search-loader.json';
import LottieLoader from 'app/components/LottieLoader/LottieLoader';
import { toast } from 'react-toastify';
import { getDatesBetween } from 'app/utils/date-utils';
import moment from 'moment';
import { calculateStops } from 'app/utils/flight-utils';
import { useMediaQuery } from 'react-responsive';
import { BackIcon, FilterMobileIcon, TuningIcon } from 'app/icons';
import produce from 'immer';
import { cloneDeep } from 'lodash';
import NProgress from 'nprogress';
import capitalize from 'lodash.capitalize';

const timeFilterMap = {
  EARLY_MORNING: { startTime: '000000', endTime: '080000' },
  MORNING: { startTime: '080000', endTime: '120000' },
  AFTERNOON: { startTime: '120000', endTime: '160000' },
  EVENING: { startTime: '160000', endTime: '200000' },
  NIGHT: { startTime: '200000', endTime: '235959' },
};

NProgress.configure({
  minimum: 0.3,
  easing: 'ease',
  speed: 500,
  showSpinner: false,
});

interface FlightsProps {}

const sortByFare = ({ data }: any) => {
  const sortedFare = data.map((element: any) => {
    element.priceList.sort((a: any, b: any) => a.totalFare - b.totalFare);
    element.minFare =
      element.priceList.length > 0 ? element.priceList[0].totalFare : 0;
    element.maxFare =
      element.priceList.length > 0
        ? element.priceList[element.priceList.length - 1].totalFare
        : 0;

    element.departure =
      element.segments.length > 0 ? element.segments[0].departure : null;

    element.arrival =
      element.segments.length > 0
        ? element.segments[element.segments.length - 1].arrival
        : null;
    element.duration = element.segments.reduce((prev: any, cur: any) => {
      return prev + cur.duration + cur.layoverDuration;
    }, 0);
    // element.stops = getFormattedStops(element.segments.length);
    return element;
  });
  data = sortedFare;

  // sort the data based on the minimum fare
  data.sort((a: any, b: any) => a.minFare - b.minFare);
  return data;
};

const mergeFunction = (listData: any) => {
  const mergedFlights: any = {};

  const notMergedFlightList = cloneDeep(listData);

  notMergedFlightList.forEach((notMergedFlight: any) => {
    if (notMergedFlight.segments.length > 0) {
      const key = `${
        notMergedFlight.segments[0].flight.code
      }${notMergedFlight.segments[0].flight.number.trim()}-${notMergedFlight.segments[0].departure.date}${
        notMergedFlight.segments[0].departure.time
      }-${notMergedFlight.segments[notMergedFlight.segments.length - 1].arrival.date}${
        notMergedFlight.segments[notMergedFlight.segments.length - 1].arrival
          .time
      }`;
      if (mergedFlights[key]) {
        mergedFlights[key].priceList.push(...notMergedFlight.priceList);
      } else {
        mergedFlights[key] = notMergedFlight;
      }
    }
  });

  return sortByFare({ data: Object.values(mergedFlights) });
};

const Flights: FC<FlightsProps> = (props) => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [search, setSearch] = useState(null);
  const [filterByAirlineList, setFilterByAirlineList] = useState([]);
  const [priceRange, setPriceRange] = useState({ min: 0, max: 0 });
  const [stopCountMap, setStopCountMap] = useState({
    NON_STOP: 0,
    ONE_STOP: 0,
    MULTIPLE_STOP: 0,
  });
  const [filter, setFilter] = useState<IFlightFilter>({
    airlines: [],
    priceRange: [0, 0],
    departureTime: [],
    stops: [],
    fareType: [],
  });

  const [showNetFare, setShowNetFare] = useState<boolean>(false);
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });
  const [showMobileFilter, setShowMobileFilter] = useState<boolean>(false);
  const divRef = useRef<HTMLDivElement>(null);

  // useLayoutEffect(() => {
  //     let node = ReactDOM.findDOMNode(divRef.current) as Element;

  //     if (isMobile) {
  //         node.scrollIntoView({ block: 'start', behavior: 'smooth' });
  //     }
  // }, []);

  useEffect(() => {
    if (!isMobile) {
      setShowMobileFilter(false);
    }
  }, [isMobile]);

  const [unFilteredFlightList, setUnFilteredFlightList] = useState<IFlight[]>(
    [],
  );
  const [flightSearchProvider, setFlightSearchProvider] = useState<IFlight[]>(
    [],
  );

  const flightListQueryProviders = useQuery(
    ['flightListProviders'],
    async () => {
      const { data } = await http.get(
        `${process.env.REACT_APP_API_URL}/api/v1/sub-agent/fare-suppliers`,
      );
      return data;
    },
  );

  useEffect(() => {
    if (flightListQueryProviders.data) {
      setFlightSearchProvider(flightListQueryProviders.data);
    }
  }, [flightListQueryProviders]);

  const fetchFlights = async (id: string) => {
    const { data } = await http.post(
      `${process.env.REACT_APP_API_URL}/api/v1/sub-agent/flights`,
      {
        fareSupplier: id,
        cabinClass: search.cabinClass || 'ECONOMY',
        passengers: {
          adult: search.adult,
          child: search.child || 0,
          infant: search.infant || 0,
        },
        routeInfo: [
          {
            from: {
              code: search.fromCode,
              name: search.fromName,
              cityCode: search.fromCityCode,
              city: search.fromCity,
              country: search.fromCountry,
              countryCode: search.fromCountryCode,
            },
            to: {
              code: search.toCode,
              name: search.toName,
              cityCode: search.toCityCode,
              city: search.toCity,
              country: search.toCountry,
              countryCode: search.toCountryCode,
            },
            travelDate: search.departureDate,
          },
        ],
        filter: {
          pft: search.pft || 'REGULAR',
          isDirectFlight: search.isDirectFlight || false,
          isConnectingFlight: search.isConnectingFlight || false,
        },
        preferredAirlines: [],
      },
    );

    return data as IFlight[];
  };

  const multiSupplierFlightQuery = useQueries({
    queries: useMemo(() => {
      return flightSearchProvider.map((sKey: any) => {
        return {
          queryKey: ['flightList', search, sKey],
          queryFn: () => fetchFlights(sKey),
          enabled: flightSearchProvider.length > 0,
          initialData: [],
        };
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [flightSearchProvider, search]),
  });

  const dependency = multiSupplierFlightQuery.reduce((acc, cur) => {
    acc += cur.data.length;

    return acc;
  }, 0);

  useEffect(() => {
    if (!flightSearchProvider) return;

    setUnFilteredFlightList(() => []);

    multiSupplierFlightQuery.forEach((row) => {
      if (row.isLoading) return;

      const flights: IFlight[] = row.data;

      setUnFilteredFlightList((prevFlights) =>
        mergeFunction(
          produce(prevFlights, (draftFlights) => {
            draftFlights.push(...flights);
          }),
        ),
      );

      // setUnFilteredFlightList((prevFlights) =>
      //     produce(prevFlights, (draftFlights) => {
      //         draftFlights.push(...flights);
      //     })
      // );
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dependency, flightSearchProvider]);

  useEffect(() => {
    const params: any = {};

    for (let [key, value] of searchParams.entries()) {
      params[key] = value;
    }

    const adult = +params.adult || 0;
    const child = +params.child || 0;
    const infant = +params.infant || 0;
    const total = adult + child;

    if (infant > adult) {
      toast.error('Number of Infants cannot be greater than the Adults');
      navigate('/');
      return;
    }

    if (+total > 9) {
      toast.error('Max passengers limit is 9 (Adult + Child)');
      navigate('/');
      return;
    }

    setSearch(() => params);
    setUnFilteredFlightList(() => []);
    setFilterByAirlineList(() => []);
    setStopCountMap(() => ({
      NON_STOP: 0,
      ONE_STOP: 0,
      MULTIPLE_STOP: 0,
    }));
    // ???
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  useEffect(() => {
    const flights = unFilteredFlightList;
    if (flights.length === 0) return;

    const AirlineMap: any = {};
    let minPriceValue = Number.MAX_VALUE;
    let maxPriceValue = 0;
    let _stopCountMap = {
      NON_STOP: 0,
      ONE_STOP: 0,
      MULTIPLE_STOP: 0,
    };

    flights.forEach((flight) => {
      const firstSegment = flight.segments[0];

      // Calculate By Airline filter
      if (AirlineMap[firstSegment.flight.code]) {
        const prevFare = AirlineMap[firstSegment.flight.code].minFare;

        AirlineMap[firstSegment.flight.code].minFare = Math.min(
          prevFare,
          flight.minFare,
        );
        AirlineMap[firstSegment.flight.code].count++;
      } else {
        AirlineMap[firstSegment.flight.code] = {
          minFare: flight.minFare,
          count: 1,
          name: firstSegment.flight.name,
          code: firstSegment.flight.code,
        };
      }

      // Calculate Min and Max Prices for filter
      minPriceValue = Math.min(minPriceValue, flight.minFare);
      maxPriceValue = Math.max(maxPriceValue, flight.maxFare);

      // Calculate Stops from filter
      const segmentLength = flight.segments.length;
      if (segmentLength === 1) {
        _stopCountMap.NON_STOP++;
      } else if (segmentLength === 2) {
        _stopCountMap.ONE_STOP++;
      } else {
        _stopCountMap.MULTIPLE_STOP++;
      }
    });

    setFilterByAirlineList(Object.values(AirlineMap));
    setPriceRange({ min: minPriceValue, max: maxPriceValue });
    setStopCountMap(_stopCountMap);
  }, [unFilteredFlightList]);

  useEffect(() => {
    setFilter((prev) =>
      update(prev, {
        priceRange: { $set: [priceRange.min, priceRange.max] },
      }),
    );
  }, [priceRange]);

  useEffect(() => {
    setFilter({
      airlines: [],
      priceRange: [0, 0],
      departureTime: [],
      stops: [],
      fareType: [],
    });
  }, [search]);

  const isSerchingFlights = useMemo(() => {
    let result = multiSupplierFlightQuery.some((qry) => {
      return qry.isFetching === true;
    });
    return result;
  }, [multiSupplierFlightQuery]);

  const departureAirport: IAirport = useMemo(() => {
    return {
      code: searchParams.get('fromCode'),
      name: searchParams.get('fromName'),
      cityCode: searchParams.get('fromCityCode'),
      city: searchParams.get('fromCity'),
      country: searchParams.get('fromCountry'),
      countryCode: searchParams.get('fromCountryCode'),
    };
  }, [searchParams]);

  const arrivalAirport: IAirport = useMemo(() => {
    return {
      code: searchParams.get('toCode'),
      name: searchParams.get('toName'),
      cityCode: searchParams.get('toCityCode'),
      city: searchParams.get('toCity'),
      country: searchParams.get('toCountry'),
      countryCode: searchParams.get('toCountryCode'),
    };
  }, [searchParams]);

  const passengers = useMemo(() => {
    return {
      adult: +searchParams.get('adult'),
      child: +searchParams.get('child') || 0,
      infant: +searchParams.get('infant') || 0,
    };
  }, [searchParams]);

  const passengerCount = () => {
    let count = 0;
    count += passengers.adult;
    count += passengers.child;
    count += passengers.infant;
    return count;
  };

  const filteredFlightList: IFlight[] = useMemo(() => {
    const allFlights = unFilteredFlightList;

    let filteredFlights = update(allFlights, {
      $set: allFlights,
    });

    // Filter by airline
    if (filter.airlines.length > 0) {
      filteredFlights = update(allFlights, {
        $set: allFlights.filter((flight) => {
          const firstSegment = flight.segments[0];

          return filter.airlines.includes(firstSegment.flight.code);
        }),
      });
    }

    // Filter by fare
    filteredFlights = filteredFlights.filter((flight) => {
      return (
        flight.minFare >= filter.priceRange[0] &&
        flight.minFare <= filter.priceRange[1]
      );
    });

    // Filter by stop
    if (filter.stops.length > 0) {
      filteredFlights = filteredFlights.filter((flight) => {
        const segmentLength = calculateStops(flight.segments);

        let str = StopTypeEnum.NON_STOP;
        if (segmentLength === 0) {
          str = StopTypeEnum.NON_STOP;
        } else if (segmentLength === 1) {
          str = StopTypeEnum.ONE_STOP;
        } else {
          str = StopTypeEnum.MULTIPLE_STOP;
        }

        return filter.stops.includes(str);
      });
    }

    // Filter by departure time
    if (filter.departureTime.length > 0) {
      filteredFlights = filteredFlights.filter((flight) => {
        let toBeIncluded = false;
        const departureTime = flight.departure.time;

        filter.departureTime.forEach((departurePeriod) => {
          if (
            +departureTime >= +timeFilterMap[departurePeriod].startTime &&
            +departureTime <= +timeFilterMap[departurePeriod].endTime
          )
            toBeIncluded = true;
        });

        return toBeIncluded;
      });
    }

    // Filter by fare type
    if (filter.fareType.length > 0) {
      filteredFlights = filteredFlights.filter((flight) => {
        let str = FareTypeEnum.SERIES;

        if (flight.priceList.some((price) => price.fareSupplier === '0')) {
          str = FareTypeEnum.SERIES;
        } else {
          str = FareTypeEnum.NORMAL;
        }

        return filter.fareType.includes(str);
      });
    }

    return filteredFlights;
  }, [unFilteredFlightList, filter]);

  // const dates = useMemo(() => {
  //   const departureDate = searchParams.get('departureDate');

  //   const today = moment().format('YYYYMMDD');

  //   const diff = Math.min(+departureDate - +today, 2);

  //   const startDate = moment(departureDate)
  //     .subtract(diff, 'days')
  //     .format('YYYYMMDD');

  //   const endDate = moment(startDate, 'YYYYMMDD')
  //     .add(5, 'days')
  //     .format('YYYYMMDD');

  //   const datesArr = getDatesBetween(startDate, endDate);

  //   const txDatesArr = datesArr.map((d) => {
  //     const date = moment(d, 'YYYYMMDD');

  //     return {
  //       day: date.format('dddd'),
  //       date: d,
  //       formattedDate: date.format('DD MMM'),
  //     };
  //   });

  //   return txDatesArr;
  // }, [searchParams]);

  const generateDate = () => {
    const departureDate = searchParams.get('departureDate');

    const today = moment().format('YYYYMMDD');

    const diff = Math.min(+departureDate - +today, 2);

    const startDate = moment(departureDate)
      .subtract(diff, 'days')
      .format('YYYYMMDD');

    const endDate = moment(startDate, 'YYYYMMDD')
      .add(5, 'days')
      .format('YYYYMMDD');

    const datesArr = getDatesBetween(startDate, endDate);

    const txDatesArr = datesArr.map((d) => {
      const date = moment(d, 'YYYYMMDD');

      return {
        day: date.format('dddd'),
        date: d,
        formattedDate: date.format('DD MMM'),
      };
    });

    return txDatesArr;
  };

  const dates = generateDate();

  const isDateSelected = (date: string) => {
    const departureDate = search?.departureDate;

    return date === departureDate;
  };

  const handleFilterChange = (values: IFlightFilter) => {
    setFilter(values);
  };

  const handleDateChange = (date: string) => {
    searchParams.set('departureDate', date);
    setSearchParams(searchParams);
    setSearch((prev: any) => ({ ...prev, departureDate: date }));
    setUnFilteredFlightList(() => []);
    setFilterByAirlineList(() => []);
    setStopCountMap(() => ({
      NON_STOP: 0,
      ONE_STOP: 0,
      MULTIPLE_STOP: 0,
    }));
  };

  useEffect(() => {
    if (isSerchingFlights) {
      NProgress.start();
    } else {
      NProgress.done();
    }

    return () => {
      NProgress.done();
    };
  }, [isSerchingFlights]);

  useEffect(() => {
    sessionStorage.clear();
  }, []);

  return (
    <FlightsContext.Provider
      value={{
        departureAirport,
        arrivalAirport,
        passengers,
        showNetFare,
        setShowNetFare,
      }}
    >
      <section>
        <BgOverlay />

        {!isMobile && (
          <section className='bg-secondary pt-8 pb-8'>
            <div className='container'>
              <FlightSearchForm search={search} />
            </div>
          </section>
        )}

        <section className=''>
          <div className='md:container'>
            <div className='grid grid-cols-7 gap-9'>
              {/* Filter column */}
              <div className='col-span-7 lg:col-span-2'>
                <FilterSidebar
                  filter={filter}
                  onFilterChange={handleFilterChange}
                  filterByAirlineList={filterByAirlineList}
                  priceRange={priceRange}
                  stopCountMap={stopCountMap}
                  show={showMobileFilter}
                  closeMobileFilter={() => setShowMobileFilter(false)}
                />
              </div>

              {/* List column */}
              {/* <div className="col-span-7 lg:col-span-5"> */}
              <div className='col-span-7 lg:col-span-5'>
                {isMobile ? (
                  <div className=' flex justify-between items-center px-6'>
                    <div onClick={() => navigate(-1)}>
                      <BackIcon />
                    </div>
                    <div className='flex flex-col items-center '>
                      <div className='flex text-lg items-center gap-2'>
                        <span className='font-bold'>
                          {departureAirport.city}
                        </span>
                        <span className='rotate-180'>
                          <BackIcon />
                        </span>
                        <span className='font-bold'>{arrivalAirport.city}</span>
                      </div>
                      <div className='flex items-center gap-2 text-sm font-medium  '>
                        <span>
                          {search?.departureDate
                            ? moment(search.departureDate, 'YYYYMMDD').format(
                                'ddd, D MMM',
                              )
                            : moment(
                                searchParams.get('departureDate'),
                                'YYYYMMDD',
                              ).format('ddd, D MMM')}
                        </span>
                        <span className='h-1 w-1 bg-primary rounded-full'></span>
                        <span> {`${passengerCount()} Pax`} </span>
                        <span className='h-1 w-1 bg-primary rounded-full'></span>

                        <span>
                          {capitalize(
                            searchParams.get('cabinClass').toLowerCase(),
                          )}
                        </span>
                      </div>
                    </div>
                    <div>
                      <FilterMobileIcon
                        className='cursor-pointer'
                        onClick={() => setShowMobileFilter(true)}
                      />
                    </div>
                  </div>
                ) : (
                  <div className='flex flex-col gap-4 md:flex-row justify-between md:items-center'>
                    <div className='flex flex-col md:flex-row md:items-center gap-4'>
                      <div className='text-2xl text-secondary-fg'>
                        Flights from{' '}
                        <span className='font-bold'>
                          {departureAirport.city}
                        </span>{' '}
                        to{' '}
                        <span className='font-bold'>{arrivalAirport.city}</span>
                      </div>

                      {/* <div>{isSerchingFlights && <FlightLoading />}</div> */}
                    </div>

                    {/* show net fare */}
                    <div className='flex gap-2 items-center' ref={divRef}>
                      <div className='text-secondary-fg text-base leading-5 font-semibold'>
                        {/* Sort by <span className="font-semibold">Price</span>
                         */}
                        {filteredFlightList.length} Options Found
                      </div>
                      {isMobile && (
                        <div
                          className='cursor-pointer'
                          onClick={() => setShowMobileFilter(true)}
                        >
                          <TuningIcon />
                        </div>
                      )}
                    </div>
                  </div>
                )}

                {flightListQueryProviders.isLoading ||
                (unFilteredFlightList.length === 0 && isSerchingFlights) ? (
                  <div
                    className={`card px-6 pt-12 mt-6 ${
                      isMobile ? 'rounded-t-[20px]' : ''
                    }`}
                  >
                    <div className='text-center'>
                      <p className='mt-12 text-lg font-semibold'>
                        Hold on, we are searching for the best fares
                      </p>
                      <div className='w-1/2 mx-auto'>
                        <LottieLoader animationData={searchLoaderLottie} />
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className='mt-6 overflow-auto'>
                    <div
                      className={`flex justify-between  gap-2 overflow-x-scroll py-4 hide-scrollbar ${
                        isMobile ? 'px-6 gap-4' : ''
                      } `}
                    >
                      {isMobile
                        ? dates.map((row) => (
                            <div key={row.date} className='flex-1 min-w-[80px]'>
                              <div
                                className={`card relative text-center px-3 py-4 cursor-pointer min-w-[80px] bg-primary/10  
                                                              ${
                                                                isDateSelected(
                                                                  row.date,
                                                                )
                                                                  ? 'after:w-9/12 after:h-[6px] after:bg-[#ECAE0E] after:bg-primary   after:absolute  after:left-1/2 after:-translate-x-1/2 after:content-[" "] after:bottom-px after:rounded-t-md'
                                                                  : ''
                                                              }
                                                              `}
                                onClick={() => handleDateChange(row.date)}
                              >
                                <div className={`mt-1 font-bold text-primary`}>
                                  {row.formattedDate}
                                </div>
                              </div>
                            </div>
                          ))
                        : dates.map((row) => (
                            <div
                              key={row.date}
                              className='flex-1 min-w-[108px]'
                            >
                              <div
                                className='card text-center px-3 py-4 cursor-pointer min-w-[106px]'
                                onClick={() => handleDateChange(row.date)}
                              >
                                <div
                                  className={`text-xs ${
                                    isDateSelected(row.date)
                                      ? 'text-black'
                                      : 'text-gray-400'
                                  }`}
                                >
                                  {row.day}
                                </div>
                                <div
                                  className={`mt-1 ${
                                    isDateSelected(row.date)
                                      ? 'font-bold text-black'
                                      : 'text-gray-400'
                                  }`}
                                >
                                  {row.formattedDate}
                                </div>
                              </div>
                            </div>
                          ))}
                    </div>

                    <div
                      className={`${
                        isMobile
                          ? 'p-6 rounded-t-[20px]  md:p-0 md:rounded-none  bg-white'
                          : ''
                      }`}
                    >
                      {filteredFlightList.length > 0 && isMobile && (
                        <div className='text-center text-base font-semibold'>
                          {filteredFlightList.length} Options Found{' '}
                        </div>
                      )}
                      <FlightList list={filteredFlightList} />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </section>
      </section>
    </FlightsContext.Provider>
  );
};

export default Flights;
