import React, { FC } from 'react';
import { useNavigate } from 'react-router-dom';

interface PageNotFoundProps {}

const PageNotFound: FC<PageNotFoundProps> = (props) => {
  const navigate = useNavigate();

  const goBack = () => {
    navigate('/');
  };

  return (
    <div className='flex flex-col items-center justify-center h-[calc(100vh-var(--navbar-height))]'>
      <h1 className='text-8xl mt-8'>404</h1>
      <h5 className='mt-4'>Page Not Found</h5>
      <button className='text-primary font-medium mt-8' onClick={goBack}>
        Go Back Home
      </button>
    </div>
  );
};

export default PageNotFound;
