import React, { FC } from 'react';
import LoaderButton from 'app/components/LoaderButton/LoaderButton';
import { ErrorMessage, Field, Formik, Form, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import { IUserForm } from 'app/pages/SubAgencyUserEdit/types';
import { toast } from 'react-toastify';
import { useMutation } from '@tanstack/react-query';
import http from 'app/config/http';
import { SubAgencyUserStatusEnum } from 'app/enums/sub-agency-user-status';
import capitalize from 'lodash.capitalize';

const UserFormSchema = Yup.object({
  name: Yup.string().trim().required('This field is required'),
  email: Yup.string()
    .trim()
    .required('This field is required')
    .email('Please enter a valid email address'),
  phoneNumber: Yup.string()
    .trim()
    .required('This field is required')
    .matches(/^\d{10}$/, 'Please enter a valid mobile number'),
  username: Yup.string()
    .required('This field is required')
    .test('no-space-in-username', 'Username cannot contain spaces', (value) => {
      let result = true;
      for (let i = 0; i < value.length; i++) {
        if (value[i] === ' ') {
          result = false;
          break;
        }
      }
      return result;
    }),
  designation: Yup.string().trim().required('This field is required'),
});

interface SubAgencyUserEditFormProps {
  user: any;
}

const SubAgencyUserEditForm: FC<SubAgencyUserEditFormProps> = (props) => {
  const { user } = props;

  const initialValues: IUserForm = {
    name: user.name,
    email: user.email,
    phoneNumber: user.phoneNumber,
    username: user.username,
    designation: user.designation,
    status: user.status,
  };

  const mutation = useMutation(async (payload: any) => {
    const { data } = await http.put(
      `${process.env.REACT_APP_API_URL}/api/v1/sub-agent/sub-agency/sub-agency-users/${user._id}`,
      payload,
    );

    return data;
  });

  const handleSubmit = async (
    values: IUserForm,
    formikhelpers: FormikHelpers<IUserForm>,
  ) => {
    try {
      await mutation.mutateAsync(values);
      toast.success('User updated successfully.');
    } catch (ex: any) {
      console.log(ex);
      toast.error(ex?.data?.message || 'Some error occured, please try again.');
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize
      validationSchema={UserFormSchema}
      onSubmit={handleSubmit}
    >
      <Form>
        <div className='grid grid-cols-12 gap-6 mt-8'>
          <div className='col-span-12  md:col-span-4'>
            <label className='text-xs mb-2' htmlFor='name'>
              NAME
            </label>
            <Field type='text' id='name' name='name' className='form-control' />
            <div className='form-error'>
              <ErrorMessage name='name' />
            </div>
          </div>

          <div className='col-span-12  md:col-span-4'>
            <label className='text-xs mb-2' htmlFor='email'>
              EMAIL
            </label>
            <Field
              type='email'
              id='email'
              name='email'
              className='form-control'
              // validate={(val: string) => uniqueEmailValidator(val)}
            />
            <div className='form-error'>
              <ErrorMessage name='email' />
            </div>
          </div>

          <div className='col-span-12  md:col-span-4'>
            <label className='text-xs mb-2' htmlFor='phoneNumber'>
              PHONE NUMBER
            </label>
            <div className='input-group'>
              <Field
                type='text'
                id='phoneNumber'
                name='phoneNumber'
                className='form-control'
              />
            </div>
            <div className='form-error'>
              <ErrorMessage name='phoneNumber' />
            </div>
          </div>

          <div className='col-span-12  md:col-span-4'>
            <label className='text-xs mb-2' htmlFor='username'>
              USERNAME
            </label>
            <Field
              type='text'
              id='username'
              name='username'
              className='form-control'
              // validate={(val: string) => uniqueUsernameValidator(val)}
            />
            <div className='form-error'>
              <ErrorMessage name='username' />
            </div>
          </div>

          <div className='col-span-12  md:col-span-4'>
            <label className='text-xs mb-2' htmlFor='designation'>
              DESIGNATION
            </label>
            <Field
              type='text'
              id='designation'
              name='designation'
              className='form-control'
            />
            <div className='form-error'>
              <ErrorMessage name='designation' />
            </div>
          </div>

          <div className='col-span-12  md:col-span-4'>
            <label className='text-xs mb-2' htmlFor='status'>
              Status
            </label>
            <Field
              as='select'
              name='status'
              type='text'
              id='status'
              className='form-control'
            >
              {Object.values(SubAgencyUserStatusEnum).map((status) => (
                <option key={status} value={status}>
                  {capitalize(status)}
                </option>
              ))}
            </Field>
            <div className='form-error'>
              <ErrorMessage name='status' />
            </div>
          </div>
        </div>

        {/* <div className="mt-8">
                    <div className="flex items-center gap-2">
                        <div className="form-switch success">
                            <Field name="isEnabled" id="seriesFare" type="checkbox" role="switch" />
                        </div>
                        <label htmlFor="seriesFare" className="text-slate-500">
                            Enabled
                        </label>
                    </div>
                </div> */}
        <LoaderButton
          type='submit'
          className='bg-primary text-white text-sm font-semibold rounded-lg w-full sm:w-56 mt-12'
          isLoading={mutation.isLoading}
          disabled={mutation.isLoading}
        >
          Save Changes
        </LoaderButton>
      </Form>
    </Formik>
  );
};

export default SubAgencyUserEditForm;
