// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Login_vertical-text__PAlsC {\n  writing-mode: vertical-rl;\n  text-orientation: mixed;\n  white-space: nowrap;\n}\n\n.Login_card-shadow__EkZee {\n  box-shadow: 0px -2px 50px 10px rgba(0, 0, 0, 0.1);\n}", "",{"version":3,"sources":["webpack://./src/app/pages/StaticPage/Login.module.scss"],"names":[],"mappings":"AAAA;EACE,yBAAA;EACA,uBAAA;EACA,mBAAA;AACF;;AAEA;EACE,iDAAA;AACF","sourcesContent":[".vertical-text {\n  writing-mode: vertical-rl;\n  text-orientation: mixed;\n  white-space: nowrap;\n}\n\n.card-shadow {\n  box-shadow: 0px -2px 50px 10px rgba(0, 0, 0, 0.1);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"vertical-text": "Login_vertical-text__PAlsC",
	"card-shadow": "Login_card-shadow__EkZee"
};
export default ___CSS_LOADER_EXPORT___;
