import debounce from 'lodash.debounce';
import { FC, useEffect, useState, useCallback } from 'react';
import { IAirport } from 'app/types';
import http from 'app/config/http';

interface AirportSearchFieldProps {
  selectedAirport: IAirport;
  onSelectedAirportChange: (airport: IAirport) => void;
  placeholder: string;
  className?: string;
  id?: string;
}

const AirportSearchField: FC<AirportSearchFieldProps> = (
  props: AirportSearchFieldProps,
) => {
  const {
    selectedAirport,
    onSelectedAirportChange,
    className,
    id,
    placeholder,
  } = props;

  const [value, setValue] = useState<string>('');
  const [suggestionList, setSuggestionList] = useState([]);
  const [showSuggestionList, setShowSuggestionList] = useState<boolean>(false);

  useEffect(() => {
    document.body.addEventListener('click', closeSuggestionList);

    return () => {
      document.body.removeEventListener('click', closeSuggestionList);
    };
  });

  const closeSuggestionList = () => {
    setShowSuggestionList(false);
  };

  const handleFocus = () => {
    setValue('');
  };

  const handleBlur = () => {
    setValue('');
  };

  const handleAirportSelect = (airport: any) => {
    setValue('');
    onSelectedAirportChange(airport);
    closeSuggestionList();
  };

  const changeHandler = async (e: any) => {
    const _value = e.target.value.trim();
    setValue(_value);

    if (_value.length < 2) {
      closeSuggestionList();
      setSuggestionList([]);

      return;
    }

    const { data } = await http.get(
      `${process.env.REACT_APP_API_URL}/api/v1/sub-agent/airports`,
      {
        params: {
          q: _value,
        },
      },
    );

    setSuggestionList(data);

    setShowSuggestionList(true);
  };

  const handleKeyUp = useCallback(debounce(changeHandler, 200), []);

  return (
    <div onClick={(e) => e.stopPropagation()} className='grow'>
      <div className='relative'>
        <input
          type='text'
          id={id}
          className={className}
          autoComplete='off'
          onFocus={handleFocus}
          onBlur={handleBlur}
          value={value}
          onKeyUp={handleKeyUp}
          onChange={(e) => setValue(e.target.value)}
        />

        {!value && (
          <div
            className={`${className} absolute top-0 left-0 w-full h-full flex items-center pointer-events-none`}
          >
            <div className='whitespace-nowrap overflow-hidden text-ellipsis'>
              {selectedAirport ? (
                <span>
                  {selectedAirport.code} - {selectedAirport.city}
                </span>
              ) : (
                <span className='text-gray-400'>{placeholder}</span>
              )}
            </div>
          </div>
        )}

        {showSuggestionList && (
          <div className='absolute w-full max-h-60 overflow-y-auto z-40 bg-white rounded-md drop-shadow'>
            {suggestionList.length > 0 && (
              <ul>
                {suggestionList.map((airport, index) => (
                  <li
                    key={index}
                    className='py-3 px-4 cursor-pointer hover:bg-blue-50'
                    onClick={() => handleAirportSelect(airport)}
                  >
                    {`${airport.code} - ${airport.city}`}
                  </li>
                ))}
              </ul>
            )}
            {suggestionList.length === 0 && (
              <div
                className='py-3 px-4 cursor-pointer text-gray-500'
                onClick={closeSuggestionList}
              >
                No airports found
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default AirportSearchField;
