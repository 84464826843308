import React, { FC } from 'react';
import { formatCurrency } from 'app/utils/currency-utils';
import moment from 'moment';

interface MarkupHistoryTableProps {
  markupHistory: any[];
}

const MarkupHistoryTable: FC<MarkupHistoryTableProps> = (props) => {
  const { markupHistory } = props;

  return (
    <div className='overflow-auto'>
      <table className='w-full'>
        <thead>
          <tr className='bg-gray-50'>
            <td className='td th'>Time & Date</td>
            <td className='td th'>Value</td>
            <td className='td th'>Agent Email</td>
            <td className='td th'>Seats Left</td>
          </tr>
        </thead>
        <tbody>
          {markupHistory.map((row, i) => (
            <tr key={i} className='border-b border-gray-200 last:border-none'>
              <td className='td tb'>
                <p>
                  {moment(row.createdAt, 'YYYYMMDDHHmmss').format(
                    'hh:mm A - DD MMM, YYYY',
                  )}
                </p>
              </td>
              <td className='td tb'>
                <div className='font-semibold'>
                  {' '}
                  {formatCurrency(row.markup)}
                </div>
              </td>
              <td className='td tb'>
                <div className='font-semibold'>{row.subAgencyUser.name}</div>
                <p className='mt-1'>{row.subAgencyUser.email}</p>
              </td>
              <td className='td tb'>
                <div className='font-semibold'>{row.seatsLeft}</div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default MarkupHistoryTable;
