import { useQuery } from '@tanstack/react-query';
import PageLoader from 'app/components/PageLoader/PageLoader';
import http from 'app/config/http';
import { formatCurrency } from 'app/utils/currency-utils';
import { FC, useEffect } from 'react';
import AsideCard from './components/AsideCard/AsideCard';
import FlightSearchForm from './components/FlightSearchForm/FlightSearchForm';
import styles from './Home.module.scss';
import { useSelector } from 'react-redux';
import { TRootState } from 'app/store';
import AnnouncementModal from './components/AnnouncementModal/AnnouncementModal';
import useDialog from 'app/hooks/use-dialog';
import {
  createSearchParams,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';

interface HomeProps {}

const Home: FC<HomeProps> = () => {
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });
  const { user } = useSelector((state: TRootState) => state.auth);

  const navigate = useNavigate();

  const annoucementDialog = useDialog();

  const [searchParams, setSearchParams] = useSearchParams();

  //   handle show annocument in mobile view
  useEffect(() => {
    if (isMobile) {
      if (Boolean(searchParams.get('showAnnoucement')) === true) {
        navigate({
          pathname: '/mobile-search',
          search: createSearchParams({
            showAnnoucement: 'true',
          }).toString(),
        });

        return;
      } else {
        navigate('/mobile-search');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMobile]);

  useEffect(() => {
    if (Boolean(searchParams.get('showAnnoucement')) === true && !isMobile) {
      annoucementDialog.show();
      searchParams.delete('showAnnoucement');
      setSearchParams(searchParams);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  const offerBannerQuery = useQuery(['offerBanner'], async () => {
    const { data } = await http.get(
      `${process.env.REACT_APP_API_URL}/api/v1/sub-agent/sub-agency/banners`,
    );

    return data;
  });

  const statsQuery = useQuery(['stats'], async () => {
    const { data } = await http.get(
      `${process.env.REACT_APP_API_URL}/api/v1/sub-agent/sub-agency/stats`,
    );

    return data;
  });

  const handleClass = (length: number, index: number): string => {
    return length === index + 1 && index % 2 === 0
      ? 'col-span-2'
      : 'col-span-1';
  };

  if (statsQuery.isLoading) return <PageLoader />;

  const statsData = statsQuery.data;

  return (
    <>
      {/* Hero section */}
      <section className={styles['hero']}>
        <div className='container pt-10 pb-20'>
          <div className='grid grid-cols-3 gap-6'>
            <div className='col-span-3 lg:col-span-2'>
              <h4 className='text-4xl font-bold text-secondary-fg '>
                Search flights
              </h4>

              <h5 className='text-2xl font-light text-secondary-fg/60 mt-2'>
                {user.agency.tagline}
              </h5>

              <FlightSearchForm />
            </div>

            <div className='col-span-3 lg:col-span-1'>
              <div className='flex flex-col items-end gap-6 mt-5 pt-4'>
                <AsideCard
                  title='MONTH SALE'
                  value={formatCurrency(statsData.sale.currentMonth)}
                />

                <AsideCard
                  title='TOTAL SALES'
                  value={formatCurrency(statsData.sale.total)}
                />

                <AsideCard title='USERS' value={statsData.subAgencyUserCount} />
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Announcements section */}
      {!offerBannerQuery.isLoading && offerBannerQuery.data.length > 0 && (
        <section className='pt-14 pb-20'>
          <div className='container'>
            <h2 className='text-4xl font-bold mb-8'>Offers and Updates</h2>
            <div className='grid grid-cols-2 gap-4 mt-6'>
              {offerBannerQuery.data.map((row: any, index: number) => (
                <div
                  key={row._id}
                  className={handleClass(offerBannerQuery.data.length, index)}
                >
                  <div className='h-full'>
                    <img
                      className='rounded-lg object-cover object-center w-full h-full'
                      src={row.url}
                      alt=''
                    />
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>
      )}

      <div className='border-b border-black/10 mx-8'></div>

      {annoucementDialog.isOpen && (
        <AnnouncementModal onClose={annoucementDialog.hide} />
      )}
    </>
  );
};

export default Home;
