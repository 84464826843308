import {
  flip,
  offset,
  useClick,
  Placement,
  useDismiss,
  autoUpdate,
  useFloating,
  useInteractions,
} from '@floating-ui/react';
import Picker from './Picker';
import Cleave from 'cleave.js/react';
import dayjs, { Dayjs } from 'dayjs';
import { FC, useEffect, useState } from 'react';
import customParseFormat from 'dayjs/plugin/customParseFormat';

// value
// onChange
// format
// excludeDates
// includeDates
// minDate
// maxDate
// highlightDates

interface DatePickerProps {
  value?: Date;
  onChange?: (date: Date) => void;
  placement?: Placement;
  className?: string;
  format?: string;
  minDate?: Date;
  maxDate?: Date;
  placeholder?: string;
}
const DatePicker: FC<DatePickerProps> = (props) => {
  dayjs.extend(customParseFormat);
  const delimiter = ' / ';

  const {
    value: controlledValue,
    onChange,
    placement,
    className,
    format,
    minDate,
    maxDate,
    placeholder,
  } = props;

  const [unControlledValue, setUnControlledValue] = useState(dayjs());

  const value =
    controlledValue !== undefined
      ? controlledValue
        ? dayjs(controlledValue)
        : null
      : unControlledValue;
  const setValue =
    controlledValue !== undefined ? undefined : setUnControlledValue;

  const [open, setOpen] = useState<boolean>(false);
  const [inputLabel, setInputLabel] = useState('');

  useEffect(() => {
    if (controlledValue) setInputLabel(value.format(format));
  }, [controlledValue]);

  const { x, y, strategy, refs, context } = useFloating({
    whileElementsMounted: autoUpdate,
    placement: placement,
    middleware: [flip(), offset(8)],
    open: open,
    onOpenChange: setOpen,
  });

  const click = useClick(context);
  const dismiss = useDismiss(context);
  const { getReferenceProps, getFloatingProps } = useInteractions([
    click,
    dismiss,
  ]);

  const handleChange = (date: Dayjs) => {
    setValue?.(date);
    onChange?.(date.toDate());
    setOpen(false);
    setInputLabel(date.format('DD / MM / YYYY'));
  };

  const handleBlur = () => {
    if (value) setInputLabel(value.format(format));
  };

  const handleTextDateChanged = (value: string) => {
    setInputLabel(value);
    const sanitizedValue = value.split(delimiter).join('').trim();
    if (sanitizedValue.length === 8) {
      let date = dayjs(sanitizedValue, 'DDMMYYYY');
      handleChange(date);
    }
  };

  return (
    <>
      {/* <div
                ref={refs.setReference}
                className={`${className}  ${value && value.isValid() ? '' : 'text-gray-400'}    `}
                {...getReferenceProps()}
            >
                {value && value.isValid() ? value.format(format) : placeholder}
            </div> */}
      <div className='w-full' ref={refs.setReference} {...getReferenceProps()}>
        <Cleave
          type='text'
          options={{
            date: true,
            delimiter: delimiter,
            datePattern: ['d', 'm', 'Y'],
            dateMin: minDate ? dayjs(minDate).format('YYYY-MM-DD') : null,
            dateMax: maxDate ? dayjs(maxDate).format('YYYY-MM-DD') : null,
          }}
          onChange={(e) => handleTextDateChanged(e.target.value)}
          placeholder='DD / MM / YYYY'
          value={inputLabel}
          // className={`outline-none w-full  ${className}  ${value && value.isValid() ? '' : 'text-gray-400'}`}
          className={`outline-none w-full  ${className}  ${value && value.isValid() ? '' : ''}`}
          onBlur={handleBlur}
        />
      </div>
      {open && (
        <div
          ref={refs.setFloating}
          style={{
            position: strategy,
            top: y ?? 0,
            left: x ?? 0,
            zIndex: '12',
          }}
          {...getFloatingProps()}
        >
          <Picker
            value={value}
            onChange={handleChange}
            minDate={minDate ? dayjs(minDate) : null}
            maxDate={maxDate ? dayjs(maxDate) : null}
          />
        </div>
      )}
    </>
  );
};

DatePicker.defaultProps = {
  placement: 'bottom-start',
  format: 'DD MMM, YYYY',
  placeholder: 'Select from calendar',
};

export default DatePicker;
