// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Home_hero__iYg73 {\n  background-image: conic-gradient(from 198.8deg at 70.5% 39.79%, rgba(var(--secondary)) -359.86deg, rgba(var(--secondary)/0.5) 0.01deg, rgba(var(--secondary)) 0.14deg, rgba(var(--secondary)/0.5) 360.01deg);\n}", "",{"version":3,"sources":["webpack://./src/app/pages/Home/Home.module.scss"],"names":[],"mappings":"AAAA;EACE,4MAAA;AACF","sourcesContent":[".hero {\n  background-image: conic-gradient(\n    from 198.8deg at 70.5% 39.79%,\n    rgba(var(--secondary)) -359.86deg,\n    rgba(var(--secondary) / 0.5) 0.01deg,\n    rgba(var(--secondary)) 0.14deg,\n    rgba(var(--secondary) / 0.5) 360.01deg\n  );\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"hero": "Home_hero__iYg73"
};
export default ___CSS_LOADER_EXPORT___;
