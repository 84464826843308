const Icon = (props: any) => {
  return (
    <svg
      width={50}
      height={32}
      viewBox='0 0 50 32'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <g clipPath='url(#clip0_164_2578)'>
        <rect
          x={0.5}
          y={0.5}
          width={49}
          height={31}
          rx={2.5}
          fill='#fff'
          stroke='#E7EAEE'
        />
        <mask
          id='a'
          style={{
            maskType: 'alpha',
          }}
          maskUnits='userSpaceOnUse'
          x={0}
          y={0}
          width={50}
          height={32}
        >
          <rect
            x={0.5}
            y={0.5}
            width={49}
            height={31}
            rx={2.5}
            fill='#fff'
            stroke='#D2D2D6'
          />
        </mask>
        <g mask='url(#a)'>
          <path
            d='M14.453 32H48.36A1.65 1.65 0 0050 30.36V18.874c-3.242 2.07-15.508 9.18-35.547 13.125z'
            fill='#F58220'
          />
        </g>
        <path
          d='M7.722 12.973H6v6.013h1.713c.91 0 1.568-.215 2.145-.695a3.017 3.017 0 001.092-2.308c0-1.774-1.326-3.01-3.228-3.01zm1.37 4.517c-.369.333-.847.478-1.605.478h-.315V13.99h.315c.758 0 1.218.136 1.605.487.406.36.65.92.65 1.496 0 .578-.244 1.155-.65 1.516zM12.663 12.973H11.49v6.013h1.173v-6.014zM15.53 15.28c-.703-.26-.91-.432-.91-.757 0-.379.368-.666.874-.666.351 0 .64.144.946.487l.613-.804a2.63 2.63 0 00-1.767-.667c-1.064 0-1.875.739-1.875 1.723 0 .829.378 1.253 1.48 1.65.459.161.692.269.81.341a.72.72 0 01.352.623c0 .487-.388.848-.91.848-.56 0-1.01-.28-1.28-.802l-.758.73c.54.793 1.189 1.144 2.081 1.144 1.22 0 2.074-.81 2.074-1.974 0-.955-.395-1.388-1.73-1.876zM17.63 15.983c0 1.768 1.388 3.138 3.174 3.138.505 0 .937-.1 1.47-.35v-1.38c-.469.469-.884.658-1.416.658-1.18 0-2.02-.857-2.02-2.074 0-1.155.866-2.066 1.966-2.066.56 0 .983.2 1.47.677v-1.38c-.514-.261-.938-.37-1.442-.37-1.778 0-3.202 1.399-3.202 3.147zM31.574 17.012l-1.603-4.04h-1.282l2.552 6.168h.632l2.597-6.168H33.2l-1.625 4.04zM35 18.986h3.327v-1.018h-2.154v-1.623h2.075v-1.019h-2.075v-1.335h2.154v-1.018H35v6.013zM42.971 14.748c0-1.126-.775-1.775-2.128-1.775h-1.739v6.013h1.172V16.57h.152l1.624 2.416h1.442l-1.892-2.534c.883-.18 1.37-.784 1.37-1.704zm-2.353.993h-.342V13.92h.36c.731 0 1.128.306 1.128.89 0 .605-.397.93-1.146.93z'
          fill='#231F20'
        />
        <path
          d='M25.857 19.199a3.199 3.199 0 100-6.398 3.199 3.199 0 000 6.398z'
          fill='url(#paint0_linear_164_2578)'
        />
        <path
          opacity={0.65}
          d='M25.857 19.199a3.199 3.199 0 100-6.398 3.199 3.199 0 000 6.398z'
          fill='url(#paint1_linear_164_2578)'
        />
        <path
          d='M43.72 13.198c0-.105-.073-.163-.2-.163h-.17v.529h.126v-.205l.148.205h.154l-.174-.218c.075-.02.116-.074.116-.148zm-.222.073h-.022v-.139h.023c.062 0 .095.023.095.069 0 .046-.033.07-.096.07z'
          fill='#231F20'
        />
        <path
          d='M43.545 12.838a.458.458 0 00-.46.46c0 .257.206.462.46.462a.46.46 0 00.455-.461.46.46 0 00-.455-.46zm-.002.84a.372.372 0 01-.367-.379c0-.21.163-.378.367-.378.201 0 .365.172.365.379a.372.372 0 01-.365.377z'
          fill='#231F20'
        />
      </g>
      <defs>
        <linearGradient
          id='paint0_linear_164_2578'
          x1={27.4939}
          y1={18.5636}
          x2={25.2791}
          y2={15.0958}
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#F89F21' />
          <stop offset={0.2502} stopColor='#F79A23' />
          <stop offset={0.5331} stopColor='#F78E22' />
          <stop offset={0.6196} stopColor='#F68721' />
          <stop offset={0.7232} stopColor='#F48220' />
          <stop offset={1} stopColor='#F27623' />
        </linearGradient>
        <linearGradient
          id='paint1_linear_164_2578'
          x1={27.1441}
          y1={18.5165}
          x2={23.907}
          y2={12.1894}
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#F68721' stopOpacity={0} />
          <stop offset={0.3587} stopColor='#E27027' stopOpacity={0.2704} />
          <stop offset={0.703} stopColor='#D4612C' stopOpacity={0.5299} />
          <stop offset={0.9816} stopColor='#D15D2D' stopOpacity={0.74} />
        </linearGradient>
        <clipPath id='clip0_164_2578'>
          <path fill='#fff' d='M0 0H50V32H0z' />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Icon;
