const Icon = (props: any) => (
  <svg
    width={19}
    height={18}
    viewBox='0 0 19 18'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M2.835 12.157h15.223c.097 0 .195 0 .291-.005a.705.705 0 00.446-.211.73.73 0 00-.344-1.213 1.342 1.342 0 00-.288-.029c-5.677-.004-11.354-.006-17.03-.008a.725.725 0 00-.425.12.746.746 0 00-.28.345c-.12.319-.042.591.193.83 1.864 1.895 3.728 3.792 5.59 5.689.06.067.127.128.198.183a.68.68 0 00.76.048c.253-.137.37-.366.369-.655 0-.238-.12-.413-.279-.575a4642.928 4642.928 0 01-4.208-4.288c-.057-.059-.112-.12-.216-.23zm13.32-6.396l-.042.08H.953c-.098 0-.195 0-.291.004a.703.703 0 00-.45.205.728.728 0 00.33 1.218c.124.024.25.035.377.031 5.645.004 11.29.007 16.937.009.336 0 .604-.175.72-.474.114-.29.041-.585-.2-.83L12.852.382a2.696 2.696 0 00-.244-.234.703.703 0 00-.92.05.73.73 0 00-.1.935c.058.079.122.153.192.22 1.392 1.42 2.785 2.839 4.178 4.256.062.055.127.105.196.15v.002z'
      fill='currentColor'
    />
  </svg>
);

export default Icon;
