/* eslint-disable jsx-a11y/anchor-is-valid */
import { Dispatch, FC, SetStateAction } from 'react';

interface BookingNavProps {
  activeTab: string;
  setActiveTab: Dispatch<SetStateAction<string>>;
}

const BookingNav: FC<BookingNavProps> = (props) => {
  const { activeTab, setActiveTab } = props;

  return (
    <ul className='flex text-base font-normal  mx-10 justify-between sm:justify-start gap-4 '>
      <li className='tab-item'>
        <a
          className={` nav-tab  ${
            activeTab === '1'
              ? 'font-bold cursor-default text-primary underline underline-offset-4'
              : ''
          }`}
          onClick={() => setActiveTab('1')}
        >
          Confirmed
        </a>
      </li>
      <li className='tab-item'>
        <a
          className={`nav-tab ${
            activeTab === '5'
              ? 'font-bold cursor-default text-primary underline underline-offset-4'
              : ''
          }`}
          onClick={() => setActiveTab('5')}
        >
          Processing
        </a>
      </li>
      <li className='tab-item'>
        <a
          className={`nav-tab ${
            activeTab === '2'
              ? 'font-bold cursor-default text-primary underline underline-offset-4'
              : ''
          }`}
          onClick={() => setActiveTab('2')}
        >
          Pending
        </a>
      </li>
      <li className='tab-item'>
        <a
          className={`nav-tab ${
            activeTab === '3'
              ? 'font-bold cursor-default text-primary underline underline-offset-4'
              : ''
          }`}
          onClick={() => setActiveTab('3')}
        >
          Cancelled
        </a>
      </li>
      <li className='tab-item'>
        <a
          className={`nav-tab ${
            activeTab === '4'
              ? 'font-bold cursor-default text-primary underline underline-offset-4'
              : ''
          }`}
          onClick={() => setActiveTab('4')}
        >
          Failed
        </a>
      </li>
    </ul>
  );
};

export default BookingNav;
