import { FC, useMemo } from 'react';
import Lottie from 'lottie-react';
import { colorify } from 'lottie-colorify';
import { rgbToHex } from 'app/utils/color-utils';

interface LottieLoaderProps {
  loop?: boolean;
  autoplay?: boolean;
  animationData: any;
}

const LottieLoader: FC<LottieLoaderProps> = (props) => {
  const { loop, autoplay, animationData } = props;

  const txAnimationData = useMemo(() => {
    const color = rgbToHex(
      document.documentElement.style.getPropertyValue('--primary'),
    );

    return colorify([color, '#ffffff', color], animationData);
  }, [animationData]);

  return (
    <Lottie animationData={txAnimationData} autoPlay={autoplay} loop={loop} />
  );
};

LottieLoader.defaultProps = {
  loop: true,
  autoplay: true,
};

export default LottieLoader;
