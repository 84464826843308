const TickIcon = (props: any) => (
  <svg
    width='20px'
    height='20px'
    viewBox='0 0 20 20'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M16.333 3.677a1.172 1.172 0 011.762 1.537l-.105.12L7.573 15.75c-.42.419-1.078.454-1.537.104l-.12-.104-3.906-3.907a1.172 1.172 0 011.537-1.762l.12.105 3.078 3.077 9.588-9.587z'
      transform='translate(-1267 -391) translate(1267 391)'
      fill='currentColor'
      fillRule='nonzero'
      stroke='none'
      strokeWidth={1}
    />
  </svg>
);

export default TickIcon;
