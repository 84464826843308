import React, { FC } from 'react';
import { useQuery } from '@tanstack/react-query';
import Modal from 'app/components/Modal/Modal';
import http from 'app/config/http';
import Spinner from 'app/components/Spinner/Spinner';

interface PaymentNotesProps {
  show: boolean;
  onClose: () => void;
  onSubmit: () => void;
  transactionId: string;
  type: string;
}

const PaymentNotes: FC<PaymentNotesProps> = (props) => {
  const { show, onClose, transactionId, type } = props;

  const paymentNote = useQuery(
    ['paymentNote', transactionId],
    async () => {
      const { data } = await http.get(
        `${process.env.REACT_APP_API_URL}/api/v1/sub-agent/payment-notes/${transactionId}`,
      );

      return data;
    },
    {
      enabled: show,
    },
  );

  return (
    <Modal show={show} onClose={onClose}>
      <div className='text-2xl font-bold'>{type} Note </div>

      {paymentNote.isLoading ? (
        <div className='flex justify-center items-center'>
          <Spinner />
        </div>
      ) : (
        <div>
          <div className='grid grid-cols-3 gap-8 mt-7 mb-6'>
            <div className='col-span-2'>
              <div className='text-xs font-normal text-gray-500'>Remark</div>
              <div className='text-lg font-semibold mt-4'>
                {paymentNote.data.remark}
              </div>
            </div>
            <div className='col-span-1'>
              <div className='text-xs font-normal text-gray-500'>Amount</div>
              <div className='text-lg font-semibold mt-4 '>
                {paymentNote.data.amount}
              </div>
            </div>
          </div>
        </div>
      )}
    </Modal>
  );
};

export default PaymentNotes;
