import { FC } from 'react';

interface BgOverlayProps {
  bgClass?: string;
}

const BgOverlay: FC<BgOverlayProps> = (props) => {
  const { bgClass } = props;
  return <div className={`${bgClass} w-full h-[32vh] absolute -z-10`}></div>;
};

BgOverlay.defaultProps = { bgClass: 'bg-secondary' };

export default BgOverlay;
