import { FC, useEffect, useState } from 'react';
import { IFlight } from 'app/types';
import FlightListItem from '../FlightListItem/FlightListItem';

interface FlightListProps {
  list: IFlight[];
}

const FlightList: FC<FlightListProps> = (props) => {
  const { list } = props;

  // temporary solution needs to be worked out ::partail work on "no-flight-fix" branch
  const [show, setShow] = useState(false);
  useEffect(() => {
    const timer = setTimeout(() => {
      setShow(true);
    }, 200);
    return () => {
      clearTimeout(timer);
    };
  }, []);

  if (!show) return null;

  if (list.length === 0)
    return <p className='mt-12 text-center text-lg'>No flights found</p>;

  return (
    <div className='mt-6'>
      {list.map((row, i) => (
        <FlightListItem key={i} index={i} {...row} />
      ))}
    </div>
  );
};

export default FlightList;
