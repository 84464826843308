import {
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
} from 'chart.js';
import moment from 'moment';
import { FC, useMemo } from 'react';

import { Line } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
);

const options: any = {
  responsive: true,
  cubicInterpolationMode: 'monotone',
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      backgroundColor: '#fff',
      titleColor: '#18181B',
      titleAlign: 'center',
      bodyColor: '#71717A',
      bodyAlign: 'center',
      borderWidth: 1,
      borderColor: '#E4E4E7',
      padding: 12,
      callbacks: {
        title: function (ctx: any) {
          return moment(ctx[0].label, 'YYYYMMDD').format('DD MMM YYYY');
        },
        label: function (context: any) {
          const value = context.raw;
          return `  ₹ ${value}`;
        },
      },
    },
  },
  scales: {
    x: {
      grid: {
        display: false,
        borderColor: 'transparent',
      },
      ticks: {
        callback: function (value: any, index: number, ticks: any) {
          const val = (this as any).getLabelForValue(value);

          return moment(val, 'YYYYMMDD').format('DD MMM YY');
        },
      },
    },
    y: {
      grid: {
        color: '#F4F4F5',
        borderColor: 'transparent',
      },
      ticks: {
        display: false,
        // stepSize: 20,
        // autoSkip: false,
        callback: function (value: any, index: number, ticks: any) {
          return `₹ ${value}`;
        },
      },
      beginAtZero: true,
    },
  },

  tension: 0.3,
};

interface SalesChartProps {
  data: any[];
}

const SalesChart: FC<SalesChartProps> = (props) => {
  const { data: inputData } = props;

  const data = useMemo(() => {
    return {
      labels: inputData.map((r) => r.date),
      datasets: [
        {
          label: 'Sale',
          data: inputData.map((r) => r.sale),
          borderColor: '#0052CC',
          backgroundColor: '#0052CC',
        },
        // {
        //     label: 'Selling Price',
        //     data: inputData.map((r) => r.sellingPrice),
        //     borderColor: '#2F80ED',
        //     backgroundColor: '#2F80ED',
        // },
      ],
    };
  }, [inputData]);

  return <Line options={options} data={data} />;
};

export default SalesChart;
