import { FC } from 'react';
import Modal from '../Modal/Modal';
import WarningStatusLottie from 'assets/lotties/warning-status.json';
import LottieLoader from '../LottieLoader/LottieLoader';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { SeperatorDot } from 'app/icons';
import { formatMinutesToTime } from 'app/utils/date-utils';
import { calculateStops, getFormattedStops } from 'app/utils/flight-utils';

interface DuplicateBookingModalProps {
  show: boolean;
  onClose: () => void;
  data: any;
  onConfirm: any;
}

const DuplicateBookingModal: FC<DuplicateBookingModalProps> = (props) => {
  const { data } = props;
  const navigate = useNavigate();

  const getStatusText = (status: string) => {
    switch (status) {
      case 'PENDING':
        return 'Pending';
      case 'AWAITING_PNR':
        return 'Awaiting PNR';
      case 'SUBMISSION_PENDING':
      case 'COMPLETED':
        return 'Completed';
      case 'CANCELLED':
        return 'Cancelled';
    }
  };

  const getStatusClass = (status: string) => {
    switch (status) {
      case 'PENDING':
      case 'AWAITING_PNR':
        return 'yellow';
      case 'SUBMISSION_PENDING':
      case 'COMPLETED':
        return 'green';
      case 'CANCELLED':
        return 'red';
    }
  };

  const getBgClass = (color: string) => {
    switch (color) {
      case 'green':
        return 'bg-green-100';
      case 'red':
        return 'bg-red-100';
      case 'yellow':
        return 'bg-yellow-100';
    }
  };

  const getTextClass = (color: string) => {
    switch (color) {
      case 'green':
        return 'text-green-900';
      case 'red':
        return 'text-red-900';
      case 'yellow':
        return 'text-yellow-900';
    }
  };

  return (
    <Modal
      className='px-12 py-14  !w-[600px] '
      show={props.show}
      onClose={props.onClose}
    >
      <div className='flex justify-center'>
        <div className='w-36'>
          <LottieLoader animationData={WarningStatusLottie} loop={false} />
        </div>
      </div>

      <div className='text-2xl leading-normal font-bold  text-center '>
        {' '}
        Detected as duplicate booking{' '}
      </div>
      <div className='mt-10  font-normal '>
        <div className='text-sm font-normal leading-normal'>
          A booking with similar passenger name, date and sector has been
          detected by the system. Following booking has been flagged as
          duplicate
        </div>
      </div>

      <div className='text-sm font-semibold mt-6'>
        Matching :{' '}
        <span
          className='text-[#0067FF] cursor-pointer '
          onClick={() => navigate(`/bookings/${data._id}`)}
        >
          {data._id}
        </span>
      </div>

      <ul className='mt-5  text-sm font-semibold '>
        {[
          ...data.passengers.adults,
          ...data.passengers.children,
          ...data.passengers.infants,
        ].map((passenger, index) => (
          <li
            key={index}
          >{`${passenger.title} ${passenger.firstName} ${passenger.lastName}`}</li>
        ))}
      </ul>

      <div className='mt-6'>
        <div className='flex flex-col gap-4  sm:flex-row sm;justify-between'>
          <div className='flex  items-center gap-2 min-w-max'>
            <img
              crossOrigin='anonymous'
              className='w-9 h-9'
              src={data.segments[0].flight.logo}
              alt=''
            />
            <div>
              <div className='text-base font-semibold'>
                {data.segments[0].flight.name}
              </div>
              <div className='text-xs text-black/70 mt-[2px]'>{`${data.segments[0].flight.code} ${data.segments[0].flight.number}`}</div>
            </div>
          </div>

          <div className='flex flex-col'>
            <div className='text-sm leading-normal font-light'>
              <span className='font-semibold'>
                {data.segments[0].departure.airport.city}
              </span>{' '}
              to{' '}
              <span className='font-semibold'>
                {' '}
                {data.segments[0].arrival.airport.city}
              </span>
              <span
                className={`px-[6px] py-[3px]  rounded-full text-[8px] font-semibold 
                                
                                ${getTextClass(getStatusClass(data.status))}

                                ml-3 ${getBgClass(getStatusClass(data.status))} `}
              >
                {getStatusText(data.status)}
              </span>
            </div>
            <div className='flex items-center gap-2 text-xs leading-normal font-normal'>
              <div className=''>
                {moment(
                  `${data.segments[0].departure.date + data.segments[0].departure.time}`,
                  'YYYYMMDDhhmmss',
                ).format('dddd, MMM  D')}
              </div>
              <SeperatorDot />
              <div>
                <span className='mr-2'>
                  {getFormattedStops(calculateStops(data.segments))}
                </span>
                <span>{formatMinutesToTime(+data.duration)}</span>
              </div>
            </div>
          </div>

          <div>
            <div className='text-xs text-black/70 mt-[2px]'>Airline PNR</div>
            <div className='text-base font-semibold'>{'-'}</div>
          </div>
        </div>
      </div>

      <div className='mt-8 flex justify-center'>
        <button
          onClick={props.onConfirm}
          className='text-base font-semibold text-white px-4 py-3 bg-[#0052CC] rounded-md'
        >
          Proceed anyway
        </button>
      </div>
    </Modal>
  );
};

export default DuplicateBookingModal;
