// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@page {\n  size: A4;\n  margin: 0;\n}\n@media print {\n  .BookingInvoice_page-wrapper__DQ2T7 {\n    margin: 0px;\n    margin-left: auto;\n    margin-right: auto;\n    width: 100%;\n    border-style: none;\n  }\n  .BookingInvoice_print-btn-wrapper__gti7Q {\n    display: none;\n  }\n}", "",{"version":3,"sources":["webpack://./src/app/pages/Account/components/BookingInvoiceDialog/BookingInvoice.module.scss"],"names":[],"mappings":"AAGA;EACE,QAAA;EACA,SAAA;AAFF;AAKA;EAEI;IAAA,WAAA;IAAA,iBAAA;IAAA,kBAAA;IAAA,WAAA;IAAA;EAAA;EAGF;IACE,aAAA;EAJF;AACF","sourcesContent":[".page-wrapper {\n}\n\n@page {\n  size: A4;\n  margin: 0;\n}\n\n@media print {\n  .page-wrapper {\n    @apply w-full border-none mx-auto m-0;\n  }\n\n  .print-btn-wrapper {\n    display: none;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"page-wrapper": "BookingInvoice_page-wrapper__DQ2T7",
	"print-btn-wrapper": "BookingInvoice_print-btn-wrapper__gti7Q"
};
export default ___CSS_LOADER_EXPORT___;
