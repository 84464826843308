import http from 'app/config/http';
import isEqual from 'lodash.isequal';
import { useFormikContext } from 'formik';
import Autosuggest from 'react-autosuggest';
import { useMutation } from '@tanstack/react-query';
import { IBookingForm, ITraveller } from '../../types';
import { PassengerEnum } from 'app/enums/passenger-enum';
import React, { FC, useEffect, useRef, useState } from 'react';
import debounce from 'lodash/debounce';

function getSuggestionValue(suggestion: ITraveller): string {
  return suggestion.firstName;
}

function renderSuggestion(suggestion: ITraveller): JSX.Element {
  return <span>{suggestion.firstName}</span>;
}

interface IPassengerSearchTwo {
  passengerType: 'adults' | 'children' | 'infants';
  index: number;
}

const PassengerSearchTwo: FC<IPassengerSearchTwo> = (props) => {
  const { index, passengerType } = props;

  const inputRef = useRef<HTMLInputElement | null>(null);
  const { setFieldValue, values } = useFormikContext<IBookingForm>();

  const [value, setValue] = useState<string>('');
  const [suggestions, setSuggestions] = useState<ITraveller[]>([]);

  const getPassengerTypeForSearch = (
    paxType: 'adults' | 'children' | 'infants',
  ): PassengerEnum => {
    if (paxType === 'adults') return PassengerEnum.ADULT;
    else if (paxType === 'children') return PassengerEnum.CHILD;
    else if (paxType === 'infants') return PassengerEnum.INFANT;
  };

  const fetchSuggestionMutation = useMutation(async (value: string) => {
    const { data } = await http.get(
      `${process.env.REACT_APP_API_URL}/api/v1/sub-agent/customers/search?q=${value}&paxType=${getPassengerTypeForSearch(passengerType)}`,
    );
    return data.customers as ITraveller[];
  });
  const loadSuggestions = useRef(
    debounce(async (value: string) => {
      const data = await fetchSuggestionMutation.mutateAsync(value);
      setSuggestions(data);
    }, 200),
  ).current;

  const onChange = (
    event: React.FormEvent<HTMLElement>,
    { newValue }: Autosuggest.ChangeEvent,
  ): void => {
    setValue(newValue);
  };

  const onSuggestionsFetchRequested = ({
    value,
  }: Autosuggest.SuggestionsFetchRequestedParams): void => {
    loadSuggestions(value);
  };

  const onSuggestionsClearRequested = (): void => {
    setSuggestions([]);
  };

  const inputProps: Autosuggest.InputProps<ITraveller> = {
    placeholder: 'First Name',
    value,
    onChange,
    className: 'form-control',
  };

  const hanldeSuggestionHighlighted = (data: any) => {
    const passenger = data.suggestion;

    if (!passenger) return;

    // Remove unnecessary fields from passenger object
    const sanitizedPassenger = { ...passenger };
    delete sanitizedPassenger.agency;
    delete sanitizedPassenger.createdAt;
    delete sanitizedPassenger.paxType;
    delete sanitizedPassenger.subAgency;
    delete sanitizedPassenger.subAgencyUser;
    delete sanitizedPassenger.createdAt;
    delete sanitizedPassenger.updatedAt;
    delete sanitizedPassenger._id;

    // add Required fields
    sanitizedPassenger.isInternational =
      values[passengerType][index].isInternational;
    sanitizedPassenger.isInfant = values[passengerType][index].isInfant;
    sanitizedPassenger.savePassengerDetails =
      values[passengerType][index].savePassengerDetails;

    // Only set the field value if it's different from the current value
    if (!isEqual(values[passengerType][index], sanitizedPassenger)) {
      setFieldValue(`${passengerType}[${index}]`, sanitizedPassenger);
    }
  };

  useEffect(() => {
    const setValueInFirstNameInput = () => {
      setFieldValue(
        `${passengerType}[${index}].firstName`,
        inputRef.current.value,
      );
    };
    inputRef.current.addEventListener('blur', setValueInFirstNameInput);
    return () => {
      inputRef.current.removeEventListener('blur', setValueInFirstNameInput);
    };
  }, []);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.value = values[passengerType][index].firstName;
    }
  }, [inputRef.current]);

  return (
    <Autosuggest
      suggestions={suggestions}
      onSuggestionsFetchRequested={onSuggestionsFetchRequested}
      onSuggestionsClearRequested={onSuggestionsClearRequested}
      getSuggestionValue={getSuggestionValue}
      renderSuggestion={renderSuggestion}
      inputProps={inputProps}
      onSuggestionHighlighted={(val) => hanldeSuggestionHighlighted(val)}
      ref={(autosuggest: Autosuggest<ITraveller> | null) => {
        if (autosuggest !== null) {
          inputRef.current = autosuggest.input;
        }
      }}
    />
  );
};

export default PassengerSearchTwo;
