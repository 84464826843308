const Icon = (props: any) => (
  <svg
    width={19}
    height={13}
    viewBox='0 0 19 13'
    fill='currentColor'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path d='M7.389 13h4.222v-2.167H7.39V13zM0 0v2.167h19V0H0zm3.167 7.583h12.666V5.417H3.167v2.166z' />
  </svg>
);

export default Icon;
