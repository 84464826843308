import http from 'app/config/http';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import Spinner from 'app/components/Spinner/Spinner';
import { toast } from 'react-toastify';

enum subAgencyDocumentEnum {
  AGENCY_REGISTRATION = 'Agency Registration',
  GST_REGISTRATION = 'GST Registration',
  PAN_CARD = 'PAN Card',
  PERSONAL_KYC = 'Personal KYC',
}

interface ISubAgencyDocument {
  id: string;
  name: subAgencyDocumentEnum;
  path: string;
  url: string;
  _id: string;
}

const SubAgencyDocuments = () => {
  const documentQueryIdentifier = ['subagency', 'documents'];
  const queryClient = useQueryClient();

  const { isLoading, data } = useQuery(documentQueryIdentifier, async () => {
    const { data } = await http.get(
      `${process.env.REACT_APP_API_URL}/api/v1/sub-agent/sub-agency/documents`,
    );
    return data as ISubAgencyDocument[];
  });

  const uploadDocumentMutation = useMutation(async (payload: any) => {
    const { data } = await http.put(
      `${process.env.REACT_APP_API_URL}/api/v1/sub-agent/sub-agency/documents`,
      payload,
    );

    return data as ISubAgencyDocument[];
  });

  const handleFileUpload = async (files: File[], name: string) => {
    if (files && files.length > 0) {
      const formData = new FormData();
      formData.append('name', name);
      formData.append('file', files[0]);

      try {
        const data = await uploadDocumentMutation.mutateAsync(formData);
        queryClient.setQueryData(documentQueryIdentifier, (prev: any) => {
          return data;
        });
        toast.success('Document Uploaded Successfully');
      } catch (err: any) {
        const errorMsg = err.data?.message
          ? err.data.message
          : 'Some error occured while updating the image, please try again.';
        toast.error(errorMsg);
      }
    }
  };

  const RenderOption = (props: { documentName: subAgencyDocumentEnum }) => {
    let isDocumentAvailable: boolean | ISubAgencyDocument = false;
    data.forEach((uploadedDocument) => {
      if (uploadedDocument.name === props.documentName) {
        isDocumentAvailable = uploadedDocument;
      }
    });

    if (isDocumentAvailable === false)
      return (
        <div className='text-blue-500 cursor-pointer relative'>
          <label className='cursor-pointer' htmlFor={props.documentName}>
            Upload
          </label>
          <input
            type='file'
            id={props.documentName}
            className='absolute w-full h-full cursor-pointer hidden '
            onChange={(e: any) =>
              handleFileUpload(e.target.files, props.documentName)
            }
          />
        </div>
      );

    return (
      <div
        className='text-blue-500 cursor-pointer'
        onClick={() =>
          window.open(
            typeof isDocumentAvailable !== 'boolean'
              ? isDocumentAvailable.url
              : '',
          )
        }
      >
        View
      </div>
    );
  };

  if (isLoading)
    return (
      <div className='mt-8'>
        <div className='text-lg font-semibold'>Documents</div>
        <div className='flex justify-center items-center mt-4'>
          <Spinner />
        </div>
      </div>
    );

  return (
    <div className='mt-8'>
      <div className='flex items-center justify-between'>
        <div className='text-lg font-semibold'>Documents</div>
        {uploadDocumentMutation.isLoading && <Spinner size={20} />}
      </div>
      <div className='flex flex-col'>
        {Object.values(subAgencyDocumentEnum).map((documentName) => (
          <div key={documentName} className='mt-2 flex'>
            <div>{documentName}</div>
            <div className='ml-auto'>
              <RenderOption documentName={documentName} />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SubAgencyDocuments;
