const Icon = (props: any) => {
  return (
    <svg
      width={18}
      height={4}
      viewBox='0 0 18 4'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M2 2h.01M9 2h.01M16 2h.01M3 2a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0z'
        stroke='#A1A1AA'
        strokeWidth={2}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default Icon;
