import { FC, useEffect, useState } from 'react';
import Modal from '../Modal/Modal';
import WarningStatusLottie from 'assets/lotties/warning-status.json';
import LottieLoader from '../LottieLoader/LottieLoader';
import { TRIPJACK_ERROR_INDICATOR } from 'app/config';

interface BookingErrorModalProps {
  show: boolean;
  onClose: () => void;
  id: string;
  errorMessage: string;
}

const BookingErrorModal: FC<BookingErrorModalProps> = (props) => {
  const { errorMessage } = props;
  const [message, setMessage] = useState('');

  useEffect(() => {
    if (errorMessage.includes(TRIPJACK_ERROR_INDICATOR)) {
      setMessage(() => errorMessage.split(TRIPJACK_ERROR_INDICATOR)[0] || '');
    }
  }, [errorMessage]);

  return (
    <Modal
      className='px-12 py-14  !w-auto '
      show={props.show}
      onClose={props.onClose}
    >
      <div className='flex justify-center'>
        <div className='w-36'>
          <LottieLoader animationData={WarningStatusLottie} loop={false} />
        </div>
      </div>

      <div className='text-2xl leading-normal font-bold  text-center '>
        {' '}
        Oops! Something went wrong.{' '}
      </div>
      <div className='mt-10  font-normal '>
        <div className='text-sm font-normal leading-normal'>
          Something went wrong while confirming this booking -{' '}
          <span className='text-[#0067FF] font-bold'>{props.id}</span>
        </div>
        <div className='text-[#0067FF] mt-6 font-semibold leading-[141%]'>
          {' '}
          Reasons for this error
        </div>
        <div className='mt-6'>
          <ol>
            {message === '' ? (
              <>
                <li>1. Seat(s) are no longer available.</li>
                <li>2. The fare for this booking has changed</li>
                <li>3. Airline is taking longer than usual to confirm</li>
              </>
            ) : (
              <li>1. {message}</li>
            )}
          </ol>
        </div>
        {true && (
          <>
            <div className='mt-6 text-red-500'>
              Please note, this booking may have been confirmed. Please check
              the <br />
              updated status for this booking under Pending Bookings
            </div>
            <div className='mt-8 flex justify-center'>
              <a
                href='/bookings?tab=2'
                target='_blank'
                className='text-base font-semibold text-white px-4 py-3 bg-[#0052CC] rounded-md'
              >
                Check pending bookings
              </a>
            </div>
          </>
        )}
      </div>
    </Modal>
  );
};

export default BookingErrorModal;
