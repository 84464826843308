import React, { FC } from 'react';
import * as Yup from 'yup';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import indiaFlag from 'assets/icons/in-flag.svg';
import http from 'app/config/http';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { TRootState } from 'app/store';
import { authActions } from 'app/store/auth';

const AgencyFormSchema = Yup.object().shape({
  name: Yup.string().trim().required('This field is required'),
  phoneNumber: Yup.string()
    .trim()
    .required('This field is required')
    .matches(/^\d+$/, 'Enter a valid mobile number'),
  email: Yup.string()
    .trim()
    .required('This field is required')
    .email('Please enter a valid email'),
  website: Yup.string().trim(),
  gst: Yup.object().shape({
    number: Yup.string().trim(),
  }),
  pan: Yup.object().shape({
    number: Yup.string().trim().required('This field is required'),
  }),
});

interface EditAgencyFormProps {
  initialValues: any;
}

const EditAgencyForm: FC<EditAgencyFormProps> = (props) => {
  const { initialValues: queryData } = props;

  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const auth = useSelector((state: TRootState) => state.auth);

  const subAgencyUpdateMutation = useMutation(async (payload: any) => {
    const { data } = await http.put(
      `${process.env.REACT_APP_API_URL}/api/v1/sub-agent/sub-agency`,
      payload,
    );
    return data;
  });

  const agenctEditFormInitialValues = {
    name: queryData.name,
    phoneNumber: queryData.phoneNumber,
    email: queryData.email,
    website: '',
    gst: {
      number: queryData.gst.number,
    },
    pan: {
      number: queryData.pan.number,
    },
  };

  const onSubmit = async (values: any) => {
    try {
      delete values.website;
      const res = await subAgencyUpdateMutation.mutateAsync({
        ...values,
      });

      queryClient.setQueryData(['subAgency'], () => {
        return {
          ...res,
        };
      });

      dispatch(
        authActions.setUser({
          ...auth.user,
          subAgency: {
            ...auth.user.subAgency,
            name: values.name,
          },
        }),
      );
      toast.success('Agency updated successfully');
    } catch (ex: any) {
      toast.error(ex?.data?.message || 'Some error occured, please try again.');
      console.log(ex);
    }
  };

  const disableForm = true;

  return (
    <Formik
      initialValues={agenctEditFormInitialValues}
      validationSchema={AgencyFormSchema}
      enableReinitialize
      onSubmit={onSubmit}
    >
      <Form>
        <div className='mt-5'>
          <label className='text-xs mb-2 block' htmlFor='name'>
            Agency Name
          </label>
          <Field
            name='name'
            type='text'
            id='name'
            className='form-control md:w-3/4'
            disabled={disableForm}
          />
          <div className='form-error'>
            <ErrorMessage name='name' />
          </div>
        </div>

        <div className='mt-5'>
          <label className='text-xs mb-2 block' htmlFor='ContactNum'>
            Contact Number
          </label>

          <div className='flex items-center relative'>
            <img className='absolute h-4 pl-3' src={indiaFlag} alt='' />
            <Field
              type='text'
              id='ContactNum'
              name='phoneNumber'
              placeholder='Mobile Number'
              className='form-control pl-10 md:w-3/4'
              disabled={disableForm}
            />
          </div>

          <div className='form-error'>
            <ErrorMessage name='phoneNumber' />
          </div>
        </div>

        <div className='mt-5'>
          <label className='text-xs mb-2 block' htmlFor='Email'>
            Email Address
          </label>
          <Field
            name='email'
            type='email'
            id='Email'
            className='form-control md:w-3/4'
            disabled={disableForm}
          />
          <div className='form-error'>
            <ErrorMessage name='email' />
          </div>
        </div>

        <div className='mt-5'>
          <label className='text-xs mb-2 block' htmlFor='Website'>
            Website
          </label>
          <Field
            name='website'
            type='text'
            id='Website'
            className='form-control md:w-3/4'
            disabled={disableForm}
          />
          <div className='form-error'>
            <ErrorMessage name='website' />
          </div>
        </div>

        <div className='mt-5'>
          <label className='text-xs mb-2 block' htmlFor='Gstin'>
            GSTIN
          </label>
          <Field
            name='gst.number'
            type='text'
            id='Gstin'
            className='form-control md:w-3/4'
            disabled={disableForm}
          />
          <div className='form-error'>
            <ErrorMessage name='gst.number' />
          </div>
        </div>

        <div className='mt-5'>
          <label className='text-xs mb-2 block' htmlFor='pan'>
            PAN
          </label>
          <Field
            name='pan.number'
            type='text'
            id='pan'
            className='form-control md:w-3/4'
            disabled={disableForm}
          />
          <div className='form-error'>
            <ErrorMessage name='pan.number' />
          </div>
        </div>

        {!disableForm && (
          <button
            type='submit'
            className='btn btn-primary mt-8'
            disabled={disableForm}
          >
            Save
          </button>
        )}
      </Form>
    </Formik>
  );
};

export default EditAgencyForm;
