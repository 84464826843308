/* eslint-disable jsx-a11y/anchor-is-valid */
import { CrossIcon } from 'app/icons';
import { Dispatch, FC, SetStateAction } from 'react';

interface AmendmentNavProps {
  activeTab: number;
  setActiveTab: Dispatch<SetStateAction<number>>;
  requestIds: string[];
  closeTab: any;
}

const AmendmentNav: FC<AmendmentNavProps> = (props) => {
  const { activeTab, setActiveTab, requestIds, closeTab } = props;

  return (
    <ul className='flex text-base font-normal justify-between gap-1 sm:justify-start  mx-6 '>
      {/* <li
                className={`tab-item pb-2 px-2  ${
                    activeTab === 1 ? 'border-b-2 border-blue-500 cursor-default' : 'cursor-pointer'
                }`}
            >
                <a
                    className={`text-sm leading-4 font-medium ${activeTab === 1 ? 'font-bold' : ''} `}
                    onClick={() => setActiveTab(1)}
                >
                    New Change Request
                </a>
            </li> */}
      <li
        className={`tab-item pb-2 px-2  ${
          activeTab === 2
            ? 'border-b-2 border-blue-500 cursor-default'
            : 'cursor-pointer'
        }`}
      >
        <a
          className={`text-sm leading-4 font-medium ${activeTab === 2 ? 'font-bold' : ''} `}
          onClick={() => setActiveTab(2)}
        >
          Previous Requests
        </a>
      </li>
      {requestIds.map((tab, index) => {
        return (
          <li
            key={tab}
            className={`tab-item pb-2 px-2  flex items-center gap-2 ${
              activeTab === 3 + index
                ? 'border-b-2 border-blue-500 cursor-default'
                : 'cursor-pointer'
            }`}
          >
            <a
              className={`text-sm leading-4 font-medium ${activeTab === 3 + index ? 'font-bold' : ''} `}
              onClick={() => setActiveTab(3 + index)}
            >
              {tab}
            </a>

            <div
              onClick={() => closeTab(index)}
              className='bg-slate-100 hover:cursor-pointer hover:bg-slate-200 p-1 rounded-full'
            >
              <CrossIcon className='h-3 w-3' />
            </div>
          </li>
        );
      })}
    </ul>
  );
};

export default AmendmentNav;
