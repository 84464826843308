import moment from 'moment';
import { FC, useMemo } from 'react';
import contryList from 'country-list';
import { CalendarIcon } from 'app/icons';
import { DOB_MANDATORY } from 'app/config';
import { DatePicker } from 'app/library/datepicker';
import { IBookingForm, ITraveller } from '../../types';
import PassengerSearchTwo from '../PassengerSearchTwo/PassengerSearchTwo';
import { titles } from 'app/modules/shared/passenger-title';
import { ErrorMessage, Field, useFormikContext } from 'formik';

interface PassengerDetailProps {
  passengerType: 'adults' | 'children' | 'infants';
  index: number;
  pessenger: ITraveller;
  tripDate: string;
}

const PassengerDetail: FC<PassengerDetailProps> = (props) => {
  const { passengerType, index, pessenger, tripDate } = props;
  const nations = useMemo(() => contryList.getNameList(), []);

  const { setFieldValue, values } = useFormikContext<IBookingForm>();

  return (
    <>
      {/* <div className='mt-4 text-xs'>Traveller gender and name</div> */}
      <div className='mt-2'>
        <div className='grid grid-cols-1 gap-2 sm:grid-cols-12 sm:gap-4'>
          <div className=' sm:col-span-2'>
            <label
              htmlFor={`${passengerType}[${index}].title`}
              className='text-xs text-slate-500'
            >
              Title
            </label>
            <Field
              as='select'
              name={`${passengerType}[${index}].title`}
              placeholder='Mr'
              className='form-control appearance-none'
            >
              {titles[passengerType].map((title: string) => (
                <option key={title} value={title}>
                  {title}
                </option>
              ))}
            </Field>
            <div className='form-error'>
              <ErrorMessage name={`${passengerType}[${index}].title`} />
            </div>
          </div>
          <div
            className={`${
              pessenger.isInternational || pessenger.isInfant || DOB_MANDATORY
                ? 'sm:col-span-3'
                : 'sm:col-span-5'
            } `}
          >
            {/* <Field
              type='text'
              name={`${passengerType}[${index}].firstName`}
              placeholder='First name'
              className='form-control'
            /> */}

            {/* <PassengerSearch passengerType={passengerType} index={index} /> */}
            <label
              htmlFor={`${passengerType}[${index}].firstName`}
              className='text-xs text-slate-500'
            >
              First Name
            </label>
            <PassengerSearchTwo passengerType={passengerType} index={index} />

            <div className='form-error'>
              <ErrorMessage name={`${passengerType}[${index}].firstName`} />
            </div>
          </div>
          <div
            className={`${
              pessenger.isInternational || pessenger.isInfant || DOB_MANDATORY
                ? 'sm:col-span-3'
                : 'sm:col-span-5'
            } `}
          >
            <label
              htmlFor={`${passengerType}[${index}].lastName`}
              className='text-xs text-slate-500'
            >
              Last Name
            </label>
            <Field
              type='text'
              name={`${passengerType}[${index}].lastName`}
              placeholder='Last name'
              className='form-control'
            />
            <div className='form-error'>
              <ErrorMessage name={`${passengerType}[${index}].lastName`} />
            </div>
          </div>
          {(pessenger.isInfant ||
            pessenger.isInternational ||
            DOB_MANDATORY) && (
            <div className='sm:col-span-4'>
              <label
                htmlFor={`${passengerType}[${index}].dob`}
                className='text-xs text-slate-500'
              >
                Date Of Birth
              </label>
              <div className='relative flex items-center'>
                <div className='absolute left-4 z-10'>
                  <CalendarIcon />
                </div>
                <DatePicker
                  format='DD MM YYYY'
                  className='form-control pl-12 cursor-pointer  z-20!'
                  value={
                    values[passengerType][index].dob === ''
                      ? null
                      : moment(
                          values[passengerType][index].dob,
                          'YYYYMMDD',
                        ).toDate()
                  }
                  onChange={(val) => {
                    setFieldValue(
                      `${passengerType}[${index}].dob`,
                      moment(val).format('YYYYMMDD'),
                    );
                  }}
                  minDate={
                    pessenger.isInfant
                      ? moment(tripDate, 'YYYYMMDD')
                          .subtract(2, 'years')
                          .toDate()
                      : passengerType === 'children'
                        ? moment(tripDate, 'YYYYMMDD')
                            .subtract(12, 'years')
                            .toDate()
                        : moment(tripDate, 'YYYYMMDD')
                            .subtract(120, 'years')
                            .toDate()
                  }
                  maxDate={
                    passengerType === 'infants'
                      ? moment().toDate()
                      : passengerType === 'children'
                        ? moment(tripDate, 'YYYYMMDD')
                            .subtract(2, 'years')
                            .toDate()
                        : passengerType === 'adults'
                          ? moment(tripDate, 'YYYYMMDD')
                              .subtract(12, 'years')
                              .toDate()
                          : moment().toDate()
                  }
                  placeholder='Date of Birth'
                />
              </div>
              <div className='form-error'>
                <ErrorMessage name={`${passengerType}[${index}].dob`} />
              </div>
            </div>
          )}
        </div>
      </div>
      {pessenger.isInternational && (
        <>
          {/* <div className='mt-4 text-xs'>Passport Details</div> */}
          <div className='mt-2'>
            <div className='grid grid-cols-1 gap-2 sm:grid-cols-12 sm:gap-4'>
              {/* nationality */}
              <div className=' sm:col-span-3'>
                <label
                  htmlFor={`${passengerType}[${index}].nationality`}
                  className='text-xs text-slate-500'
                >
                  Nationality
                </label>

                <Field
                  as='select'
                  name={`${passengerType}[${index}].passport.nationality`}
                  placeholder='Mr'
                  className='form-control appearance-none'
                >
                  {Object.keys(nations).map((val) => (
                    <option key={val} value={nations[val]}>
                      {val.charAt(0).toUpperCase() + val.slice(1)}
                    </option>
                  ))}
                </Field>
                <div className='form-error'>
                  <ErrorMessage
                    name={`${passengerType}[${index}].passport.nationality`}
                  />
                </div>
              </div>
              {/* number */}
              <div className=' sm:col-span-3'>
                <label
                  htmlFor={`${passengerType}[${index}].passport.number`}
                  className='text-xs text-slate-500'
                >
                  Passport Number
                </label>
                <Field
                  type='text'
                  name={`${passengerType}[${index}].passport.number`}
                  placeholder='Passport Number'
                  className='form-control'
                />
                <div className='form-error'>
                  <ErrorMessage
                    name={`${passengerType}[${index}].passport.number`}
                  />
                </div>
              </div>
              {/* issueDate */}
              <div className=' sm:col-span-3'>
                <label
                  htmlFor={`${passengerType}[${index}].passport.issueDate`}
                  className='text-xs text-slate-500'
                >
                  Passport Issue Date
                </label>
                <div className='relative flex items-center'>
                  <div className='absolute left-4 z-10'>
                    <CalendarIcon />
                  </div>
                  <DatePicker
                    format='DD MM YYYY'
                    className='form-control pl-12 cursor-pointer  z-20!'
                    value={
                      values[passengerType][index].passport.issueDate === ''
                        ? null
                        : moment(
                            values[passengerType][index].passport.issueDate,
                            'YYYYMMDD',
                          ).toDate()
                    }
                    onChange={(val) => {
                      setFieldValue(
                        `${passengerType}[${index}].passport.issueDate`,
                        moment(val).format('YYYYMMDD'),
                      );
                    }}
                    placeholder='Issue Date'
                    // maxDate={moment().toDate()}
                  />
                </div>
                <div className='form-error'>
                  <ErrorMessage
                    name={`${passengerType}[${index}].passport.issueDate`}
                  />
                </div>
              </div>
              {/* expiryData */}
              <div className=' sm:col-span-3'>
                <label
                  htmlFor={`${passengerType}[${index}].passport.expiry`}
                  className='text-xs text-slate-500'
                >
                  Passport Expiry Date
                </label>
                <div className='relative flex items-center'>
                  <div className='absolute left-4 z-10'>
                    <CalendarIcon />
                  </div>

                  <DatePicker
                    format='DD MM YYYY'
                    className='form-control pl-12 cursor-pointer  z-20!'
                    value={
                      values[passengerType][index].passport.expiry === ''
                        ? null
                        : moment(
                            values[passengerType][index].passport.expiry,
                            'YYYYMMDD',
                          ).toDate()
                    }
                    onChange={(val) => {
                      setFieldValue(
                        `${passengerType}[${index}].passport.expiry`,
                        moment(val).format('YYYYMMDD'),
                      );
                    }}
                    placeholder='Passport Expiry'
                    // minDate={moment().toDate()}
                  />
                </div>
                <div className='form-error'>
                  <ErrorMessage
                    name={`${passengerType}[${index}].passport.expiry`}
                  />
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {true && (
        <div className='flex items-center'>
          <input
            className='form-checkbox'
            type='checkbox'
            id='saveCustomer'
            checked={values[passengerType][index].savePassengerDetails}
            onChange={(e) =>
              setFieldValue(
                `${passengerType}.[${index}].savePassengerDetails`,
                e.target.checked,
              )
            }
          />
          <label className='ml-2 text-xs' htmlFor='saveCustomer'>
            Save Passenger Details
          </label>
        </div>
      )}
    </>
  );
};

export default PassengerDetail;