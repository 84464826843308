import moment from 'moment';
import http from 'app/config/http';
import { IBooking } from 'app/types';
import { BackIcon } from 'app/icons';
import { useParams } from 'react-router-dom';
import useDialog from 'app/hooks/use-dialog';
import { FC, useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import Amendment from './components/Amendment/Amendment';
import BgOverlay from 'app/components/BgOverlay/BgOverlay';
import SideDrawer from 'app/components/SideDrawer/SideDrawer';
import PageLoader from 'app/components/PageLoader/PageLoader';
import { createSearchParams, useNavigate } from 'react-router-dom';
import PrintOptionModal from './components/PrintOptionModal/PrintOptionModal';
import CancelRequestDialog from './components/CancelRequestDialog/CancelRequestDialog';
import FlightDetails from 'app/modules/booking/components/FlightDetails/FlightDetails';
import PassengerDetails from 'app/modules/booking/components/PassengerDetails/PassengerDetails';
import BookedTicketPriceDetails from 'app/components/BookedTicketPriceDetails/BookedTicketPriceDetails';

interface BookingDetailProps {}

const BookingDetail: FC<BookingDetailProps> = (props) => {
  const { bookingId: id } = useParams();
  const navigate = useNavigate();
  const printDialog = useDialog();
  const amendmentDialog = useDialog();
  const cancellationDialog = useDialog();

  const [bgColor, setBgColor] = useState<string>('bg-secondary');
  const [txtColor, setTxtColor] = useState<string>('text-secondary-fg');

  const bookingQuery = useQuery<IBooking>(['booking', id], async () => {
    const { data } = await http.get(
      `${process.env.REACT_APP_API_URL}/api/v1/sub-agent/bookings/${id}`,
    );

    return data;
  });

  const booking = bookingQuery.data;

  useEffect(() => {
    if (!bookingQuery.data) return;

    const currentTime = moment().format('YYYYMMDDhhmmss');

    let bgColor = '';
    let txtColor = '';

    if (booking.status === 'PENDING') {
      bgColor = 'bg-blue-500';
      txtColor = 'text-white';
    } else if (booking.status === 'CANCELLED' || booking.status === 'FAILED') {
      bgColor = 'bg-danger';
      txtColor = 'text-white';
    } else if (
      `${booking.departure.date}${booking.departure.time}` >= currentTime &&
      booking.status === 'COMPLETED'
    ) {
      bgColor = 'bg-secondary';
      txtColor = 'text-secondary-fg';
    } else if ('COMPLETED') {
      bgColor = 'bg-success';
      txtColor = 'text-white';
    }

    setBgColor(bgColor);
    setTxtColor(txtColor);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bookingQuery.data]);

  const handlePrintTicket = (values: any) => {
    printDialog.hide();

    navigate({
      pathname: `/bookings/${booking._id}/ticket`,
      search: createSearchParams({
        ...values,
      }).toString(),
    });
  };

  if (bookingQuery.isLoading) return <PageLoader />;

  const isCancelledOrPending =
    booking.status === 'CANCELLED' || booking.status === 'PENDING';

  const isInChangeWindow = true; // logic has to be added at agency level  and this flag will be fo
  // const isInChangeWindow =`${booking.departure.date}${booking.departure.time}` >= moment().add(2, 'hours').format('YYYYMMDDhhmmss');

  return (
    <div>
      <BgOverlay bgClass={bgColor} />

      <div className='container min-h-full'>
        <div className='flex flex-col gap-4 sm:flex-row  sm:justify-between sm:items-center py-8'>
          <div className={`title ${txtColor} flex items-center gap-2`}>
            <span className='md:hidden' onClick={() => navigate(-1)}>
              <BackIcon />
            </span>
            Booking details
          </div>

          <div className={`text-sm ${txtColor}`}>
            Booking ID :{' '}
            <span className='font-semibold mx-1'>{booking._id}</span> | Booked
            On :{' '}
            <span className='font-semibold mx-1'>
              {moment(booking.updatedAt).format('DD MMM, YYYY')}
            </span>{' '}
            at{' '}
            <span className='font-semibold mx-1'>
              {moment(booking.updatedAt).format('h:mm:ss a')}
            </span>
          </div>
        </div>

        <div className='grid grid-cols-3 gap-9 '>
          <div className='col-span-3 lg:col-span-2'>
            <FlightDetails booking={booking} />
            <PassengerDetails booking={booking} />
          </div>
          {/* side cards */}
          <div className=' col-span-3 lg:col-span-1'>
            {/* ticked Share , print , donwload */}
            <div className='card p-4 mb-4 '>
              <div className='text-sm font-semibold'>Ticket(s)</div>
              <button
                onClick={printDialog.show}
                className={`mt-4 block text-sm text-blue-500   ${
                  isCancelledOrPending ? '' : 'cursor-pointer'
                } `}
                disabled={isCancelledOrPending || booking.status === 'FAILED'}
              >
                Print Ticket
              </button>
            </div>

            {/* Ammendment */}
            <div className='card p-4 mb-4 '>
              <div className='text-sm font-semibold'>Change Ticket</div>

              {/* <button
                                onClick={amendmentDialog.show}
                                className={`mt-4 block text-sm text-blue-500   ${isCancelledOrPending ? '' : 'cursor-pointer'} `}
                                disabled={isCancelledOrPending}
                            >
                                Request Change
                            </button> */}

              <button
                onClick={amendmentDialog.show}
                className={`mt-4 block text-sm text-blue-500   ${
                  isCancelledOrPending ? '' : 'cursor-pointer'
                } `}
                disabled={isCancelledOrPending || booking.status === 'FAILED'}
              >
                Request Status
              </button>

              <button
                onClick={cancellationDialog.show}
                className={`mt-4 block text-sm text-red-500   ${
                  isCancelledOrPending ? '' : 'cursor-pointer'
                } `}
                disabled={
                  isCancelledOrPending ||
                  !isInChangeWindow ||
                  booking.status === 'FAILED'
                }
              >
                Request Full Cancellation
              </button>
            </div>

            {booking.status !== 'FAILED' && (
              <>
                <BookedTicketPriceDetails
                  booking={booking}
                  isCancelledOrPending={isCancelledOrPending}
                />
              </>
            )}
          </div>
        </div>
      </div>
      <PrintOptionModal
        show={!isCancelledOrPending && printDialog.isOpen}
        onClose={printDialog.hide}
        onSubmit={(value: any) => handlePrintTicket(value)}
      />

      <SideDrawer
        show={amendmentDialog.isOpen}
        onClose={amendmentDialog.hide}
        className='w-[850px] px-0'
      >
        <Amendment
          id={booking._id}
          passengers={[
            ...booking.passengers.adults,
            ...booking.passengers.children,
            ...booking.passengers.infants,
          ]}
          onClose={amendmentDialog.hide}
        />
      </SideDrawer>
      <CancelRequestDialog
        id={booking._id}
        passengers={[
          ...booking.passengers.adults,
          ...booking.passengers.children,
          ...booking.passengers.infants,
        ]}
        show={cancellationDialog.isOpen}
        onClose={cancellationDialog.hide}
      />
    </div>
  );
};

export default BookingDetail;