import { useState } from 'react';
import { Wallet } from 'app/icons';
import { TRootState } from 'app/store';
import { useDispatch, useSelector } from 'react-redux';
import { getInitials } from 'app/utils/common-utils';
import { NavLink, useNavigate } from 'react-router-dom';
import { formatCurrency } from 'app/utils/currency-utils';
import { authActions } from 'app/store/auth';

const MobileTopBar = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [showOptions, setShowOptions] = useState(false);
  const { user } = useSelector((state: TRootState) => state.auth);
  const { creditLimit, availableCashBalance, availableCredit } = useSelector(
    (state: TRootState) => state.account,
  );

  let availableBalance = availableCredit + availableCashBalance;
  let amountDue = creditLimit - availableCredit - availableCashBalance;

  if (availableBalance < 0) {
    availableBalance = 0;
  }

  if (amountDue < 0) {
    amountDue = 0;
  }

  return (
    <div className='flex md:hidden justify-between items-center px-6 py-3'>
      <div>
        <NavLink to={'/'}>
          <img src={user.agency.logoUrl} className='h-6' alt='' />
        </NavLink>
      </div>
      <div className='flex items-center justify-between gap-3'>
        <div
          onClick={() => navigate('/my-agency/account')}
          className='flex items-center gap-2 border rounded-full  text-[#52525B]   border-[#D9D9D9] border-primary  py-1 px-2 '
        >
          <Wallet />
          <div className='text-xs leading-5 font-semibold  whitespace-nowrap'>
            {formatCurrency(availableBalance)}
          </div>
        </div>
        <div
          onClick={() => setShowOptions((isOpen) => !isOpen)}
          className={
            'w-[30px] h-[30px] bg-primary/10 rounded-full flex items-center justify-center'
          }
        >
          <div className='text-primary text-xs font-bold'>
            {getInitials('Majid Shah')}
          </div>
        </div>
      </div>

      {showOptions && (
        <div
          className={
            'z-[109] fixed  top-0 left-0  bottom-0 w-full bg-white border-b border-white/10 '
          }
        >
          <div className='flex md:hidden justify-between items-center px-6 py-3'>
            <div>
              <NavLink to={'/'}>
                <img src={user.agency.logoUrl} className='h-6' alt='' />
              </NavLink>
            </div>
            <div className='flex items-center justify-between gap-3'>
              <div
                onClick={() => navigate('/my-agency/account')}
                className='flex items-center gap-2 border rounded-full  text-[#52525B]   border-primary  py-1 px-2 '
              >
                <Wallet />
                <div className='text-xs leading-5 font-semibold  whitespace-nowrap'>
                  {formatCurrency(availableBalance)}
                </div>
              </div>
              <div
                onClick={() => setShowOptions((isOpen) => !isOpen)}
                className={
                  'w-[30px] h-[30px] bg-primary/10 rounded-full flex items-center justify-center'
                }
              >
                <div className='text-primary text-xs font-bold'>
                  {getInitials('Majid Shah')}
                </div>
              </div>
            </div>
          </div>
          <div className='container border-t'>
            <div className='flex flex-col items-start gap-4 pt-8 pb-10'>
              <NavLink
                onClick={(e) => {
                  e.preventDefault();
                  setShowOptions(false);
                  navigate('/profile');
                }}
                to='/profile'
                className={({ isActive }) =>
                  `text-sm  text-black  ${isActive ? 'font-semibold' : 'font-normal'} `
                }
              >
                Profile
              </NavLink>

              <NavLink
                onClick={(e) => {
                  e.preventDefault();
                  setShowOptions(false);
                  dispatch(authActions.logout());
                }}
                to=''
                className={({ isActive }) =>
                  `text-sm   ${isActive ? 'font-semibold' : 'font-normal'} `
                }
              >
                <span className='pr-28  text-red-500 '>Logout</span>
              </NavLink>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default MobileTopBar;
